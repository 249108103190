import { OfficeButton } from "components";
import { FC } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";



const CompletedJoinPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();


  return (
    <div className="mx-auto w-full flex-1 justify-center items-center flex-col max-w-lg lg:w-[1200px] ">
      <div className="flex flex-col justify-center items-center flex-1  h-full">
        <div className="flex items-center flex-col">
          <div className="w-full justify-center py-4 flex align-middle flex-shrink-0">
            <img
              className="h-72 w-96 rounded-full"
              src={require("assets/complete_join.png")}
              alt="Duke Supplier's Dashboard"
            />
          </div>
          <div className="">
            <span className="text-lg text-primary-600 font-mono">
              Duke Reseller's Network
            </span>
          </div>
        </div>



        <div className="space-y-6 text-center">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Congratulations , {search?.email}
          </h2>
          <p>
            {search?.message}
          </p>

          <OfficeButton width="full" onClick={() => navigate({
            to: "/",
            search(prev) {
              return {
                ...prev,
                email: search?.email,
              }
            }
          })}>
            Continue
          </OfficeButton>
        </div>
      </div>

    </div >
  );
};

export default CompletedJoinPage;
