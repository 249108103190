import { useMutation, useReactiveVar } from "@apollo/client";
import { usePlugins } from "../settings/hooks/use-plugins";
import { OfficeHeader, toast } from "components";
import { addPluginToUser, removePluginFromUser } from "_graphql/mutation/integration";
import { AddPluginToUser, AddPluginToUserVariables } from "_graphql/mutation/__generated__/AddPluginToUser";
import { currentUserVar } from "_graphql/cache/auth";
import { RemovePluginFromUser, RemovePluginFromUserVariables } from "_graphql/mutation/__generated__/RemovePluginFromUser";
import clsx from "clsx";
import Loader from "components/loader/loader";

function AppIntegration() {
  const currentUser = useReactiveVar(currentUserVar);
  const { plugins, loading } = usePlugins({
    variables: {
      pagination: {
        limit: 10,
        offset: 0
      },
      supplierId: currentUser?.id
    },
  });

  const [addPlugin, { loading: isLoading }] = useMutation<AddPluginToUser, AddPluginToUserVariables>(addPluginToUser, {
    refetchQueries: ['GetPlugins', 'GetUserPlugins', 'GetSupplier']
  });
  const [removePlugin, { loading: removeLoad }] = useMutation<RemovePluginFromUser, RemovePluginFromUserVariables>(removePluginFromUser, {
    refetchQueries: ['GetPlugins']
  });

  function submitAddPlugin(pluginId: string) {
    addPlugin({
      variables: {
        data: {
          plugin_id: pluginId,
          user_id: currentUser?.id
        }
      }
    }).then((res) => {
      if (res.data?.addPluginToUser) {
        toast('Successfully installed', 'success')
        window.location.reload()
        // navigate({
        //   to: '/shopify'
        // })
      }
    }).catch((err) => {
      toast(err?.message, 'error')
    })
  }
  function submitRemovePlugin(pluginId: string) {
    removePlugin({
      variables: {
        filter: {
          plugin_id: {
            eq: pluginId
          },
          user_id: {
            eq: currentUser?.id
          }
        }
      }
    }).then((res) => {
      if (res.data?.removePluginFromUser) {
        toast('Successfully uninstalled', 'success')
      }
    }).catch((err) => {
      toast(err?.message, 'error')
    })
  }

  const installedPlugins = plugins.filter((item) => item?.is_installed_by_current_user)

  if (loading) return <Loader />

  return (
    <div className="divide-y divide-white/5">
      <OfficeHeader />
      <div className='px-10 max-w-5xl mx-auto'>
        {installedPlugins.length >= 1 && <>
          <div className="relative w-fit font-body-lg-medium py-4 text-black">
            <h2 className="text-base font-semibold leading-7 text-gray-700">Installed Apps</h2>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 items-start gap-4">
            {installedPlugins.map((card) => (
              <div key={card?.id} className=" py-2 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 flex  flex-col items-start">
                <div className="flex items-center">
                  <img className=" w-full h-10 " alt="shopify" src={card?.logo} />
                  <div className="flex flex-col">
                    <p className=" font-semibold text-gray-700 dark:text-gray-400">{card?.name}</p>
                    <span onClick={() => submitRemovePlugin(card?.id as string)} className={clsx("text-xs underline", removeLoad ? "cursor-wait" : "cursor-pointer")}>Uninstall</span>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </>}
        {plugins.length >= 1 && <>
          <div className="relative w-fit font-body-lg-medium py-4 text-black">
            <h2 className="text-base font-semibold leading-7 text-gray-700">Available Apps</h2>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 items-start gap-4">
            {plugins.map((card) => (
              <div key={card?.id} className=" p-6 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                <div className="flex items-center">
                  <img className="object-cover w-46 h-16 pb-4" alt="shopify" src={card?.logo} />
                  <span className="font-semibold text-lg">{card?.name}</span>
                </div>
                <p className="mb-3 font-normal text-center text-gray-700 dark:text-gray-400 text-sm">{card?.description}</p>
                {!card?.is_installed_by_current_user && <button onClick={() => submitAddPlugin(card?.id as string)} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary-500 rounded-lg hover:bg-primary-800 hover:text-primary-50">
                  {isLoading ? "Installing..." : "Install"}
                </button>}
              </div>
            ))}
          </div>
        </>}

      </div>
      {/* <ConnectShop open={modal === "connect"} setOpen={(val: boolean) => setModal(val ? "connect" : undefined)} /> */}
    </div >

  )
}

export default AppIntegration