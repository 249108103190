import { useQuery } from '@apollo/client';
import {
  GetProductTemplate,
  GetProductTemplateVariables,
} from '_graphql/queries/__generated__/GetProductTemplate';
import { getProductTemplate } from '_graphql/queries/products';

export const useTemplate = (variables?: GetProductTemplateVariables) => {
  const { data, ...rest } = useQuery<
    GetProductTemplate,
    GetProductTemplateVariables
  >(getProductTemplate, {
    variables,
  });
  return {
    template: data?.getProductTemplate ?? [],
    ...rest,
  };
};
