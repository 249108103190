import { gql } from "@apollo/client";

export const get_suppliers = gql`
  query GetSuppliers($filter: SupplierFilter, $pagination: Pagination) {
    getSuppliers(filter: $filter, pagination: $pagination) {
      avatar
      agreement_terms
      business_details {
        business_name
        country
        phone
        supplier_business_verified
        logo
      }
      country
      email
      generic_name
      name
      phone
      shop {
        avatar
        shopTags
      }
      supplier_type
      id
    }
  }
`;

export const getPaymentMethods = gql`
  query GetPaymentMethods {
    getPaymentMethods {
      name
      slug
      code
      country
      currency
      longcode
      gateway
      pay_with_bank
    }
  }
`;

export const resolvePaymentMethod = gql`
  query PaystackResolveAccountNumber(
    $data: PaystackResolveAccountNumberRequestDto!
  ) {
    paystackResolveAccountNumber(data: $data) {
      status
      message
      data {
        account_number
        account_name
      }
    }
  }
`;
