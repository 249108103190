import { FormikProps } from 'formik';
import _ from 'lodash';
import { useDeliveryLocation } from 'pages/create-discount/hooks/use-delivery-locations';
import { FC, useRef, useState } from 'react';
import Select from 'react-select';

type Props = {
  form: FormikProps<any>;
};

const DeliveryLocationInput: FC<Props> = ({ form }) => {
  const {
    deliveryLocation,
    totalCount,
    fetchMore,
    loading: deliveryLoading,
  } = useDeliveryLocation();

  const init = useRef({
    fetchMore,
  });

  const loadMoreDeliveryLocation = async () => {
    const { fetchMore } = init.current;
    await fetchMore({
      variables: {
        filter: {},
        pagination: {
          offset: deliveryLocation.length,
          limit: 10,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = fetchMoreResult.deliveryLocations ?? [];
        const prevData = prev.deliveryLocations ?? [];
        return Object.assign({}, prev, {
          getProductCategories: [...prevData, ...newData],
        });
      },
    });
  };

  const handleDeliveryLocationChange = (e: any) => {
    form.setFieldValue(`delivery`, e ?? [{}]);
  };

  return (
    <div className='w-full'>
      <label
        htmlFor='delivery'
        className='block text-sm font-medium text-gray-700'>
        Delivery Locations
      </label>
      <p className='block text-xs text-gray-500 my-1'>
        Select locations you'd like to apply the free delivery discount.
      </p>
      <Select
        id='delivery'
        isMulti
        isLoading={deliveryLoading}
        className='basic-single text-sm'
        classNamePrefix='select'
        placeholder='Select delivery location'
        noOptionsMessage={() => 'No delivery location available'}
        isSearchable={true}
        options={deliveryLocation?.map((deli) => {
          return {
            value: deli?.id || '',
            label: deli?.location || '',
          };
        })}
        value={form.values.delivery}
        onChange={handleDeliveryLocationChange}
        onMenuScrollToBottom={async () => {
          if (totalCount) {
            if (deliveryLocation.length < totalCount) {
              await loadMoreDeliveryLocation();
            }
          }
        }}
      />
      {_.get(form.errors, 'delivery') && (
        <p className='mt-2 mb-5 text-sm text-red-600 '>
          {_.get(form?.errors as unknown as string[], 'delivery')}
        </p>
      )}
    </div>
  );
};

export default DeliveryLocationInput;
