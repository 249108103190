import { OfficeButton, OfficeHeader } from "components";
import { useMatch } from "react-location";
import { useCart } from "./hooks/use-cart";
import moment from "moment";
import { OrderStatus } from "utils/lib";
import { useMutation } from "@apollo/client";
import { changeSupplierStatus, processOrder } from "_graphql/mutation/order";
import { ProcessOrder, ProcessOrderVariables } from "_graphql/mutation/__generated__/ProcessOrder";
import { useState } from "react";
import toast from "react-hot-toast";
import OrderInvoice from "./order-invoice";
import { useUrlState } from "utils";
import SectionLayout from "pages/create-product/components/SectionLayout";
import { ChangeSupplierStatus, ChangeSupplierStatusVariables } from "_graphql/mutation/__generated__/ChangeSupplierStatus";

const ViewOrders = () => {
    const { params } = useMatch();
    const [modal, setModal] = useUrlState("modal");
    const [status, setStatus] = useState("");
    const { cart } = useCart({
        filter: {
            id: {
                eq: params?.orderId,
            },
        },
    });

    const [process, { loading: loadingStatusUpdate }] = useMutation<ChangeSupplierStatus, ChangeSupplierStatusVariables>(changeSupplierStatus, {
        refetchQueries: ["GetCart"],
    });

    const changeStatus = (id: string) => {
        process({
            variables: {
                supplierStatus: status,
                changeSupplierStatusId: id,
            },
        })
            .then(({ data }) => {
                if (data?.changeSupplierStatus) {
                    toast.success("Order Status Updated");
                }
            })
            .catch((err) => {
                toast.error(err?.message);
            });
    };

    return (
        <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray- mb-10">
            <OfficeHeader />
            <div className="grid lg:grid-cols-5 gap-2 overflow-y-scroll">
                <div className="lg:col-span-3 grid ">
                    {cart?.source !== "supplier-storefront" && (
                        <SectionLayout freeFlow title="Reseller Details">
                            <div className="grid md:grid-cols-3 gap-2">
                                <div className="grid">
                                    <span className="text-gray-500 text-sm">Name</span>
                                    <span className="text-sm">{cart?.reseller?.name ?? "Not Specified"}</span>
                                </div>
                                {/* <div className="grid">
                                    <span className="text-gray-500 text-sm">Phone Number</span>
                                    <span className="text-sm">{cart?.reseller?.phone ?? "Not Specified"}</span>
                                </div> */}
                                <div className="grid">
                                    <span className="text-gray-500 text-sm">Email</span>
                                    <span className="text-sm">{cart?.reseller?.email ?? "Not Specified"}</span>
                                </div>
                            </div>
                        </SectionLayout>
                    )}
                    <SectionLayout freeFlow title="Customer Details">
                        <div className="grid md:grid-cols-3 gap-2">
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Name</span>
                                <span className="text-sm">{cart?.customer?.name ?? "Not Specified"}</span>
                            </div>
                            {/* <div className="grid">
                                <span className="text-gray-500 text-sm">Phone Number</span>
                                <span className="text-sm">{cart?.customer?.phone ?? "Not Specified"}</span>
                            </div> */}
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Email</span>
                                <span className="text-sm">{cart?.customer?.email ?? "Not Specified"}</span>
                            </div>
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Delivery Location</span>
                                <span className="text-sm">{cart?.delivery?.location ?? "Not Specified"}</span>
                            </div>
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Delivery Price</span>
                                <span className="text-sm">{cart?.delivery?.price ?? "Not Specified"}</span>
                            </div>
                        </div>
                    </SectionLayout>
                    <SectionLayout freeFlow title="Payment Details">
                        <div className="grid md:grid-cols-3 gap-2">
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Payment Method</span>
                                <span className="text-sm">{cart?.payment?.network || "Not Specified"}</span>
                            </div>
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Payment Status</span>
                                <span className="text-sm">{cart?.payment_status ?? "Not Specified"}</span>
                            </div>
                            <div className="grid">
                                <span className="text-gray-500 text-sm">Payment Reference</span>
                                <span className="text-sm">{cart?.payment?.reference || "Not Specified"}</span>
                            </div>
                        </div>
                    </SectionLayout>
                    <SectionLayout freeFlow title="Order Items">
                        <div className="grid gap-6 ">
                            {cart?.orderItems?.map((order) => (
                                <div className=" pr-5">
                                    <div className="flex items-center gap-2">
                                        <img src={order?.product?.avatar} className="h-20 w-48 object-contain" alt="" />
                                        <div className="  w-full">
                                            <span className=" font-medium">{order?.product?.title}</span>
                                            <div className="flex justify-between w-full">
                                                <span className="text-sm text-gray-500 font-light">Price</span>
                                                <span className="text-sm">{order?.price}</span>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <span className="text-sm text-gray-500 font-light">Profit added</span>
                                                <span className="text-sm">{order?.profitAdded}</span>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <span className="text-sm text-gray-500 font-light">Total</span>
                                                <span className="text-sm">{Number(order?.profitAdded) + Number(order?.price)}</span>
                                            </div>
                                            <div className="flex justify-between w-full items-center mt-4">
                                                <div className="flex flex-col  justify-center items-start gap-1">
                                                    <span className=" text-gray-700 font-semibold">Status</span>
                                                    <span className=" text-xs text=gray-300 px-2 py-1 bg-slate-100 rounded-full">{order?.status?.toUpperCase()}</span>
                                                </div>
                                                <div className="flex gap-2">
                                                    <select defaultValue={order?.supplier_status?.toString()} onChange={(e) => setStatus(e.target.value)} className="border-gray-400 rounded-md" name="" id="">
                                                        {OrderStatus.map((status) => (
                                                            <option key={status} value={status}>
                                                                {status}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <OfficeButton onClick={() => changeStatus(order?.id as string)} variant="primary">
                                                        {loadingStatusUpdate ? "Saving..." : "Save"}
                                                    </OfficeButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </SectionLayout>
                </div>
                <div className="lg:col-span-2 flex flex-col">
                    <SectionLayout
                        freeFlow
                        title="Order Details"
                        renderActions={() => (
                            <div className="">
                                <OfficeButton size="sm" onClick={() => setModal("view")} variant="tertiary">
                                    <span className="text-sm whitespace-nowrap">View Invoice</span>
                                </OfficeButton>
                            </div>
                        )}>
                        <div className="grid gap-2">
                            <div className=" flex justify-between">
                                <span className="text-sm text-gray-500 font-light">Cart Number</span>
                                <span className="text-sm">{cart?.cart_number}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-500 font-light">Payment Status</span>
                                <span className="text-sm">{cart?.payment_status}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-500 font-light">Tracking Code</span>
                                <span className="text-sm">{cart?.tracking_code}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-500 font-light">Date Placed</span>
                                <span className="text-sm">{moment(cart?.createdAt).format("lll")}</span>
                            </div>
                        </div>
                    </SectionLayout>

                    <SectionLayout freeFlow title="Order Total">
                        <div className="grid gap-2">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-500 font-light">Discount</span>
                                <span className="text-sm">{cart?.discount}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-500 font-light">Total amount</span>
                                <span className="text-sm">{cart?.order_total}</span>
                            </div>
                        </div>
                    </SectionLayout>
                </div>
            </div>
            <OrderInvoice open={modal === "view"} setOpen={(val: boolean) => setModal(val ? "view" : undefined)} order={cart} />
        </main>
    );
};

export default ViewOrders;
