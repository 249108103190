import { Modal, TableComponent, Paginator } from "components";
import {
  GetProducts,
  GetProductsVariables,
} from "_graphql/queries/__generated__/GetProducts";
import { getProducts } from "_graphql/queries/products";
import { useQuery } from "@apollo/client";
import { usePagination } from "hooks";
import { ProductsColumn } from "./Table-column";
import { useProducts } from "pages/products/hooks/use-products";

type Props = {
  selectedProducts: string[];
};
const RequestedProductsModal = ({ selectedProducts }: Props) => {
  const { offset, limit, setPage } = usePagination();
  const { products, loading, refetch } = useProducts({
    variables: {
      getProductsFilter: {
        sku: { in: selectedProducts },
      },
    },
  });
  console.log(products);
  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"Products"}
            columns={ProductsColumn}
            data={products }
            defaultView="list"
         
            renderGridItem={(product) => (
              <div key={product?.id} className="hover:cursor-pointer">
                <div className="flex bg-white shadow-lg p-3 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={
                          product?.avatar ||
                          "https://d3ug0vbiixnxyq.cloudfront.net/utility/qejpc8bkdzgvlfnghc9no-165859213609.jpeg"
                        }
                        alt={product?.title}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium leading-5 text-gray-900">
                        {product?.title}
                      </div>
                      <div className="text-sm leading-5 text-ellipsis text-gray-500">
                        {product?.description?.substring(0, 30)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            refetch={refetch}
            loading={loading}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={products.length}
                currentSize={products.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>
    </main>
  );
};

export default RequestedProductsModal;
