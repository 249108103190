import { gql } from "@apollo/client";
export const getCustomers = gql`
  query GetCustomers($filter: CustomerFilter, $pagination: Pagination) {
    getCustomersLength(filter: $filter)
    getCustomers(filter: $filter, pagination: $pagination) {
      id
      name
      email
      phone
      address {
        area
        city
        country
        landmark
      }
    }
  }
`;

export const getCustomer = gql`
  query GetCustomer($filter: CustomerFilter) {
    getCustomer(filter: $filter) {
      address {
        area
        city
        country
        landmark
      }
      email
      id
      name
      phone
      reseller {
        id
      }
    }
  }
`;
