
import { OfficeButton } from 'components';
import { useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useUrlState } from 'utils';

type Props = {};

const AddProductButton = (props: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const [, setModal] = useUrlState('modal');

  const goToCreateProduct = () => {
    navigate({
      to: `./create-product`,
    });
  };

  return (
    <>
      {' '}
      <OfficeButton variant="primary" onClick={() => setModal('update')}>
        Bulk Update Products
      </OfficeButton>{' '}
      <OfficeButton variant="primary" onClick={() => goToCreateProduct()}>
        Add Product
      </OfficeButton>{' '}
    </>
  );
};

export default AddProductButton;
