import { Avatar,AvatarUpload, OfficeHeader,toast,Loader } from "components";
import { FC } from "react";
import { useShop } from "./hooks/use-shop";
import { useCurrentUser } from "_graphql/cache/auth";
import SectionLayout from "pages/create-product/components/SectionLayout";
import { BuildingStorefrontIcon,PencilIcon ,DocumentDuplicateIcon} from "@heroicons/react/24/outline";
import { ACTIVATE_SHOP } from "_graphql/mutation/shop";
import { useMutation } from "@apollo/client";
import { ActivateShopVariables,ActivateShop } from "_graphql/mutation/__generated__/ActivateShop";
import EditShopContainer from "./edit";
import EditSlugContainer from "./editslug";
import { dispatchAction, useUrlState } from "utils";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";



type Props = {};

const ShopPage: FC<Props> = () => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const currentUser = useCurrentUser();
  const { shop, loading,refetch} = useShop({
    filter: {
     supplierId:{
      eq:currentUser?.id || ''
     }
    },
  });

  const [activate]=useMutation<ActivateShop,ActivateShopVariables>(ACTIVATE_SHOP);

  const copyShopLink = async (link:string) => {
    try {
      await navigator.clipboard.writeText(link);
      toast("Shop link copied Successfully","success");
    } catch (err) {
      toast("Error copying Shop link","error");
    }
  }

  const handleActivation=()=>{
    activate({
       variables:{
      data: {
      supplierId: currentUser?.id || "",
  }
}
    }).then(({data})=>{
  if(data?.activateShop===true){
    
    toast("Shop Activated Successfully","success");
    refetch();
    }else{
      console.error('Activation failed');
    }
  }).catch(error=>{
    console.error('Error during activation:', error);
  });
}

if (loading) {
  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader />
      <Loader/>
    </main>
  );
}
  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader />
      {shop?.isActive ? (<div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light grid grid-cols-1 md:grid-cols-3">
          <div className="lg:col-span-2">
            <SectionLayout loading={loading} freeFlow title={"Shop Details"} 
            renderActions={()=>(
              <div onClick={dispatchAction(shop.id, 'update', navigate)} className="flex cursor-pointer flex-row items-center bg-gray-200 rounded-full py-0.5 px-3 space-x-1">
                <PencilIcon className="w-4 h-4  text-gray-400"/>
                <button
              className=' text-gray-400  text-sm '
              type='button'
              >
              Edit
            </button>
              </div>
              
            )
            } >
              <div className="flex flex-col justify-between">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row space-x-4">
                    <div className="-mt-1 ">
                      <div>
                        <dl className="row-span-2 flex flex-col items-center justify-center">
                          <Avatar
                            size="lg"
                            src={shop?.avatar || ""}
                            alt={shop?.name || ""}
                          />
                          
                        </dl>
                      </div>

                    
                    </div>

                    <div className="flex flex-col space-y-2">
                        <div>
                        <dl className="">
                        <dt className="text-sm font-normal text-gray-400 truncate">
                          Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 font-medium">
                          {shop?.name || "N/A"}
                        </dd>
                      </dl>
                        </div>
                      
                      <div>
                      <dl className="">
                          <dt className="text-sm font-normal text-gray-400 truncate">
                            Contact Phone Number
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 font-medium">
                            {shop?.phone || "N/A"}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <Badge color={shop?.isActive ? "blue" : "red"}>
                      {shop?.isActive ? "Active" : "In Active"}
                    </Badge>
                  </div> */}
                </div>

                <div className="mt-6">
                  <dl className="flex flex-row my-4 w-full justify-between">
                    <dt className="text-sm font-normal text-gray-400 truncate">
                      Contact Email
                    </dt>
                    <dd className="mt-1 text-sm text-gray-800 font-medium">
                      {shop?.contactEmail || "N/A"}
                    </dd>
                  </dl>

                  <dl className="flex flex-row my-4 w-full justify-between">
                    <dt className="text-sm font-normal text-gray-400 truncate">
                      Country
                    </dt>
                    <dd className="mt-1 text-sm text-gray-800 font-medium">
                      {shop?.country || "N/A"}
                    </dd>
                  </dl>
                  <dl className="flex flex-row my-4 w-full justify-between">
                    <dt className="text-sm font-normal text-gray-400 truncate">
                      Currency
                    </dt>
                    <dd className="mt-1 text-sm text-gray-800 font-medium">
                      {shop?.currency || "N/A"}
                    </dd>
                  </dl>
                  <dl className="flex flex-row my-4 w-full justify-between">
                    <dt className="text-sm font-normal text-gray-400 truncate">
                      Description
                    </dt>
                    <dd className="mt-1 text-sm text-gray-800 font-medium">
                      {shop?.description ?? "N/A"}
                    </dd>
                  </dl>
                </div>
              </div>
            </SectionLayout>
          </div>
          <SectionLayout loading={loading} freeFlow  title={"Shop Access"}
          renderActions={()=>(
            <div onClick={dispatchAction(shop.id, 'reassign', navigate)} className="flex cursor-pointer w-[100px] flex-row items-center bg-gray-200 rounded-full py-0.5 px-3 space-x-1">
              <PencilIcon className="w-4 h-4  text-gray-400"/>
              <button
            className=' text-gray-400  text-sm '
            type='button'
            >
            Edit slug
          </button>
            </div>
            
          )
          } 
          >
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <dl>
                <dt className="text-sm font-normal text-gray-400 truncate">
                  URL
                </dt>
                <dd className="mt-1 flex items-center space-x-1 text-sm text-black font-medium">
                  {shop?.primaryDomain ?? "Not Set"}
                  <DocumentDuplicateIcon className="w-4 h-4 mx-1 mt-1 text-gray-500 bg-gray-100 py-0.5 px-0.5" onClick={()=>{copyShopLink(shop?.primaryDomain!)}}/>
                </dd>
                <dd>
                  
                </dd>
              </dl>
              <dl>
                <dt className="text-sm font-normal text-gray-400 truncate">
                  Slug
                </dt>
                <dd className="mt-1 text-sm text-black font-medium">
                  {shop?.slug ?? "Not Set"}
                </dd>
              </dl>
            </div>
          </SectionLayout>
        </div>
      </div>):
      (<div>
        <div className="w-9/12 m-32 h-96 border border-gray-200 space-y-3 rounded-md bg-white  flex flex-col justify-center items-center">
        <div><BuildingStorefrontIcon className="text-primary-500 w-8 h-8"/></div>
        <div className="text-bold">Activate your shop</div>
        <div className="text-white rounded-md px-6 w-48 text-center py-2 bg-primary-500 cursor-pointer" onClick={handleActivation}>Activate</div>
        </div>
      </div>)
      }

{!searchParams?.id?.length && (
        <EditShopContainer
          open={modal === 'update'}
          setOpen={(val: boolean) => setModal(val ? 'update' : undefined)}
        />
      )}
{!searchParams?.id?.length && (
        <EditSlugContainer
          open={modal === 'reassign'}
          setOpen={(val: boolean) => setModal(val ? 'reassign' : undefined)}
        />
      )}

     
    </main>
  );
};
export default ShopPage;
