import { gql } from "@apollo/client";

export const addSupplierPaymentDetails = gql`
mutation AddSupplierPaymentDetails($input: addSupplierPaymentInput) {
  addSupplierPaymentDetails(input: $input)
}`;
export const updateSupplierPaymentDetails = gql`
mutation UpdateSupplierPayment($input: UpdateSupplierPaymentInput) {
  updateSupplierPayment(input: $input) {
    id
  }
}
`;

export const requestPayout = gql`
mutation CreatePayoutRequest($input: PayoutInput) {
  createPayoutRequest(input: $input)
}
`;

export const cardSetup = gql`
mutation InitiateCardSetup($input: InitiateCardSetupInput) {
  initiateCardSetup(input: $input)
}
`