import { useMutation } from '@apollo/client';
import {
  RemoveProduct,
  RemoveProductVariables,
} from '_graphql/mutation/__generated__/RemoveProduct';
import { REMOVE_PRODUCT } from '_graphql/mutation/products';

export const useDeleteProduct = (variables?: RemoveProductVariables) => {
  const [productDeletion, { ...rest }] = useMutation<
    RemoveProduct,
    RemoveProductVariables
  >(REMOVE_PRODUCT, {
    variables,
    refetchQueries: ['GetProducts'],
  });
  return {
    ...rest,
    productDeletion,
  };
};
