import { gql } from "@apollo/client";

export const Get_Partnerships = gql`
query GetPartnerships($filter: PartnershipFilter, $pagination: Pagination) {
  rows: getPartnerships(filter: $filter, pagination: $pagination) {
    createdAt
    id
    name
    skus
    status
    supplier_id
    updatedAt
  }
  count: getPartnershipsLength(filter: $filter)
}
`;
export const Get_Partnership = gql`
query GetPartnership($filter: PartnershipFilter!) {
  getPartnership(filter: $filter) {
    id
    name
    supplier_id
    skus
    createdAt
    updatedAt
    status
    products {
      sku
      id
      title
      limited_stock
      qty
      main_stock
      minimumOrderQuantity
      distributionChannels
      transit_stock
      price
      profit_added
      currency
      country
      description
      extra_information
      combination_string
      published
      inhouse
      attended_to
      verified
      cost_price
      weight
      avatar
      tags
      shop_id
      resell
      uuid
      likesCount
      savesCount
      sharesCount
      supplier_id
      created_at
      overalReview
    }
  }
}

`;