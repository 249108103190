import { GetProduct_getProduct } from '_graphql/queries/__generated__/GetProduct';
import { Avatar } from 'components';
import _ from 'lodash';
import { FC } from 'react';

type Props = {
  product: GetProduct_getProduct | null | undefined;
};

const ProductView: FC<Props> = ({ product }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Product Information</span>
        <div className='grid md:grid-cols-3 gap-6 mt-2'>
          <div className='row-span-3 '>
            <Avatar
              size='2xl'
              src={product?.avatar}
              alt={product?.title || ''}
              fitStyle='contain'
            />
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Product Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {product?.title?.toUpperCase() || 'N/A'}
            </div>
          </div>
          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                SKU (Stock Keeping Unit)
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {product?.sku || 'N/A'}
              </div>
            </div>
          </div>
          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Weight
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {product?.weight || 'N/A'} KG
              </div>
            </div>
          </div>
          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Selling Price
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {product?.currency} {product?.price || 'N/A'}
              </div>
            </div>
          </div>
          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Cost Price
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {product?.currency} {product?.cost_price || 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
