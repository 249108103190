import { DeliveryLocationInput } from 'components';
import { RichEditor, SelectInput, Show, TextInput } from 'components/core';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { useProducts } from 'pages/products/hooks/use-products';
import { FC } from 'react';

export interface DiscountFormProps {
  form: FormikProps<any>;
}

const DiscountForm: FC<DiscountFormProps> = ({ form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <div className='grid grid-cols-2 gap-2'>
          <div className='col-span-2'>
            <TextInput
              id={'code'}
              label='Code'
              type='text'
              description='This serves as a name for the discount'
              placeholder='eg. WINTERSALE'
              {...form}
            />
          </div>
          <div className='col-span-2'>
            <SelectInput
              options={[
                { label: 'Percentage', value: 'PERCENTAGE' },
                {
                  label: 'Fixed Amount',
                  value: 'FIXED_AMOUNT',
                },
                {
                  label: 'Free Delivery',
                  value: 'FREE_DELIVERY',
                },
              ]}
              id={'discount_type'}
              label='Discount Type'
              {...form}
            />
          </div>

          <div className='col-span-2'>
            <Show if={form.values.discount_type === 'PERCENTAGE'}>
              <TextInput
                id={'percentage'}
                label='Percentage'
                type='number'
                description='The value must be between 1% - 99%'
                min={1}
                placeholder='eg. 20'
                {...form}
              />
            </Show>
            <Show if={form.values.discount_type === 'FIXED_AMOUNT'}>
              <div className='space-y-3'>
                <TextInput
                  id={'amount'}
                  label='Amount'
                  type='number'
                  min={1}
                  {...form}
                />

                <SelectInput
                  options={[
                    {
                      label: 'Product Specific',
                      value: 'PRODUCT_SPECIFIC',
                    },
                    {
                      label: 'Total Amount',
                      value: 'TOTAL_AMOUNT',
                    },
                  ]}
                  id={'fixed_amount_allocation'}
                  label='Fixed Amount Allocation'
                  {...form}
                />
              </div>
            </Show>
            <Show if={form.values.discount_type === 'FREE_DELIVERY'}>
              <div className='space-y-3'>
                <TextInput
                  id={'tagname'}
                  label='Location Tagname'
                  placeholder='eg. Accra Environs'
                  type='text'
                  {...form}
                />

                <div className='col-span-full'>
                  <DeliveryLocationInput form={form} />
                </div>
              </div>
            </Show>
          </div>

          <div className=''>
            <TextInput
              type='date'
              label='Start Date'
              id='start_date'
              {...form}
            />
          </div>
          <div className=''>
            <TextInput
              type='date'
              label='Expiry Date'
              id={'expiry_date'}
              {...form}
            />
          </div>
          <div className='col-span-full'>
            <TextInput
              label='Redemption Limit'
              type='number'
              id={'r_limit'}
              {...form}
            />
          </div>
          <div className='col-span-full'>
            <RichEditor
              id={'description'}
              label='Discount Description'
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountForm;
