import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { FormikProps } from 'formik';
import { SelectInput } from '../../../components';

interface Props {
  form: FormikProps<any>;
}

const UploadFile: React.FC<Props> = ({ form }) => {
  const [columns, setColumns] = useState<string[]>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (!data) {
        return;
      }
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheet = workbook.SheetNames[0];
      const excelRows: any[] = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheet]
      );
      // exteract header names
      const headers = Object.keys(excelRows[0]);
      setColumns(headers);

      const skuColumn = headers.find((header) =>
        header.toLowerCase().includes('sku')
      );
      if (skuColumn) {
        form.setFieldValue('sku_column', skuColumn);
      }
      // remove header row
      excelRows.shift();
      form.setFieldValue('products_sku', excelRows);
    };
    reader.readAsBinaryString(file);
  };

  const openFilePicker = () => {
    inputRef.current?.click();
  };

  return (
    <React.Fragment>
      <>
        <button
          type='button'
          onClick={openFilePicker}
          className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
          <svg
            className='mx-auto h-12 w-12 text-gray-400'
            stroke='currentColor'
            fill='none'
            viewBox='0 0 48 48'
            aria-hidden='true'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
            />
          </svg>
          <span className='mt-2 block text-sm font-semibold text-gray-900'>
            Upload file containing SKUs
          </span>
        </button>
        <input
          type='file'
          ref={inputRef}
          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          className='hidden'
          onChange={handleFileChange}
        />
      </>

      {form.values.products_sku?.length > 0 && (
        <div className='flex'>
          <div className='pt-4'>
            <label htmlFor='SKU'>Select SKU Column</label>
            <SelectInput
              id={'sku_column'}
              options={[
                {
                  label: '--- Select SKU Column ---',
                  value: '',
                },
                ...columns.map((column) => ({ label: column, value: column })),
              ]}
              {...form}
            />
          </div>
        </div>
      )}
      {form.values.products_sku?.length > 0 && (
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    {columns.map((column) => (
                      <th
                        key={column}
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        {column}
                      </th>
                    ))}
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {form?.values?.products_sku?.map((person: any) => (
                      <tr key={person.email}>
                        {columns.map((column) => (
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {person[column]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadFile;
