import { Switch } from '@headlessui/react';
import React from 'react';

type Props = {
  label?: string;
  display?: string;
  description?: string;
  value: boolean;
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
};

const Toggle = ({
  label,
  description,
  value,
  setValue,
  display,
  disabled,
}: Props) => {
  return (
    <Switch.Group as='div' className='flex items-center justify-between'>
      {(label || description) && (
        <span className='flex-grow flex flex-col space-y-1'>
          <Switch.Label
            as='span'
            className='text-sm font-medium text-gray-900'
            passive>
            {label}
          </Switch.Label>
          {description && (
            <Switch.Description as='span' className='text-xs text-gray-500'>
              {description}
            </Switch.Description>
          )}
        </span>
      )}
      <div className='flex-col space-y-1'>
        {display && (
          <span
            className={`block text-sm font-semibold ${
              value ? 'text-green-900' : 'text-black'
            } ml-2`}>
            {display}
          </span>
        )}
        <Switch
          checked={value}
          onChange={setValue}
          disabled={disabled ? disabled : false}
          className={`
          relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
           ${value ? 'bg-primary-600' : 'bg-gray-200'}
          
          `}>
          <span className='sr-only'>Use setting</span>
          <span
            className={`
            pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200
            ${value ? 'translate-x-5' : 'translate-x-0'}
            `}>
            <span
              className={`
              absolute inset-0 h-full w-full flex items-center justify-center transition-opacity
               ${
                 value
                   ? 'opacity-0 ease-out duration-100'
                   : 'opacity-100 ease-in duration-200'
               }
              `}
              aria-hidden='true'>
              <svg
                className='h-3 w-3 text-gray-400'
                fill='none'
                viewBox='0 0 12 12'>
                <path
                  d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                  stroke='currentColor'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            <span
              className={`
              absolute inset-0 h-full w-full flex items-center justify-center transition-opacity
               ${
                 value
                   ? 'opacity-100 ease-in duration-200'
                   : 'opacity-0 ease-out duration-100'
               }
              `}
              aria-hidden='true'>
              <svg
                className='h-3 w-3 text-indigo-600'
                fill='currentColor'
                viewBox='0 0 12 12'>
                <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
              </svg>
            </span>
          </span>
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
