import { FC, useEffect } from 'react';
import { SearchSelectInput } from 'components/core';
import { usePaymentMethods } from 'pages/wallet/hooks/use-payment-methods';
import _ from 'lodash';

interface PaymentMethodPickerProps {
    id: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    labelHidden?: boolean;
    values: any;
    errors?: any;
    touched?: any
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    rawId?: boolean;
    clearAfterSelect?: string[]
}


const PaymentMethodPicker: FC<PaymentMethodPickerProps> = ({ id, label, rawId, clearAfterSelect, ...form }) => {
    const { loading, methods } = usePaymentMethods({
        fetchPolicy: "network-only"
    });

    return (
        <SearchSelectInput
            id={id ?? "payment-method"}
            label={label ?? "Payment Method"}
            placeholder="Select Payment Method"
            optionsLoading={loading}
            handdleFinishedOnChange={() => {
                clearAfterSelect?.forEach((field) => {
                    form.setFieldValue(field, "")
                })
            }}
            options={(methods ?? [])?.map((activity: any) => ({
                label: {
                    title: activity.name as string,
                },
                value: rawId ? activity?.code : activity
            }))}
            {...form}
            disabled={form.disabled}
        />
    )
}

export default PaymentMethodPicker