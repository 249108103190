import { useProductTitle } from 'pages/products/hooks/use-products'

type Props = {
    prodcut_id: string
}

const ViewProductBreadCrumb = ({ prodcut_id }: Props) => {
    const { title, loading } = useProductTitle({
        variables: {
            filter: {
                id: {
                    eq: prodcut_id
                }
            }
        }
    })
    return (
        <div className='capitalize truncate'>{loading ? "Please wait ..." : title}</div>
    )
}

export default ViewProductBreadCrumb