import { FormikProps } from 'formik'
import { FC } from 'react'
import { useCategories } from '../hooks/use-categories'
import _ from 'lodash'

type Props = {
    form: FormikProps<any>
}

const CreateCategoryForm: FC<Props> = ({ form }) => {
    const { categories } = useCategories({
        variables: {
            pagination: {
                limit: 300,
                offset: 0,
            },
        }
    })

    const handleCheckboxChange = (event: any) => {
        if (form.values?.categories?.includes(event.target.value)) {
            const nextValue = form.values.categories.filter((value: any) => value !== event.target.value)
            form.setFieldValue("categories", nextValue)
        } else {
            form.setFieldValue("categories", [...form.values.categories, event.target.value])
        }
    }

    return (
        <>
            {/* {JSON.stringify(form.values, null, 2)} */}
            <div className='divide-y divide-gray-100'>
                {categories?.map((category) => (
                    <div key={category?.id} className="relative flex items-start pb-4 pt-3.5">
                        <div className="min-w-0 flex-1 capitalize text-sm leading-6">
                            <label htmlFor={_.snakeCase(category?.name)} className="font-medium text-gray-900">
                                {category?.name}
                            </label>
                            <p id="candidates-description" className="text-gray-500">
                                {category?.description ?? "No description"}
                            </p>
                        </div>
                        <div className="ml-3 flex h-6 items-center">
                            <input
                                type="checkbox"
                                value={category?.id}
                                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                onChange={handleCheckboxChange}
                                checked={form.values?.categories?.includes(category?.id)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default CreateCategoryForm