import SectionLayout from "pages/create-product/components/SectionLayout";
import { GetProduct_getProduct } from "_graphql/queries/__generated__/GetProduct";
import { FC } from "react";
import { Avatar, List } from "components";
import { wrapImage } from "utils";
import _ from "lodash";

type Props = {
  product: GetProduct_getProduct | null | undefined;
};
const ProductDetails: FC<Props> = ({ product }) => {
  return (
    <main className="flex-1 flex flex-col  h-screen bg-white  relative">
      {/* <OfficeHeader
      renderActions={() => (
        <OfficeButton onClick={refetch}>Refresh</OfficeButton>
      )}
    /> */}

      <div className="grid grid-cols-3 mb-5 ">
        <div className="col-span-2">
          <SectionLayout
            // loading={loading}
            freeFlow
            title={"Product Information"}
            // renderActions={() => (
            //   <button
            //     className='text-primary-400 text-sm '
            //     type='button'
            //     onClick={dispatchAction(
            //       product?.id || '',
            //       'update_product_information',
            //       navigate
            //     )}>
            //     Edit
            //   </button>
            // )}
          >
            <div className="space-y-6 divide-y divide-gray-200  w-full">
              <div>
                <div className="grid grid-cols-4 gap-6">
                  <div className="row-span-3">
                    <Avatar
                      size="2xl"
                      src={product?.avatar}
                      alt={product?.title || ""}
                      fitStyle="contain"
                    />
                  </div>
                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {product?.title?.toUpperCase() || "No data found"}
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        SKU (Stock Keeping Unit)
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.sku || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Weight
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.weight || "N/A"} KG
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Selling Price
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.currency} {product?.price || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Cost Price
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.currency} {product?.cost_price || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Minimum Order Quantity
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.minimumOrderQuantity || "No data found"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionLayout>
          <SectionLayout
            freeFlow
            // loading={loading}
            title={"Product Media"}
            // renderActions={() => (
            //   <button
            //     className='text-primary-400 text-sm '
            //     type='button'
            //     onClick={dispatchAction(
            //       product?.id || '',
            //       'update_product_media',
            //       navigate
            //     )}>
            //     Edit
            //   </button>
            // )}
          >
            <div className="grid grid-cols-2 sm:grid-cols-4  gap-2">
              <List
                data={product?.media || []}
                renderItem={(img) =>
                  wrapImage(
                    <img
                      src={img?.avatar || ""}
                      alt="product_image"
                      key={img?.id}
                      className="w-36 h-36 rounded object-contain   flex-shrink-0 flex-grow-0 "
                    />
                  )
                }
              />
            </div>
          </SectionLayout>
          <SectionLayout
            freeFlow
            // loading={loading}
            title={"Product Description"}
          >
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: product?.description || "" }}
            />
          </SectionLayout>
          {product?.extra_information && (
            <SectionLayout
              freeFlow
              // loading={loading}
              title={"Extra Information"}
            >
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: product?.extra_information || "",
                }}
              />
            </SectionLayout>
          )}
        </div>
        <div>
          <div className="sticky top-6">
            <SectionLayout
              // loading={loading}
              title={"Category And Tags"}
              freeFlow
              //   renderActions={() => (
              //     <button
              //       className='text-primary-400 text-sm '
              //       type='button'
              //       onClick={dispatchAction(
              //         product?.id || '',
              //         'update_product_category',
              //         navigate
              //       )}>
              //       Edit
              //     </button>
              //   )}
            >
              <div>
                <div className="flex items-start justify-start gap-2">
                  <div className="">Categories :</div>
                  <div className="grid grid-cols-3 gap-3">
                    <List
                      data={product?.categories || []}
                      renderItem={(category) => (
                        <span
                          key={category?.id}
                          className="px-2 py-1 mr-2 bg-gray-100 rounded text-sm whitespace-nowrap"
                        >
                          {category?.name}
                        </span>
                      )}
                    />
                  </div>
                </div>
                <hr className="my-5" />
                <div className="flex items-start justify-start gap-2">
                  <div className="">Tags :</div>
                  <div className="flex  ">
                    <List
                      data={product?.tags || []}
                      renderItem={(tag) => (
                        <span
                          key={tag}
                          className="px-2 py-1 mr-2 bg-gray-100 rounded text-sm whitespace-nowrap"
                        >
                          {tag}
                        </span>
                      )}
                    />
                  </div>
                </div>
              </div>
            </SectionLayout>

            <SectionLayout
              // loading={loading}
              title={"Product Variations"}
              freeFlow
            >
              <div className="space-y-2">
                <List
                  data={product?.variations || []}
                  renderItem={(variation) => (
                    <div
                      key={variation?.id}
                      className="flex items-center justify-between"
                    >
                      <span className="">{variation?.variation_name} </span>
                      <span className="space-x-2">
                        <List
                          data={variation?.variation_options || []}
                          renderItem={(option) => (
                            <div className="px-1 py-1 bg-gray-100 rounded text-sm">
                              {option?.variation_value || "No data found"}
                            </div>
                          )}
                        />
                      </span>
                    </div>
                  )}
                />
              </div>
            </SectionLayout>
            <SectionLayout
              // loading={loading}
              title={"Distribution Channels"}
              freeFlow
              //   renderActions={() => (
              //     <button
              //       className='text-primary-400 text-sm '
              //       type='button'
              //       onClick={dispatchAction(
              //         product?.id || '',
              //         'update_product_distribution',
              //         navigate
              //       )}>
              //       Edit
              //     </button>
              //   )}
            >
              <List
                data={product?.distributionChannels || []}
                renderItem={(tag) => (
                  <span
                    key={tag}
                    className="px-2 py-1 mr-2 bg-gray-100 rounded text-sm whitespace-nowrap"
                  >
                    {_.startCase(tag)}
                  </span>
                )}
              />
            </SectionLayout>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductDetails;
