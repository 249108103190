import { usePagination } from 'hooks';
import { useDiscountProducts } from './hooks/use-discount-products';
import {
  OfficeButton,
  OfficeHeader,
  TableComponent,
  TableLoader,
  toast,
} from 'components';
import * as yup from 'yup';
import { GetDiscountProductConditions_getDiscountProductConditions } from '_graphql/queries/__generated__/GetDiscountProductConditions';
import { useAppURL } from '_graphql/cache/auth';
import { useFormik } from 'formik';
import {
  AddProductstoDiscount,
  AddProductstoDiscountVariables,
} from '_graphql/mutation/__generated__/AddProductstoDiscount';
import { addProductstoDiscount } from '_graphql/mutation/discount';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useState } from 'react';
import AddProductContainer from './components/AddProductModal';
import moment from 'moment';

const ViewDiscount = () => {
  const { offset, limit } = usePagination();
  const { params } = useAppURL();
  const [openProductUpload, setOpenProductUpload] = useState<boolean>(false);

  const { discountProducts, loading, refetch } = useDiscountProducts({
    filter: {
      discount_id: {
        eq: params?.discount_id || '',
      },
    },
    pagination: {
      limit: Number(limit),
      offset: Number(offset),
    },
  });

  const [add, { loading: loadAdding }] = useMutation<
    AddProductstoDiscount,
    AddProductstoDiscountVariables
  >(addProductstoDiscount, {
    refetchQueries: ['GetDiscountProductConditions'],
  });
  const form = useFormik({
    initialValues: {
      products: [],
      sku_column: 'SKU',
    },
    validationSchema: yup.object().shape({
      sku_column: yup.string().required('sku column is required'),
    }),
    onSubmit: async (values) => {
      add({
        variables: {
          discountId: params?.discount_id || '',
          productSkUs: values.products?.map((el) =>
            _.toString(_.get(el, values.sku_column))
          ),
        },
      })
        .then(() => {
          form.resetForm();
          toast('Products added to discount', 'success');
          setOpenProductUpload(false);
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={() => setOpenProductUpload(true)}>
            <svg
              className='-ml-1 mr-2 h-5 w-5'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'>
              <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
            </svg>
            Update Products
          </OfficeButton>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={'discount products'}
            hasSearch
            loading={loading}
            columns={[
              {
                accessor: 'product.title',
                name: 'product title',
                render: (
                  value: GetDiscountProductConditions_getDiscountProductConditions
                ) => (
                  <div className='flex items-center max-w-2xl break-all truncate text-xs'>
                  <div className='h-10 w-10 flex-shrink-0 text-xs'>
                    <img
                      className='h-10 w-10 rounded-none'
                      src={value.product?.avatar}
                      alt=''
                    />
                  </div>
                  <div className='ml-4 break-all flex-wrap text-xs'>
                    <div className='font-medium text-gray-900 truncate flex-wrap text-xs'>
                      {value.product?.title}
                    </div>
                    <div className='text-gray-500'>{value.product?.sku}</div>
                    <div className='text-gray-500 font-medium'>
                      {value.product?.combination_string?.replaceAll('&', ', ')}
                    </div>
                  </div>
                </div>
                ),
              },
              {
                accessor: 'product.price',
                name: 'product price',
                render: (
                  value: GetDiscountProductConditions_getDiscountProductConditions
                ) => (
                  <span>
                {value.product?.currency} {value.product?.price}
                  </span>
                ),
              },
              {
                accessor: 'updated_at',
                name: 'Updated At',
                render: (
                  value: GetDiscountProductConditions_getDiscountProductConditions
                ) => (
                  <span>
                  {moment(value.updated_at).fromNow()}
                  </span>
                ),
              },
            
            ]}
            data={discountProducts}
            refetch={refetch}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
          />

          <AddProductContainer
            open={openProductUpload}
            setOpen={setOpenProductUpload}
            form={form}
            loading={loadAdding}
          />
        </div>
      </div>
    </main>
  );
};

export default ViewDiscount;
