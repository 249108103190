import { GetPartnership, GetPartnership_getPartnership } from "_graphql/queries/__generated__/GetPartnership";
import _ from "lodash";
import { FC } from "react";

type Props = {
    partnership: GetPartnership_getPartnership | null | undefined
};

const PartnerView: FC<Props> = ({ partnership }) => {

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Partnership Information</span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {partnership?.name || "N/A"}
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Created At
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {partnership?.createdAt || "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-6">
                <span className="text-xs font-light">Products Information</span>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                SKU
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Title
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Cost Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Selling Price
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {partnership?.products.map((product, idx) => (
                                            <tr key={idx}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {product.sku}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.title}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.cost_price}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerView;
