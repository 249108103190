import ApolloProvider from "_graphql";
import toast, { Toaster, } from 'react-hot-toast';

import LayoutProvider from "layouts";
import { Tooltip } from "react-tooltip";
import RoutesProvider from "router";
import { MantineProvider } from '@mantine/core';
import { useEffect } from "react";
import { messaging } from "notifications/firebase";
import { onMessage } from "firebase/messaging";
// import TranslationProvider from 'translation';
// const stripePromise = loadStripe(config.stripe.key);


function App() {
  useEffect(() => {
    onMessage(messaging, (payload) => {
      // console.log(payload)
      toast(payload?.notification?.body!)
    })
  }, [])
  return (
    <MantineProvider>
      <ApolloProvider>
        <RoutesProvider>
          <LayoutProvider />
          <Tooltip id="global-tooltip" />
          <Toaster />
        </RoutesProvider>
      </ApolloProvider>
    </MantineProvider>
  );
}

export default App;
