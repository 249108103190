import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetCarts,
  GetCartsVariables,
} from "_graphql/queries/__generated__/GetCarts";
import {
  GetTerm,
  GetTermVariables,
} from "_graphql/queries/__generated__/GetTerm";
import {
  GetTerms,
  GetTermsVariables,
} from "_graphql/queries/__generated__/GetTerms";
import { getCarts } from "_graphql/queries/orders";
import { getTerm, getTerms } from "_graphql/queries/terms";

export const useTerms = (
  options?: QueryHookOptions<GetTerms, GetTermsVariables>
) => {
  const { data, ...rest } = useQuery<GetTerms, GetTermsVariables>(
    getTerms,
    options
  );

  return {
    terms: data?.getTerms || [],
    count: data?.getTermsLength || 0,
    ...rest,
  };
};
export const useTerm = (
  options?: QueryHookOptions<GetTerm, GetTermVariables>
) => {
  const { data, ...rest } = useQuery<GetTerm, GetTermVariables>(
    getTerm,
    options
  );

  return {
    term: data?.getTerm,
    ...rest,
  };
};
