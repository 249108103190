import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetCarts,
  GetCartsVariables,
} from "_graphql/queries/__generated__/GetCarts";
import { getCarts } from "_graphql/queries/orders";

export const useCarts = (
  options?: QueryHookOptions<GetCarts, GetCartsVariables>
) => {
  const { data, ...rest } = useQuery<GetCarts, GetCartsVariables>(
    getCarts,
    options
  );

  return {
    orders: data?.rows || [],
    count: data?.count || 0,
    ...rest,
  };
};
