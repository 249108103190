import { useDebounce } from "hooks";
import { useEffect } from "react";
import { useUrlState } from "utils";

const usePagination = () => {
  const [page, setPage] = useUrlState("page");
  const [limit, setLimit] = useUrlState("pageSize");
  const [searchValue] = useUrlState("search");
  const search = useDebounce(searchValue, 500);

  const offset = (page - 1) * limit;
  const handlePageChange = (newPage: number) => {
    setPage((newPage - 1) * limit);
  };

  useEffect(() => {
    if (!page) {
      setPage(1);
    }
    if (!limit) {
      setLimit(10);
    }
  }, [limit, page, setLimit, setPage]);

  return {
    currentPage: page || 1,
    // totalPages,
    offset: Number(offset || 0),
    limit: Number(limit || 20),
    handlePageChange,
    setLimit,
    setPage,
    search,
  };
};

export default usePagination;
