import { GetProduct_getProduct } from '_graphql/queries/__generated__/GetProduct';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';

import Select from 'react-select';

type Props = {
  form: FormikProps<any>;
  product: GetProduct_getProduct | null | undefined;
  loading: boolean;
};

const UpdateDistributionForm: FC<Props> = ({
  form,
  product,
  loading,
}: Props) => {
  const [dis, setDistribution] = useState<any[]>([{}]);

  useEffect(() => {
    if (product?.distributionChannels) {
      product?.distributionChannels?.forEach((el: any, index: number) => {
        form.setFieldValue(`distribution.${index}`, el);
        setDistribution((state) => {
          state[index] = { value: el, label: el };
          return state;
        });
      });
    }
  }, [loading]);

  const handleDistributionChange = (e: any) => {
    setDistribution((state) => {
      state = e ?? [];
      form.setFieldValue(
        `distribution`,
        e?.map((el: any) => el?.value)
      );
      return state;
    });
  };

  return (
    <form className='w-full py-5'>
      <div className='w-full'>
        <label
          htmlFor='distribution'
          className='block text-sm font-medium text-gray-700'>
          Distribution
        </label>
        <p className='block text-xs text-gray-500 my-1'>
          Select distribution channel to assign the product. A product can be
          assign to multiple distribution channel.
        </p>
        <Select
          id='distribution'
          isMulti
          className='basic-single text-sm'
          classNamePrefix='select'
          placeholder='Select distribution channel'
          noOptionsMessage={() => 'No category available'}
          isSearchable={true}
          value={dis}
          options={[
            'TendoNetwork',
            'SupplierStorefront',
            'ResellerNetwork',
          ].map((el) => ({
            value: el,
            label: _.startCase(el),
          }))}
          onChange={handleDistributionChange}
        />
        {_.get(form.errors, 'distribution') && (
          <p className='mt-2 mb-5 text-sm text-red-600 '>
            <> {_.get(form.errors, 'distribution')}</>
          </p>
        )}
      </div>
    </form>
  );
};

export default UpdateDistributionForm;
