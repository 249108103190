import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon, Bars3Icon} from '@heroicons/react/24/outline';
import { useCurrentUser } from '_graphql/cache/auth';
import _ from 'lodash';
import { FC, Fragment, useMemo } from 'react';
import { Link, useNavigate, useRouter } from 'react-location';
import { withRoutePermissions } from 'router/filter';
import { LocationGenerics } from 'router/location';
import routes from 'router/routes';
import { classNames, processNavigationSections, wrapClick } from 'utils';

interface HeaderProps {
  renderActions?: FC<{}>;
}

const Header: FC<HeaderProps> = ({ renderActions }) => {
  const { matches } = useRouter<LocationGenerics>().state;
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const navigation = _.chain(routes)
    .filter((route) => _.get(route, "meta.layout") === "App")
    .thru(withRoutePermissions)
    .filter((item) => !!item.sidebar)
    .value();
  const breadcrumbs = useMemo(
    () => matches.filter((match) => match?.route?.meta?.breadcrumb),
    [matches]
  );
  const firstBreadcrumb = _.first(breadcrumbs);
  const lastBreadcrumb = _.last(breadcrumbs);

  const openLink = (link: string) => () => {
    navigate({
      to: link,
      search(prev) {
        return {
          ...prev,
        };
      },
    });
  };

  const goBack = () => {
    const destination = _.nth(breadcrumbs, -2);
    if (destination?.pathname !== lastBreadcrumb?.pathname) {
      navigate({
        to: destination?.pathname,
        search(prev) {
          return {
            ...prev,
          };
        },
        replace: true,
      });
    }
  };



  return (
    <div className='p-4 flex items-center justify-between flex-shrink-0 bg-white shadow-sm sticky top-0 z-40'>
      <div className='min-w-0 flex-1'>
        <div className='flex flex-row  items-center space-x-2'>
          {breadcrumbs.length > 1 ? (
            <button
              type='button'
              onClick={wrapClick(goBack)}
              className='inline-flex items-center rounded border border-transparent p-1 text-gray-200 bg-primary-500 shadow-sm hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
              <ArrowLeftIcon className='h-4 w-4' aria-hidden='true' />
            </button>
          ) : (
            <Menu as="div" className="relative inline-block  lg:hidden text-left">
              <div>
                <Menu.Button className='inline-flex items-center rounded border border-transparent p-1 text-gray-200 bg-primary-500 shadow-sm hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                  <Bars3Icon className='h-4 w-4' aria-hidden='true' />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 p-3 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {processNavigationSections(currentUser?.supplier_type).map((section) => (
                    <div key={section} className="space-y-1 pt-3 first:pt-0">
                      <h3
                        className="px-3 text-xs font-light text-gray-500"
                        id={section}
                      >
                        {section}
                      </h3>
                      <div
                        className="space-y-1"
                        role="group"
                        aria-labelledby={section}
                      >
                        {
                          _.filter(
                            navigation,
                            (item) => _.get(item, "meta.section") === section
                          ).map((item) => {
                            const isCurrent = false;
                            return (
                              <Link
                                key={item.sidebar?.label}
                                to={`/${item.path}`}
                                getActiveProps={() => ({
                                  className: "bg-primary-500 text-gray-900",
                                })}
                                getInactiveProps={() => ({
                                  className:
                                    "text-gray-600 hover:bg-primary-800 hover:text-white",
                                })}
                                className="group flex items-center px-2 py-2 text-sm rounded-md"
                              >
                                {item.sidebar?.icon && (
                                  <item.sidebar.icon
                                    className={classNames(
                                      isCurrent
                                        ? "text-gray-900"
                                        : "text-gray-400 group-hover:text-gray-50",
                                      "mr-3 flex-shrink-0 h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                                {item.sidebar?.label}
                              </Link>
                            );
                          })
                        }

                      </div>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          )}
          <h2 className='text-lg leading-7 text-gray-900 tracking-wider truncate text-ellipsis'>
            {lastBreadcrumb?.route.meta?.breadcrumb?.(lastBreadcrumb.params)}
          </h2>
        </div>
        <nav className='hidden sm:flex' aria-label='Breadcrumb'>
          <ol className='flex items-center space-x-2'>
            <li>
              <div className='flex'>
                <span className='text-xs font-medium text-gray-500'>
                  {firstBreadcrumb?.route.meta?.section}
                </span>
              </div>
            </li>
            {breadcrumbs
              .map(match => (
                <li key={match.pathname}>
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <button
                      onClick={wrapClick(openLink(match.pathname))}
                      disabled={lastBreadcrumb?.id === match.id}
                      className={classNames(
                        lastBreadcrumb?.id === match.id ? "bg-gray-100" : "bg-white",
                        "ml-2 text-xs font-medium text-gray-500 rounded-md p-0.5 px-1.5 truncate hover:bg-gray-200 hover:text-gray-700 "
                      )}
                      aria-current={lastBreadcrumb?.id === match.id ? "page" : undefined}
                    >
                      {match.route.meta?.breadcrumb(match.params)}
                    </button>
                  </div>
                </li>
              ))}
          </ol>
        </nav>
      </div>
      {_.isFunction(renderActions) && (
        <div className='mt-4 flex flex-shrink-0 md:mt-0 md:ml-4 space-x-3'>
          {renderActions({})}
        </div>
      )}
    </div>
  );
};

export default Header;
