import { useQuery } from "@apollo/client";
import { GetPartnership, GetPartnershipVariables } from "_graphql/queries/__generated__/GetPartnership";
import { GetPartnerships, GetPartnershipsVariables } from "_graphql/queries/__generated__/GetPartnerships";
import { Get_Partnership, Get_Partnerships } from "_graphql/queries/partnerships";


export const usePartnerships = (variables?: GetPartnershipsVariables) => {
    const { data, ...rest } = useQuery<GetPartnerships, GetPartnershipsVariables>(Get_Partnerships, {
        variables: variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    return {
        partnerships: data?.rows || [],
        count: data?.count || 0,
        ...rest,
    };
}
export const usePartnership = (variables?: GetPartnershipVariables) => {
    const { data, ...rest } = useQuery<GetPartnership, GetPartnershipVariables>(Get_Partnership, {
        variables: variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    return {
        partnership: data?.getPartnership,
        ...rest,
    };
}