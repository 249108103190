import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetCustomer,
  GetCustomerVariables,
} from "_graphql/queries/__generated__/GetCustomer";
import { getCustomer } from "_graphql/queries/customers";

export const useCustomer = (
  options?: QueryHookOptions<GetCustomer, GetCustomerVariables>
) => {
  const { data, ...rest } = useQuery<GetCustomer, GetCustomerVariables>(
    getCustomer,
    options
  );

  return {
    customer: data?.getCustomer,
    ...rest,
  };
};
