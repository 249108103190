import {
  OfficeButton,
  OfficeHeader,
  TableComponent,
  Paginator,
  ActionButton,
} from "components";
import { FC } from "react";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useUsers } from "./hooks/use-users";
import { usePagination } from "hooks";

import {
  GetUser_getUser,
} from "_graphql/queries/__generated__/GetUser";
import InviteResellerContainer from "./invite";
import { useUrlState } from "utils";
import { Action } from "components/buttons/action-button";
import RemoveResellerContainer from "./remove";
import { currentUserVar, useAppSearch } from "_graphql/cache/auth";
import { useReactiveVar } from "@apollo/client";

type Props = {};

const ResellersPage: FC<Props> = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const searchParams = useAppSearch()
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, setPage } = usePagination();
  const [modal, setModal] = useUrlState("modal");



  const viewUser = (id: string) => {
    navigate({
      to: `./${id}`,
    });
    // console.log(id);
  };

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };
  const { users, refetch, loading, count } = useUsers({
    variables: {
      pagination: {
        limit: limit,
        offset: offset,
      },
      filter: {
        supplier_id: {
          eq: currentUser?.id
        },
        name:searchParams?.search? {
          like:`${searchParams.search}`
        }:undefined
      }
    },
  });

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton variant={'minimal'} onClick={() => setModal("invite")}>Invite Reseller</OfficeButton>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"Resellers"}
            columns={[
              {
                name: "Name",
                accessor: "name",
              },
              {
                name: "Phone",
                accessor: "phone",
              },
              {
                name: "Email",
                accessor: "email",
              },
              {
                name: "Actions",
                accessor: "",
                render: (user: GetUser_getUser) => (
                  <div className="flex flex-row">
                    <ActionButton
                      action={"view"}
                      tooltip="View Reseller"
                      onClick={() => viewUser(user.id)}
                    />
                    <ActionButton
                      action={"remove"}
                      tooltip="Remove Reseller"
                      onClick={dispatchAction(user.id, "remove")}

                    />
                  </div>
                ),
              },
            ]}
            hasSearch
            data={users}
            refetch={refetch}
            loading={loading}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={users.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>

      <InviteResellerContainer open={modal === "invite"} setOpen={(val: boolean) => setModal(val ? "invite" : undefined)} />
      <RemoveResellerContainer open={modal === "remove"} setOpen={(val: boolean) => setModal(val ? "remove" : undefined)} />
    </main>
  );
};

export default ResellersPage;
