import { useCurrentUser } from '_graphql/cache/auth';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useOrdersByStatus, useProductsByCategory } from './hooks/use-summary';
import DashboardStats from './stats';
import OrderPerDaySection from './order-per-day';
import { classNames } from 'utils';
import numeral from 'numeral';
import { Pie } from 'components';


const colors = ["bg-blue-600", "bg-teal-600", "bg-purple-600", "bg-yellow-600", "bg-red-600", "bg-green-600"];
const colorsbg = ["#2563eb", "#0d9488", "#9333ea", "#f59e0b", "#ef4444", "#10b981"];

const Dashboard = () => {
    const currentUser = useCurrentUser();
    const searchParams = useSearch<LocationGenerics>();
    const { summary } = useOrdersByStatus({
        from: searchParams?.fromDate,
        to: searchParams.toDate,
        suuplierId: currentUser?.id
    });
    const { productSummary } = useProductsByCategory({
        supplierId: currentUser?.id
    });
    return (
        <div className="flex-1 p-4 sm:p-6 overflow-y-auto bg-gray-50">
            <DashboardStats />
            <div className="grid grid-cols-2 gap-4 p-4 gap-y-6 w-full mt-3">
                <OrderPerDaySection />
                <div className="col-span-2 sm:col-span-1 flex flex-col">
                    <h3 className="text-md font-medium leading-6 text-gray-900">
                        Products By Category
                    </h3>
                    <div className="mt-3 flex items-center space-x-6 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6">
                        <div className="flex-1 flex space-x-12">
                            <div className="flex-1 space-y-6 my-auto">
                                {[...productSummary?.map?.((item: any, idx) => ({
                                    color: colorsbg[idx],
                                    bgColor: colors[idx],
                                    id: item?.category,
                                    label: item?.category,
                                    value: item?.count || 0,
                                }))

                                ]?.map?.((item: any) => (
                                    <div
                                        key={item?._id}
                                        className="rounded-lg bg-white p-2 flex space-x-4"
                                    >
                                        <div>
                                            <div
                                                className={classNames(
                                                    item?.bgColor,
                                                    "rounded-md flex items-center justify-center p-2.5"
                                                )}
                                            >
                                                <span
                                                    className="text-lg text-white"
                                                    aria-hidden="true"
                                                ></span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
                                            <div>
                                                <dt className="truncate capitalize text-xs text-gray-500">
                                                    {item?.label}
                                                </dt>
                                                <dd className="flex items-baseline justify-between">
                                                    <p className="text-xl font-normal text-gray-900">
                                                        {numeral(item?.value).format("0,0")}
                                                    </p>
                                                </dd>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="w-80 h-80">
                                <Pie
                                    data={[...productSummary?.map?.((item: any, idx) => ({
                                        color: colorsbg[idx],
                                        bgColor: colors[idx],
                                        id: item?.category,
                                        label: item?.category,
                                        value: item?.count || 0,
                                    }))
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 sm:col-span-1 flex flex-col">
                    <h3 className="text-md font-medium leading-6 text-gray-900">
                        Orders By Status
                    </h3>
                    <div className="mt-3 flex items-center space-x-6 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6">
                        <div className="flex-1 flex space-x-12">
                            <div className="flex-1 space-y-6 my-auto">
                                {[
                                    {
                                        color: "#2563eb",
                                        bgColor: "bg-yellow-600",
                                        id: "Pending",
                                        label: "Pending",
                                        value: summary?.totalPending || 0,
                                    },
                                    {
                                        color: "#0d9488",
                                        bgColor: "bg-red-600",
                                        id: "Cancelled",
                                        label: "Cancelled",
                                        value: summary?.totalCancelled || 0,
                                    },
                                    {
                                        color: "#9333ea",
                                        bgColor: "bg-teal-600",
                                        id: "Delivered",
                                        label: "Delivered",
                                        value: summary?.totalDelivered || 0,
                                    },
                                ]?.map?.((item: any) => (
                                    <div
                                        key={item?._id}
                                        className="rounded-lg bg-white p-2 flex space-x-4"
                                    >
                                        <div>
                                            <div
                                                className={classNames(
                                                    item?.bgColor,
                                                    "rounded-md flex items-center justify-center p-2.5"
                                                )}
                                            >
                                                <span
                                                    className="text-lg text-white"
                                                    aria-hidden="true"
                                                ></span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
                                            <div>
                                                <dt className="truncate text-xs text-gray-500">
                                                    {item?.label}
                                                </dt>
                                                <dd className="flex items-baseline justify-between">
                                                    <p className="text-xl font-normal text-gray-900">
                                                        {numeral(item?.value).format("0,0")}
                                                    </p>

                                                </dd>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="w-80 h-80">
                                <Pie
                                    data={[
                                        {
                                            color: "#2563eb",
                                            bgColor: "bg-yellow-600",
                                            id: "Pending",
                                            label: "Pending",
                                            value: summary?.totalCancelled || 0,
                                        },
                                        {
                                            color: "#0d9488",
                                            bgColor: "bg-red-600",
                                            id: "Cancelled",
                                            label: "Cancelled",
                                            value: summary?.totalCancelled || 0,
                                        },
                                        {
                                            color: "#9333ea",
                                            bgColor: "bg-teal-600",
                                            id: "Delivered",
                                            label: "Delivered",
                                            value: summary?.totalCancelled || 0,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard