import { useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { currentTokenVar } from '_graphql/cache/auth';

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  }
}>

const OnBordingLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>()
  const currentToken = useReactiveVar(currentTokenVar)

  if (currentToken) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate
      to={search?.redirect ?? "/"}
      replace
    />
  }
  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-1 flex-row">
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require("assets/auth.png")}
            alt=""
          />
        </div>
        <div className="flex-1 h-full flex flex-col justify-start py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative max-w-1/3">
          <Outlet />
          <span className="absolute text-sm bottom-8 text-center inset-x-0">&copy; {new Date().getFullYear()} - Powered by <a href="https://dukesell.com/" target={"_blank"} className="text-blue-700 hover:text-blue-900" rel="noreferrer">Duke Soko Technologies LTD.</a></span>
        </div>

      </div>
    </div>
  )
}

export default OnBordingLayout;