import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { wrapClick } from 'utils';

type Props = {
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  ml?: boolean;
  variant?:
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'warning'
  | 'success'
  | 'info'
  | 'light'
  | 'dark'
  | 'minimal'
  | 'link';
  disabled?: boolean;
  width?: 'full' | 'auto';
  type?: 'button' | 'submit' | 'reset';
};



const OfficeButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  disabled,
  type,
  ml,
  size = 'md',
  variant = 'primary',
  width = 'auto',
}) => {
  return (
    <button
      type={type ?? 'button'}
      disabled={disabled}
      onClick={wrapClick(() => onClick?.())}
      className={clsx(
        'rounded-md   disabled:cursor-not-allowed px-4 py-2 text-sm leading-4 shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 w-full text-center flex items-center justify-center md:w-auto',
        {
          'text-sm leading-4 font-medium': size === 'sm',
          'text-base leading-6 font-medium': size === 'md',
          'text-lg leading-6 font-medium': size === 'lg',
          'bg-primary-600 border border-transparent text-white focus:ring-primary-500 hover:bg-primary-700':
            variant === 'primary',
          'bg-yellow-600 border border-transparent focus:ring-yellow-500 text-white hover:bg-yellow-700':
            variant === 'warning',
          'bg-teal-600 border border-transparent focus:ring-teal-500 text-white hover:bg-teal-700':
            variant === 'success',
          'bg-gray-600 border border-transparent focus:ring-gray-500 text-white hover:bg-gray-700':
            variant === 'tertiary',
          'bg-red-600 border border-transparent focus:ring-red-500 text-white hover:bg-red-700':
            variant === 'danger',
          'bg-white border  text-gray-500  border-gray-300 focus:ring-gray-200 hover:bg-red-700 hover:text-white':
            variant === 'minimal',
          'w-full justify-center': width === 'full',
          'md:ml-2': ml
        }
      )}>
      {children}
    </button>
  );
};

export default OfficeButton;
