import { getProducts } from '_graphql/queries/products';
import { GetProducts,GetProductsVariables } from '_graphql/queries/__generated__/GetProducts';
import { GetProducts_getProducts } from '_graphql/queries/__generated__/GetProducts';
import { ActionButton } from 'components';
import { TColumn, TableColumn } from 'utils/types';
import image from "assets/noProduct.png"

export const ProductsColumn: TableColumn<TColumn> = [
  {
    name: 'Product Name',
    accessor: 'title',
    render: (product: GetProducts_getProducts | null) => (
      <div className='flex items-center max-w-2xl break-all truncate text-xs'>
        <div className='h-10 w-10 flex-shrink-0 text-xs'>
          <img
            className='h-10 w-10 rounded-none'
            src={product?.avatar || image}
            alt=''
          />
        </div>
        <div className='ml-4 break-all flex-wrap text-xs'>
          <div className=' text-gray-900 truncate flex-wrap text-xs'>
            {product?.title.toLocaleUpperCase()}
          </div>
        </div>
        
      </div>
    ),
  },
  {
    name: 'Sku',
    accessor: 'title',
    render: (product: GetProducts_getProducts | null) => (
      <div className='flex items-center max-w-2xl break-all truncate text-xs'>
        
        <div className=' break-all flex-wrap text-xs'>
          <div className=' text-gray-900 truncate flex-wrap text-xs'>
            {product?.sku}
          </div>
        </div>
        
      </div>
    ),
  },
  {
    name: 'Cost',
    accessor: 'title',
    render: (product: GetProducts_getProducts | null) => (
      <div className='flex items-center max-w-2xl break-all truncate text-xs'>
        
        <div className=' break-all flex-wrap text-xs'>
          <div className=' text-gray-900 truncate flex-wrap text-xs'>
           {product?.currency} {product?.cost_price}
          </div>
        </div>
        
      </div>
    ),
  },
  {
    name: 'Qty',
    accessor: 'title',
    render: (product: GetProducts_getProducts | null) => (
      <div className='flex items-center max-w-2xl break-all truncate text-xs'>
        
        <div className=' break-all flex-wrap text-xs'>
          <div className=' text-gray-900 truncate flex-wrap text-xs'>
           {product?.qty} 
          </div>
        </div>
        
      </div>
    ),
  },
  // {
  //   name: 'Actions',
  //   accessor: 'action',
  //   render: (category: GetProductCategory_getProductCategory | null) => (
  //     <div className='-ml-3'>
  //       <ActionButton
  //         action={'view'}
  //         tooltip='View Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //       <ActionButton
  //         action={'update'}
  //         tooltip='Update Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //       <ActionButton
  //         action={'remove'}
  //         tooltip='Delete Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //     </div>
  //   ),
  // },
];

 
