import { FC } from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

type Props = {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
};

const RichEditor: FC<Props> = ({
  id,
  disabled,
  required,
  values,
  handleChange,
  placeholder,
  label,
  errors,
  touched,
}) => {
  return (
    <>
      <label
        htmlFor={id}
        className='block text-sm font-medium text-gray-700 mb-1'>
        {label} {required ? <span className='text-red-500'>*</span> : ''}
      </label>
      <div>
        <ReactQuill
          theme='snow'
          readOnly={disabled}
          value={_.get(values, id, '')}
          onChange={(value) => {
            handleChange({
              target: {
                name: id,
                value,
              },
            });
          }}
          placeholder={placeholder || 'Type your description ...'}
        />
      </div>
      <p className='text-[11px] text-gray-500'>For better expression of what you have to type, We recommend that you either use Microsoft Word or Google docs to type it out and paste it here in the box.</p>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className='mt-2 text-sm text-red-600' id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default RichEditor;
