import { setSupplierSetupConfig, useAppNaviagte, useCurrentUser } from '_graphql/cache/auth';
import { Modal, OfficeButton, toast } from 'components';
import { FC, useEffect } from 'react'
import PaymentDetailsForm from './forms/payment-details';
import { useFormik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PaystackResolveAccountNumber, PaystackResolveAccountNumberVariables } from '_graphql/queries/__generated__/PaystackResolveAccountNumber';
import { resolvePaymentMethod } from '_graphql/queries/suppliers';
import { useDebounce } from 'hooks';
import { AddSupplierPaymentDetails, AddSupplierPaymentDetailsVariables } from '_graphql/mutation/__generated__/AddSupplierPaymentDetails';
import { addSupplierPaymentDetails } from '_graphql/mutation/payment';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

export type IPaymentMethod = { provider: any, account_name: string, account_number: string }

const CreatePaymentDetailsContainer: FC<Props> = ({ setOpen, open }) => {
    const currentUser = useCurrentUser();
    const navigate = useAppNaviagte();
    const [resolve, { loading }] = useLazyQuery<PaystackResolveAccountNumber, PaystackResolveAccountNumberVariables>(resolvePaymentMethod);
    const [create, { loading: creating }] = useMutation<AddSupplierPaymentDetails, AddSupplierPaymentDetailsVariables>(addSupplierPaymentDetails, {
        refetchQueries: ["GetSupplier"]
    });

    const form = useFormik<IPaymentMethod>({
        initialValues: {
            provider: null,
            account_number: "",
            account_name: "",
        },
        onSubmit: (values) => {
            create({
                variables: {
                    input: {
                        account_name: values.account_name,
                        account_number: values.account_number,
                        provider: values.provider?.code,
                        supplier_id: currentUser.id,
                        method: values.provider?.code === "mobile_money" ? "mobile_money" : "bank",
                    }
                }
            }).then((res) => {
                setOpen(false)
                if (res.data?.addSupplierPaymentDetails) {
                    navigate({
                        search: (old) => ({
                            ...old,
                            modal: undefined,
                            id: undefined,
                        }),
                    })
                    toast("Payment details added successfully", "success");
                    setSupplierSetupConfig({
                        hasPaymentMethod: true,
                    })
                } else {
                    toast("Failed to add payment details", "error")
                }
            }).catch((err) => {
                console.log(err)
                toast("Failed to add payment details", "error")
            })
        },
    })


    const handleValidation = async () => {
        form.setFieldValue("account_name","")
        if (form.values.provider && form.values.account_number) {
            resolve({
                variables: {
                    data: {
                        bank_code: form.values.provider?.code,
                        account_number: form.values.account_number,
                    },
                },
            })
                .then((res) => {
                    if (res.data?.paystackResolveAccountNumber.status) {
                        form.setFieldValue("account_name", res.data.paystackResolveAccountNumber.data.account_name);
                    } else {
                        toast("Invalid account number", "error");
                    }
                })
                .catch((err) => {
                    toast("Something wrong happened. Try again", "error");
                });
        }
    }

    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            title="Payment Details"
            description="View your payment details."
            renderActions={() => (
                <OfficeButton ml onClick={form.handleSubmit}>
                    {creating ? "Adding details ..." : "Add details"}
                </OfficeButton>
            )}
        >
            <PaymentDetailsForm form={form} resolving={loading} handleOnBlur={handleValidation} />
        </Modal>
    )
}

export default CreatePaymentDetailsContainer