import { useMutation, useReactiveVar } from '@apollo/client';
import { currentUserVar } from '_graphql/cache/auth';
import {
  ActionButton,
  OfficeButton,
  OfficeHeader,
  Paginator,
  TableComponent,
  TableLoader,
  toast,
} from 'components';
import { usePagination } from 'hooks';
import { FC } from 'react';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useDiscounts } from './hooks/use-discounts';
import { DiscountColumn } from './table-columns';
import DeleteDiscountContainer from './delete';
import { dispatchAction, naviagteTo, useUrlState } from 'utils';
import ViewDiscountContainer from './view';
import { GetDiscount_getDiscount } from '_graphql/queries/__generated__/GetDiscount';
import UpdateDiscountContainer from './update';
import { UserType } from '_graphql/__generated__/globalTypes';
import {
  UpdateDiscount,
  UpdateDiscountVariables,
} from '_graphql/mutation/__generated__/UpdateDiscount';
import { updateDiscount } from '_graphql/mutation/discount';
import { GetDiscounts_getDiscounts } from '_graphql/queries/__generated__/GetDiscounts';
import Toggle from 'pages/create-product/components/Toggle';

type Props = {};

const DiscountPage: FC<Props> = (props) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);
  const [modal, setModal] = useUrlState('modal');
  const { offset, limit, setPage } = usePagination();

  const [initiate, { loading: updating }] = useMutation<UpdateDiscount, UpdateDiscountVariables>(
    updateDiscount,
    {
      refetchQueries: ['GetDiscounts'],
    }
  );

  const { discount, count, refetch, loading } = useDiscounts({
    variables: {
      pagination: {
        limit: limit,
        offset: offset,
      },
      filter: {
        user_id: currentUser?.id
          ? {
              eq: currentUser?.id,
            }
          : undefined,
        created_by: {
          eq: UserType.SUPPLIER,
        },
        code: searchParams?.search
          ? {
              like: `${searchParams.search}`,
            }
          : undefined,
      },
    },
  });
  const goToCreateDiscount = () => {
    navigate({
      to: `./create-discount`,
    });
  };

  const handleVisibilty = async (id: string, value: boolean) => {
    await initiate({
      variables: {
        filter: {
          id: { eq: id },
        },
        data: {
          is_draft: value,
        },
      },
    })
      .then(() => {
        toast('Discount visibility updated successfully', 'success');
      })
      .catch((err) => {
        toast(err.message, 'error');
      });
  };

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={goToCreateDiscount}>
            <svg
              className='-ml-1 mr-2 h-5 w-5'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'>
              <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
            </svg>
            Add Discount
          </OfficeButton>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={'Discounts'}
            columns={DiscountColumn.concat([
              {
                name: 'Visibility',
                accessor: 'is_draft',
                render: (discount: GetDiscounts_getDiscounts | null) => (
                  <Toggle
                    disabled={updating}
                    value={discount?.is_draft || false}
                    setValue={(value) => handleVisibilty(discount?.id || '', value as boolean)}
                  />
                ),
              },
              {
                name: 'Actions',
                accessor: 'action',
                render: (discount: GetDiscount_getDiscount) => (
                  <div className='-ml-3'>
                    <ActionButton
                      action={'view'}
                      tooltip='View Discount'
                      onClick={dispatchAction(discount?.id, 'view', navigate)}
                    />
                    <ActionButton
                      action={'expand'}
                      tooltip='Update Discount Product'
                      onClick={() =>
                        navigate({
                          to: `./view/${discount?.id}`,
                          search(prev) {
                            return {
                              ...prev,
                            };
                          },
                        })
                      }
                    />
                    <ActionButton
                      action={'update'}
                      tooltip='Update Discount'
                      onClick={dispatchAction(discount?.id, 'update', navigate)}
                    />
                    <ActionButton
                      action={'remove'}
                      tooltip='Delete Discount'
                      onClick={dispatchAction(discount?.id, 'remove', navigate)}
                    />
                  </div>
                ),
              },
            ])}
            data={discount}
            hasSearch
            refetch={refetch}
            loading={loading}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1]} />}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={discount.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>
      {!searchParams?.id?.length && (
        <DeleteDiscountContainer
          open={modal === 'remove'}
          setOpen={(val: boolean) => setModal(val ? 'remove' : undefined)}
        />
      )}
      {!searchParams?.id?.length && (
        <ViewDiscountContainer
          open={modal === 'view'}
          setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
        />
      )}
      {!searchParams?.id?.length && (
        <UpdateDiscountContainer
          open={modal === 'update'}
          setOpen={(val: boolean) => setModal(val ? 'update' : undefined)}
        />
      )}
    </main>
  );
};

export default DiscountPage;
