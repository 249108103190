import { useMutation, useReactiveVar } from '@apollo/client';
import { currentUserVar } from '_graphql/cache/auth';
import { RemoveResellerBySupplier, RemoveResellerBySupplierVariables } from '_graphql/mutation/__generated__/RemoveResellerBySupplier';
import { removeResellerBySupplier } from '_graphql/mutation/removereseller';
import { OfficeButton, PopUp, toast } from 'components'
import React, { FC } from 'react'
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const RemoveResellerContainer: FC<Props> = ({ open, setOpen }) => {
    const currentUser = useReactiveVar(currentUserVar)
    const searchParams = useSearch<LocationGenerics>();
    const [remove, { loading }] = useMutation<
        RemoveResellerBySupplier,
        RemoveResellerBySupplierVariables
    >(removeResellerBySupplier);
    return (
        <PopUp title={'Remove Reseller'}
            description={'Are you sure you want to remove this reseller?'}
            variant='danger'
            open={open} setOpen={setOpen} renderActions={() => (
                <div className='ml-2'>
                    <OfficeButton onClick={() => remove({
                        variables: {
                            resellerId: searchParams?.id || "",
                            supplierId: currentUser?.id
                        }
                    }).then(({ data }) => {
                        if (data?.removeResellerBySupplier) {
                            toast("Reseller removed successfully", "success")
                            setOpen(false)
                        }
                    }).catch(err => {
                        toast(err.message, "error")
                    })} variant='danger'>
                        {loading ? "Removing..." : "Remove Reseller"}
                    </OfficeButton>
                </div>

            )} />
    )
}

export default RemoveResellerContainer