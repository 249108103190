import { Modal, OfficeButton, toast } from 'components';

import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { usePartnership } from './hooks/use-partnerships';
import ApprovePartnerForm from './forms/approve-form';
import { useFormik } from 'formik';
import { useCurrentUser } from '_graphql/cache/auth';
import { useMutation } from '@apollo/client';
import { ApprovePartnership, ApprovePartnershipVariables } from '_graphql/mutation/__generated__/ApprovePartnership';
import { approvePartnership } from '_graphql/mutation/partnerships';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const ApprovePartnershipContainer = ({
    open,
    setOpen,
}: Props) => {
    const navigate = useNavigate<LocationGenerics>()
    const currentUser = useCurrentUser()
    const seacrhParams = useSearch<LocationGenerics>();
    const [approve, { loading: approving }] = useMutation<ApprovePartnership, ApprovePartnershipVariables>(approvePartnership, {
        refetchQueries: ["GetPartnerships"]
    });
    const { partnership, loading } = usePartnership({
        filter: {
            id: {
                eq: seacrhParams?.id
            }
        }
    });

    const form = useFormik({
        initialValues: {
            supplier_id: currentUser?.id,
            terms_id: "",
        },
        onSubmit: (values) => {
            approve({
                variables: {
                    approvePartnershipId: seacrhParams?.id || "",
                    termId: values.terms_id
                }
            }).then(res => {
                toast("Partnership Approved", "success")
            }).catch(err => {
                toast(err?.message, "error");
            })
        }
    });

    return (
        <Modal
            open={open}
            size='6xl'
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            loading={loading}
            title="Approve Partnership"
            description="Select a term to approve the partnership"
            renderActions={() => (
                <div className='md:ml-3 flex space-x-3'>
                    <OfficeButton disabled={approving} onClick={form.handleSubmit}>
                        {approving ? "Approving ..." : "Approve"}
                    </OfficeButton>
                </div>
            )}

        >
            <ApprovePartnerForm partnership={partnership} form={form} />
        </Modal>
    )
}

export default ApprovePartnershipContainer