import toater from "react-hot-toast";

const toast = (message: string, type: "success" | "error" | "loading" | "default" = "default") => {
    switch (type) {
        case "success":
            return toater.success(message, {
                icon: "👏",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
                position: "top-center",
            });
        case "error":
            return toater.error(message, {
                icon: "🚨",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
                position: "top-center",
            });
        case "loading":
            return toater.loading(message, {
                icon: "🚨",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
                position: "top-center",

            });
        default:
            return toater(message);
    }
};

export default toast;