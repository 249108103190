import { useQuery } from "@apollo/client"
import { GetCart, GetCartVariables } from "_graphql/queries/__generated__/GetCart"
import { getCart } from "_graphql/queries/orders"

export const useCart = (variables?: GetCartVariables) => {
    const { data, ...rest } = useQuery<GetCart, GetCartVariables>(getCart, {
        variables
    })
    return {
        cart: data?.getCart,
        ...rest
    }
}