import { ReactElement } from 'react';
import { ReactLocation, MakeGenerics } from 'react-location';
import { parseSearch, stringifySearch } from 'react-location-jsurl';

export type LocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
    from?: string;
    modal?:
      | 'create'
      | 'update'
      | 'update_product_information'
      | 'update_product_media'
      | 'update_product_description'
      | 'update_product_inventory'
      | 'update_product_category'
      | 'update_product_tag'
      | 'update_product_variation'
      | 'update_product_distribution'
      | 'update_discount_product'
      | 'update_slug'
      | 'view'
      | 'configure'
      | 'calculate'
      | 'assign'
      | 'schedule'
      | 'send'
      | 'resolve'
      | 'approve'
      | 'reject'
      | 'reassign'
      | 'export'
      | 'import'
      | 'investigate'
      | 'remove'
      | 'assignPercentage'
      | 'close';
    id?: string;
    role?: 'SuperAdmin' | 'Admin' | 'Supervisor' | 'Reader';
    month?: number;
    year?: number;
    customerType?: string;
    view?: 'day' | 'week' | 'month' | 'quarter' | 'year' | 'custom';
    orderStatus?: string;
    token?: string;
    email?: string;
    message?: string;
    viewType: 'grid' | 'list';
    term?: string;
    supplier?: string;
    country?: string;
    activeTab?: string;
  };

  Params: {
    user_id?: string;
    supplier_id?: string;
    customer_id?: string;
    product_id?: string;
    discount_id?: string;
  };
  RouteMeta: {
    layout?: 'App' | 'Auth';
    breadcrumb: (params: LocationGenerics['Params']) => ReactElement;
    section?: 'General' | 'Structure' | 'Configuration' | 'Users & Profiles';
  };
}>;

const location = new ReactLocation({
  parseSearch,
  stringifySearch,
});

export default location;
