import { gql } from "@apollo/client";

export const addPluginToUser = gql`
mutation AddPluginToUser($data: AddPluginToUserRequestDto!) {
  addPluginToUser(data: $data) {
    id
    user_id
    plugin_id
  }
}
`

export const removePluginFromUser = gql`
mutation RemovePluginFromUser($filter: UserPluginFilter!) {
  removePluginFromUser(filter: $filter)
}
`
export const removeShopifyShop = gql`
mutation RemoveShopifyShop($supplierId: ID!) {
  removeShopifyShop(supplier_id: $supplierId)
}
`