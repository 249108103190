import { ActionButton, Paginator, TableComponent, TableLoader,toast } from "components";
import { usePagination } from "hooks";
import { FC, useEffect, useState } from "react";
import { useProducts } from "../hooks/use-products";
import { useAppNaviagte, useCurrentUser } from "_graphql/cache/auth";
import { PartnershipProductColumn } from "../partnership-table-columns";
import { dispatchAction, useUrlState } from "utils";
import ViewProductContainer from "../view";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import DeleteProductContainer from "../delete";
import { useShopifyShop } from "../../shopify/hooks/useShopifyShop";
import { GetProducts_getProducts } from "_graphql/queries/__generated__/GetProducts";

type Props = {
  selectedProductIds: string[];
  setSelectedProductIds: React.Dispatch<React.SetStateAction<string[]>>;
  loadingProductsIds: string[];
  setLoadingProductIds: React.Dispatch<React.SetStateAction<string[]>>;
};

interface ProductWithUploadStatus extends GetProducts_getProducts {
  uploadedToShopify: boolean;
}

const PartnershipProductsTab: FC<Props> = ({
  setSelectedProductIds,
  selectedProductIds,
  loadingProductsIds,
  setLoadingProductIds,
}) => {
  const navigate = useAppNaviagte();
  const [modal, setModal] = useUrlState("modal");
  const currentUser = useCurrentUser();
  const searchParams = useSearch<LocationGenerics>();
  const { offset, limit, setPage } = usePagination();
  const { shopData } = useShopifyShop({
    input: {
      filter: {
        supplier_id: { eq: currentUser?.id },
      },
    },
  });
  const shopifyresellingproducts = shopData?.shop_reselling_products;
  console.log(shopifyresellingproducts);
  const [shopifyResellingProductIds, setShopifyResellingProductIds] = useState<
    any[]
  >([]);
  useEffect(() => {
    if (shopifyresellingproducts) {
      const resellingIds = shopifyresellingproducts.map(
        (product) => product?.product
      );
      setShopifyResellingProductIds(resellingIds);
    }
  }, [shopifyresellingproducts]);
  console.log(shopifyResellingProductIds);
  const handleCheckboxClick = (productId: string) => {
    toast("Added to cart,proceed to load product on top right corner","success")
    setSelectedProductIds((prevIds) => {
      const index = prevIds.indexOf(productId);

      if (index === -1) {
        return [...prevIds, productId];
      } else {
        const newArray = [...prevIds];
        newArray.splice(index, 1);
        return newArray;
      }
    });
  };

  const { products, count, refetch, loading } = useProducts({
    variables: {
      getProductsFilter: {
        // supplier_id: {
        //   eq: currentUser.id,
        // },
        partner: {
          eq: currentUser.id,
        },
        title:searchParams?.search? {
          like:`${searchParams.search}`
        }:undefined
      },
      getProductsPagination: {
        limit: limit,
        offset: offset,
      },
    },
  });

  const productsWithUploadStatus = products.map((product) => ({
    ...product,
    uploadedToShopify: shopifyResellingProductIds.includes(product?.id!),
  }));

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      {/* <OfficeHeader renderActions={() => <AddProductButton />} /> */}
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"Products"}
            renderLoader={() => <TableLoader items={[2, 1, 1, 1, 1]} />}
            columns={[
              {
                name: "",
                accessor: "id",
                render: (product: GetProducts_getProducts | null) => (
                  <input
                    type="checkbox"
                    className="ml-4 border-gray-200 rounded-sm"
                    checked={selectedProductIds.includes(product?.id || "")}
                    onChange={() => handleCheckboxClick(product?.id || "")}
                  />
                ),
              },
              ...PartnershipProductColumn,
              {
                name: "Reselling Platforms",
                accessor: "id",
                render: (product: ProductWithUploadStatus) => (
                  <div>
                    {product?.uploadedToShopify ? (
                      <div className="border rounded-full text-center w-16 text-xs text-green-500 bg-green-100 pb-1">
                        shopify
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="text-xs">
                      {loadingProductsIds.includes(product?.id) && (
                        <span>Loading to shopify...</span>
                      )}
                    </div>
                  </div>
                ),
              },
              {
                name: 'Actions',
                accessor: 'action',
                render: (product: ProductWithUploadStatus) => {
                  return (
                    <div className='-ml-3'>
                      <ActionButton
                        action={'view'}
                        tooltip='View Product'
                        onClick={dispatchAction(product?.id, 'view', navigate)}
                      />
                    </div>
                  );
                },
              },
            ]}
            data={productsWithUploadStatus}
            loading={loading}
            refetch={refetch}
            hasSearch
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={products.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>
      {!searchParams?.id?.length && (
        <ViewProductContainer
          open={modal === "view"}
          setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
        />
      )}

      {!searchParams?.id?.length && (
        <DeleteProductContainer
          open={modal === "remove"}
          setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
        />
      )}
    </main>
  );
};

export default PartnershipProductsTab;
