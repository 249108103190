import { ActionButton, OfficeHeader, Paginator, TableComponent } from 'components'
import { FC, useState, useEffect } from 'react'
import { usePartnerships } from './hooks/use-partnerships'
import { useAppNaviagte, useAppSearch, useCurrentUser } from '_graphql/cache/auth'
import { usePagination } from 'hooks'
import { GetPartnerships_rows } from '_graphql/queries/__generated__/GetPartnerships'
import { classNames, dispatchAction, useUrlState } from 'utils'
import ViewPartnershipContainer from './view'
import ApprovePartnershipContainer from './approve'
import { Link } from 'react-location'
import { SegmentedControl } from '@mantine/core'

type Props = {}

const tabs = [
    { name: "Pending", href: "Pending" },
    { name: "Approved", href: "Approved" },
    { name: "Rejected", href: "Rejected" },

];

const PartnershipsPage: FC<Props> = () => {
    const currentUser = useCurrentUser()
    const navigate = useAppNaviagte();
    const [activeTab] = useUrlState("activeTab");
    const [type, setType] = useState('incoming')

    const searchParams = useAppSearch()
    const { limit, offset, setPage } = usePagination()
    const { partnerships, loading, refetch, count } = usePartnerships({
        filter: {
            supplier_id: { eq: currentUser?.id },
            status: !activeTab ? undefined : activeTab,
            // name:searchParams?.search? {
            //     like:`${searchParams.search}`
            //   }:undefined
        },
        pagination: {
            limit,
            offset
        }
    })

    useEffect(() => {
        if (type === 'requested') {
            refetch({
                filter: {
                    client_id: { eq: currentUser?.id }
                }
            })
        } else {
            refetch({
                filter: {
                    supplier_id: { eq: currentUser?.id }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])
    return (
        <>
            <OfficeHeader renderActions={() => (
                <div className='flex'>
                    <SegmentedControl defaultValue='incoming' data={[{ label: 'Incoming Partnerships', value: 'incoming' }, { label: 'Requested Partnerships', value: 'requested' }]} onChange={setType} value={type} />
                </div>
            )} />
            <div className="block">
                <div className=" flex flex-row justify-between border-b border-gray-200 bg-white px-6">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        <Link
                            search={(old) => ({
                                ...old,
                                activeTab: undefined,
                            })}
                            className={classNames(
                                !activeTab
                                    ? "border-primary-500 text-primary-600"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={!activeTab ? "page" : undefined}
                        >
                            All
                        </Link>
                        {tabs.map((_activeTab) => (
                            <Link
                                key={_activeTab.name}
                                search={(old) => ({
                                    ...old,
                                    activeTab: _activeTab.href,
                                })}
                                className={classNames(
                                    activeTab === _activeTab.href
                                        ? "border-primary-500 text-primary-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    activeTab === _activeTab.href ? "page" : undefined
                                }
                            >
                                {_activeTab.name}
                            </Link>
                        ))}
                    </nav>
                </div>

            </div>
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent hasSearch
                        defaultView='grid'
                        title={'partnerships'} loading={loading} columns={[
                            {
                                name: 'Name',
                                accessor: 'name',
                            },
                            {
                                name: 'Status',
                                accessor: 'status',
                            },
                            {
                                name: 'Created At',
                                accessor: 'createdAt',
                            },
                            {
                                name: 'Updated At',
                                accessor: 'updatedAt',
                            },
                            {
                                name: 'Actions',
                                accessor: 'id',
                                render: (row: GetPartnerships_rows) => (
                                    <>
                                        <ActionButton onClick={dispatchAction(row?.id, "view", navigate)} tooltip='View Partnership' action={'view'} />
                                    </>
                                )
                            }
                        ]} data={partnerships} refetch={refetch}
                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={count} currentSize={partnerships.length} setOffset={setPage} />}
                    />
                </div>
            </div>

            {searchParams?.id && (
                <>
                    <ViewPartnershipContainer
                        type={type}
                        open={searchParams?.modal === 'view'}
                        setOpen={() => {
                            navigate({
                                search: (old) => ({
                                    ...old,
                                    modal: undefined,
                                    id: undefined,
                                }),
                            })
                        }}
                    />
                    <ApprovePartnershipContainer
                        open={searchParams?.modal === 'approve'}
                        setOpen={() => {
                            navigate({
                                search: (old) => ({
                                    ...old,
                                    modal: undefined,
                                    id: undefined,
                                }),
                            })
                        }}
                    />
                </>
            )}
        </>
    )
}

export default PartnershipsPage