import { useMutation } from '@apollo/client';
import {
  CountryCode,
  DiscountType,
  FixedAmountAllocation,
  ProductCondition,
  UserType,
} from '_graphql/__generated__/globalTypes';
import { useAppNaviagte, useCurrentUser } from '_graphql/cache/auth';
import {
  CreateDiscount,
  CreateDiscountVariables,
} from '_graphql/mutation/__generated__/CreateDiscount';
import { createDiscount } from '_graphql/mutation/discount';
import {
  Avatar,
  DeliveryLocationInput,
  List,
  OfficeButton,
  OfficeHeader,
  RichEditor,
  SelectInput,
  Show,
  TextInput,
  toast,
} from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SectionLayout from 'pages/create-product/components/SectionLayout';
import Toggle from 'pages/create-product/components/Toggle';
import { FC, useState } from 'react';
import SelectProductContainer from './select-product';
import _ from 'lodash';
import { useProducts } from 'pages/products/hooks/use-products';
import moment from 'moment';

import UploadProductContainer from './upload-product';

type Props = {};

type DeliveryType = {
  value: string;
  label: string;
};

const CreateDiscountPage: FC<Props> = (props) => {
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [openProduct, setOpenProduct] = useState<boolean>(false);
  const [openProductUpload, setOpenProductUpload] = useState<boolean>(false);
  const [discountCreation, { loading: creating }] = useMutation<
    CreateDiscount,
    CreateDiscountVariables
  >(createDiscount, {
    refetchQueries: ['GetDiscounts'],
  });

  const currentUser = useCurrentUser();
  const navigate = useAppNaviagte();

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };
  const handleCheckboxChange = (event: any) => {
    if (discountInfoForm.values?.products?.includes(event.target.value)) {
      const nextValue = discountInfoForm.values.products.filter(
        (value: any) => value !== event.target.value
      );
      discountInfoForm.setFieldValue('products', nextValue);
    } else {
      discountInfoForm.setFieldValue('products', [
        ...discountInfoForm.values.products,
        event.target.value,
      ]);
    }
  };

  const discountInfoForm = useFormik({
    initialValues: {
      discount_type: 'PERCENTAGE',
      sku_column: '',
      description: '',
      start_date: '',
      expiry_date: '',
      code: '',
      search: '',
      r_limit: null,
      tagname: null,
      amount: null,
      percentage: null,
      products: [] as string[],
      products_sku: [] as any[],
      delivery: null as DeliveryType[] | null,
    },
    validationSchema: Yup.object().shape({
      percentage: Yup.string().when('discount_type', {
        is: (dtype: string) => dtype === 'PERCENTAGE',
        then: () => Yup.string().required('Percentage value is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      amount: Yup.string().when('discount_type', {
        is: (dtype: string) => dtype === 'FIXED_AMOUNT',
        then: () => Yup.string().required('Amount value is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      tagname: Yup.string().when('discount_type', {
        is: (dtype: string) => dtype === 'FREE_DELIVERY',
        then: () => Yup.string().required('Location tagname is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      delivery: Yup.array().when('discount_type', {
        is: (dtype: string) => dtype === 'FREE_DELIVERY',
        then: () =>
          Yup.array()
            .min(1, 'Choose at least one delivery location')
            .required('Delivery Location is required'),
        otherwise: () => Yup.array().notRequired(),
      }),
      sku_column: Yup.string().when('product_sku', {
        is: (prod: string[]) => prod?.length < 0,
        then: () => Yup.string().required('SKU Column is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      discount_type: Yup.string().required('Discount type is required'),
      expiry_date: Yup.date()
        .required('End date is required')
        .min(
          Yup.ref('start_date'),
          'End date must be equal to or later than the start date'
        ),
      start_date: Yup.date()
        .required('Start date is required')
        .min(
          getToday(),
          'Start date must be equal to or later than today'
        ),
      description: Yup.string().required('Description is required'),
      r_limit: Yup.string().required('Redemption limit is required'),
      code: Yup.string().required('Code name is required'),
    }),
    onSubmit: async (values) => {
      await discountCreation({
        variables: {
          input: {
            type: values.discount_type as DiscountType,
            valid_country: currentUser.country as CountryCode,
            description: values.description,
            code: values.code,
            is_draft: isDraft,
            start_date: moment(values.start_date).format('DD/MM/YYYY'),
            expiry_date: moment(values.expiry_date).format('DD/MM/YYYY'),
            amount:
              values.discount_type === 'FIXED_AMOUNT' ? values.amount : null,
            locations_tagname:
              values.discount_type === 'FREE_DELIVERY' ? values.tagname : null,
            locations:
              values.discount_type === 'FREE_DELIVERY'
                ? values.delivery?.map((_deli) => _deli.value)
                : null,
            fixed_amount_allocation:
              values.discount_type === 'FIXED_AMOUNT'
                ? FixedAmountAllocation.TOTAL_AMOUNT
                : null,
            percentage:
              values.discount_type === 'PERCENTAGE' ? values.percentage : null,
            products: values.products,
            user_id: currentUser?.id,
            redemptions_limit: values.r_limit,
            created_by: UserType.SUPPLIER,
            product_condition: ProductCondition.IN,
          },
        },
      })
        .then(({ data }) => {
          if (data?.createDiscount) {
            toast('Discount created successfully', 'success');
            discountInfoForm.resetForm();
            navigate({
              to: '/discount',
            });
          } else {
            toast('Something went wrong', 'error');
          }
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });

  const { products, loading: productLoading } = useProducts({
    variables: {
      getProductsPagination: {
        limit: discountInfoForm.values.products.length,
        offset: 0,
      },
      getProductsFilter: {
        supplier_id: {
          eq: currentUser?.id,
        },
        sku: { in: discountInfoForm.values.products },
      },
    },
  });
  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader />
      <div className='w-full overflow-y-auto'>
        <form className='w-full py-5'>
          <div className=' grid grid-cols-1 md:grid-cols-5'>
            {/* left */}
            <div className='w-full col-span-3'>
              <SectionLayout title='Discount Information'>
                <>
                  <div className='col-span-full'>
                    <TextInput
                      id={'code'}
                      label='Code'
                      type='text'
                      description='This serves as a name for the discount'
                      placeholder='eg. WINTERSALE'
                      {...discountInfoForm}
                    />
                  </div>
                  <div className='col-span-full'>
                    <SelectInput
                      options={[
                        { label: 'Percentage', value: 'PERCENTAGE' },
                        {
                          label: 'Fixed Amount',
                          value: 'FIXED_AMOUNT',
                        },
                        {
                          label: 'Free Delivery',
                          value: 'FREE_DELIVERY',
                        },
                      ]}
                      id={'discount_type'}
                      label='Discount Type'
                      {...discountInfoForm}
                    />
                  </div>
                  <div className='col-span-full'>
                    <Show
                      if={
                        discountInfoForm.values.discount_type === 'PERCENTAGE'
                      }>
                      <TextInput
                        id={'percentage'}
                        label='Percentage'
                        type='number'
                        description='The value must be between 1% - 99%'
                        min={1}
                        placeholder='eg. 20'
                        {...discountInfoForm}
                      />
                    </Show>
                    <Show
                      if={
                        discountInfoForm.values.discount_type === 'FIXED_AMOUNT'
                      }>
                      <div className='space-y-3'>
                        <TextInput
                          id={'amount'}
                          label='Amount'
                          type='number'
                          min={1}
                          {...discountInfoForm}
                        />
                      </div>
                    </Show>
                    <Show
                      if={
                        discountInfoForm.values.discount_type ===
                        'FREE_DELIVERY'
                      }>
                      <div className='space-y-3'>
                        <TextInput
                          id={'tagname'}
                          label='Location Tagname'
                          placeholder='eg. Accra Environs'
                          type='text'
                          {...discountInfoForm}
                        />

                        <div className='col-span-full'>
                          <DeliveryLocationInput form={discountInfoForm} />
                        </div>
                      </div>
                    </Show>
                  </div>

                  <div className='col-span-3'>
                    <TextInput
                      type='date'
                      label='Start Date'
                      id='start_date'
                      {...discountInfoForm}
                    />
                  </div>
                  <div className='col-span-3'>
                    <TextInput
                      label='Expiry Date'
                      type='date'
                      id={'expiry_date'}
                      {...discountInfoForm}
                    />
                  </div>
                  <div className='col-span-full'>
                    <TextInput
                      label='Redemption Limit'
                      type='number'
                      id={'r_limit'}
                      {...discountInfoForm}
                    />
                  </div>
                  <div className='col-span-full'>
                    <RichEditor
                      id={'description'}
                      label='Discount Description'
                      {...discountInfoForm}
                    />
                  </div>
                </>
              </SectionLayout>
            </div>
            {/* right */}
            <div className='md:col-span-2'>
              <div className='md:sticky top-8'>
                <SectionLayout
                  title={'Product List'}
                  renderActions={() => (
                    <div className='flex space-x-2'>
                      <OfficeButton
                        size='sm'
                        onClick={() => setOpenProductUpload(true)}>
                        Upload
                      </OfficeButton>{' '}
                      <OfficeButton
                        size='sm'
                        onClick={() => setOpenProduct(true)}>
                        Add
                      </OfficeButton>
                    </div>
                  )}>
                  <div className='col-span-full max-h-96 overflow-auto'>
                    {discountInfoForm.values?.products.length > 0 && (
                      <List
                        data={discountInfoForm?.values?.products || []}
                        renderItem={(data) => {
                          const newProduct = products?.find(
                            (prod) => prod?.sku === data
                          );
                          if (newProduct) {
                            return (
                              <div
                                key={newProduct?.id}
                                className='relative flex items-start pb-4 pt-3.5'>
                                <div className='mr-3 flex items-start'>
                                  <Avatar
                                    alt={'Product avatar'}
                                    size='sm'
                                    src={newProduct?.avatar}
                                  />
                                </div>
                                <div className='min-w-0 flex-1 capitalize text-sm leading-6'>
                                  <label
                                    htmlFor={_.snakeCase(newProduct?.title)}
                                    className='font-medium text-gray-900'>
                                    {newProduct?.title}
                                  </label>
                                  <p
                                    id='candidates-description'
                                    className='text-gray-500'>
                                    {newProduct?.sku}
                                  </p>
                                </div>
                                <div className='ml-3 flex h-6 items-center'>
                                  <input
                                    type='checkbox'
                                    value={newProduct?.sku}
                                    className='h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600'
                                    onChange={handleCheckboxChange}
                                    checked={discountInfoForm.values?.products?.includes(
                                      newProduct?.sku
                                    )}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              key={data}
                              className='relative flex items-start pb-4 pt-3.5'>
                              <div className='mr-3 flex items-start'>
                                <Avatar alt={'Product Avatar'} size='sm' />
                              </div>
                              <div className='min-w-0 flex-1 capitalize text-sm leading-6'>
                                <label
                                  htmlFor={_.snakeCase(data)}
                                  className='font-medium text-gray-900'>
                                  Unknown Product
                                </label>
                                <p
                                  id='candidates-description'
                                  className='text-gray-500'>
                                  {data}
                                </p>
                              </div>
                              <div className='ml-3 flex h-6 items-center'>
                                <input
                                  type='checkbox'
                                  value={data}
                                  className='h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600'
                                  onChange={handleCheckboxChange}
                                  checked={discountInfoForm.values?.products?.includes(
                                    data
                                  )}
                                />
                              </div>
                            </div>
                          );
                        }}
                      />
                    )}
                  </div>
                </SectionLayout>
                <SectionLayout title={'Publish'}>
                  <>
                    <div className='col-span-full'>
                      <Toggle
                        label='Visibility'
                        description='Discount are set to draft ie unpublish until set to live.'
                        value={isDraft}
                        setValue={(value) => {
                          setIsDraft(value);
                          discountInfoForm.setFieldValue(
                            'isDraft',
                            value ? true : false
                          );
                        }}
                        display={isDraft ? 'Live' : 'Draft'}
                      />
                    </div>
                    <div className='col-span-full flex items-center justify-between'>
                      <OfficeButton
                        size='sm'
                        width='auto'
                        variant='danger'
                        onClick={() => navigate({ to: '/discount' })}>
                        Discard
                      </OfficeButton>
                      <OfficeButton
                        onClick={discountInfoForm.handleSubmit}
                        size='sm'
                        width='auto'
                        disabled={creating}
                        type='submit'>
                        {creating ? 'Saving...' : 'Save'}
                      </OfficeButton>
                    </div>
                  </>
                </SectionLayout>
              </div>
            </div>
          </div>
        </form>
      </div>
      <SelectProductContainer
        open={openProduct}
        setOpen={setOpenProduct}
        form={discountInfoForm}
        handleCheckboxChange={handleCheckboxChange}
      />
      <UploadProductContainer
        open={openProductUpload}
        setOpen={setOpenProductUpload}
        form={discountInfoForm}
        loading={productLoading}
      />
    </main>
  );
};

export default CreateDiscountPage;
