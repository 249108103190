import { useReactiveVar } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { currentUserVar } from "_graphql/cache/auth";
import { Modal, OfficeButton, TextInput } from "components";
import { useFormik } from "formik";


interface Props {
    open: boolean;
    setOpen: (val: any) => void;
}

const ConnectShop = ({ open, setOpen }: Props) => {
    const currentUser = useReactiveVar(currentUserVar);
    const form = useFormik({
        initialValues: {
            url: ""
        },
        onSubmit: (values) => {
            const redirectUrl = `${window.location.href.split('?')[0]}`
            window.location.href = `${process.env.REACT_APP_CONNECT_SHOPIIFY_URL}?shop=${values.url}&supplier_id=${currentUser?.id}&redirect_url=${redirectUrl}`
        }
    })

    // async function connectShopify() {
    //     // const res = await fetch(`https://supplier-core.staging.dukesell.dev/rest/shop/${currentUser?.id}?shop=${url}&redirect_url=${redirectUrl}`);
    //     // const data = await res.json();
    //     // return data

    // }


    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Connnect store"
            description="Connect your Shopify store to start selling products"
            renderActions={
                () =>
                    <div className="ml-2">
                        <OfficeButton
                            onClick={form.handleSubmit}
                            disabled={!form.isValid || !form.dirty}
                            variant="primary">
                            Install
                        </OfficeButton>
                    </div>
            }
        >
            <div>
                <div className="border-l-4 border-sky-400 bg-sky-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-sky-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-sky-800">
                                Your store is not connected</p>
                            <p className="text-sm text-sky-700">
                                To connect your Shopify store and start selling products, follow these steps:
                            </p>
                            <ol className="list-decimal pl-5">
                                <li className="text-sm text-sky-700">Log in to your Shopify admin account.</li>
                                <li className="text-sm text-sky-700">Navigate to the "Apps" section in the sidebar.</li>
                                <li className="text-sm text-sky-700">Search for our application and click "Add app."</li>
                                <li className="text-sm text-sky-700">Follow the prompts to authorize and connect your store.</li>
                            </ol>
                            <p className="text-sm text-sky-700">
                                If you encounter any issues, refer to our support documentation or contact our support team for assistance.
                            </p>
                        </div>
                    </div>
                </div>


                <div className="mt-5">
                    <TextInput id="url" label="Shopify domain" placeholder="Enter your url here" {...form} />
                    {/* <span className="text-xs">How to get your shopify domain</span> */}
                </div>
            </div>
        </Modal >
    )
}

export default ConnectShop