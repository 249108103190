import { gql } from "@apollo/client";

export const getCarts = gql`
   query GetCarts($filter: CartFilter, $pagination: Pagination) {
    count: getCartsLength(filter: $filter)
    rows: getCarts(filter: $filter, pagination: $pagination) {
      delivery_id
      id
      customer_id
      supplier_id
      customer_address_id
      user_id
      order_total
      security_deposit_paid
      security_deposit
      delivery_amount
      customer_paid
      cart_number
      payment_status
      discount
      free_delivery
      payment_id
      payment_type
      tracking_code
      delivery_instructions
      source
      country
      createdAt
      updatedAt
      payment {
        amount
        reference
        status
      }
      reseller {
        id
        email
        name
        country
        phone
        verified
        first_order_created
        user_type
        username
        google_id
        deviceToken
        facebook_id
        twitter_id
        supplier_shop_id
      }
      supplier {
        id
        name
        email
        phone
        country
        has_verified_phonenumber
        has_verified_email
        setup_complete
        generic_name
        device_token
        agreement_terms
        social_platform
        social_platform_token
        createdAt
        deletedAt
      }
      orderItems {
      id
      orderNumber
      quantity
      profitAdded
      status
      supplier_status
      source
      price
      sku
      order_id
      createdAt
      updatedAt
      product {
        sku
        id
        title
        limited_stock
        qty
        instantDelivery
        main_stock
        minimumOrderQuantity
        distributionChannels
        transit_stock
        price
        profit_added
        currency
        country
        description
        extra_information
        combination_string
        published
        inhouse
        attended_to
        verified
        cost_price
        weight
        avatar
        tags
        shop_id
        resell
        uuid
        likesCount
        savesCount
        sharesCount
        supplier_id
        created_at
        overalReview
      }
    }
      customer {
        id
        name
        email
        phone
      }
      delivery {
        id
        location
        price
        country
        remarks
        isPayOnline
      }
    }
  }
`;

export const getCart = gql`
query GetCart($filter: CartFilter) {
  getCart(filter: $filter) {
    delivery_id
    id
    customer_id
    supplier_id
    customer_address_id
    user_id
    order_total
    security_deposit_paid
    security_deposit
    delivery_amount
    customer_paid
    cart_number
    payment_status
    discount
    free_delivery
    payment_id
    payment_type
    delivery_type
    tracking_code
    delivery_instructions
    source
    country
    createdAt
    updatedAt
    orderItems {
      id
      orderNumber
      quantity
      profitAdded
      status
      supplier_status
      source
      price
      sku
      order_id
      createdAt
      updatedAt
      product {
        sku
        id
        title
        limited_stock
        qty
        main_stock
        minimumOrderQuantity
        distributionChannels
        transit_stock
        price
        profit_added
        currency
        country
        description
        extra_information
        combination_string
        published
        inhouse
        attended_to
        verified
        cost_price
        weight
        avatar
        tags
        shop_id
        resell
        uuid
        likesCount
        savesCount
        sharesCount
        supplier_id
        created_at
        instantDelivery
        overalReview
      }
    }
    delivery {
      id
      location
      price
      country
      remarks
      isPayOnline
    }
    customer {
      id
      name
      email
      phone
    }
    reseller {
      id
      email
      name
      country
      phone
      verified
      first_order_created
      user_type
      username
      google_id
      deviceToken
      facebook_id
      twitter_id
      supplier_shop_id
    }
    address {
      country
      city
      area
      landmark
      lat
      lng
      id
    }
    payment {
      id
      reference
      status
      message
      phone
      email
      amount
      currency
      cart
      fullname
      paidAt
      network
      createdAt
      updatedAt
      psp
      reasonForPayment
    }
  }
}
`