import {
  OfficeButton,
  TextInput,
  toast,
  AvatarUpload,
} from "components";
import { useFormik } from "formik";
import { updateSupplierDetails } from "_graphql/mutation/updatesupplier";
import {
  UpdateSupplierDetails,
  UpdateSupplierDetailsVariables,
} from "_graphql/mutation/__generated__/UpdateSupplierDetails";
import * as Yup from "yup";
import { useMutation, useReactiveVar } from "@apollo/client";
import { clearAuth, currentUserVar, setAuth } from "_graphql/cache/auth";
import { changePassword } from "_graphql/mutation/auth";
import { changeSupplierEmail } from "_graphql/mutation/updatesupplier";
import {
  ChangeSupplierEmail,
  ChangeSupplierEmailVariables,
} from "_graphql/mutation/__generated__/ChangeSupplierEmail";
import { FC, useEffect } from "react";
import {
  ChangeSupplierPassword,
  ChangeSupplierPasswordVariables,
} from "_graphql/mutation/__generated__/ChangeSupplierPassword";
import { useSupplier } from "../hooks/use-supplier";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { classNames } from "utils";


type Props = {};

const SettingsAccountTab: FC<Props> = (props) => {
  const currentUser = useReactiveVar(currentUserVar);
  const {
    supplier,
    loading: supplierLoading,
    refetch,
  } = useSupplier({
    variables: {
      filter: {
        id: {
          eq: currentUser?.id,
        },
      },
    },
  });

  const [initiate, { loading }] = useMutation<
    UpdateSupplierDetails,
    UpdateSupplierDetailsVariables
  >(updateSupplierDetails);

  const currentuser = useReactiveVar(currentUserVar);

  const fullName = supplier?.name || "";
    const nameParts = fullName.split(" ").filter(Boolean);
  const firstname = nameParts.length > 0 ? nameParts[0] : "";
const lastname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  const supplierEmail = supplier?.email!;

  useEffect(() => {
    if (supplier) {
      const fullName = supplier.name || "";
      const nameParts = fullName.split(" ").filter(Boolean);
      const firstname = nameParts.length > 0 ? nameParts[0] : "";
      const lastname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
      const supplierEmail = supplier.email || "";
  
      personalInfoForm.setValues({
        firstName: firstname,
        lastName: lastname,
        avatar: supplier.avatar || "",
      });
  
      emailForm.setValues({
        newEmail: supplierEmail,
      });
    }
  }, [supplier]);

 

  const personalInfoForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      avatar: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      avatar: Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      initiate({
        variables: {
          input: {
            id: currentUser?.id,
            data: {
              name: values.firstName + " " + values.lastName,
              avatar: values.avatar,
            },
          },
        },
      }).then(({ data }) => {
        console.log(data);
        if (data?.updateSupplierDetails) {
          toast("Personal details successfully updated", "success");
          // refetch();
          // window.location.reload();
        }
      });
    },
  });
  const [change, { loading: updatingPassword }] = useMutation<
    ChangeSupplierPassword,
    ChangeSupplierPasswordVariables
  >(changePassword);

  const passwordForm = useFormik<any>({
    initialValues: {
      input: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    },
    validationSchema: Yup.object().shape({
      input: Yup.object().shape({
        oldPassword: Yup.string().required("Old Password is required"),
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("newPassword")], "Passwords must match"),
      }),
    }),
    onSubmit: async (values) => {
      console.log(values);
      change({
        variables: {
          input: {
            newPassword: values.input.newPassword,
            oldPassword: values.input.oldPassword,
            id: currentUser.id || "",
          },
        },
      })
        .then(({ data }) => {
          if (data?.changeSupplierPassword) {
            passwordForm.resetForm();
            toast("Password updated successfully", "success");
            setAuth({ token: "", user: currentUser });
          }
        })
        .catch((err) => {
          toast(err.message, "error");
        });
    },
  });

  const [changeEmail, { loading: updatingEmail }] = useMutation<
    ChangeSupplierEmail,
    ChangeSupplierEmailVariables
  >(changeSupplierEmail);

  const emailForm = useFormik<any>({
    initialValues: {
      // input: {
      newEmail:"",
      // },
    },
    validationSchema: Yup.object().shape({
      newEmail: Yup.string().required("New Email is required"),
    }),
    onSubmit: async (values) => {
      console.log("clicked");
      changeEmail({
        variables: {
          input: {
            id: currentUser?.id,
            newEmail: values.newEmail,
          },
        },
      }).then(({ data }) => {
        console.log(data);
        if (data?.changeSupplierEmail) {
          toast("Email successfully updated", "success");
          refetch();
        }
      });
    },
  });
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="divide-y divide-white/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-black">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Update personal details of your account here.
          </p>
        </div>
        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full flex flex-col gap-x-8">
              {/* <img
                src={supplier?.avatar! || img}
                alt=""
                className="h-24 w-24 flex-none rounded-full  object-cover"
              /> */}
              <div className="mt-8">
                <AvatarUpload id={"avatar"} {...personalInfoForm} />
                {/* <OfficeButton>Change avatar</OfficeButton> */}

                <p className="mt-2 text-xs leading-5 text-gray-600">
                  JPG, GIF or PNG. 1MB max.
                </p>
              </div>
            </div>

            <div className="sm:col-span-3">
              <TextInput
                id={"firstName"}
                label="First Name"
                {...personalInfoForm}
              />
            </div>

            <div className="sm:col-span-3">
              <TextInput
                id={"lastName"}
                label="Last Name"
                {...personalInfoForm}
              />
            </div>

            {/* <div className="col-span-full">
                            <TextInput id={''} placeholder='eg. ebaidoo79@gmail.com' label='Email' {...personalInfoForm} />
                        </div>

                        <div className="col-span-full">
                            <TextInput id={''} label='Username' placeholder='eg. Khoby79' preText='Hello' {...personalInfoForm} />
                        </div>

                        <div className="col-span-full">
                            <TextInput id={''} label='Phone Number' placeholder='eg. 0274488676' preText='Hello' {...personalInfoForm} />
                        </div> */}
          </div>

          <div className="mt-8 flex">
            <OfficeButton
              disabled={!personalInfoForm.isValid || loading}
              onClick={personalInfoForm.handleSubmit}
            >
              {loading ? "Saving.." : " Save"}
            </OfficeButton>
          </div>
        </form>
      </div>
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-700">
            Change email
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Update your email associated with your account.
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full">
              <TextInput
                id={"newEmail"}
                type="email"
                label="New Email"
                {...emailForm}
              />
            </div>
          </div>

          <div className="mt-8 flex">
            <OfficeButton
              onClick={emailForm.handleSubmit}
              disabled={updatingEmail}
            >
              {updatingEmail ? "Updating email ..." : "update email"}
            </OfficeButton>
          </div>
        </form>
      </div>
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-700">
            Change password
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Update your password associated with your account.
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full">
              <TextInput
                id={"input.oldPassword"}
                type="password"
                label="Old Password"
                placeholder="eg. ************"
                preText="Hello"
                {...passwordForm}
              />
            </div>

            <div className="col-span-full">
              <TextInput
                id={"input.newPassword"}
                type="password"
                label="New Password"
                placeholder="eg. ************"
                preText="Hello"
                {...passwordForm}
              />
            </div>

            <div className="col-span-full">
              <TextInput
                id={"input.confirmPassword"}
                type="password"
                label="Confirm New Password"
                placeholder="eg. ************"
                preText="Hello"
                {...passwordForm}
              />
            </div>
          </div>

          <div className="mt-8 flex">
            <OfficeButton
              onClick={passwordForm.handleSubmit}
              disabled={updatingPassword}
            >
              {updatingPassword ? "Updating password ..." : "update password"}
            </OfficeButton>
          </div>
        </form>
      </div>
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-black">
            Log out
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-700">
            Log out of all devices and locations. You will be logged out of all
            devices and locations.
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="mt-8 flex">
            <OfficeButton onClick={clearAuth}>
              <ArrowRightOnRectangleIcon
                className={classNames(
                  "text-gray-200 group-hover:text-gray-50",
                  "mr-3 flex-shrink-0 h-4 w-6"
                )}
                aria-hidden="true"
              />
              Logout
            </OfficeButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SettingsAccountTab;
