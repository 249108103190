import { DiscountType } from '_graphql/__generated__/globalTypes';
import { GetDiscounts_getDiscounts } from '_graphql/queries/__generated__/GetDiscounts';
import { TColumn, TableColumn } from 'utils/types';

export const DiscountColumn: TableColumn<TColumn> = [
  {
    name: 'Discount Code',
    accessor: 'code',
  },
  {
    name: 'Type',
    accessor: 'type',
  },
  {
    name: 'Amount',
    accessor: '',
    render: (discount: GetDiscounts_getDiscounts) => (
      <div>
        {discount.type === DiscountType.PERCENTAGE ? (
          <span>{discount.percentage}%</span>
        ) : (
          <span>{discount.amount ? discount.amount : 'N/A'}</span>
        )}
      </div>
    ),
  },
  {
    name: 'Start Date',
    accessor: 'start_date',
  },
  {
    name: 'Expiry Date',
    accessor: 'expiry_date',
  },
  {
    name: 'Status',
    accessor: 'status',
    render: (discount: GetDiscounts_getDiscounts | null) => (
      <span className='py-1 px-2 bg-green-300 text-green-800 text-xs rounded'>
        {discount?.has_expired ? 'EXPIRED' : 'ACTIVE'}
      </span>
    ),
  },
];
