import { List, Show } from "components/core";
import { Shimmers } from "components";
import React, { FC } from "react";


type Props = {
    items: number[];
    actionsCount?: number;
    showAction?: boolean;
};

const TableLoader: FC<Props> = ({
    items,
    actionsCount = 0,
    showAction = false,
}) => {
    return (
        <tr>
            <List
                data={items}
                renderItem={(count) => (
                    <td className="px-6 py-4 border-b border-gray-200">
                        {count === 3 ? (
                            <Shimmers.AvatarShimmer />
                        ) : count === 2 ? (
                            <Shimmers.DoubleShimmer />
                        ) : (
                            <Shimmers.SingleShimmer />
                        )}
                    </td>
                )}
            />
            <Show if={showAction}>
                <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.ActionsShimmer actionsCount={actionsCount} />
                </td>
            </Show>
        </tr>
    );
};

export default TableLoader;
