import { useMatch } from "react-location";
import { useUser } from "pages/resellers/hooks/use-user";
import { LocationGenerics } from "router/location";
import { Avatar, OfficeHeader } from "components";
import SectionLayout from "pages/create-product/components/SectionLayout";
import { Badge } from "@mantine/core";

const Viewuser = () => {
  const { params } = useMatch<LocationGenerics>();
  const { user, loading } = useUser({
    variables: {
      filter: {
        id: { eq: params.user_id } || "",
      },
    },
  });

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light ">
          <SectionLayout
            loading={loading}
            freeFlow
            title={"Reseller Information"}>
            <div className="flex flex-col justify-between">
              <div className="flex flex-col-reverse sm:flex-row sm:justify-between">
                <div className="flex flex-row space-x-4">
                  <div className="-mt-1 ">
                    <div>
                      <dl className="row-span-2 flex flex-col items-center justify-center">
                        <Avatar size="lg" src={""} alt={user?.name || ""} />
                      </dl>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div>
                      <dl className="">
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 font-bold">
                          {user?.name || "N/A"}
                        </dd>
                      </dl>
                    </div>

                    <div>
                      <dl className="">
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          Username
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 font-bold">
                          {user?.username || "N/A"}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="mb-5 flex justify-end sm:justify-normal sm:mb-0">
                  <Badge color={user?.verified ? "blue" : "red"}>
                    {user?.verified ? "Verified" : "Not Verified"}
                  </Badge>
                </div>
              </div>

              <div className="mt-6 grid md:gap-x-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <dl className="flex flex-col my-4 w-full">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Email
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-bold">
                    {user?.email || "N/A"}
                  </dd>
                </dl>

                <dl className="flex flex-col my-4 w-full ">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Phone
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-bold">
                    {user?.phone || "N/A"}
                  </dd>
                </dl>
                <dl className="flex flex-col my-4 w-full">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Country
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-bold">
                    {user?.country || "N/A"}
                  </dd>
                </dl>
                <dl className="flex flex-col my-4 w-full">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Gender
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-bold">
                    {user?.details?.gender ?? "N/A"}
                  </dd>
                </dl>
              </div>
            </div>
          </SectionLayout>
        </div>
      </div>
    </main>
  );
};

export default Viewuser;
