import { GetCustomer_getCustomer } from "_graphql/queries/__generated__/GetCustomer";
import { GetTerm_getTerm } from "_graphql/queries/__generated__/GetTerm";
import _ from "lodash";
import { FC } from "react";

type Props = {
    term: GetTerm_getTerm | null | undefined
};

const TermView: FC<Props> = ({ term }) => {

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Csutomer Information</span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {term?.name || "N/A"}
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Settlement Type
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.settlementType || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Settlement Currency
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.settlementCurrency || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                {term?.settlementType}
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.settlementValue || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Settlement Period
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.settlementPeriod || "N/A"} day(s)
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Estimated Delivery Time ({term?.estimatedDeliveryTimeUnit})
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.estimatedDeliveryTime || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Return Period
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.returnPeriod || "N/A"} day(s)
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Ships To
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.shipTo || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Shipping Location
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {term?.shippingLocations?.join(", ") || "N/A"}
                            </div>
                        </div>
                    </div>

                    <div className="col-span-3 gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Description
                            </span>
                            <div className="mt-1 block w-full sm:text-sm prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: term?.description || "N/A" }} />
                        </div>
                    </div>
                    <div className="col-span-3 gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Shipping Policy
                            </span>
                            <div className="mt-1 block w-full sm:text-sm prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: term?.shippingPolicy || "N/A" }} />
                        </div>
                    </div>
                    <div className="col-span-3 gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Cancellation Policy
                            </span>
                            <div className="mt-1 block w-full sm:text-sm prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: term?.cancellationPolicy || "N/A" }} />
                        </div>
                    </div>
                    <div className="col-span-3 gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Return Policy
                            </span>
                            <div className="mt-1 block w-full sm:text-sm prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: term?.returnPolicy || "N/A" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TermView;
