import { useMutation } from '@apollo/client';
import {
  UpdateProductInformation,
  UpdateProductInformationVariables,
} from '_graphql/mutation/__generated__/UpdateProductInformation';
import { UPDATE_PRODUCT_INFORMATION } from '_graphql/mutation/products';

export const useUpdateProductInformation = (
  variables?: UpdateProductInformationVariables
) => {
  const [updateProductInformation, { ...rest }] = useMutation<
    UpdateProductInformation,
    UpdateProductInformationVariables
  >(UPDATE_PRODUCT_INFORMATION, {
    variables,
    refetchQueries: ['GetProduct'],
  });
  return {
    ...rest,
    updateProductInformation,
  };
};
