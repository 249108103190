import { gql } from "@apollo/client";

export const inviteReseller = gql`
  mutation InviteResellerBySupplier(
    $supplierId: ID!
    $email: String!
    $termsId: ID!
  ) {
    inviteResellerBySupplier(
      supplier_id: $supplierId
      email: $email
      terms_id: $termsId
    )
  }
`;
