import { Modal, OfficeButton, toast } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { usePartnership } from './hooks/use-partnerships';
import PartnerView from './cards/partnership-view';
import { dispatchAction } from 'utils';
import { useMutation } from '@apollo/client';
import { RejectPartnership, RejectPartnershipVariables } from '_graphql/mutation/__generated__/RejectPartnership';
import { rejectPartnership } from '_graphql/mutation/partnerships';


type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
    type: string
}

const ViewPartnershipContainer = ({
    open,
    setOpen,
    type
}: Props) => {
    const navigate = useNavigate<LocationGenerics>()
    const seacrhParams = useSearch<LocationGenerics>()
    const [reject, { loading: rejecting }] = useMutation<RejectPartnership, RejectPartnershipVariables>(rejectPartnership, {
        refetchQueries: ["GetPartnerships"]
    })
    const { partnership, loading } = usePartnership({
        filter: {
            id: {
                eq: seacrhParams?.id
            }
        }
    })

    const handleReject = () => {
        reject({
            variables: {
                rejectPartnershipId: seacrhParams?.id || ""
            }
        }).then(res => {
            toast("Partnership Rejected", "success");
            setOpen(false);
        }).catch(err => {
            toast(err?.message, "error");
        })
    }

    return (
        <Modal
            open={open}
            size='6xl'
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            loading={loading}
            title="View Partnership"
            description="View Partnership Details"
            renderActions={() => (type === 'incoming' ?
                <div className='md:ml-3 flex space-x-3'>
                    <OfficeButton variant='success' onClick={dispatchAction(seacrhParams?.id || "", "approve", navigate)}>
                        Approve
                    </OfficeButton>
                    <OfficeButton onClick={handleReject} variant='danger'>
                        {rejecting ? "Rejecting..." : "Reject"}
                    </OfficeButton>
                </div> : <div></div>
            )}

        >
            <PartnerView partnership={partnership} />
        </Modal>
    )
}

export default ViewPartnershipContainer