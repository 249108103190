import { FormikProps } from 'formik';
import { ChangeEvent, FC, useState } from 'react';
import _ from 'lodash';
import { Avatar, Loader } from 'components';
import { useProducts } from 'pages/products/hooks/use-products';
import { useCurrentUser } from '_graphql/cache/auth';

type Props = {
  form: FormikProps<any>;
  handleCheckboxChange: (event: any) => void;
};

const ProductList: FC<Props> = ({ form, handleCheckboxChange }) => {
  const [search, setSearch] = useState<string>('');
  const currentUser = useCurrentUser();
  const { products, refetch, loading } = useProducts({
    variables: {
      getProductsPagination: {
        limit: 10,
        offset: 0,
      },
      getProductsFilter: {
        supplier_id: {
          eq: currentUser?.id,
        },
      },
    },
  });

  const searchingProducts = _.debounce(() => {
    refetch({
      getProductsPagination: {
        limit: 10,
        offset: 0,
      },
      getProductsFilter: {
        supplier_id: {
          eq: currentUser?.id,
        },
        title: {
          like: search,
        },
      },
    });
  }, 300);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    searchingProducts();
  };

  return (
    <div className='relative'>
      <div className='py-5 mb-5 sticky -top-6 z-10 bg-white w-full'>
        <label className='block text-sm font-medium text-gray-700'>
          Search for products
        </label>
        <p className='block text-xs text-gray-500 my-1'>
          Search for products you'd like to apply this discount on by product
          name.
        </p>
        <input
          value={search}
          onChange={handleSearch}
          className='focus:ring-primary-500 focus:border-primary-500 border-gray-300 shadow-sm block w-full sm:text-sm rounded-md placeholder:font-light placeholder:text-xs h-[38px]'
        />
      </div>
      <div className='divide-y divide-gray-100'>
        {loading && <Loader />}
        {products?.map((product) => (
          <div
            key={product?.id}
            className='relative flex items-start pb-4 pt-3.5'>
            <div className='mr-3 flex items-start'>
              <Avatar alt={'Product avatar'} size='sm' src={product?.avatar} />
            </div>
            <div className='min-w-0 flex-1 capitalize text-sm leading-6'>
              <label
                htmlFor={_.snakeCase(product?.title)}
                className='font-medium text-gray-900'>
                {product?.title}
              </label>
              <p id='candidates-description' className='text-gray-500'>
                {product?.sku}
              </p>
            </div>
            <div className='ml-3 flex h-6 items-center'>
              <input
                type='checkbox'
                value={product?.sku}
                className='h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600'
                onChange={handleCheckboxChange}
                checked={form.values?.products?.includes(product?.sku)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
