import { useMutation } from '@apollo/client';
import {
  UpdateProductCategoryAndTags,
  UpdateProductCategoryAndTagsVariables,
} from '_graphql/mutation/__generated__/UpdateProductCategoryAndTags';
import { UPDATE_PRODUCT_CATEGORIES_AND_TAG } from '_graphql/mutation/products';

export const useUpdateProductCategoriesAndTag = (
  variables?: UpdateProductCategoryAndTagsVariables
) => {
  const [updateProductCategoryAndTag, { ...rest }] = useMutation<
    UpdateProductCategoryAndTags,
    UpdateProductCategoryAndTagsVariables
  >(UPDATE_PRODUCT_CATEGORIES_AND_TAG, {
    variables,
    refetchQueries: ['GetProduct'],
  });
  return {
    ...rest,
    updateProductCategoryAndTag,
  };
};
