import { FC } from 'react';
import { SearchSelectInput } from 'components/core';
import { useTerms } from 'pages/terms/hooks/use-terms';

interface TermsPickerProps {
    id: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    labelHidden?: boolean;
    values: any;
    errors?: any;
    touched?: any
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    rawId?: boolean;
    filter?: {
        supplierId?: any;
    };
}


const TermsPicker: FC<TermsPickerProps> = ({ id, label, filter, rawId, ...form }) => {
    const { loading, terms } = useTerms({
        variables: {
            pagination: {
                offset: 0,
                limit: 100,
            },
            filter: {
                supplier_id: {
                    eq: filter?.supplierId || 0
                }
            }
        },
        fetchPolicy: "network-only"
    })

    return (
        <SearchSelectInput
            id={id ?? "activity"}
            label={label ?? "Activity"}
            placeholder="Select Activity"
            optionsLoading={loading}
            options={(terms ?? [])?.map((activity: any) => ({
                label: {
                    title: activity.name as string,
                },
                value: rawId ? activity?.id : activity
            }))}
            {...form}
            disabled={form.disabled}
        />
    )
}

export default TermsPicker