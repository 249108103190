import { OfficeHeader } from 'components';
import { Link } from 'react-location';
import { classNames, useUrlState } from 'utils';
import LocalProductsTab from './tabs/LocalProductsTab';
import { FC, useState } from 'react';
import PartnershipProductsTab from './tabs/PartnershipProductsTab';
import AddProductButton from './add-product-button';
import LoadProductButton from './load-product-button';
import DigitalProductTab from './tabs/DigitalProductsTab';
import BulkUpdateProductContainer from './bulk-update-stock';

const ProductsPage: FC = () => {
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [loadingProductIds, setLoadingProductIds] = useState<string[]>([]);

  const [activeTab] = useUrlState('activeTab');
  const [modal, setModal] = useUrlState('modal');
  const tabs = [{ name: 'Partnership Products', href: 'partnershipproducts' },{ name: 'Digital Products', href: 'digitalproducts' }];
  return (
    <>
      <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
        {activeTab === "partnershipproducts" ? (
          <div>
            <OfficeHeader
              renderActions={() => (
                <LoadProductButton
                  selectedProductIds={selectedProductIds}
                  loadingProductsIds={loadingProductIds}
                  setLoadingProductIds={setLoadingProductIds}
                  setSelectedProductIds={setSelectedProductIds}
                />
              )}
            />
          </div>
        ) : (
          <div>
            <OfficeHeader renderActions={() => <AddProductButton />} />
          </div>
        )}

        <div className="block">
          <div className=" flex flex-row justify-between border-b border-gray-200 bg-white px-6">
            <nav
              className="-mb-px flex space-x-8 items-center"
              aria-label="Tabs"
            >
              <Link
                search={(old) => ({
                  ...old,
                  activeTab: undefined,
                })}
                className={classNames(
                  !activeTab
                    ? 'border-primary-500 text-primary-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                  'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={!activeTab ? 'page' : undefined}
              >
                My Products
              </Link>
              {tabs.map((_activeTab) => (
                <Link
                  key={_activeTab.name}
                  search={(old) => ({
                    ...old,
                    activeTab: _activeTab.href,
                  })}
                  className={classNames(
                    activeTab === _activeTab.href
                      ? 'border-primary-500 text-primary-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                    'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={
                    activeTab === _activeTab.href ? 'page' : undefined
                  }
                >
                  {_activeTab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex flex-1 overflow-y-auto">
          <div className="flex-1 mx-auto min-w-0   sm:py-6 overflow-hidden overflow-y-auto light flex">
            {!activeTab && <LocalProductsTab />}
            {activeTab === 'digitalproducts' && <DigitalProductTab />}
            {activeTab === 'partnershipproducts' && (
              <PartnershipProductsTab
                loadingProductsIds={loadingProductIds}
                setLoadingProductIds={setLoadingProductIds}
                selectedProductIds={selectedProductIds}
                setSelectedProductIds={setSelectedProductIds}
              />
            )}
          </div>
        </div>
      </main>
      {modal === 'update' && (
        <BulkUpdateProductContainer
          open={modal === 'update'}
          setOpen={(val: boolean) => setModal(val ? 'update' : undefined)}
        />
      )}
    </>
  );
};

export default ProductsPage;
