import { CheckCircleIcon, ChevronRightIcon, CreditCardIcon, DocumentDuplicateIcon, QueueListIcon } from '@heroicons/react/24/outline'
import { useCurrentUser } from '_graphql/cache/auth'
import _ from 'lodash'
import { Link } from 'react-location'
import { classNames } from 'utils'

type Props = {}

const items = [
    {
        name: 'Categories',
        description: 'Select categories from a list of predefined categories. These categories will be used to classify your products.',
        href: 'setupConfig.hasCategories',
        routeKey: 'category',
        iconColor: 'bg-pink-500',
        icon: QueueListIcon,
        supplierTypes: ['BASIC', 'ADVANCED'],
    },
    {
        name: 'Terms & Conditions',
        description: 'Create terms and conditions for your customers to agree to before they can purchase your products.',
        href: 'setupConfig.hasTerms',
        routeKey: 'terms',
        iconColor: 'bg-purple-500',
        icon: DocumentDuplicateIcon,
        supplierTypes: ['ADVANCED'],

    },
    {
        name: 'Payment Method',
        description: 'Setup your payment method to receive payments from your customers. This will be used to receive payments from your customers and make payments via the platform.',
        href: 'setupConfig.hasPaymentMethod',
        routeKey: 'wallet',
        iconColor: 'bg-yellow-500',
        icon: CreditCardIcon,
        supplierTypes: ['BASIC', 'ADVANCED'],

    },
]

const SetupConfig = (props: Props) => {
    const currentUser = useCurrentUser()
    return (
        <div className="flex-1 p-4 sm:p-6 overflow-y-auto max-w-5xl mx-auto my-11 bg-gray-50">
            <h2 className="text-base font-semibold leading-6 text-gray-900">
                Setup your account
            </h2>
            <p className="mt-1 text-sm text-gray-500">
                Get started by completing the following steps to setup your account.
            </p>
            <ul className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                {items.filter(el => el.supplierTypes.includes(currentUser?.supplier_type)).map((item, itemIdx) => {
                    const done: boolean = _.get(currentUser, item.href);
                    return (
                        <li key={itemIdx}>
                            <div className="group relative flex items-start space-x-3 py-4">
                                <div className="flex-shrink-0">
                                    <span
                                        className={classNames(item.iconColor, 'inline-flex h-10 w-10 items-center justify-center rounded-lg')}
                                    >
                                        <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="min-w-0 flex-1">
                                    <div className="text-sm font-medium text-gray-900">
                                        <Link disabled={done} to={item?.routeKey} className={classNames(done ? "line-through" : "")} search={(previius) => ({
                                            ...previius,
                                            modal: "create",
                                        })}>
                                            <span className={classNames("absolute inset-0")} aria-hidden="true" />
                                            {item.name}
                                        </Link>
                                    </div>
                                    <p className={classNames("text-sm text-gray-500", done ? "line-through" : "")}>{item.description}</p>
                                </div>
                                <div className="flex-shrink-0 self-center">
                                    {done ? <CheckCircleIcon className="h-5 w-5 text-green-400 group-hover:text-gray-500" /> : <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default SetupConfig