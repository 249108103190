import { gql } from "@apollo/client";

export const createPartnership = gql`
mutation CreatePartnership($name: String!, $contractor: ID!, $skus: [String!]!, $client: ID!) {
    createPartnership(name: $name, contractor: $contractor, skus: $skus, client: $client)
  }
`;

export const updatePartnership = gql`
mutation UpdatePartnership($updatePartnershipId: ID!, $name: String, $supplierId: ID, $skus: [String!]) {
    updatePartnership(id: $updatePartnershipId, name: $name, supplier_id: $supplierId, skus: $skus)
  }
`;
export const rejectPartnership = gql`
mutation RejectPartnership($rejectPartnershipId: ID!) {
    rejectPartnership(id: $rejectPartnershipId)
  }
`;
export const approvePartnership = gql`
mutation ApprovePartnership($approvePartnershipId: ID!, $termId: ID!) {
    approvePartnership(id: $approvePartnershipId, term_id: $termId)
  }
`;