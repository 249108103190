import { Switch } from '@headlessui/react';
import { TemplateType } from '_graphql/__generated__/globalTypes';
import { GetProductTemplate_getProductTemplate } from '_graphql/queries/__generated__/GetProductTemplate';
import { TextInput, SelectInput } from 'components';
import { FormikProps } from 'formik';
import _, { uniqueId } from 'lodash';
import { classNames } from 'utils/helpers';
import useUploadFile, { IDigiFile } from '../hooks/use-upload-file';
import { useRef } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';

type Props = {
  template: GetProductTemplate_getProductTemplate | null | undefined;
  form: FormikProps<any>;
};

const TemplateRenderer = ({ template, form }: Props) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  /** File manipulation */
  const onFilesUploaded = (images: IDigiFile[]) => {
    images?.forEach((file, i) => {
      form.setFieldValue(`attachedFiles.${i}`, file.fileLink);
    });
  };

  const {
    getInputProps: getFileInputProps,
    isDragActive: isFileDragActive,
    uploadingFile,
    deleteFile: deleteFileFile,
    getRootProps: getFileRootProps,
  } = useUploadFile(uniqueId('digitalProduct-'), {
    onUploaded: onFilesUploaded,
    multiple: true,
  });

  const fileUploadClickHandler = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  return (
    <div className="space-y-3">
      {template?.body?.map((block, index) => (
        <div key={index}>
          {block?.type === TemplateType.TEXT && (
            <TextInput
              id={_.snakeCase(block.label).toLocaleLowerCase()}
              label={block?.label}
              {...form}
            />
          )}
          {block?.type === TemplateType.NUMBER && (
            <TextInput
              id={_.snakeCase(block.label).toLocaleLowerCase()}
              type="number"
              label={block?.label}
              {...form}
            />
          )}
          {block?.type === TemplateType.SELECT && (
            <SelectInput
              options={block?.options ?? []}
              id={_.snakeCase(block.label).toLocaleLowerCase()}
              label={block?.label}
              {...form}
            />
          )}
          {block?.type === TemplateType.DATE && (
            <TextInput
              id={_.snakeCase(block.label).toLocaleLowerCase()}
              type="date"
              label={block?.label}
              {...form}
            />
          )}
          {block?.type === TemplateType.CHECKBOX && (
            <div className="p-3 bg-gray-100 border border-dashed border-gray-200">
              <Switch.Group as="div">
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-900"
                    passive
                  >
                    {block?.label}
                  </Switch.Label>
                </span>
                <div className="flex items-center justify-between h-[38px]">
                  <Switch.Description
                    as="span"
                    className="text-sm text-gray-500"
                  >
                    {block?.descrition || 'No description'}
                  </Switch.Description>
                  <Switch
                    // checked={true}
                    onChange={(val: boolean) => {
                      form.setFieldValue(
                        _.snakeCase(block?.label)?.toLocaleLowerCase(),
                        val
                      );
                      // if (val) form.setFieldValue("parent", "")
                    }}
                    className={classNames(
                      true ? 'bg-primary-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        true ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>
          )}

          {block?.type === TemplateType.FILE && (
            <div className="col-span-full">
              <SelectInput
                options={[
                  { label: 'Select One Access Type', value: '' },
                  { label: 'Downloadable File', value: 'Download' },
                  { label: 'Read-Only File', value: 'ReadOnly' },
                ]}
                id={'accessType'}
                label="File Access Type"
                {...form}
              />
              <div className="mt-5">
                <>
                  <div
                    {...getFileRootProps()}
                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer w-full col-span-full"
                    onClick={fileUploadClickHandler}
                  >
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <input
                          {...getFileInputProps()}
                          id="attachedFiles"
                          name="digital-file-upload"
                          type="file"
                          ref={fileUploadRef}
                          accept="application/*.pdf application/*.zip"
                          className="hidden sr-only"
                        />
                        {isFileDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <>
                            <label
                              htmlFor="image-upload"
                              className="relatives bg-white rounded-md font-medium   focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </>
                        )}
                      </div>
                      <p className="text-xs text-gray-500">
                        PDF, ZIP, DOCS, MP4 up to 50MB
                      </p>
                    </div>
                  </div>

                  {uploadingFile.length !== 0 && (
                    <div className="mt-2 col-span-full ">
                      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-3 w-full">
                        {uploadingFile.map((item, index) => (
                          <li key={item?.fileName} className="relative">
                            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                              {item?.loading ? (
                                <div className="flex justify-center items-center p-4">
                                  <span>Loading... </span>
                                </div>
                              ) : (
                                <img
                                  src={item?.fileLink}
                                  alt=""
                                  className="object-cover pointer-events-none group-hover:opacity-75"
                                />
                              )}
                            </div>

                            <p className="mt-2  text-sm font-medium flex flex-col items-center text-gray-900 truncate pointer-events-none">
                              {item?.fileName}
                            </p>

                            <span
                              className="md cursor-pointer hover:opacity-80 text-xs flex items-center justify-start"
                              onClick={() => deleteFileFile(index)}
                            >
                              <TrashIcon className="mr-2 text-red-500 h-4 w-4" />{' '}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
                {_.get(form.errors, 'attachedFiles') && (
                  <p className="mt-2 mb-5 mx-5 text-sm text-red-600 ">
                    <>{_.get(form.errors, 'attachedFiles')}</>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TemplateRenderer;
