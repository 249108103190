import { makeVar, useReactiveVar } from "@apollo/client";
import Cookies from "js-cookie";
import config from "config";
import { Permissions } from "_graphql/data";
import { SupplierSignIn_supplierSignIn_supplier } from "_graphql/mutation/__generated__/SupplierSignIn";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const LOG_ITEM_AUTH = "duke-supplier:authorization:duke-services:user";
const LOG_ITEM_TOKEN = "duke-supplier:authorization:duke-services:token";
const LOG_ITEM_COUNTRY = "duke-supplier:authorization:duke-services:country";
const LOG_ITEM_PUSH_TOKEN = "duke-supplier:authorization:duke-services:push-token";

export const UserPermissions = Permissions.map(({ value }) => value);
export type UserPermission = (typeof UserPermissions)[number];


export const isLoggedInVar = makeVar<boolean>(!!Cookies.get(LOG_ITEM_TOKEN));
export const currentTokenVar = makeVar<string | null>(
  Cookies.get(LOG_ITEM_TOKEN) || null
);
export const currentCountryVar = makeVar<string | null>(
  Cookies.get(LOG_ITEM_COUNTRY) || null
);
export const currentUserVar = makeVar<SupplierSignIn_supplierSignIn_supplier>(
  JSON.parse(Cookies.get(LOG_ITEM_AUTH) ?? "{}") ?? null
);

export const useCurrentUser = () => useReactiveVar(currentUserVar);
export const useAppNaviagte = () => useNavigate<LocationGenerics>();
export const useAppSearch = () => useSearch<LocationGenerics>();
export const useAppURL = () => useMatch<LocationGenerics>();


export const currentPushTokenVar = makeVar<string | null>(
  Cookies.get(LOG_ITEM_PUSH_TOKEN) || null
);

export const setAuth = ({ user, token }: { user: SupplierSignIn_supplierSignIn_supplier; token: string }) => {
  currentUserVar(user);
  Cookies.set(LOG_ITEM_AUTH, JSON.stringify(user), { ...config.cookies });
  isLoggedInVar(true);
  currentTokenVar(token);
  Cookies.set(LOG_ITEM_TOKEN, token, { ...config.cookies, expires: 1 });
};

export const setSetupComplete = (setup_complete: boolean) => {
  const user = currentUserVar();
  currentUserVar({ ...user, setup_complete });
  Cookies.set(LOG_ITEM_AUTH, JSON.stringify({ ...user, setup_complete }), { ...config.cookies });
}

export const setMe = (user: SupplierSignIn_supplierSignIn_supplier) => {
  currentUserVar(user);
  Cookies.set(LOG_ITEM_AUTH, JSON.stringify(user), { ...config.cookies });
};

export const setPushToken = (token: any) => {
  currentPushTokenVar(token);
  Cookies.set(LOG_ITEM_PUSH_TOKEN, token, { ...config.cookies });
};
export const setCountry = (country: string) => {
  currentCountryVar(country);
  Cookies.set(LOG_ITEM_COUNTRY, country, { ...config.cookies });
};

export const setSupplierSetupConfig = (config: any) => {
  const user = currentUserVar();
  currentUserVar({
    ...user, setupConfig: {
      ...user.setupConfig,
      ...config
    }
  });
  Cookies.set(LOG_ITEM_AUTH, JSON.stringify({ ...user, setup_config: config }), { ...config.cookies });
}

export const clearAuth = () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach((key) => {
    Cookies.remove(key, { ...config.cookies });
  });
};
