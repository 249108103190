import {
  ActionButton,
  Paginator,
  TableComponent,
  TableLoader,
} from 'components';
import { usePagination } from 'hooks';
import { FC, SetStateAction } from 'react';
import { useProducts } from '../hooks/use-products';
import { useAppNaviagte, useCurrentUser } from '_graphql/cache/auth';
import { ProductColumn } from '../table-columns';
import { dispatchAction, useUrlState } from 'utils';
import ViewProductContainer from '../view';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import DeleteProductContainer from '../delete';
import { GetProducts_getProducts } from '_graphql/queries/__generated__/GetProducts';
import { ProductStatus } from '_graphql/__generated__/globalTypes';
import RejectReasonModal from '../modal/RejectReasonModal';

type Props = {};

const LocalProductsTab: FC<Props> = (props) => {
  const [modal, setModal] = useUrlState('modal');
  const [id, setId] = useUrlState('id');
  const currentUser = useCurrentUser();
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useAppNaviagte();
  const { offset, limit, setPage } = usePagination();
  const navigateItem = (id: string) => () => {
    navigate({
      to: `./${id}/view`,
    });
  };

  const { products, count, refetch, loading } = useProducts({
    variables: {
      getProductsFilter: {
        supplier_id: {
          eq: currentUser.id,
        },
        title: searchParams?.search
          ? {
              like: `${searchParams.search}`,
            }
          : undefined,
      },
      getProductsPagination: {
        limit: limit,
        offset: offset,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  return (
    <div className="flex flex-1 overflow-y-auto">
      <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
        <TableComponent
          title={'Products'}
          renderLoader={() => (
            <TableLoader items={[2, 1, 1, 1, 1, 1, 1, 1, 1]} />
          )}
          columns={ProductColumn.concat([
            {
              name: 'Status',
              accessor: 'published',
              render: (product: GetProducts_getProducts | null) => (
                <button
                  disabled={product?.status !== ProductStatus.REJECTED}
                  onClick={() => {
                    setId(product?.id);
                    setModal('reject_reason');
                  }}
                  className={` rounded-full text-[11px] py-1 px-3 ${product?.status === ProductStatus.ACCEPTED ? 'bg-green-200 text-green-800' : product?.status === ProductStatus.REJECTED ? 'bg-red-200 text-red-800' : 'bg-gray-200 text-gray-800'}`}
                >
                  {product?.status}
                </button>
              ),
            },
            {
              name: 'Actions',
              accessor: 'action',
              render: (product: GetProducts_getProducts) => {
                return (
                  <div className="-ml-3">
                    <ActionButton
                      action={'view'}
                      tooltip="View Product"
                      onClick={dispatchAction(product?.id, 'view', navigate)}
                    />
                    <ActionButton
                      action={'expand'}
                      tooltip="Edit Product"
                      onClick={navigateItem(product?.id)}
                    />
                  </div>
                );
              },
            },
          ])}
          data={products}
          loading={loading}
          refetch={refetch}
          hasSearch
          pagination={() => (
            <Paginator
              offset={offset}
              limit={limit}
              totalItems={count}
              currentSize={products.length}
              setOffset={setPage}
            />
          )}
        />

        {!searchParams?.id?.length && (
          <ViewProductContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />
        )}

        {modal === 'reject_reason' && (
          <RejectReasonModal
            open={modal === 'reject_reason'}
            setOpen={() =>
              navigate({
                search(prev) {
                  return { ...prev, modal: undefined, id: undefined };
                },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default LocalProductsTab;
