export default [
  { name: "", value: "Dashboard" },
  { name: "", value: "Resellers" },
  { name: "", value: "My Wallet" },
  { name: "", value: "My Shop" },
  { name: "", value: "Customers" },
  { name: "", value: "Terms & Conditions" },
  { name: "", value: "Category" },
  { name: "", value: "Products" },
  { name: "", value: "Discount" },
  { name: "", value: "Orders" },
  { name: "", value: "Apps" },
  { name: "", value: "Shopify" },
  { name: "", value: "Magento" },
  { name: "", value: "Settings" },
] as const;