import { OfficeButton, PopUp } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useDeleteProduct } from './hooks/use-delete-product';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const DeleteProductContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { productDeletion, loading } = useDeleteProduct({
    removeProductId: searchParams?.id || '',
  });

  const handleDelete = async () => {
    await productDeletion()
      .then(() => setOpen(false))
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <PopUp
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      title='Delete Product'
      description='Confirm delete to remove product item from all distribution channels.
                    This will lead to the removal of this product from customer cart and order,
                    as well as all distribution platforms including the mobile, web application and all storefronts(Supplier and Reseller Storefront).'
      renderActions={() => (
        <OfficeButton
          type='button'
          variant='danger'
          size='sm'
          disabled={loading}
          onClick={handleDelete}>
          {loading ? 'Loading ...' : 'Confirm'}
        </OfficeButton>
      )}
    />
  );
};

export default DeleteProductContainer;
