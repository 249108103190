import * as R from 'ramda';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MediaServices from '../services/media.service';

export type IDigiFile = {
  id: number | undefined;
  loading: boolean;
  error: string;
  fileLink: string;
  fileName: string;
  filePath: string;
};

type UploadProps = {
  multiple?: boolean;
  onUploaded?: (images: IDigiFile[]) => void;
};

const useUploadFile = (title?: string, props?: UploadProps) => {
  const [uploadingFile, setUploadingFile] = useState<IDigiFile[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files

      acceptedFiles.forEach(async (file: File) => {
        setUploadingFile((prevState) =>
          prevState.concat({
            id: undefined,
            loading: true,
            error: '',
            fileLink: '',
            fileName: file.name,
            filePath: '',
          })
        );

        const formData = new FormData();
        formData.append('media', file);
        formData.append('folder', `tendo/${title ? title.replace(' ', '-') : 'product-file'}`);
        formData.append('origin', 'supplier-dashboard');

        MediaServices.uploadImage(formData)
          .then((res) => {
            setUploadingFile((prev) => {
              const index = prev.findIndex((el) => el.fileName === file.name);

              const newImages = R.adjust(
                index,
                (item) => ({
                  ...item,
                  loading: false,
                  error: '',
                  imgLink: res.data.data[0].imageLink,
                  imagePath: res.data.data[0].path,
                  id: res.data.data[0].id,
                }),
                prev
              );
              props?.onUploaded && props?.onUploaded(newImages);
              return newImages;
            });
          })
          .catch((err) => {
            setUploadingFile((prevState) =>
              prevState.map((item) =>
                item.fileName === file.name
                  ? {
                      ...item,
                      loading: false,
                      error: err.message,
                    }
                  : item
              )
            );
          });
      });
    },
    [title]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/zip': ['.zip'],
      'applicaton/docx': ['.docx'],
      'video/mp4': ['.mp4'],
    },
    multiple: props?.multiple,
  });

  const deleteFile = (index: number) => {
    const temp = uploadingFile[index];
    const file = {
      id: temp.id,
      imageLink: temp.fileLink,
      name: temp.fileName,
      path: temp.filePath,
    };
    if (file.id) {
      MediaServices.deleteMedia(file)
        .then(() => {
          setUploadingFile((prevState) => prevState.filter((item, i) => i !== index));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUploadingFile((prevState) => prevState.filter((item, i) => i !== index));
    }
  };

  const onUrlInputSubmit = (e: Record<string, any>, callback: Function) => {
    const mediaUrlInputArray = Object.values(e).map((obj) => ({
      url: obj,
      folder: `tendo/${title ? title.replace(/\s+/g, '-').toLowerCase() : 'product-file'}`,
      origin: 'supplier-dashboard',
    }));

    mediaUrlInputArray.forEach(async (mediaUrlInput) => {
      setUploadingFile((prevState) =>
        prevState.concat({
          id: undefined,
          loading: true,
          error: '',
          fileLink: '',
          fileName: mediaUrlInput.url,
          filePath: '',
        })
      );
      MediaServices.uploadMediaUsingUrl(mediaUrlInput)
        .then((res) => {
          setUploadingFile((prevState) => {
            const index = prevState.findIndex((el) => el.fileName === mediaUrlInput.url);
            const newImages = R.adjust(
              index,
              (item) => ({
                ...item,
                loading: false,
                error: '',
                imgLink: res.data.data.imageLink,
                imagePath: res.data.data.path,
                id: res.data.data.id,
                imageName: res.data.data.originalname,
              }),
              prevState
            );

            props?.onUploaded && props?.onUploaded(newImages);
            return newImages;
          });
        })
        .catch((err) => {
          setUploadingFile((prevState) =>
            prevState.map((item) =>
              item.fileName === mediaUrlInput.url
                ? {
                    ...item,
                    loading: false,
                    error: err.message,
                  }
                : item
            )
          );
        });
    });
    callback();
  };

  return {
    onUrlInputSubmit,
    uploadingFile,
    getRootProps,
    getInputProps,
    isDragActive,
    deleteFile,
  };
};

export default useUploadFile;