import { gql } from '@apollo/client';

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: createProductInput) {
    createProduct(input: $input)
  }
`;

export const UPDATE_PRODUCT_CATEGORIES_AND_TAG = gql`
  mutation UpdateProductCategoryAndTags($input: ProductCategoryAndTagsInput) {
    updateProductCategoryAndTags(input: $input)
  }
`;
export const UPDATE_PRODUCT_MEDIA = gql`
  mutation UpdateProductMedia($input: ProductMediaInput) {
    updateProductMedia(input: $input)
  }
`;

export const BULK_UPDATE_PRODUCT_STOCK = gql`
  mutation BulkUpdateProductSock($input: [BulkProductStockInput!]) {
    bulkUpdateProductSock(input: $input)
  }
`;


export const UPDATE_PRODUCT_INVENTORY = gql`
  mutation UpdateProductInventory($input: ProductInventoryInput) {
    updateProductInventory(input: $input)
  }
`;
export const UPDATE_PRODUCT_INFORMATION = gql`
  mutation UpdateProductInformation($input: ProductInformationInput) {
    updateProductInformation(input: $input)
  }
`;
export const UPDATE_PRODUCT_DISTRIBUTION = gql`
  mutation UpdateProductDistribution($input: UpdateProductDistributionInput) {
    updateProductDistribution(input: $input)
  }
`;
export const REMOVE_PRODUCT = gql`
  mutation RemoveProduct($removeProductId: ID!) {
    removeProduct(id: $removeProductId)
  }
`;

export const SYNCSHOPIFYPRODUCTS = gql`
  mutation SyncShopifyProducts($input: SyncShopifyProductInput!) {
    syncShopifyProducts(input: $input)
  }
`;

export const PUBLISH_PRODUCTS = gql`
  mutation PublishOrUnpublishProduct(
    $publishOrUnpublishProductId: ID!
    $published: Boolean!
  ) {
    publishOrUnpublishProduct(
      id: $publishOrUnpublishProductId
      published: $published
    )
  }
`;

export const LOAD_PRODUCTS_TO_SHOPIFY =gql`
mutation LoadProductsToShopify($input: LoadProductToShopifyInput) {
  loadProductsToShopify(input: $input)
}
`;
