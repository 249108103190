import { useQuery } from '@apollo/client';
import { GetSku } from '_graphql/queries/__generated__/GetSku';
import { getSku } from '_graphql/queries/products';

export const useGetSKU = () => {
  const { data, ...rest } = useQuery<GetSku>(getSku);
  return {
    ...rest,
    sku: data?.getSKU,
  };
};
