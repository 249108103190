import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetUsers,
  GetUsersVariables,
} from "_graphql/queries/__generated__/GetUsers";
import { getUsers } from "_graphql/queries/users";

export const useUsers = (
  options?: QueryHookOptions<GetUsers, GetUsersVariables>
) => {
  const { data, ...rest } = useQuery<GetUsers, GetUsersVariables>(
    getUsers,
    options
  );

  return {
    users: data?.getUsers || [],
    count: data?.getUsersLength || 0,
    ...rest,
  };
};
