import { gql } from "@apollo/client";

export const createTerm = gql`
  mutation CreateTerm($input: TermInput!) {
    createTerm(input: $input)
  }
`;
export const updateTerm = gql`
  mutation UpdateTerm($input: TermInput!) {
    updateTerm(input: $input)
  }
`;
