import { GetCart_getCart } from "_graphql/queries/__generated__/GetCart";
import { Modal } from "components";
import moment from "moment";
import { forwardRef, useMemo } from "react";


type Props = {
    order: GetCart_getCart | null | undefined;
    open: boolean;
    setOpen: (val: any) => void;
};

const OrderInvoice = forwardRef<HTMLDivElement, Props>(
    ({ order, open, setOpen, ...props }, ref) => {
        // const { user } = useAppSelector((state) => state.auth);
        // const currency = useGetCurrency(String(user?.country));

        const subtotal = useMemo(() => {
            return (
                order?.orderItems?.reduce((total, item) => {
                    return total + Number(item?.price) * Number(item?.quantity);
                }, 0) ?? 0
            );
        }, [order?.orderItems]);

        const totalAdjustment = useMemo(() => {
            return (
                order?.orderItems?.reduce((total, item) => {
                    return total + Number(item?.profitAdded);
                }, 0) ?? 0
            );
        }, [order?.orderItems]);

        return (
            <Modal open={open} setOpen={setOpen} title="Invoice" >

                <div className="w-full p-2 print:py-16 print:px-12" ref={ref} {...props}>
                    <div className="flex flex-col space-y-3">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col text-right">
                                <span className="text-2xl font-medium text-gray-700">
                                    Invoice
                                </span>
                                <span className="text-xs font-medium text-gray-500">
                                    # {order?.cart_number}
                                </span>
                                <span className="text-xs font-medium text-gray-500 bg-gray-100 rounded-sm p-0.5 uppercase">
                                    {order?.payment_status}
                                </span>
                            </div>
                        </div>

                        <div className="flex justify-end items-center py-4">
                            <div className="flex flex-col text-right">
                                <span className="text-xs font-medium text-gray-500">
                                    Balance Due
                                </span>
                                <span className="text-base font-medium text-gray-700">
                                    {order?.order_total}
                                </span>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-3">
                            <div className="col-span-1">
                                <div className="flex flex-col space-y-1">
                                    <span className="text-sm font-medium text-gray-700">
                                        Billed To
                                    </span>
                                    <span className="text-xs text-gray-500">
                                        {order?.customer?.name}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                        {order?.address?.area}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                        {order?.address?.city}
                                    </span>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex flex-col space-y-2">
                                    <div className="flex justify-between">
                                        <span className="text-xs text-gray-500">Invoice Date:</span>
                                        <span className="text-xs text-gray-500">
                                            {moment(order?.createdAt).format("Do MMM, YYYY")}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-xs text-gray-500">Due Date:</span>
                                        <span className="text-xs text-gray-500">
                                            {moment().format("Do MMM, YYYY")}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-xs text-gray-500">Order #:</span>
                                        <span className="text-xs text-gray-500">
                                            {order?.cart_number}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-6 divide-y divide-gray-100 pb-6">
                            <div className=" bg-gray-100">
                                <span>#</span>
                            </div>
                            <div className="col-span-2 bg-gray-100">
                                <span>Item & description</span>
                            </div>
                            <div className="text-center bg-gray-100">
                                <span>Qty</span>
                            </div>
                            <div className=" bg-gray-100">
                                <span>Rate</span>
                            </div>
                            <div className=" bg-gray-100">
                                <span>Amount</span>
                            </div>
                            {order?.orderItems?.map((item, index) => {
                                return (
                                    <>
                                        <div className="">
                                            <span className="text-xs text-gray-500">{index + 1}</span>
                                        </div>
                                        <div className="col-span-2">
                                            <span className="text-xs text-gray-500">
                                                {item?.product?.title}
                                            </span>{" "}
                                            <br />
                                            <span className="text-xs text-gray-500">
                                                {item?.product?.combination_string?.replace("&", " ")}
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <span className="text-xs text-gray-500">
                                                x{item?.quantity}
                                            </span>
                                        </div>
                                        <div className="">
                                            <span className="text-xs text-gray-500">
                                                {item?.price?.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="">
                                            <span className="text-xs text-gray-500">
                                                {(Number(item?.quantity) * Number(item?.price)).toFixed(
                                                    2
                                                )}
                                            </span>
                                        </div>
                                    </>
                                );
                            })}
                        </div>

                        <div className="grid grid-cols-2 gap-x-3">
                            <div className="col-span-1"></div>
                            <div className="col-span-1">
                                <div className="flex flex-col space-y-2">
                                    <div className="flex justify-between">
                                        <span className="text-xs text-gray-500">Subtotal</span>
                                        <span className="text-xs text-gray-500">
                                            {subtotal.toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-xs text-gray-500">Shipping charge</span>
                                        <span className="text-xs text-gray-500">
                                            {order?.delivery?.price.toFixed(2) ?? "0.00"}
                                        </span>
                                    </div>
                                    <div className="flex justify-between font-medium">
                                        <span className="text-xs text-gray-500">Adjustment</span>
                                        <span className="text-xs text-gray-500">
                                            {totalAdjustment.toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="flex justify-between font-medium">
                                        <span className="text-xs text-gray-700">Total</span>
                                        <span className="text-xs text-gray-700">
                                            {order?.order_total?.toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-xs text-gray-500">Payment Made</span>
                                        <span className="text-xs text-gray-500">
                                            - {order?.payment?.amount ?? "0.00"}
                                        </span>
                                    </div>
                                    <div className="flex justify-between font-medium bg-gray-100 p-2">
                                        <span className="text-xs text-gray-700">Balance Due</span>
                                        <span className="text-xs text-gray-700">
                                            {order?.order_total?.toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col mt-4">
                            <span className="text-sm font-medium text-gray-500">Notes</span>
                            <span className="text-xs text-gray-500 mr-2">
                                From: {order?.reseller?.name}.
                            </span>
                            {/* <span className="text-xs text-gray-500">
                                {order?.reseller?.phone}
                            </span> */}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
);

export default OrderInvoice;
