import { useMutation } from '@apollo/client';
import {
  UpdateProductDistribution,
  UpdateProductDistributionVariables,
} from '_graphql/mutation/__generated__/UpdateProductDistribution';
import { UPDATE_PRODUCT_DISTRIBUTION } from '_graphql/mutation/products';

export const useUpdateProductDistribution = (
  variables?: UpdateProductDistributionVariables
) => {
  const [updateProductDistribution, { ...rest }] = useMutation<
    UpdateProductDistribution,
    UpdateProductDistributionVariables
  >(UPDATE_PRODUCT_DISTRIBUTION, {
    variables,
    refetchQueries: ['GetProduct'],
  });
  return {
    ...rest,
    updateProductDistribution,
  };
};
