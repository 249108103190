import { QueryHookOptions, useQuery } from "@apollo/client";
import { GetPayoutRequests, GetPayoutRequestsVariables } from "_graphql/queries/__generated__/GetPayoutRequests";
import { GetSupplierWalletSummary, GetSupplierWalletSummaryVariables } from "_graphql/queries/__generated__/GetSupplierWalletSummary";
import { Wallet, WalletVariables } from "_graphql/queries/__generated__/Wallet";
import { WalletHistory, WalletHistoryVariables } from "_graphql/queries/__generated__/WalletHistory";
import { getPayoutTransactions, getWallet, getWalletHistory, getWalletSummary } from "_graphql/queries/wallet";


export const useWallet = (
    options?: QueryHookOptions<Wallet, WalletVariables>
) => {
    const { data, ...rest } = useQuery<Wallet, WalletVariables>(
        getWallet,
        options
    );

    return {
        wallet: data?.wallet,
        ...rest,
    };
}
export const useWalletSummary = (
    options?: QueryHookOptions<GetSupplierWalletSummary, GetSupplierWalletSummaryVariables>
) => {
    const { data, ...rest } = useQuery<GetSupplierWalletSummary, GetSupplierWalletSummaryVariables>(
        getWalletSummary,
        options
    );

    return {
        wallet: data?.getSupplierWalletSummary,
        ...rest,
    };
}
export const usePayoutRequests = (
    options?: QueryHookOptions<GetPayoutRequests, GetPayoutRequestsVariables>
) => {
    const { data, ...rest } = useQuery<GetPayoutRequests, GetPayoutRequestsVariables>(
        getPayoutTransactions,
        options
    );

    return {
        payouts: data?.getPayoutRequests || [],
        count: 120,
        ...rest,
    };
}


export const useWalletHistory = (
    options?: QueryHookOptions<WalletHistory, WalletHistoryVariables>
) => {
    const { data, ...rest } = useQuery<WalletHistory, WalletHistoryVariables>(
        getWalletHistory,
        options
    );

    return {
        history: data?.walletHistory || [],
        count: data?.walletHistoryLength || 0,
        ...rest,
    };
}