import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { TextInput } from "components/core";
import { TermsPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";
import { Link } from "react-location";

export interface InviteResellerFormProps {
    form: FormikProps<any>;
}

const InviteResellerForm: FC<InviteResellerFormProps> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <div className="rounded-md bg-sky-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-sky-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-sky-800">
                            Take note
                        </h3>
                        <div className="mt-2 text-sm text-sky-700">
                            <p>Hello Supplier, Just a friendly reminder: without clear terms and conditions in place, inviting a reseller might grant them the liberty to set their own profit margins. Let's ensure we have our agreement solid before extending any invitations. Thanks!</p>
                        </div>
                        <div className="mt-4">
                            <div className="-mx-2 -my-1.5 flex">
                                <Link
                                    to="/terms"
                                    search={{ modal: 'create' }}
                                    className="rounded-md bg-sky-50 px-2 py-1.5 text-sm font-medium text-sky-800 hover:bg-sky-100 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2 focus:ring-offset-sky-50"
                                >
                                    Create terms and conditions
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-6">
                <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <TextInput
                            id="email"
                            label="Email"
                            type="email"
                            placeholder="e.g. ebaidoo79@gmail.com"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <TermsPicker
                            id="terms_id"
                            label="Terms and Conditions"
                            rawId
                            filter={{ supplierId: form.values.supplier_id }}
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default InviteResellerForm;

