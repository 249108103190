import { DiscountType } from '_graphql/__generated__/globalTypes';
import { GetDiscount_getDiscount } from '_graphql/queries/__generated__/GetDiscount';
import { Loader, Show } from 'components';
import _ from 'lodash';
import { useDeliveryLocation } from 'pages/create-discount/hooks/use-delivery-locations';
import { FC } from 'react';

type Props = {
  discount: GetDiscount_getDiscount | null | undefined;
};

const DiscountView: FC<Props> = ({ discount }) => {
  const { deliveryLocation, loading } = useDeliveryLocation({
    variables: {
      filter: discount?.locations
        ? {
            id: {
              in: discount?.locations as string[],
            },
          }
        : undefined,
    },
  });

  return (
    <div className='space-y-6 divide-y divide-gray-200 overscroll-auto'>
      <div>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='col-span-3'>
            <span className='block text-sm font-light text-gray-700'>
              Discount Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {discount?.code || 'N/A'}
            </div>
          </div>

          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Discount Type
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {discount?.type}
              </div>
            </div>
          </div>
          <Show if={discount?.type !== DiscountType.FREE_DELIVERY}>
            <div className=' gap-6 mt-2 '>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  {discount?.type === DiscountType.PERCENTAGE
                    ? 'Discount Percentage'
                    : 'Discount Amount'}
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {discount?.type === DiscountType.PERCENTAGE
                    ? discount?.percentage + '%'
                    : discount?.amount}
                </div>
              </div>
            </div>
          </Show>
          <Show if={discount?.type === DiscountType.FREE_DELIVERY}>
            <div className=' gap-6 mt-2 '>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Delivery Location Tagname
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {discount?.locations_tagname}
                </div>
              </div>
            </div>
          </Show>
          <div className='gap-6 mt-2 '>
            <span className='block text-sm font-light text-gray-700'>
              Redemption Limit
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {discount?.redemptions_limit || 'N/A'}
            </div>
          </div>

          <Show if={discount?.type === DiscountType.FREE_DELIVERY}>
            <div className=' gap-6 mt-2 col-span-full '>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Delivery Locations
                </span>
                <div className='mt-1  w-full sm:text-sm flex justify-start items-center gap-2 overflow-auto'>
                  {loading ? (
                    <Loader />
                  ) : (
                    deliveryLocation?.map((el) => (
                      <span className='p-1 bg-gray-200 rounded '>
                        {el?.location}
                      </span>
                    ))
                  )}
                </div>
              </div>
            </div>
          </Show>
          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Discount Status
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {discount?.has_expired ? (
                  <span className='py-1 px-2 bg-red-300 text-green-800 text-xs rounded'>
                    EXPIRE
                  </span>
                ) : (
                  <span className='py-1 px-2 bg-green-300 text-green-800 text-xs rounded'>
                    ACTIVE
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className=' gap-6 mt-2 '>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Start Date
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {discount?.start_date || 'N/A'}
              </div>
            </div>
          </div>
          <div className=' gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Expire Date
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {discount?.expiry_date || 'N/A'}
              </div>
            </div>
          </div>

          <div className='col-span-full gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Description
              </span>
              <div
                className='mt-1 block w-full sm:text-sm prose lg:prose-xl'
                dangerouslySetInnerHTML={{
                  __html: discount?.description || 'N/A',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountView;
