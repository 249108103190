import { useQuery } from '@apollo/client';
import {
  GetDiscountProductConditions,
  GetDiscountProductConditionsVariables,
} from '_graphql/queries/__generated__/GetDiscountProductConditions';
import { getDiscountProducts } from '_graphql/queries/products';

export const useDiscountProducts = (variables?: any) => {
  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery<
    GetDiscountProductConditions,
    GetDiscountProductConditionsVariables
  >(getDiscountProducts, {
    notifyOnNetworkStatusChange: true,
    variables,
  });
  return {
    discountProducts: data?.getDiscountProductConditions ?? [],
    loading,
    error,
    fetchMore,
    networkStatus,
    refetch,
  };
};
