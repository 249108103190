import { Modal } from 'components';
import CustomerView from './cards/view-customer';
import { useCustomer } from './hooks/useCustomer';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const ViewCustomerContainer = ({
    open,
    setOpen,
}: Props) => {
    const navigate = useNavigate<LocationGenerics>()
    const seacrhParams = useSearch<LocationGenerics>()
    const { customer, loading } = useCustomer({
        variables: {
            filter: seacrhParams?.id ? {
                id: {
                    eq: seacrhParams?.id
                }
            } : undefined
        }
    })

    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            loading={loading}
            title="View Customer"
            description="View Customer Details"

        >
            <CustomerView customer={customer} />
        </Modal>
    )
}

export default ViewCustomerContainer