import { gql } from "@apollo/client";


export const getSupplierDailyOrdersSummary = gql`
query GetSupplierDailyOrdersSummary(
  $id: ID!
  $from: Date!
  $to: Date!
) {
  getSupplierDailyOrdersSummary(
    id: $id
    from: $from
    to: $to
  )
}
`;


export const getSupplierStats = gql`
query GetSupplierStats($supplierId: ID!) {
  getSupplierStats(supplier_id: $supplierId) {
    supplier_id
    total_active_orders
    total_orders
    total_finished_orders
    total_products
    total_verified_products
    total_unverified_products
    total_products_in_stock
    total_products_out_of_stock
    total_products_in_transit
    total_customers
    total_resellers
  }
}

`;


export const getSupplierOrdersByStatus = gql`
query GetSupplierOrdersByStatusSummary(
  $suuplierId: ID!
  $from: Date!
  $to: Date!
) {
  getSupplierOrdersByStatusSummary(
    suuplier_id: $suuplierId
    from: $from
    to: $to
  ) {
    totalPending
    totalDelivered
    totalCancelled
    totalSuccessful
  }
}
`;

export const getProductsCountByCategories = gql`
query GetProductsCountByCategories($supplierId: ID!) {
  getProductsCountByCategories(supplier_id: $supplierId) {
    category
    count
  }
}

`