import { Nationalities } from "_graphql/data";
import lodash from "lodash";

export const GhanaCardRegex = new RegExp(
  `^(${lodash
    .map(Nationalities, "alpha_3_code")
    .join("|")})-\\d{9}-(\\d|[A-Z])$`,
  "g"
);


export function maskEmail(email: string) {
  const atIndex = email.indexOf('.');
  // const firstPart = email.replace(email.substring(1, atIndex), '*'.repeat(atIndex - 1));
  // eg email = 'johndoe@gmail.com' becomes j**** @***.com
  const maskedEmail = `${email.substring(0, 1)}**** @***.${email.substring(atIndex + 1)}`;
  return maskedEmail;
}

export const DATE_FORMAT = "YYYY-MM-DD";