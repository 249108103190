import _ from "lodash";
import { FC } from "react";
import { TColumn, TableColumn } from "../../utils/types";
import clsx from "clsx";
import { classNames } from "utils";
import { Show } from "components/core";


interface TableBodyComponentProps<TData = any> {
  data: TData[];
  columns: TableColumn<TColumn>;
  renderItem?: FC<TData>;
  renderLoader?: FC;
  loading?: boolean;
  isEmpty?: boolean;
  showHeader: boolean;
}

const TableBodyComponent: FC<TableBodyComponentProps> = ({
  data,
  renderItem,
  loading,
  renderLoader,
  columns,
  isEmpty,
  showHeader,
}) => {
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0 no-scrollbar flex-1 max-h-full  flex overflow-y-auto">
      <div className="align-middle min-w-full sm:px-6 lg:px-6 flex-1 flex flex-col overflow-y-auto no-scrollbar">
        <table
          className="border-separate max-w-full divide-y table-fixed divide-gray-200  border-x border-gray-200  "
          style={{ borderSpacing: 0 }}
        >
          <Show if={!isEmpty && showHeader}>
            <thead className="bg-gray-50 ">
              <tr>
                {columns?.map((columnItem, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 px-2  py-3 text-left text-xs font-light text-gray-900  uppercase tracking-wider whitespace-normal"
                  >
                    {columnItem?.name}
                  </th>
                ))}
              </tr>
            </thead>
          </Show>
          <tbody className="bg-white  divide-y divide-gray-200  overflow-y-auto">
            {loading
              ? renderLoader?.({}) ?? (
                <tr>
                  {Object.keys(columns).map((_, index) => (
                    <td key={index} className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200  flex-1">
                      <div className="bg-gray-500  h-3 rounded-md w-full animate-pulse" />
                    </td>
                  ))}
                </tr>
              )
              : data?.map(
                (item, index) =>
                  renderItem?.(item) ?? (
                    <tr key={index}>
                      {columns?.map((col) =>
                        col?.render ? (
                          <td
                            className={clsx(
                              "whitespace-normal p-2 text-sm text-gray-500 border-b border-gray-200 "
                            )}
                          >
                            <col.render {...item} />
                          </td>
                        ) : (
                          <td
                            className={classNames(
                              "whitespace-normal px-2 py-4 text-sm text-gray-500 border-b border-gray-200 "
                            )}
                          >
                            {_.get(item, (col.accessor as string) ?? "N/A")}
                          </td>
                        )
                      )}
                    </tr>
                  )
              )}
          </tbody>
          <tfoot className="bg-gray-50  sticky bottom-0 hidden">
            <tr>
              {columns?.map((columnItem, index) => (
                <th
                  key={index}
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50  px-6 py-3 text-left text-xs font-light text-gray-900  uppercase tracking-wider whitespace-nowrap"
                >
                  {columnItem?.name}
                </th>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default TableBodyComponent;
