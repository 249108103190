import { useQuery, QueryHookOptions } from "@apollo/client";
import { GetPlugins, GetPluginsVariables } from "_graphql/queries/__generated__/GetPlugins";
import { GetUserPlugins, GetUserPluginsVariables } from "_graphql/queries/__generated__/GetUserPlugins";
import { getPlugins, getUserPlugins } from "_graphql/queries/integrations";

export const usePlugins = (
    options?: QueryHookOptions<GetPlugins, GetPluginsVariables>
) => {
    const { data, ...rest } = useQuery<GetPlugins, GetPluginsVariables>(
        getPlugins,
        options
    );

    return {
        plugins: data?.getPlugins || [],
        // count: data?.count || 0,
        ...rest,
    };
};

export const useUserPlugins = (variables?: GetUserPluginsVariables) => {
    const { data, ...rest } = useQuery<GetUserPlugins, GetUserPluginsVariables>(getUserPlugins, {
        variables
    })

    return {
        userPlugins: data?.getUserPlugins || [],
        ...rest
    }
}