import { QueryHookOptions, useQuery } from "@apollo/client";
import { GetPaymentMethods } from "_graphql/queries/__generated__/GetPaymentMethods";
import { getPaymentMethods } from "_graphql/queries/suppliers";



export const usePaymentMethods = (
    options?: QueryHookOptions<GetPaymentMethods>
) => {
    const { data, ...rest } = useQuery<GetPaymentMethods>(
        getPaymentMethods,
        options
    );

    return {
        methods: data?.getPaymentMethods || [],
        count: 120,
        ...rest,
    };
}
