import { useAppNaviagte, useCurrentUser } from '_graphql/cache/auth';
import { Modal, OfficeButton, toast } from 'components';
import { FC } from 'react'
import { useFormik } from 'formik';
import * as yup from "yup"
import { useMutation } from '@apollo/client';
import { CreatePayoutRequest, CreatePayoutRequestVariables } from '_graphql/mutation/__generated__/CreatePayoutRequest';
import { requestPayout } from '_graphql/mutation/payment';
import countryToCurrency from "country-to-currency";
import _ from 'lodash';
import PayoutForm from './forms/payour';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const validationSchema = yup.object().shape({
    amount: yup.number().typeError("Must be a number").min(1, "Minimum of 1").required("Amount is required")
})


const CreatePayoutContainer: FC<Props> = ({ setOpen, open }) => {
    const currentUser = useCurrentUser();
    const navigate = useAppNaviagte();
    const [initiate, { loading }] = useMutation<CreatePayoutRequest, CreatePayoutRequestVariables>(requestPayout, {
        refetchQueries: ["GetPayoutRequests"]
    })

    const form = useFormik({
        initialValues: {
            amount: 0,
        },
        validationSchema,
        onSubmit: (values) => {
            initiate({
                variables: {
                    input: {
                        account_name: currentUser?.payment_details?.account_name || "",
                        account_number: currentUser?.payment_details?.account_number || "",
                        provider: currentUser?.payment_details?.provider || "",
                        country: currentUser?.country || "",
                        currency: _.get(countryToCurrency, currentUser?.country as any, "GHS"),
                        amount: Number(values.amount),
                        supplier_id: currentUser?.id
                    }
                }
            }).then(({ data }) => {
                if (data?.createPayoutRequest) {
                    toast("Payoout Request created successfully", "success")
                } else {
                    toast("Payoout Request not created successfully", "error")
                }
            }).catch(err => {
                toast(err?.message, "error")
            })
        },
    })





    return (
        <Modal
            open={open}

            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            title="Request Payout"
            description=""
            renderActions={() => (
                <OfficeButton ml onClick={form.handleSubmit}>
                    {loading ? "Requesting..." : "Request"}
                </OfficeButton>
            )}
        >
            <PayoutForm form={form} />
        </Modal>
    )
}

export default CreatePayoutContainer