import { useAppNaviagte, useCurrentUser } from '_graphql/cache/auth';
import { Modal, OfficeButton } from 'components';
import { FC } from 'react'
import PaymentMethodView from './cards/payment-method';
import { useSupplier } from 'pages/settings/hooks/use-supplier';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const ViewPaymentMEthodContainer: FC<Props> = ({ setOpen, open }) => {
    const navigate = useAppNaviagte();
    const currentUser = useCurrentUser();
    const { supplier, loading } = useSupplier({
        variables: {
            filter: {
                id: {
                    eq: currentUser?.id
                }
            }
        }
    })
    return (
        <Modal
            open={open}
            loading={loading}
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            title="Payment Details"
            description="View your payment details."
            renderActions={() => (
                <OfficeButton ml onClick={() => navigate({
                    search: (old) => ({
                        ...old,
                        modal: supplier?.payment_details?.id ? "update" : "create"
                    })
                })}>
                    {supplier?.payment_details?.id ? "Update details" : "Add details"}
                </OfficeButton>
            )}
        >
            <PaymentMethodView payment={supplier?.payment_details} />
        </Modal>
    )
}

export default ViewPaymentMEthodContainer