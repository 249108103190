import { OfficeButton } from "components";
import { TextInput } from "components/core";
import { PaymentMethodPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

export interface PaymentDetailsFormProps {
    form: FormikProps<any>;
    resolving?: boolean;
    handleOnBlur: () => void;
}

const PaymentDetailsForm: FC<PaymentDetailsFormProps> = ({ form, resolving,handleOnBlur }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <div className="grid grid-cols-1 gap-4">
                    <div className="">
                        <PaymentMethodPicker
                            id="provider"
                            label="Payment Provider (Any mobile money provider or your bank)"
                            placeholder="e.g. MTN, AirtelTigo, Vodafone, Absa, etc."
                            required={true}
                            clearAfterSelect={["account_number", "account_name"]}
                            {...form}
                        />
                    </div>
                    <div className="flex justify-start items-end gap-2">
                        <div className="flex-1 w-full">
                            <TextInput
                            id="account_number"
                            label="Account Number"
                            type="text"
                            placeholder="e.g. 0241234567"
                            required={true}
                            {...form}
                            handleBlur={()=>handleOnBlur()}
                        />
                        </div>
                        <OfficeButton>
                            <span className="text-xs">{resolving? "Verifying ...":"Continue"}</span>
                        </OfficeButton>
                    </div>

                    <div className="">
                        <TextInput
                            id="account_name"
                            label="Account Name"
                            disabled
                            type="text"
                            placeholder="e.g. John Doe"
                            description={resolving ? "Resolving..." : "This will be resolved automatically"}
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDetailsForm;
