// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine } from "@nivo/line";
import { FC } from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyResponsiveLine: FC<{
  data: {
    value: { total: number };
    day: string;
  }[];
  fromDate: string;
  toDate: string;
  xLabel?: string;
  yLabel?: string;
}> = ({ data, xLabel, yLabel }) => (
  <ResponsiveLine
    data={[
      {
        id: "Total",
        color: "green",
        data: data.map((datum) => ({
          x: datum.day,
          y: datum.value?.total || 0,
        })),
      },
    ]}
    margin={{ top: 40, right: 50, bottom: 110, left: 60 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: false,
      reverse: false,
    }}
    yFormat=" >-"
    curve="natural"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      // orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 90,
      legend: xLabel ?? "dates",
      legendOffset: 80,
      legendPosition: "middle",
    }}
    axisLeft={{
      // orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel ?? "counts",
      legendOffset: -40,
      legendPosition: "middle",
    }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    useMesh={true}
    colors={{
      datum: "color",
    }}
  />
);

export default MyResponsiveLine;
