import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetSupplier,
  GetSupplierVariables,
} from "_graphql/queries/__generated__/GetSupplier";
import { getSupplier } from "_graphql/queries/supplier";

export const useSupplier = (
  options?: QueryHookOptions<GetSupplier, GetSupplierVariables>
) => {
  const { data, ...rest } = useQuery<GetSupplier, GetSupplierVariables>(
    getSupplier,
    options
  );

  return {
    supplier: data?.getSupplier,
    // count: data?.count || 0,
    ...rest,
  };
};
