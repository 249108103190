import { FC, PropsWithChildren } from "react";
import { Router } from "react-location";
import routes from "./routes";
import location from "./location";
import filterRoutes from "./filter";
import { useApp } from "hooks";

const RoutesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useApp();
  return (
    <div className={theme}>
      <Router location={location} routes={routes} filterRoutes={filterRoutes()}>
        {children}
      </Router>
    </div>
  )
};

export default RoutesProvider;
