import { gql } from "@apollo/client";

export const getPlugins = gql`
query GetPlugins($filter: PluginFilter, $pagination: Pagination, $supplierId: ID) {
  getPlugins(filter: $filter, pagination: $pagination, supplier_id: $supplierId) {
    id
    name
    logo
    banner_image
    category
    developer
    verified
    description
    endpoint
    is_installed_by_current_user
    created_at
    updated_at
  }
}
`;

export const getUserPlugins = gql`
query GetUserPlugins($filter: UserPluginFilter, $pagination: Pagination) {
  getUserPlugins(filter: $filter, pagination: $pagination) {
    id
    user_id
    plugin_id
  }
}
`

export const confirmCardSetup = gql`
query ConfirmCardSetup($confirmCardSetupId: String) {
  confirmCardSetup(id: $confirmCardSetupId)
}
`