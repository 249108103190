import { GetProduct_getProduct } from '_graphql/queries/__generated__/GetProduct';
import { RichEditor, SelectInput, TextInput } from 'components';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { FC } from 'react';

type Props = {
  form: FormikProps<any>;
  product: GetProduct_getProduct | null | undefined;
};

const UpdateProductInformationForm: FC<Props> = ({ form, product }: Props) => {
  return (
    <form className='w-full py-5'>
      <div className='w-full space-y-3'>
        <div className='col-span-full'>
          <TextInput id={'title'} label='Product Name' {...form} />
        </div>
        <div className='col-span-3'>
          <TextInput
            id={'price'}
            label='Selling price'
            type='number'
            {...form}
          />
        </div>
        <div className='col-span-3'>
          <TextInput
            id={'cost_price'}
            label='Cost price'
            type='number'
            {...form}
          />
        </div>
        <div className='col-span-3'>
          <TextInput
            id={'minimumOrderQuantity'}
            label='Mininum Order Quantity'
            type='number'
            {...form}
          />
        </div>
        <div className='col-span-3'>
          <SelectInput
            options={[
              { label: 'Ghana', value: 'GH' },
              { label: 'Nigeria', value: 'NG' },
              { label: 'South Africa', value: 'ZA' },
            ]}
            disabled
            id={'country'}
            label='Country'
            {...form}
          />
        </div>
        <div className='col-span-3'>
          <SelectInput
            options={['GHS', 'NGN', 'ZAR']}
            disabled
            id={'currency'}
            label='Currency'
            {...form}
          />
        </div>
        <div className='col-span-3'>
          <TextInput
            id={'sku'}
            label='Stock Keeping Unit'
            placeholder='eg.200945'
            {...form}
            disabled={true}
          />
        </div>
        <div className='col-span-3'>
          <TextInput
            id={'weight'}
            label='Weight (Optional)'
            placeholder='Weight(kg)'
            {...form}
          />
        </div>
        <div className='col-span-full'>
          <RichEditor
            id={'description'}
            label='Product Description'
            {...form}
          />
        </div>
        <div className='col-span-full'>
          <RichEditor
            id={'extra_information'}
            label='Extra Description'
            placeholder='Add Extra Description'
            {...form}
          />
        </div>
      </div>
    </form>
  );
};

export default UpdateProductInformationForm;
