import { FC, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import _ from 'lodash';
import { classNames } from 'utils';
import { Switch } from '@headlessui/react';

interface CheckBoxInputProps {
    id: string;
    label: string;
    placeholder?: string;
    verticalAlign?: boolean;
    required?: boolean;
    values: any;
    handleChange: any;
    handleBlur: any;
    errors?: any;
    touched?: any;
}

const CheckBoxInput: FC<CheckBoxInputProps> = ({
    id,
    values,
    handleChange,
    label,
    errors,
    touched,
    required,
}) => {
    return (
        <div className='mt-2'>
            <Switch.Group>
                <div className="flex items-center">
                    <Switch.Label className="mr-4">
                        {label} {required ? <span className='text-red-500'>*</span> : ''}
                    </Switch.Label>
                    <Switch
                        checked={_.get(values, id)}
                        onChange={() => handleChange({ target: { name: id, value: !_.get(values, id) } })}
                        className={`${_.get(values, id) ? 'bg-primary-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2`}
                    >
                        <span
                            className={`${_.get(values, id) ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                    </Switch>
                </div>
            </Switch.Group>
            {_.get(errors, id) && _.get(touched, id) ? (
                <p className='mt-2 text-sm text-red-600' id={`${id}-error`}>
                    {_.get(errors, id)}
                </p>
            ) : null}
        </div>
    );
};

export default CheckBoxInput;
