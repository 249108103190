import { useQuery } from "@apollo/client"
import { GetProductsCountByCategories, GetProductsCountByCategoriesVariables } from "_graphql/queries/__generated__/GetProductsCountByCategories";
import { GetSupplierDailyOrdersSummary, GetSupplierDailyOrdersSummaryVariables } from "_graphql/queries/__generated__/GetSupplierDailyOrdersSummary"
import { GetSupplierOrdersByStatusSummary, GetSupplierOrdersByStatusSummaryVariables } from "_graphql/queries/__generated__/GetSupplierOrdersByStatusSummary";
import { GetSupplierStats, GetSupplierStatsVariables } from "_graphql/queries/__generated__/GetSupplierStats";
import { getProductsCountByCategories, getSupplierDailyOrdersSummary, getSupplierOrdersByStatus, getSupplierStats } from "_graphql/queries/dashboard"
import moment from "moment";
import { DATE_FORMAT } from "utils/regex";


export const useOrdersPerDay = (variables?: GetSupplierDailyOrdersSummaryVariables) => {
    
    const { data, ...rest } = useQuery<GetSupplierDailyOrdersSummary, GetSupplierDailyOrdersSummaryVariables>(getSupplierDailyOrdersSummary, {
        variables,
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
    });

    return {
        ordersPerDay: data?.getSupplierDailyOrdersSummary?.map((el: any) => ({
            ...el,
            date: moment(el.date).format(DATE_FORMAT),
        })) || [],
        ...rest
    }
}


export const useDashboardStats = (variables?: GetSupplierStatsVariables) => {
    const { data, ...rest } = useQuery<GetSupplierStats, GetSupplierStatsVariables>(getSupplierStats, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables
    });

    return {
        supplierStats: data?.getSupplierStats,
        ...rest
    }
}


export const useOrdersByStatus = (variables?: GetSupplierOrdersByStatusSummaryVariables) => {
    const { data, ...rest } = useQuery<GetSupplierOrdersByStatusSummary, GetSupplierOrdersByStatusSummaryVariables>(getSupplierOrdersByStatus, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables
    });

    return {
        summary: data?.getSupplierOrdersByStatusSummary,
        ...rest
    }
}

export const useProductsByCategory = (variables?: GetProductsCountByCategoriesVariables) => {
    const { data, ...rest } = useQuery<GetProductsCountByCategories, GetProductsCountByCategoriesVariables>(getProductsCountByCategories, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables
    });

    return {
        productSummary: data?.getProductsCountByCategories || [],
        ...rest
    }
}