import clsx from 'clsx';
import { FC, ReactElement, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  title: string;
  description?: string;
  freeFlow?: boolean;
  loading?: boolean;
  renderActions?: () => JSX.Element;
};

const SectionLayout: FC<Props> = ({
  children,
  title,
  description,
  freeFlow,
  loading,
  renderActions,
}) => {
  if (loading) {
    // Render a loading shimmer or spinner when loading is true
    return (
      <div className='w-full px-4'>
        <div className='rounded-md shadow-sm border border-gray-300 flex flex-col bg-white mt-6'>
          <div className='animate-pulse flex flex-col w-full py-4 px-6'>
            <span className='w-3/4 h-4 bg-gray-200 mb-2 rounded'></span>
            <span className='w-1/2 h-3 bg-gray-200 rounded'></span>
          </div>
          <hr className='mb-6' />
          <div className='px-5 pb-5'>
            <div className='animate-pulse flex flex-col w-full py-4 px-6'>
              <span className='w-3/4 h-4 bg-gray-200 mb-2 rounded'></span>
              <span className='w-1/2 h-3 bg-gray-200 rounded'></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='w-full px-4 '>
      <div className='rounded-md shadow-sm border border-gray-300 flex flex-col bg-white mt-6 '>
        <div className='flex justify-between items-center w-full sm:pr-5 pr-2'>
          <div className='flex flex-col w-full py-4 px-6'>
            <span className='font-semibold text-sm'>{title}</span>
            {description && (
              <p className='block text-xs text-gray-500 mt-1'>{description}</p>
            )}
          </div>
          <div className='flex flex-1 justify-end items-center pr-4'>
            {renderActions?.()}
          </div>
        </div>
        <hr className='mb-6' />
        <div
          className={clsx('px-5 pb-5', {
            'grid grid-cols-1 gap-x-6 gap-y-8 w-full sm:grid-cols-6': !freeFlow,
            'w-full': freeFlow,
          })}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SectionLayout;
