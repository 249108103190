import { Modal, OfficeButton, toast } from 'components';
import InviteResellerForm from './forms/invite-reseller';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { inviteReseller } from '_graphql/mutation/invitereseller';
import { InviteResellerBySupplier, InviteResellerBySupplierVariables } from '_graphql/mutation/__generated__/InviteResellerBySupplier';
import { useMutation } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { currentUserVar } from '_graphql/cache/auth';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const InviteResellerContainer = ({
    open,
    setOpen,
    refetch,
}: Props) => {
    const currentuser = useReactiveVar(currentUserVar);
    const [initiate, { loading }] = useMutation<InviteResellerBySupplier, InviteResellerBySupplierVariables>(inviteReseller)
    const form = useFormik<any>({
        initialValues: {
            email: "",
            terms_id: "",
            supplier_id: currentuser?.id,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required("Email is required"),
            terms_id: Yup.string().required("Terms and Conditions is required, Create one if you don't have any"),
        }),
        onSubmit: async (values) => {
            initiate({
                variables: {
                    supplierId: currentuser?.id,
                    email: values.email,
                    termsId: values.terms_id,
                }
            }).then(({ data }) => {
                if (data?.inviteResellerBySupplier) {
                    setOpen(false);
                    form.resetForm()
                    toast("Invite successfully sent", 'success');
                }
            })
        }
    });
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Invite Reseller"
            description="Invite a reseller to your office."
            renderActions={() => (
                <div className='ml-2'>
                    <OfficeButton disabled={loading} onClick={form.handleSubmit}>
                        {loading ? "Inviting ..." : " Invite Reseller"}
                    </OfficeButton>
                </div>
            )}
        >
            <InviteResellerForm form={form} />
        </Modal>
    )
}

export default InviteResellerContainer