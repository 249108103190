import { gql } from "@apollo/client";

export const getSupplier = gql`
query GetSupplier($filter: SupplierFilter) {
  getSupplier(filter: $filter) {
    setupConfig {
      hasCategories
      hasTerms
      hasPaymentMethod
    }
    id
    permissions
    name
    email
    phone
    country
    avatar
    supplier_type
    has_verified_phonenumber
    has_verified_email
    setup_complete
    generic_name
    device_token
    agreement_terms
    social_platform
    social_platform_token
    createdAt
    deletedAt
    business_details {
      id
      supplier_id
      business_name
      business_address
      industry
      description
      logo
      registered
      email
      phone
      city
      state
      postal_code
      delivery_option
      supplier_business_verified
      country
    }
    payment_details {
      id
      supplier_id
      account_name
      account_number
      provider
      method
    }
  }
}

`;