import { Modal, OfficeButton } from 'components';
import { FormikProps } from 'formik';
import UploadFile from 'pages/create-discount/cards/upload';
import { FC } from 'react';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  form: FormikProps<any>;
  loading: boolean;
};

const AddProductContainer: FC<Props> = ({ form, open, setOpen, loading }) => {
  return (
    <Modal
      title='Upload Products'
      description='Upload products by selecting the sku column in an excel file'
      setOpen={setOpen}
      open={open}
      loading={loading}
      renderActions={() => (
        <div className='ml-2'>
          <OfficeButton
            type='button'
            onClick={() => {
              form.setFieldValue(
                'products',
                form.values.products_sku?.map((data: any) =>
                  data[form?.values?.sku_column || '']?.toString()
                )
              );
              form.handleSubmit();
            }}>
            Done
          </OfficeButton>
        </div>
      )}>
      <UploadFile form={form} />
    </Modal>
  );
};

export default AddProductContainer;
