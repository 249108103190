import { useMatch } from "react-location";
import { useCustomer } from "pages/customers/hooks/useCustomer";
import { LocationGenerics } from "router/location";
import SectionLayout from "pages/create-product/components/SectionLayout";


const stats = [
    { name: 'Total Subscribers', stat: '71,897' },
    { name: 'Avg. Open Rate', stat: '58.16%' },
    { name: 'Avg. Click Rate', stat: '24.57%' },
]

const ViewCustomer = () => {
    const { params } = useMatch<LocationGenerics>();
    const { customer, loading } = useCustomer({
        variables: {
            filter: {
                id: { eq: params.customer_id || "" },
            },
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    return (
        <div className="grid w-full lg:grid-cols-2 gap-2 items-start overflow-y-scroll">
            <div>
                <div className="mx-5">
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        {stats.map((item) => (
                            <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <SectionLayout freeFlow title="Customer Information" loading={loading}>
                    <div className="grid md:grid-cols-2 gap-2 p-2 w-full">
                        <div className="grid">
                            <span className="text-gray-500 text-sm">Name</span>
                            <span className="text-sm">{customer?.name ?? "Not Specified"}</span>
                        </div>
                        <div className="grid">
                            <span className="text-gray-500 text-sm">Phone Number</span>
                            <span className="text-sm">{customer?.phone ?? "Not Specified"}</span>
                        </div>
                        <div className="grid">
                            <span className="text-gray-500 text-sm">Email</span>
                            <span className="text-sm">{customer?.email ?? "Not Specified"}</span>
                        </div>
                    </div>
                </SectionLayout>

            </div>
            <SectionLayout freeFlow title="Customer Addresses" loading={loading}>
                {customer?.address?.map((address) => (
                    <div className="grid gap-2 p-2 md:p-5">
                        <div className="flex justify-between">
                            <span className="text-sm text-gray-500 font-light">Area</span>
                            <span className="text-sm">{address?.area}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-sm text-gray-500 font-light">City</span>
                            <span className="text-sm">{address?.city}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-sm text-gray-500 font-light">Country</span>
                            <span className="text-sm">{address?.country}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-sm text-gray-500 font-light">Landmark</span>
                            <span className="text-sm">{address?.landmark}</span>
                        </div>
                    </div>
                ))}
            </SectionLayout>
        </div>
    );
};

export default ViewCustomer;
