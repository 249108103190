import { OfficeButton, toast } from "components";
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { cardSetup } from "_graphql/mutation/payment";
import {
  InitiateCardSetup,
  InitiateCardSetupVariables,
} from "_graphql/mutation/__generated__/InitiateCardSetup";
import { currentUserVar } from "_graphql/cache/auth";
import { useUrlState } from "utils";
import {
  ConfirmCardSetup,
  ConfirmCardSetupVariables,
} from "_graphql/queries/__generated__/ConfirmCardSetup";
import { confirmCardSetup } from "_graphql/queries/integrations";
import { useEffect } from "react";


interface Props {
  // open: boolean;
  // setOpen: (val: any) => void;
  integrationID: string | null | undefined;
}

const PaymentMethod = ({ integrationID }: Props) => {
  const currentUser = useReactiveVar(currentUserVar);
  const stripe = useStripe();
  const elements = useElements();

  const [mutate, { loading }] = useMutation<
    InitiateCardSetup,
    InitiateCardSetupVariables
  >(cardSetup);

  const [setupIntent, setSetupIntent] = useUrlState("setup_intent");
  const [redirectStatus] = useUrlState("redirect_status");

  const [confirmCard, { loading: confirmCardLoad }] = useLazyQuery<
    ConfirmCardSetup,
    ConfirmCardSetupVariables
  >(confirmCardSetup);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      //   handleError(submitError);
      return;
    }

    const { data } = await mutate({
      variables: {
        input: {
          integration_id: integrationID ?? "",
          supplier_email: currentUser?.email ?? "",
          supplier_phone: currentUser?.phone ?? "",
        },
      },
    });

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: data?.initiateCardSetup ?? "",
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      // window.location.reload()
      toast(result.error.message as string, "error");
      // setOpen(undefined);
    } else {
      // setOpen(undefined);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(
    function checkConfirmCardSetup() {
      // setOpen(undefined);
      if (redirectStatus === "succeeded" && setupIntent !== undefined) {
        confirmCard({
          variables: {
            confirmCardSetupId: setupIntent,
          },
        })
          .then((res) => {
            if (res.data?.confirmCardSetup) {
              toast("Payment details added successfully", "success");
              setSetupIntent(undefined);
              // setOpen(undefined);
              // const newloc = window.location.href.split('?')[0]
              // window.location.replace(newloc)
            } else {
              toast("A problem occured", "error");
            }
          })
          .catch((err) => {
            toast(err?.message, "error");
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setupIntent]
  );

  return (
    <div>
      <PaymentElement
        onLoadError={({ error }) => console.log(error, "<<< Stripe error")}
      />
      <AddressElement
        options={{ mode: "billing", fields: { phone: "always" } }}
      />
      <div className=" mt-5 flex justify-end ">
        <OfficeButton onClick={handleSubmit} disabled={!stripe}>
          {loading || confirmCardLoad ? "loading..." : "Submit"}
        </OfficeButton>
      </div>
    </div>
  );
};

export default PaymentMethod;
