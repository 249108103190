import { FC, useState } from "react";
import {
  ArrowRightOnRectangleIcon,
  MegaphoneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, Navigate, Outlet, useLocation } from "react-location";
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import routes from "router/routes";
import {
  clearAuth,
  currentTokenVar,
  currentUserVar,
} from "_graphql/cache/auth";
import { classNames, processNavigationSections, wrapClick } from "utils";
import { Avatar } from "components/core";
import { withRoutePermissions } from "router/filter";
import { Popover } from "@headlessui/react";
import { useApp } from "hooks";
import { useSupplier } from "pages/settings/hooks/use-supplier";

const AppLayout: FC = () => {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(true);
  const currentUser = useReactiveVar(currentUserVar);
  const currentToken = useReactiveVar(currentTokenVar);
  const {logo} = useApp()
  useSupplier({
    variables: {
      filter: {
        id: {
          eq: currentUser?.id,
        },
      },
    },
    onCompleted: (data) => {
      if (data.getSupplier) {
        currentUserVar({
          ...currentUser,
          ...data.getSupplier,
        });
      }
    },
  });
  const { name } = useApp();
  const navigation = _.chain(routes)
    .filter((route) => _.get(route, "meta.layout") === "App")
    .thru(withRoutePermissions)
    .filter((item) => !!item.sidebar)
    .value();

  if (!currentToken || !currentUser?.id ) {
    return (
      <Navigate
        to={"/sign-in"}
        search={{ redirect: location.current.href }}
        replace
      />
    );
  }

  if (!currentUser.setup_complete) {
    return (
      <Navigate
        to={"/setup-business"}
        search={{ redirect: location.current.href }}
        replace
      />
    );
  }

  return (
    <div className="h-screen">
      {showBanner && currentUser?.supplier_type === "BASIC" && (
        <div className="bg-primary-600 absolute top-0 z-50 right-0 left-0">
          <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-primary-800 p-2">
                  <MegaphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 truncate font-medium text-white">
                  <span className="md:hidden">Become an Advanced User Today!</span>
                  <span className="hidden md:inline">
                  Unlock exclusive functionalities and take your experience to the next level!
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  href="https://supplier.dukesell.com/pro/"
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary-600 shadow-sm hover:bg-primary-50">
                  Learn more
                </a>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  onClick={() => setShowBanner(false)}
                  className="-mr-1 flex rounded-md p-2 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="h-full flex">
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col border-r border-gray-300 bg-white w-64">
            <div className="flex flex-shrink-0 items-center border-b border-gray-300 bg-white p-5 flex-row">
              <div className="flex items-center justify-start gap-2">
                <img
                  className="h-10 w-10 rounded-full"
                  src={logo}
                  alt="Duke Supplier's Dashboard"
                />
                <span className="text-sm font-bold text-primary-600 font-mono">
                <span>Supplier</span> <span className="pt-3"> Dashboard</span>
                </span>
              </div>
            
            </div>
            <div className="py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar">
              <nav className="flex-1 space-y-6 px-2" aria-label="Sidebar">
                {processNavigationSections(currentUser?.supplier_type).map(
                  (section) => (
                    <div key={section} className="space-y-1">
                      <h3
                        className="px-3 text-xs font-light text-gray-400"
                        id={section}
                      >
                        {section}
                      </h3>
                      <div
                        className="space-y-1"
                        role="group"
                        aria-labelledby={section}
                      >
                        {_.filter(
                          navigation,
                          (item) => _.get(item, "meta.section") === section
                        ).map((item) => {
                          return (
                            <Link
                              key={item.sidebar?.label}
                              to={item.path}
                              getActiveProps={() => ({
                                className: "bg-primary-300 text-primary-500",
                              })}
                              getInactiveProps={() => ({
                                className:
                                  "text-gray-500 hover:bg-primary-200 hover:text-primary-600",
                              })}
                              className="group flex items-center px-2 py-2 text-sm rounded-md"
                            >
                              {({ isActive }) => (
                                <>
                                  {item.sidebar?.icon && (
                                    // <img src={item.sidebar.icon}
                                    //   className={classNames(
                                    //     isActive
                                    //       ? "fill text-primary-500"
                                    //       : "text-gray-600 ",
                                    //     "mr-3 flex-shrink-0 h-7 w-7"
                                    //   )}
                                    //   aria-hidden="true"
                                    // />
                                    <item.sidebar.icon
                                      className={classNames(
                                        isActive ? "fill text-primary-500" : "text-gray-600",
                                        "mr-3 flex-shrink-0 h-7 w-7"
                                      )}
                                      aria-hidden="true"
                                    />
                                  )}
                                  {item.sidebar?.label}
                                </>
                              )}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  )
                )}
              </nav>
              {/* <nav className="space-y-1 px-2">
                <button
                  onClick={wrapClick(clearAuth)}
                  className="group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white"
                >
                  <ArrowRightOnRectangleIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Logout
                </button>
              </nav> */}
            </div>

            <Popover className="flex relative flex-shrink-0 border-t border-gray-300 p-4">
              <Popover.Button className=" flex items-left space-x-1 overflow-hidden relative">
                <div className="flex-shrink-0">
                  <Avatar alt={currentUser?.name || "N A"} />
                </div>
                <div className=" overflow-hidden">
                  <p className="text-sm font-medium text-left text-gray-400 group-hover:text-white line-clamp-1">
                    {currentUser?.name || "N A"}
                  </p>
                  <p className="text-xs font-medium text-left text-gray-400 group-hover:text-gray-100 line-clamp-1 text-ellipsis overflow-hidden">
                    {currentUser?.email || "N A"}
                  </p>
                </div>
              </Popover.Button>
              <Popover.Panel className="absolute -top-[70px] align-middle mx-0  p-3 left-0 right-0 mt-2   shadow-lg z-10">
                {/* Content inside the Popover.Panel */}
                <button
                  onClick={wrapClick(clearAuth)}
                  className="group w-full flex items-center px-2 py-2 z-20 relative bg-primary-800 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white"
                >
                  <ArrowRightOnRectangleIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Logout
                </button>
              </Popover.Panel>
            </Popover>
          </div>
        </div>

        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          {/* Display content here */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
