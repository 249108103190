import { TableComponent, Paginator, OfficeHeader, TableLoader } from "components";
import { useCarts } from "pages/orders/hooks/use-carts";
import { usePagination } from "hooks";
import { GetCarts_rows } from "_graphql/queries/__generated__/GetCarts";
import _ from "lodash";
import numeral from "numeral";
import { useAppURL } from "_graphql/cache/auth";


const CustomerOrders = () => {
  const { params } = useAppURL()
  const { offset, limit, setPage } = usePagination();
  const { orders, refetch, loading, count } = useCarts({
    variables: {
      filter: {
        id: {
          eq: params?.customer_id
        }
      }
      ,
      pagination: {
        limit: limit,
        offset: offset,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  return (
    <div className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            data={orders}
            title={"Orders"}
            refetch={refetch}
            columns={[
              {
                name: "Cart Number",
                accessor: "cart_number",
              },
              {
                name: "Payment Status",
                accessor: "payment_status",
              },
              {
                name: "Payment Type",
                accessor: "payment_type",
                render: (value: GetCarts_rows) => _.startCase(value?.payment_type) || "N/A"
              },
              {
                name: "Order Total",
                accessor: "order_total",
                render: (value: GetCarts_rows) => numeral(value?.order_total).format("#,#.##")
              },
            ]}
            hasSearch
            loading={loading}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={orders.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>

    </div>
  );
}

export default CustomerOrders;