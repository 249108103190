import { Modal, OfficeButton } from 'components';
import { FormikProps } from 'formik';
import { FC } from 'react';
import ProductList from './cards/product-list';
type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  form: FormikProps<any>;
  handleCheckboxChange: (event: any) => void;
};

const SelectProductContainer: FC<Props> = ({
  form,
  open,
  setOpen,
  handleCheckboxChange,
}) => {
  return (
    <Modal
      title='Select Products'
      description="Select the products you'd like to apply this discount on"
      setOpen={setOpen}
      open={open}
      renderActions={() => (
        <div className='ml-2'>
          <OfficeButton type='button' onClick={() => setOpen(false)}>
            Done
          </OfficeButton>
        </div>
      )}>
      <ProductList form={form} handleCheckboxChange={handleCheckboxChange} />
    </Modal>
  );
};

export default SelectProductContainer;
