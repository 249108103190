import { useMutation } from '@apollo/client'
import { setSetupComplete, useCurrentUser } from '_graphql/cache/auth'
import { AddSupplierBusinessDetails, AddSupplierBusinessDetailsVariables } from '_graphql/mutation/__generated__/AddSupplierBusinessDetails'
import { addSupplierBusinessDetails } from '_graphql/mutation/auth'
import { AvatarUpload, CheckBoxInput, OfficeButton, TextArea, TextInput, toast } from 'components'
import { useFormik } from 'formik'
import { useApp } from 'hooks'
import { AuthLocationGenerics } from 'layouts/auth'
import { FC } from 'react'
import { Navigate, useSearch } from 'react-location'
import * as Yup from 'yup'
type Props = {}

const validationSchema = Yup.object().shape({
    business_name: Yup.string().required('Business Name is required'),
    description: Yup.string().required('Business Description is required'),
    business_address: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    postal_code: Yup.string().notRequired(),
    logo: Yup.string().notRequired(),
    referred: Yup.string().notRequired(),
})

const SetupBusinessPage: FC<Props> = (props) => {
    const currentUser = useCurrentUser()
    const search = useSearch<AuthLocationGenerics>()
    const [
        addSupplierBusinessInfo,
        { loading },
    ] = useMutation<AddSupplierBusinessDetails, AddSupplierBusinessDetailsVariables>(addSupplierBusinessDetails);
    const form = useFormik({
        onSubmit: (values) => {
            addSupplierBusinessInfo({
                variables: {
                    input: {
                        business_name: values.business_name,
                        description: values.description,
                        business_address: values.business_address,
                        city: values.city,
                        state: values.state,
                        postal_code: values.postal_code,
                        logo: values.logo,
                        referred_by: values.referred,
                        email: "",
                        phone: "",
                        industry: "",
                        supplier_id: currentUser?.id || "",
                        delivery_option: "",
                        registered: false,
                    }
                }
            }).then(({ data }) => {
                if (data?.addSupplierBusinessDetails) {
                    toast("Business details saved successfully", "success")
                    setSetupComplete(true)
                }
            }).catch(err => {
                toast("An error occured while saving your business details", "error")
            })
        },
        validationSchema,
        initialValues: {
            supplier_id: '',
            business_name: '',
            description: '',
            business_address: '',
            city: '',
            state: '',
            postal_code: '',
            logo: '',
            referred: '',
            registered: false,
        },
    })

    if(!currentUser?.id){
        return <Navigate
        to={search?.redirect || "/sign-in"}
        replace
      />
    }
    return (
        <form className="space-y-3 divide-y divide-gray-200">
            <div className="pt-6">
                <span className="text-xs font-light">Busines Details</span>
                <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <CheckBoxInput id={'registered'} label={'Registered Business'} {...form} />
                    </div>
                    <div className="col-span-2">
                        <TextInput
                            id="business_name"
                            label="Business Name"
                            type="text"
                            placeholder="e.g. Nike"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <TextArea
                            id="description"
                            label="Business Description"
                            placeholder="e.g. We sell all kinds of shoes"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
            <div className="pt-6">
                <span className="text-xs font-light">Busines Contact</span>
                <div className="grid grid-cols-2 gap-2">
                    <div className="">
                        <TextInput
                            id="business_address"
                            label="Street Address"
                            type="text"
                            placeholder="e.g. Nike"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <TextInput
                            id="city"
                            label="City"
                            type="text"
                            placeholder="e.g. Nike"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <TextInput
                            id="state"
                            label="State/Province"
                            type="text"
                            placeholder="e.g. Nike"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <TextInput
                            id="postal_code"
                            label="ZIP/Postal Code"
                            type="text"
                            placeholder="e.g. Nike"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className="text-xs font-light">Busines Info</span>
                <div className="grid grid-cols-2 gap-2">
                    <div className="">
                        <AvatarUpload
                            id="logo"
                            label="Logo"
                            // type="text"
                            // placeholder="e.g. Nike"
                            required={false}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <TextInput
                            id="referred"
                            label="Who Referred you?"
                            type="text"
                            placeholder="e.g. Nike"
                            required={false}
                            {...form}
                        />
                    </div>
                </div>
            </div>
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <div className='grid grid-cols-1 gap-4 pt-6'>
                {/* <OfficeButton width='full' disabled={loading} type='button' variant='tertiary'>
                    Skip
                </OfficeButton> */}
                <OfficeButton width='full' disabled={loading} onClick={form.handleSubmit}>
                    Save
                </OfficeButton>

            </div>
        </form>
    )
}

export default SetupBusinessPage