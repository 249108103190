import { GetProductCategories } from '_graphql/queries/__generated__/GetProductCategories';
import { GetProductCategory_getProductCategory } from '_graphql/queries/__generated__/GetProductCategory';
import { ActionButton } from 'components';
import { TColumn, TableColumn } from 'utils/types';

export const CategoryColumn: TableColumn<TColumn> = [
  {
    name: 'Category Name',
    accessor: 'title',
    render: (category: GetProductCategory_getProductCategory | null) => (
      <div className='flex items-center max-w-2xl break-all truncate text-xs'>
        <div className='h-10 w-10 flex-shrink-0 text-xs'>
          <img
            className='h-10 w-10 rounded-none'
            src={category?.avatar || ''}
            alt=''
          />
        </div>
        <div className='ml-4 break-all flex-wrap text-xs'>
          <div className=' text-gray-900 truncate flex-wrap text-xs'>
            {category?.name.toLocaleUpperCase()}
          </div>
        </div>
      </div>
    ),
  },
  // {
  //   name: 'Actions',
  //   accessor: 'action',
  //   render: (category: GetProductCategory_getProductCategory | null) => (
  //     <div className='-ml-3'>
  //       <ActionButton
  //         action={'view'}
  //         tooltip='View Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //       <ActionButton
  //         action={'update'}
  //         tooltip='Update Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //       <ActionButton
  //         action={'remove'}
  //         tooltip='Delete Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //     </div>
  //   ),
  // },
];
