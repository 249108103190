import { Modal, OfficeButton, toast } from 'components';

import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useMutation } from '@apollo/client';

import { useEffect } from 'react';

import { useCurrentUser } from '_graphql/cache/auth';
import { useDiscount } from './hooks/use-discounts';
import {
  UpdateDiscount,
  UpdateDiscountVariables,
} from '_graphql/mutation/__generated__/UpdateDiscount';
import { updateDiscount } from '_graphql/mutation/discount';
import {
  CountryCode,
  DiscountType,
  FixedAmountAllocation,
  UserType,
} from '_graphql/__generated__/globalTypes';
import moment from 'moment';
import DiscountForm from './forms/update-discount';
import { useDeliveryLocation } from 'pages/create-discount/hooks/use-delivery-locations';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

type DeliveryType = {
  value: string | null;
  label: string;
};

interface FormValues {
  discount_type: DiscountType | null;

  description: string | null;
  start_date: string;
  expiry_date: string;
  code: string | null;
  r_limit: number | null;
  tagname: string | null;
  amount: number | null;
  fixed_amount_allocation: FixedAmountAllocation | null;
  percentage: number | null;
  products: (string | null)[] | null;
  delivery: DeliveryType[] | undefined;
}

const UpdateDiscountContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();
  const currentUser = useCurrentUser();
  const [initiate, { loading: updating }] = useMutation<UpdateDiscount, UpdateDiscountVariables>(
    updateDiscount,
    {
      refetchQueries: ['GetDiscounts'],
    }
  );

  const { discount, loading } = useDiscount({
    variables: {
      filter: {
        id: {
          eq: seacrhParams?.id ? seacrhParams?.id : null,
        },
      },
    },
  });

  const initialValues: FormValues = {
    discount_type: null,
    description: '',
    start_date: '',
    expiry_date: '',
    code: '',
    r_limit: null,
    tagname: null,
    amount: null,
    fixed_amount_allocation: null,
    percentage: null,
    products: [] as string[],
    delivery: undefined,
  };
  const form = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      percentage: Yup.string().when('discount_type', {
        is: (dtype: string) => dtype === 'PERCENTAGE',
        then: () => Yup.string().required('Percentage value is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      amount: Yup.string().when('discount_type', {
        is: (dtype: string) => dtype === 'FIXED_AMOUNT',
        then: () => Yup.string().required('Amount value is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      fixed_amount_allocation: Yup.string().when('discount_type', {
        is: (dtype: string) => dtype === 'FIXED_AMOUNT',
        then: () => Yup.string().required('Fixed amount allocation is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      discount_type: Yup.string().required('Discount type is required'),
      expiry_date: Yup.date()
        .required('End date is required')
        .min(Yup.ref('start_date'), 'End date must be equal to or later than the start date'),
      start_date: Yup.date().required('Start date is required'),
      description: Yup.string().required('Description is required'),
    }),
    onSubmit: async (values) => {
      await initiate({
        variables: {
          filter: {
            id: { eq: seacrhParams.id },
          },
          data: {
            type: values.discount_type as DiscountType,
            valid_country: currentUser.country as CountryCode,
            description: values.description,
            code: values.code,
            start_date: moment(values.start_date).format('DD/MM/YYYY'),
            expiry_date: moment(values.expiry_date).format('DD/MM/YYYY'),
            amount: values.discount_type === 'FIXED_AMOUNT' ? values.amount : null,

            locations:
              values.discount_type === 'FREE_DELIVERY'
                ? values.delivery?.map((_deli) => _deli.value)
                : null,
            fixed_amount_allocation:
              values.discount_type === 'FIXED_AMOUNT'
                ? (values.fixed_amount_allocation as FixedAmountAllocation | null)
                : null,
            percentage: values.discount_type === 'PERCENTAGE' ? values.percentage : null,
            user_id: currentUser?.id,
            redemptions_limit: values.r_limit,
            created_by: UserType.SUPPLIER,
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateDiscount) {
            toast('Discount updated successfully', 'success');
            form.resetForm();
            navigate({
              to: '/discount',
            });
          } else {
            toast('Something went wrong', 'error');
          }
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });

  const { deliveryLocation, loading: deliveryLoading } = useDeliveryLocation({
    variables: {
      filter: discount?.locations
        ? {
            id: {
              in: discount?.locations as string[],
            },
          }
        : undefined,
    },
  });

  useEffect(() => {
    if (discount) {
      form.setValues({
        discount_type: discount?.type,
        description: discount?.description,
        code: discount?.code || null,
        r_limit: discount?.redemptions_limit,
        tagname: discount?.locations_tagname,
        amount: discount?.amount,
        fixed_amount_allocation: discount?.fixed_amount_allocation,
        percentage: discount?.percentage,
        products: discount?.products,
        delivery: deliveryLocation?.map((data) => ({
          value: data?.id || '',
          label: data?.location || '',
        })),
        start_date: moment(discount?.start_date, 'DD/MM/YYYY').format('yyyy-MM-DD') || '',
        expiry_date: moment(discount?.expiry_date, 'DD/MM/YYYY').format('yyyy-MM-DD') || '',
      });
      form.setTouched({ start_date: true, expiry_date: true });
    }
  }, [loading, seacrhParams.id, deliveryLoading]);

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='Update Discount'
      description='Update Discount Details'
      renderActions={() => (
        <div className='ml-2'>
          <OfficeButton disabled={!form.isValid || loading} onClick={form.handleSubmit}>
            {updating ? 'Updating ...' : ' Update Discount'}
          </OfficeButton>
        </div>
      )}>
      <DiscountForm form={form} />
    </Modal>
  );
};

export default UpdateDiscountContainer;
