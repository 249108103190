import { List, OfficeHeader, toast } from "components";
import { useMatch } from "react-location";
import { useSupplier } from "pages/settings/hooks/use-supplier";
import RequestedProductsModalContainer from "./components/RequestedProductsModalContainer";
import ViewProductContainer from "pages/marketplace/components/ViewProductContainer";
import {
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import ProductCard from "components/cards/ProductCard";
import { useProducts } from "pages/products/hooks/use-products";
import { usePagination } from "hooks";
import { currentUserVar } from "_graphql/cache/auth";
import { LocationGenerics } from "router/location";
import emptybox from "assets/empty-box (1) 1.png";
import { SetStateAction, useState } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import image from "assets/noProduct.png";
import {
  CreatePartnership,
  CreatePartnershipVariables,
} from "_graphql/mutation/__generated__/CreatePartnership";
import { createPartnership } from "_graphql/mutation/partnerships";
import { useUrlState } from "utils";
import { ShoppingBagIcon } from "@heroicons/react/24/solid";
import Loader from "components/loader/loader";
const ViewSupplier = () => {
  const { params } = useMatch<LocationGenerics>();
  const { supplier } = useSupplier({
    variables: {
      filter: {
        id: { eq: params?.supplier_id } || "",
      },
    },
  });

  const { offset, limit  } = usePagination();
  const { products, refetch, loading } = useProducts({
    variables: {
      getProductsFilter: {
        supplier_id: {
          eq: params?.supplier_id,
        },
      },
      getProductsPagination: {
        limit: limit,
        offset: offset,
      },
    },
  });
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handleProductSelectionChange = (
    productsku: string,
    selected: boolean
  ) => {
    if (selected) {
      toast("Product has been added to cart, check top right corner to view cart ","success")
      setSelectedProducts((prevSelected: any) => [...prevSelected, productsku]);
    } else {
      setSelectedProducts((prevSelected: any) =>
        prevSelected.filter((sku: any) => sku !== productsku)
      );
    }
  };
  const currentUser = useReactiveVar(currentUserVar);
  const [create, { loading: isCreating }] = useMutation<
    CreatePartnership,
    CreatePartnershipVariables
  >(createPartnership);
  const [modal, setModal] = useUrlState("modal");
  const handleRequestProducts = () => {
    create({
      variables: {
        contractor: params!.supplier_id!,
        skus: selectedProducts!,
        name:
          "Contract between" +
          " " +
          supplier?.business_details?.business_name +
          " and " +
          currentUser?.business_details?.business_name,
        client: currentUser.id,
      },
    }).then(({ data }) => {
      if (data?.createPartnership) {
        toast("Partnership created successfully ", "success");
        setModal(undefined);
        setSelectedProducts([]);
        refetch();
      }
    });
  };
  const handleRequestModal = () => {
    setModal("invite");
  };
  const [viewDetailsVisible, setViewDetailsVisible] = useState(false);
  const [currentProductID, setCurrentProductID] = useState(
    null as string | null
  );
  const handleViewDetails = (
    currentProductID: SetStateAction<string | null>
  ) => {
    setViewDetailsVisible(true);
    setCurrentProductID(currentProductID);
  };

  return (
    <main className="flex-1 flex flex-col px-8  overflow-scroll h-screen bg-white">
      <OfficeHeader
        renderActions={() => (
          // <ShoppingBagIcon
          //   className="w-6 h-6 bg-primary-700 text-white"
          //   onClick={handleRequestModal}
          // />
          <div  onClick={handleRequestModal} className="flex flex-row mb-3 ">
            
            <ShoppingBagIcon
              className="w-6 h-6 bg-white text-primary-500 mt-3"
              onClick={handleRequestModal}
            />
            <div className="text-white text-center text-xs flex items-center justify-center  font-bold bg-primary-500 rounded-full w-4 h-4 relative right-4 text-bold -left-3 -bottom-3">
              {selectedProducts.length}
            </div>
            <div className="mt-3 text-primary-500">Request Products</div>
          </div>
        )}
      />
      <div className="flex flex-col pt-4   space-y-4">
        <div className="flex flex-row space-x-4 items-center space-y-2">
          <div className="">
            <img
              src={supplier?.business_details?.logo! || image}
              className="h-24 w-24 flex-none rounded-full  object-cover"
              alt=""
            />
          </div>
          <div className="flex flex-col space-y-1">
            {/* <div className="w-5 h-5 rounded-full object-contain mt-1">
              <img
                src={ghanaflag}
                className="object-cover rounded-full"
                alt=""
              />
            </div> */}
            <div className="font-bold text-lg">
              {supplier?.business_details?.business_name}
            </div>
            {supplier?.business_details?.city &&
             <div>City:{supplier?.business_details?.city}</div>
             }
          <div className="">{supplier?.business_details?.description}</div>

          </div>
          {/* <div className="flex flex-row space-x-4">
            <div className="flex flex-row text-center justify-center space-x-2 py-1 px-1   border border-gray-300 rounded-md ">
              <div className="text-center text-sm ">Contact Supplier</div>
              <div>
                <ChatBubbleLeftRightIcon className="h-4 w-4 mt-1 text-gray-40" />
              </div>
            </div>
            <div className="flex flex-row text-center text-sm justify-center space-x-2 py-1 px-1   border border-gray-300 rounded-md">
              View terms
            </div>
          </div> */}
        </div>
        <div className="w-full border border-gray-200 "></div>
        {/* <div>
          <div className="flex flex-row w-96 text-gray-500  justify-between border px-3 bg-white  border-[#C0C0C0] rounded-md">
            <div className="flex flex-row space-x-2">
              <div className="flex m-auto">
                <MagnifyingGlassIcon className="w-4 h-4 text-gray-400" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search "
                  className="border-none hover:border-none bg-white appearance-none active:border-none focus:ring-0"
                />
              </div>
            </div>
            <div className="flex flex-row space-x-2 m-auto">
              <div className="border h-6 border-gray-400"></div>
              <div>All categories</div>
              <div>
                <ArrowDownIcon className="w-4 h-4 mt-1.5 text-gray-400" />
              </div>
            </div>
          </div>
        </div> */}
        <div>
          {!loading && products.length === 0 ? (
            <div className=" w-full m-auto flex flex-col justify-center items-center">
            <div>
              <img src={emptybox} className="w-48 h-48" alt="" />
            </div>
            <div className="text-xl">No product</div>
            <div className="text-md w-96 text-center">
              This supplier doesn't have any product at the moment. Please
              check back later or explore other suppliers
            </div>
          </div>
          ) :loading?(<Loader/>) :(
            <div className=" flex flex-col justify-center items-center   sm:grid sm:grid-cols-4 sm:gap-4">
              <List
                data={products}
                renderItem={(product) => (
                  <ProductCard
                    key={product?.id}
                    product={product}
                    productselected={selectedProducts.includes(product?.sku)}
                    onSelectionChange={handleProductSelectionChange}
                    onViewDetails={() => handleViewDetails(product?.id)}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
      <RequestedProductsModalContainer
        loading={isCreating}
        handleRequestProducts={handleRequestProducts}
        selectedProducts={selectedProducts}
        open={modal === "invite"}
        setOpen={(val: boolean) => setModal(val ? "invite" : undefined)}
      />
      {viewDetailsVisible && (
        <ViewProductContainer
          open={viewDetailsVisible}
          setOpen={setViewDetailsVisible}
          currentProductID={currentProductID}
        />
      )}
    </main>
  );
};

export default ViewSupplier;
