import axios, { AxiosRequestConfig, Method } from 'axios';

type RequestParamsType = {
  url: string;
  method: Method | undefined;
  data?: {} | undefined;
  token?: string | null | undefined;
  params?: {} | undefined;
  contentType?: string | undefined;
};

class MediaService {
  private ENDPOINT: string;

  constructor() {
    this.ENDPOINT = process.env.NODE_ENV === "development"
    ? "https://media.tendo.africa"
    : "https://media.vbjxzqu.dukesell.app";
  }

  private request = async ({
    method,
    url,
    data,
    token,
    params,
    contentType = 'application/json',
  }: RequestParamsType) => {
    const config: AxiosRequestConfig = {
      method,
      baseURL: this.ENDPOINT,
      url,
      data,
      params,
    };
    config.headers = {
      'Content-Type': contentType,
    };

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return await axios(config);
  };

  public uploadImage = async (formData: any) => {
    return await this.request({
      method: 'post',
      url: '/api/v1/soko/media/upload',
      data: formData,
      contentType: 'multipart/form-data',
    });
  };

  /**
   * name: getUserMediaFolders
   */
  public getUserMediaFolders(user_id: number) {
    return this.request({
      method: 'get',
      url: `/soko/media/folders/${user_id}`,
    });
  }

  /**
   * name: getAllMediaInFolder
   */
  public getAllMediaInFolder(id: number) {
    return this.request({
      method: 'get',
      url: `/soko/media/files/${id}`,
    });
  }

  /**
   * name: deleteMedia
   * */
  public deleteMedia = async (image: Record<string, any>) => {
    return await this.request({
      method: 'delete',
      url: '/soko/media/delete',
      data: { file: image },
    });
  };

  /**
   * name: uploadMediaUsingUrl
   */
  public uploadMediaUsingUrl(linkInfo: Record<string, any>) {
    return this.request({
      method: 'post',
      url: '/soko/media/url',
      data: linkInfo,
    });
  }
}

const MediaServices = new MediaService();
export default MediaServices;
