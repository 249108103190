import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface IVariationProp {
  name?: string;
  id?: string;
  options?: Record<string, any>[];
  optionName?: string;
  defaultValue?: any;
  optionsDefault?: any;
  removeFn?: () => void;
  onSelectChange?: (value: any, actionMeta: ActionMeta<any>) => void;
  onOptionChange?: (value: any, action: ActionMeta<any>) => void;
}

const VariationOptionInput = ({
  name,
  id,
  options,
  optionName,
  defaultValue,
  optionsDefault,
  removeFn,
  onSelectChange,
  onOptionChange,
}: IVariationProp) => (
  <div className='w-full flex items-center justify-start col-span-full gap-3'>
    <div className=''>
      <div className='mt-2 relative rounded-md shadow-sm'>
        <CreatableSelect
          className='basic-single text-sm'
          classNamePrefix='select'
          placeholder='eg. Size'
          isSearchable={true}
          options={options}
          name={name}
          id={id}
          onChange={onSelectChange}
          hideSelectedOptions
          defaultValue={defaultValue}
        />
      </div>
    </div>
    <div className='col-span-2'>
      <div className='flex items-center'>
        <div className='mt-2 relative rounded-md shadow-sm w-full'>
          <CreatableSelect
            isMulti
            className='basic-single text-sm'
            classNamePrefix='select'
            isSearchable={true}
            options={[]}
            isClearable={false}
            name={optionName}
            onChange={onOptionChange}
            defaultValue={optionsDefault}
            placeholder='Enter your options'
          />
        </div>
        <span className='p-2 rounded-md cursor-pointer ' onClick={removeFn}>
          <TrashIcon className='mr-2 h-6 w-6 text-red-500' />
        </span>
      </div>
    </div>
  </div>
);

export default VariationOptionInput;
