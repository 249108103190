import { useLoadShopifyProducts } from "../../apps/hooks/load-shopify-products"
import noProductImg from '../../../assets/noProduct.png'
import { OfficeButton, toast } from "components";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SYNCSHOPIFYPRODUCTS } from "_graphql/mutation/products";
import { SyncShopifyProducts, SyncShopifyProductsVariables } from "_graphql/mutation/__generated__/SyncShopifyProducts";
import { useCurrentUser } from "_graphql/cache/auth";
import { GetShopifyShop_getShopifyShop_products } from "_graphql/queries/__generated__/GetShopifyShop";
import { distributionChannel } from "_graphql/__generated__/globalTypes";
import { isEmpty } from "lodash";
import { Chip, Group } from "@mantine/core";

interface Props {
    // open: boolean;
    // setOpen: (val: any) => void;
    syncedProducts: (GetShopifyShop_getShopifyShop_products | null)[] | null | undefined;
    integrationId: string | null | undefined
}

const Productssync = ({ syncedProducts, integrationId }: Props) => {
    const currentUser = useCurrentUser()
    const [selectedProducts, setSelectedProducts] = useState<Array<string>>([])
    const [distrChannel, setDistrChannel] = useState<Array<string>>([])
    const { shopifyProducts } = useLoadShopifyProducts({
        integrationId: integrationId as any
    })


    const [sync, { loading }] = useMutation<SyncShopifyProducts, SyncShopifyProductsVariables>(SYNCSHOPIFYPRODUCTS, {
        refetchQueries: ['GetShopifyShop']
    })

    const handleProductSelect = (productId: string) => {
        const isSelected = selectedProducts.includes(productId);

        if (isSelected) {
            setSelectedProducts((prevSelected: any) =>
                prevSelected.filter((id: string) => id !== productId)
            );
        } else {
            setSelectedProducts((prevSelected: any) => [...prevSelected, productId]);
        }
    };

    const onSubmit = () => {
        sync({
            variables: {
                input: {
                    integration_id: integrationId as string,
                    product_ids: selectedProducts,
                    supplier_country: currentUser?.country as string,
                    distributionChannel: distrChannel as distributionChannel[]
                }
            }
        }).then((res) => {
            if (res.data?.syncShopifyProducts) {
                toast("Products synced successfully", "success")
                setSelectedProducts([])
                // setOpen(false)
            } else {
                toast("A problem occured", "error")

            }
        }).catch((err) => {
            toast(err?.message, "error")

        })
    }


    const unsyncedProducts = shopifyProducts?.filter((item) => !syncedProducts?.some((item2) => item?.id === item2?.product_id))


    return (
        <>
            <div className="px-4">
                {/* <div>
                    <h2 className="text-lg">Product Sync</h2>
                    <span className="text-sm text-gray-500">Product that are being synced are shown here. They are not displayed within the duke Network until you make them available from the Products tab within Duke </span>
                </div> */}
                <div className="flex justify-between items-center">
                    <div className="my-3">
                        <label className="font-medium text-sm"> <span className="text-red-500">*</span>Select distribution channel</label>
                        <Chip.Group onChange={setDistrChannel} multiple>
                            <Group mt="md">
                                <Chip value="TendoNetwork">Tendo Network</Chip>
                                <Chip value="SupplierStorefront">Supplier Storefront</Chip>
                                <Chip value="ResellerNetwork">Reseller Network</Chip>
                                <Chip value="MarketPlace">Marketplace</Chip>
                            </Group>
                        </Chip.Group>
                    </div>
                    {(!isEmpty(distrChannel) && !isEmpty(selectedProducts)) && <div className="ml-2 flex justify-end mt-5">
                        <OfficeButton
                            onClick={onSubmit}
                            disabled={isEmpty(distrChannel) || isEmpty(selectedProducts)}
                            variant="primary">
                            {loading ? "Syncing" : "Sync"}
                        </OfficeButton>
                    </div>}
                </div>
                <div className="bg-white ">
                    <div className="grid grid-cols-4 gap-10">

                        {
                            unsyncedProducts.map((product) => (
                                <div key={product?.id} onClick={() => handleProductSelect(product?.id as string)} className="flex justify-center bg-[#F6F5F8] border border-[#DDDDDD] 0 rounded-lg relative cursor-pointer">
                                    <div className=" w-full flex flex-col ">
                                        {product?.images && <img className="h-40 w-full object-cover object-center " src={product.images[0]?.src as string ?? noProductImg} alt={product?.title ?? ''} />}
                                        <div className="p-2" >
                                            <span className=" ">{product?.title}</span>
                                            {/* <div className=" flex items-center ">
                                                <span onClick={() => handleProductSelect(product?.id as string)} className={clsx(selectedProducts.includes(product?.id as string) ? "bg-red-200 text-red-500" : "bg-green-200 text-green-500", "text-sm  px-2 py-1 rounded-md cursor-pointer")}> {selectedProducts.includes(product?.id as string) ? 'Deselect' : 'Select'}</span>
                                            </div> */}
                                            <div className="absolute top-0 right-0 p-2">
                                                <input checked={selectedProducts.includes(product?.id as string)} className="rounded-md p-3 border border-gray-400" type="checkbox" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </>
        // </Modal>
    )
}

export default Productssync