import {gql} from "@apollo/client";
export const updateSupplierDetails = gql`
mutation UpdateSupplierDetails($input: UpdateSupplierDetailsInput) {
    updateSupplierDetails(input: $input)
  }
`;

export const changeSupplierEmail = gql`
mutation ChangeSupplierEmail($input: SupplierEmailChangeInput) {
  changeSupplierEmail(input: $input)
}
`;

export const resetpassword = gql`
mutation ResetSupplierPassword($input: SupplierPasswordResetInput) {
  resetSupplierPassword(input: $input)
}
`