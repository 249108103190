import { useCurrentUser } from '_graphql/cache/auth';
import { GetProduct_getProduct } from '_graphql/queries/__generated__/GetProduct';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { useCategories } from 'pages/category/hooks/use-categories';
import { FC, useEffect, useRef, useState } from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

type Props = {
  form: FormikProps<any>;
  product: GetProduct_getProduct | null | undefined;
  loading: boolean;
};

const UpdateCategoryAndTagForm: FC<Props> = ({
  form,
  product,
  loading,
}: Props) => {
  const currentUser = useCurrentUser();
  const [cat, setCategories] = useState<any[]>([{}]);
  const [ta, setTags] = useState<any[]>([]);
  const emptyOptions: Record<string, any>[] = [];
  const {
    categories,
    loading: loadingCategories,
    fetchMore,
    totalCount,
  } = useCategories({
    variables: {
      filter: {
        supplier_id: {
          eq: currentUser?.id
        }
      },
    },
  });

  const handleCategoriesChange = (e: any) => {
    setCategories((state) => {
      state = e ?? [{}];
      form.setFieldValue(
        `categories`,
        e.map((el: any) => el?.value)
      );

      return state;
    });
  };
  const init = useRef({
    fetchMore,
  });

  const loadMoreCategory = async () => {
    const { fetchMore } = init.current;
    await fetchMore({
      variables: {
        filter: {},
        pagination: {
          offset: categories.length,
          limit: 10,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = fetchMoreResult.getProductCategories ?? [];
        const prevData = prev.getProductCategories ?? [];
        return Object.assign({}, prev, {
          getProductCategories: [...prevData, ...newData],
        });
      },
    });
  };

  const handleTagChanges = (e: any) => {
    setTags((state) => {
      state = e ?? [];

      form.setFieldValue(
        `tags`,
        e?.map((el: any) => el?.value)
      );

      return state;
    });
  };

  useEffect(() => {
    if (product?.categories) {
      product?.categories?.forEach((el: any, index: number) => {
        form.setFieldValue(`categories.${index}`, el?.id);
        setCategories((state) => {
          state[index] = { value: el?.id, label: el?.name };
          return state;
        });
      });
    }
    if (product?.tags) {
      product?.tags?.forEach((el: any, index: number) => {
        form.setFieldValue(`tags.${index}`, el);
        setTags((state) => {
          state[index] = { value: el, label: el };
          return state;
        });
      });
    }
  }, [loading]);

  return (
    <form className='w-full py-5'>
      <div className='w-full'>
        <div className='w-full'>
          <label
            htmlFor='category'
            className='block text-sm font-medium text-gray-700'>
            Category
          </label>
          <p className='block text-xs text-gray-500 mt-2 mb-5'>
            Select categories to assign the product. A product can be assign to
            multiple categories. Use the add button to add a new category
          </p>
          <Select
            id='categories'
            isMulti
            isLoading={loadingCategories}
            className='basic-single text-sm'
            classNamePrefix='select'
            placeholder='Select categories'
            noOptionsMessage={() => 'No category available'}
            isSearchable={true}
            options={categories?.map((category) => {
              return {
                value: category?.id,
                label: category?.name,
              };
            })}
            value={cat}
            onChange={handleCategoriesChange}
            onMenuScrollToBottom={async () => {
              if (totalCount) {
                if (categories.length < totalCount) {
                  await loadMoreCategory();
                }
              }
            }}
          />
          {_.get(form.errors, 'categories') && (
            <p className='mt-4 mb-5 text-sm text-red-600 '>
              <>{_.get(form.errors, 'categories')}</>
            </p>
          )}
        </div>
        <div className='w-full mt-10'>
          <label
            htmlFor='tag'
            className='block text-sm font-medium text-gray-700'>
            Tags
          </label>
          <p className='block text-xs text-gray-500 my-1'>
            Enter the tags for the product.
          </p>
          <CreatableSelect
            id='tags'
            isMulti
            className='basic-single text-sm'
            classNamePrefix='select'
            isSearchable={true}
            onChange={handleTagChanges}
            options={emptyOptions}
            isClearable={false}
            tabSelectsValue
            value={ta as any[]}
            placeholder='Enter tags for the product'
            {...form}
          />
        </div>
      </div>
    </form>
  );
};

export default UpdateCategoryAndTagForm;
