import { Modal, OfficeButton, toast } from 'components'
import { useFormik } from 'formik';
import { FC, useEffect } from 'react'
import CreateCategoryForm from './forms/create-category';
import { setSupplierSetupConfig, useCurrentUser } from '_graphql/cache/auth';
import { useCategories } from './hooks/use-categories';
import { useMutation } from '@apollo/client';
import { AddSupplierCategories, AddSupplierCategoriesVariables } from '_graphql/mutation/__generated__/AddSupplierCategories';
import { creaateCategory } from '_graphql/mutation/category';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const CreateCategoryContainer: FC<Props> = ({ open, setOpen }) => {
    const currentUSer = useCurrentUser();
    const [initiate, { loading: creating }] = useMutation<AddSupplierCategories, AddSupplierCategoriesVariables>(creaateCategory, {
        refetchQueries: ["GetProductCategories"]
    });
    const { categories, loading } = useCategories({
        variables: {
            pagination: {
                limit: 300,
                offset: 0,
            },
            filter: {
                supplier_id: {
                    eq: currentUSer?.id
                }

            }
        }
    })
    const form = useFormik<any>({
        initialValues: {
            categories: [],
        },
        onSubmit: async (values) => {
            initiate({
                variables: {
                    categories: values.categories,
                    supplierId: currentUSer?.id
                }
            }).then(() => {
                setOpen?.(false);
                setSupplierSetupConfig({
                    hasCategories: values.categories.length > 0,
                })
                toast("Categories added successfully", "success")
            }).catch((e) => {
                toast(e.message, "error")
            })
        }
    })


    useEffect(() => {
        if (categories?.length > 0) {
            form.setFieldValue("categories", categories?.map((category) => category?.id))
        }
    }, [categories])

    return (
        <Modal
            title='Select Category(ies)'
            description='Select the category(ies) you want to add to your office.'
            setOpen={setOpen}
            open={open}
            loading={loading}
            renderActions={() => (
                <div className='ml-2'>
                    <OfficeButton onClick={form.handleSubmit}>
                        {creating ? "Saving ..." : "save changes"}
                    </OfficeButton>
                </div>

            )}
        >
            <CreateCategoryForm form={form} />
        </Modal>
    )
}

export default CreateCategoryContainer