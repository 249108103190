import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useMediaQuery } from '@mantine/hooks';
import { Loader, Modal, toast } from 'components';
import { useState, useMemo, useRef } from 'react';
import MediaServices from '../services/media.service';

interface Props {
  variation: Record<string, any>[];
  combinationData: any[];
  imgSrc?: string;
  price?: number;
  closeModalFn?: () => void;
  formInputs?: any[];
  setFormInputs?: (inputs: any[]) => void;
  uploadedMedia?: Record<string, any>[];
}

const CombinationTable = ({
  variation = [],
  formInputs = [],
  setFormInputs,
  uploadedMedia,
}: Props) => {
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [openVariationMediaModal, setOpenVariationMediaModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [uploading, setUploading] = useState(false);

  const mediaUploadRef = useRef<HTMLInputElement>(null);

  const inc = (index: number) => {
    const tempInputs = [...formInputs];
    tempInputs[index].available_stock = tempInputs[index].available_stock + 1;
    setFormInputs && setFormInputs(tempInputs);
  };

  const dec = (index: number) => {
    const tempInputs = [...formInputs];
    tempInputs[index].available_stock =
      tempInputs[index].available_stock > 0
        ? tempInputs[index].available_stock - 1
        : tempInputs[index].available_stock;
    setFormInputs && setFormInputs(tempInputs);
  };

  const ChangePrice = (evt: any, index: number) => {
    const { value } = evt?.target;

    const tempInputs = [...formInputs];
    tempInputs[index].price = +value;
    setFormInputs && setFormInputs(tempInputs);
  };

  const ChangeCostPrice = (evt: any, index: number) => {
    const { value } = evt?.target;
    const tempInputs = [...formInputs];
    tempInputs[index].cost_price = +value;
    setFormInputs && setFormInputs(tempInputs);
  };

  const ChangeQty = (evt: any, index: number) => {
    const { value } = evt?.target;
    const tempInputs = [...formInputs];
    tempInputs[index].available_stock = value;
    setFormInputs && setFormInputs(tempInputs);
  };

  const ChangeVariation = (evt: any, index: number) => {
    const { value, name } = evt?.target;
    const tempInputs = [...formInputs];
    tempInputs[index][name] = value;
    setFormInputs && setFormInputs(tempInputs);
  };

  const ChangeSKU = (evt: any, index: number) => {
    const { value } = evt?.target;
    const tempInputs = [...formInputs];
    tempInputs[index].sku = value;
    setFormInputs && setFormInputs(tempInputs);
  };

  const removeCombination = (id: number) => {
    const tempInputs = [...formInputs];
    tempInputs.splice(id, 1);
    setFormInputs && setFormInputs(tempInputs);
  };

  const AddAvatar = (index: number) => {
    setOpenMediaModal(true);
    setCurrentIndex(index);
  };

  const currentVariationMedia = useMemo(() => {
    const tempInputs = [...formInputs];
    const media = tempInputs[currentIndex]?.media;

    return media;
  }, [currentIndex, formInputs]);

  const parentMedia = useMemo(() => {
    if (uploadedMedia) {
      return uploadedMedia.map((m) => ({
        imgLink: m.imgLink,
        imageName: m.imageName,
        selected: false,
      }));
    }
    return [];
  }, [uploadedMedia]);

  const fileUploadClickHandler = () => {
    if (mediaUploadRef.current) {
      mediaUploadRef.current.click();
    }
  };

  const onUploadHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files) {
      return;
    }

    setUploading(true);
    const tempInputs = [...formInputs];
    const formData = new FormData();

    const media = e.target.files;

    Object.values(media).forEach(async (file: File) => {
      formData.append('media', file);
      formData.append('folder', `tendo/${tempInputs[currentIndex].sku}`);
      formData.append('origin', 'supplier-dashboard');

      try {
        const result = await MediaServices.uploadImage(formData);

        tempInputs[currentIndex].media.push(result.data.data[0].imageLink);
        setFormInputs && setFormInputs(tempInputs);
      } catch (error: any) {
        toast('Error uploading media', 'error');
      } finally {
        setUploading(false);
      }
    });
  };

  const isTabletOrMobile = useMediaQuery('(max-width: 1224px)');
  return (
    <>
      <table className='min-w-full divide-y divide-gray-200'>
        {/* {!isTabletOrMobile && ( */}
        <thead className='bg-gray-50 text-semibold'>
          <tr className='p-2 uppercase font-bold'>
            <td>Avatar</td>
            {variation?.map((x, i) => {
              return (
                <td key={i} className='p-2'>
                  {x?.variation_name}
                </td>
              );
            })}
            <td>Selling Price</td>
            <td>Cost Per Item</td>
            <td>SKU</td>
            <td>Quantity</td>
            <td>Media</td>
            <td></td>
          </tr>
        </thead>
        {/* )} */}
        <tbody className='bg-white'>
          {formInputs?.map((x, i) => (
            <tr key={i + 1}>
              {!isTabletOrMobile && (
                <td>
                  <span className='inline-block h-10 w-10 mt-2 rounded-md overflow-hidden bg-gray-100'>
                    {!['', undefined].includes(x?.avatar) ? (
                      <img
                        src={x?.avatar}
                        alt='avatar'
                        className='object-cover cursor-pointer'
                        onClick={() => AddAvatar(i)}
                      />
                    ) : (
                      <span
                        className='text-sm font-semibold text-blue-300 cursor-pointer flex items-center justify-center mt-2'
                        onClick={() => AddAvatar(i)}>
                        Add
                      </span>
                    )}
                  </span>
                </td>
              )}
              {!isTabletOrMobile &&
                variation.map((y: any, j: number) => (
                  <td key={j}>
                    <div className='mt-2 relative rounded-md p-2'>
                      <input
                        type='text'
                        id={`${y}_${j + 1}`}
                        required
                        className='focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-4 pr-4 py-3 sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100'
                        name={y.variation_name}
                        value={x[y.variation_name]}
                        onChange={(e) => ChangeVariation(e, i)}
                        disabled
                      />
                    </div>
                  </td>
                ))}
              {isTabletOrMobile && (
                <td>
                  <span className='text-xs font-semibold mt-2'>
                    {x.combination_string.replace('&', ' - ')}
                  </span>
                </td>
              )}
              <td>
                <div className='mt-2 relative rounded-md p-1'>
                  <input
                    type='number'
                    id={`price_${i + 1}`}
                    value={+x?.price}
                    onChange={(e) => ChangePrice(e, i)}
                    className='focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-4 pr-4 py-3 sm:text-sm border-gray-300 rounded-md'
                  />
                </div>
              </td>
              <td>
                <div className='mt-2 relative rounded-md p-1'>
                  <input
                    type='number'
                    id={`cost_price_${i + 1}`}
                    value={+x?.cost_price}
                    onChange={(e) => ChangeCostPrice(e, i)}
                    className='focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-4 pr-4 py-3 sm:text-sm border-gray-300 rounded-md'
                  />
                </div>
              </td>
              <td>
                <div className='mt-2 relative rounded-md p-1'>
                  <input
                    type='text'
                    id={`sku_${i + 1}`}
                    value={x?.sku}
                    disabled
                    onChange={(e) => ChangeSKU(e, i)}
                    className='focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-4 pr-4 py-3 sm:text-sm border-gray-300 rounded-md'
                  />
                </div>
              </td>
              <td>
                <div className='mt-2 relative rounded-md p-1'>
                  <input
                    type='number'
                    id={`qty_${i + 1}`}
                    value={+x?.available_stock}
                    className='focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md relative'
                    // defaultValue={1}
                    onChange={(e) => ChangeQty(e, i)}
                  />
                  <div className='absolute inset-y-0 right-0 pr-3 flex items-center'>
                    <PlusCircleIcon
                      className='h-4 w-4 mx-1 cursor-pointer'
                      onClick={() => inc(i)}
                    />
                    <MinusCircleIcon
                      onClick={() => dec(i)}
                      className='h-4 w-4 mx-1 cursor-pointer'
                    />
                  </div>
                </div>
              </td>
              <td>
                <div
                  className='mt-2 relative rounded-md p-1 overflow-hidden'
                  onClick={() => {
                    setCurrentIndex(i);
                    setOpenVariationMediaModal(true);
                  }}>
                  <div className='flex items-end space-x-2 cursor-pointer hover:bg-slate-200 p-3'>
                    <span className='text-sm font-bold'>{x.media.length} </span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
                      />
                    </svg>
                  </div>
                </div>
              </td>
              <td>
                <div className='mt-2 relative rounded-md p-1'>
                  <TrashIcon
                    onClick={() => removeCombination(i)}
                    className='h-4 w-4 lg:mx-2 text-danger-500'
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={openMediaModal}
        title='Add Media'
        setOpen={() => setOpenMediaModal(!openMediaModal)}>
        <div className='flex flex-col'>
          <p className='block text-xs font-semibold lg:text-black uppercase'>
            Media
          </p>
        </div>
        <div className='py-4'>
          {uploadedMedia && uploadedMedia.length !== 0 && (
            <div className='mt-2'>
              <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-3'>
                {uploadedMedia &&
                  uploadedMedia?.map((item, index) => (
                    <li key={item?.imageName} className='relative'>
                      <div
                        className='group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden'
                        onClick={() => {
                          const tempInputs = [...formInputs];
                          tempInputs[currentIndex].avatar =
                            uploadedMedia && uploadedMedia[index].imgLink;
                          setFormInputs && setFormInputs(tempInputs);
                          setOpenMediaModal(false);
                        }}>
                        <img
                          src={item?.imgLink}
                          alt=''
                          className='object-cover pointer-events-none group-hover:opacity-75'
                        />
                      </div>

                      <p className='mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none'>
                        {item?.imageName}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={openVariationMediaModal}
        title='Add Media'
        setOpen={() => {
          parentMedia.map((m) => (m.selected = false));
          setOpenVariationMediaModal(!openVariationMediaModal);
        }}>
        <div className='flex flex-col'>
          <p className='block text-xs font-semibold lg:text-black uppercase'>
            Media
          </p>
        </div>
        <div className='py-4'>
          <div className='py-4 border-b-2'>
            <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-3'>
              {currentVariationMedia &&
                currentVariationMedia.length !== 0 &&
                currentVariationMedia?.map((item: string, index: number) => (
                  <li key={item} className='relative'>
                    <div className='group block w-full h-24 aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden'>
                      <img
                        src={item}
                        alt=''
                        className='object-cover pointer-events-none group-hover:opacity-75'
                      />
                    </div>

                    <p
                      className='mt-2 flex text-sm font-medium cursor-pointer'
                      onClick={() => {
                        if (parentMedia && parentMedia.length !== 0) {
                          const m = parentMedia.find((m) => m.imgLink === item);
                          if (m) m.selected = false;
                        }
                        currentVariationMedia.splice(index, 1);
                        const tempInputs = [...formInputs];
                        tempInputs[currentIndex].media = currentVariationMedia;
                        setFormInputs && setFormInputs(tempInputs);
                      }}>
                      <TrashIcon className='text-danger-500 h-5 w-5 mr-2' />{' '}
                      Delete
                    </p>
                  </li>
                ))}
              {uploading && (
                <div className='flex justify-center items-center p-4 w-full h-24 aspect-w-10 aspect-h-7 rounded-lg bg-gray-100'>
                  <Loader />
                </div>
              )}
              <button
                type='button'
                className='w-full flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-8 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={fileUploadClickHandler}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 4.5v15m7.5-7.5h-15'
                  />
                </svg>
                <input
                  id='file-upload'
                  name='file-upload'
                  type='file'
                  ref={mediaUploadRef}
                  className='hidden sr-only'
                  onChange={onUploadHandler}
                  multiple
                />

                <span className='mt-2 block text-sm font-medium text-gray-900'>
                  Add image
                </span>
              </button>
            </ul>
          </div>
          <div className='mt-2 mb-4'>
            <span className='block text-sm font-medium text-gray-900'>
              Select from available media
            </span>
          </div>
          {parentMedia.length !== 0 && (
            <div className='mt-2'>
              <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-3'>
                {parentMedia &&
                  parentMedia?.map((item, index) => (
                    <li key={item?.imageName} className='relative'>
                      <div
                        className='group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden'
                        onClick={() => {
                          if (parentMedia[index].selected) return;
                          parentMedia[index].selected = true;
                          currentVariationMedia.push(item.imgLink);
                          const tempInputs = [...formInputs];
                          tempInputs[currentIndex].media =
                            currentVariationMedia;
                          setFormInputs && setFormInputs(tempInputs);
                        }}>
                        <img
                          src={item?.imgLink}
                          alt=''
                          className='object-cover pointer-events-none group-hover:opacity-75'
                        />
                      </div>

                      <p className='mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none'>
                        {item?.imageName}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
        <div className='flex justify-end'>
          <button
            type='button'
            className='font-medium
          
            bg-primary-500
            p-3
            rounded-md
            hover:bg-primary-800
            hover:text-gray-900
            cursor-pointer
            hover:opacity-80
            text-sm
            text-white
            flex
            items-center'
            onClick={() => {
              parentMedia.map((m) => (m.selected = false));
              setOpenVariationMediaModal(false);
            }}>
            Done
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CombinationTable;
