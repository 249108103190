import { Paginator, TableComponent, TableLoader } from 'components';
import { usePagination } from 'hooks';
import { FC } from 'react';
import { usePayoutRequests } from '../hooks/use-wallet';
import moment from 'moment';
import { useAppSearch, useCurrentUser } from '_graphql/cache/auth';

type Props = {}

const PayoutsTab: FC<Props> = (props) => {
    const currentUser = useCurrentUser()
    const searchParams = useAppSearch()
    const { offset, limit, setPage } = usePagination();
    const { payouts, loading, refetch, count } = usePayoutRequests({
        variables: {
            filter: {
                country: {
                    eq: "GH"
                },
                supplier_id: {
                    eq: currentUser?.id
                },
                transaction_id:searchParams?.search? {
                    like:`${searchParams.search}`
                  }:undefined
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    })
    return (
        <TableComponent
            title={'Payout Requests'}
            columns={[
                {
                    accessor: "transaction_id",
                    name: "transaction id"
                },
                {
                    accessor: "provider",
                    name: "Provider"
                },
                {
                    accessor: "account_number",
                    name: "Account Number"
                },
                {
                    accessor: "account_name",
                    name: "Account name"
                },
                {
                    accessor: "amount",
                    name: "Amount"
                },
                {
                    accessor: "status",
                    name: "Status"
                },
                {
                    accessor: "updated_at",
                    name: "Last Updated",
                    render: (value: any) => moment(value.updated_at).format("Do, MMM YYYY hh:mm A")
                },
            ]}
            hasSearch
            data={payouts}
            loading={loading}
            refetch={refetch}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1, 1, 1]} />}
            emptyDescription='No Payout Requests found'
            pagination={() => <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={payouts.length}
                setOffset={setPage} />}
        />
    )
}

export default PayoutsTab