import { FC } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import { forgotPassword, verifyOTP } from "_graphql/mutation/auth";
import { VerifySupplierOTP, VerifySupplierOTPVariables } from "_graphql/mutation/__generated__/VerifySupplierOTP";
import { maskEmail } from "utils/regex";
import { RequestSupplierOTP, RequestSupplierOTPVariables } from "_graphql/mutation/__generated__/RequestSupplierOTP";
import { useApp } from "hooks";



const ResetPasswordOTPPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate();
  const [initiate, { loading }] = useMutation<VerifySupplierOTP, VerifySupplierOTPVariables>(verifyOTP);
  const [initiateResend, { loading: resending }] = useMutation<RequestSupplierOTP, RequestSupplierOTPVariables>(forgotPassword);
  const { logo, name } = useApp()
  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await initiate({
        variables: {
          input: {
            code: values.code,
            medium: "EMAIL",
            token: search?.token ?? "",
          }
        },
      }).then(({ data }) => {
        if (data?.verifySupplierOTP) {
          return navigate({
            replace: true,
            to:  "/reset-password",
            search(prev) {
              return {
                ...prev,
                email: search?.email,
                token: search?.token,
                from: "reset_success",
              }
            },
          });
        } else {

          return navigate({
            replace: true,
            to: search?.redirect ?? "/",
          });
        }
      });
    },
  });

  const goToHomepage=()=>{
    navigate({
      replace: true,
      to:  "/sign-up",
    });
  }

  const resend = () => initiateResend({
    variables: {
      input: {
        [search.email?.includes("@") ? "email" : "phone"]: search?.email ?? "",
      }
    }
  }).then(({ data }) => {
    if (data?.requestSupplierOTP?.token) {
      return navigate({
        replace: true,
        to: "/reset-password-otp",
        search: {
          token: data?.requestSupplierOTP?.token,
          redirect: search?.redirect ?? "/",
          email: search?.email,
        }
      });
    } else {
      return navigate({
        replace: true,
        to: search?.redirect ?? "/",
      });
    }
  })

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96 ">
      <div>
        <div className="flex items-start flex-col">
          <div className="w-full justify-start py-4 flex align-middle flex-shrink-0">
            <img
              className="h-16 w-16 rounded-full"
              src={logo}
              alt="Duke Supplier's Dashboard"
              onClick={goToHomepage}
            />
          </div>
          <div className="">
            <span className="text-lg text-primary-600 font-mono">
              {name} Supplier's  Dashboard
            </span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Verify OTP Code
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Enter the 4 digit OTP code send to your inbox - {maskEmail(search?.email || "")}. Check spam if could not find in your inbox
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="code"
                label="OTP Code"
                placeholder="e.g. 7778"
                {...form}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">

              </div>

              <div className="text-sm text-primary-500">
                <button disabled={resending}
                  onClick={resend}
                >{resending ? "Resending, please wait" : "Didn't receive code, resend"}</button>
              </div>
            </div>
            <div>
              <button
                type="submit"

                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? "Verifying..." : "Verify OTP"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordOTPPage;