import { Loader as MantineLoader } from '@mantine/core'

const Loader = () => {
    return (
        <div className="min-h-[80vh] w-full flex items-center justify-center">
            <MantineLoader color="gray" type="bars" size={'xl'} />
        </div>
    )
}

export default Loader