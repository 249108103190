import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { FC, Fragment, useRef } from 'react';
import { classNames, wrapClick } from 'utils';

type Props = {
  title: string;
  description?: string;
  open: boolean;
  cancelText?: string;
  setOpen: (val: boolean) => void;
  renderActions?: () => JSX.Element;
  variant?: 'danger' | 'success' | 'warning' | 'info';
};

const variantMap = {
  danger: 'focus:ring-red-500 text-red-500',
  success: 'focus:ring-green-500 text-green-500',
  warning: 'focus:ring-yello-500 text-yellow-500',
  info: 'focus:ring-blue-500  text-blue-500',
};

const PopUp: FC<Props> = ({
  open,
  setOpen,
  renderActions,
  title,
  cancelText,
  description,
  variant = 'danger',
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className={classNames('theme', 'fixed z-50 inset-0 overflow-y-auto')}
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-top sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div
              className={classNames(
                'max-w-xl',
                'inline-block align-bottom bg-white  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full'
              )}>
              <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                <button
                  type='button'
                  className='bg-white  rounded-md text-gray-400  hover:text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 '
                  onClick={() => setOpen(false)}>
                  <span className='sr-only'>{cancelText ?? 'Cancel'}</span>
                  <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='sm:flex sm:flex-col'>
                <div className='mx-auto pt-4 flex-shrink-0 flex flex-col items-center rounded sm:mx-0 sm:px-6 sm:py-4 border-gray-200'>
                  <Dialog.Title
                    as='h2'
                    className='flex-1 mt-4 text-lg leading-6 font-bold text-gray-900  text-center sm:text-center'>
                    {title}
                  </Dialog.Title>
                  {description && (
                    <p className='w-full mt-1 text-center  text-base  text-gray-600  sm:w-auto sm:text-sm'>
                      {description}
                    </p>
                  )}
                </div>
              </div>
              <div className=' px-4 py-3 space-x-3 justify-end items-center sm:py-4 sm:px-6 sm:flex'>
                <button
                  type='button'
                  className={classNames(
                    'w-full inline-flex justify-center rounded-md border border-gray-300  shadow-sm px-4 py-1.5 bg-white  text-base font-medium text-gray-700  hover:bg-gray-50 hover: focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm',
                    variantMap[variant]
                  )}
                  onClick={wrapClick(() => setOpen(false))}
                  ref={cancelButtonRef}>
                  {cancelText ?? 'Cancel'}
                </button>
                <>{renderActions?.()}</>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PopUp;
