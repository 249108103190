import { useSuppliers } from "../hooks/use-suppliers";
import { usePagination } from "hooks";
import {  useAppSearch, useCurrentUser } from "_graphql/cache/auth";
import { Paginator,TableComponent } from "components";
import { SuppliersColumn } from "../components/Table-column";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { SupplierType } from "_graphql/__generated__/globalTypes";


const SuppliersTab = () => {
  const { offset, limit, setPage } = usePagination();
  const currentUser =useCurrentUser();
  const searchParams = useAppSearch()
  const { suppliers, refetch, loading } = useSuppliers({
    variables: {
      filter: {
        id: {
          notIn: [currentUser?.id!],
        },
        country: {
          "eq": currentUser?.country
        },
        supplier_type: currentUser?.supplier_type || SupplierType.BASIC,
        has_accepted_terms: {
          eq: true,
        },
        name:searchParams?.search? {
          like:`${searchParams.search}`
        }:undefined
      },
    },
  });
  const navigate = useNavigate<LocationGenerics>();
  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={'Suppliers'}
            columns={SuppliersColumn}
            data={suppliers}
            hasSearch
            defaultView='grid'
            renderGridItem={(supplier) => (
              <div key={supplier?.id} onClick={() => navigate({ to: "./" + supplier.id + "/view-supplier" })} className="hover:cursor-pointer">
                <div className="flex flex-col bg-white shadow-md p-3  justify-between">
                  <div className="flex flex-row w-full justify-between">
                    <div className="w-1/3 flex-shrink-0">
                      <img
                        className="h-12 w-12 -mt-2 rounded-full"
                        src={
                          supplier?.business_details?.logo 
                            || "https://d3ug0vbiixnxyq.cloudfront.net/utility/qejpc8bkdzgvlfnghc9no-165859213609.jpeg"
                        }
                        alt={supplier?.business_details?.business_name}
                      />
                    </div>
                   
                    <div className="  ml-4">
                      <div className="text-sm font-medium leading-5 text-gray-900">
                        {supplier?.business_details?.business_name.substring(0,19)}
                      </div>
                      <div className="text-sm leading-5 text-ellipsis text-gray-500">
                        {supplier?.business_details?.description?.substring(0, 30)}
                        
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-[0.5px] border-gray-200"></div>
                  <div className="flex flex-col items-start">
                    <div className="flex flex-row items-start space-x-1 ml-2">
                      <div className="text-gray-400">City:</div>
                      <div className="text-black">{supplier?.business_details?.city}</div>
                    </div>
                    <div className="flex flex-row space-x-1 ml-2">
                      <div className="text-gray-400">Desc:</div>
                      <div className="text-black">{supplier?.business_details?.description}</div>

                    </div>
                  </div>
                </div>
              </div>
            )}
            refetch={refetch}
            loading={loading}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={suppliers.length}
                currentSize={suppliers.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>

     
    </main>
  );
};

export default SuppliersTab;
