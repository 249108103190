import { SupplierSignIn_supplierSignIn_supplier_payment_details } from "_graphql/mutation/__generated__/SupplierSignIn";
import _ from "lodash";
import { FC } from "react";

type Props = {
    payment: SupplierSignIn_supplierSignIn_supplier_payment_details | null | undefined
};

const PaymentMethodView: FC<Props> = ({ payment }) => {

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Payment Information</span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Account Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {payment?.account_name || "N/A"}
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Account Number
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {payment?.account_number || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Payment Method
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {payment?.method || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Payment Provider
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {payment?.provider || "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PaymentMethodView;
