import { Modal, OfficeButton } from "components";
import RequestedProductsModal from "./RequestedProductsModal";
type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    selectedProducts:string[];
    loading:boolean;
    handleRequestProducts: ()=>void;
   
}
const RequestedProductsModalContainer = ({
    open,
    setOpen,
    selectedProducts,
    loading,
    handleRequestProducts,
    
}: Props) => {
    return ( 
        <Modal
            open={open}
            setOpen={setOpen}
            title="Requested Products"
            // description="Glance through products requested."
            renderActions={() => (
                <div className='ml-2'>
                    <OfficeButton onClick={handleRequestProducts} >
                        {loading ? "Requesting ..." : "  Request"}
                    </OfficeButton>
                </div>
            )}
        >
            <RequestedProductsModal selectedProducts={selectedProducts}/>
        </Modal>
     );
}
 
export default RequestedProductsModalContainer;