import { Modal, OfficeButton, toast } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useProduct } from 'pages/products/hooks/use-products';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateProductInformation } from './hooks/use-update-information';
import { CountryCode, CurrencyCode, ProductStatus } from '_graphql/__generated__/globalTypes';
import UpdateProductInformationForm from './forms/update-information-form ';
import { useEffect } from 'react';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const UpdateProductInformation = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();
  const { loading: updating, updateProductInformation } =
    useUpdateProductInformation();

  const { product, loading } = useProduct({
    variables: {
      filter: seacrhParams?.id
        ? {
          id: {
            eq: seacrhParams?.id,
          },
        }
        : undefined,
    },
  });

  useEffect(() => {
    if (product) {
      form.setValues({
        cost_price:  product?.supplier_cost?.toString() || '',
        country: product?.country || '',
        currency: product?.currency || '',
        description: product?.description || '',
        extra_information: product?.extra_information || '',
        minimumOrderQuantity: 1,
        price:product?.cost_price?.toString(),
        sku: product?.sku || '',
        title: product?.title || '',
        weight: product?.weight?.toString() || '',
      });
    }
  }, [loading,seacrhParams?.id]);

  const { ...form } = useFormik({
    initialValues: {
      cost_price: '',
      country: '',
      currency: '',
      description: '',
      extra_information: '',
      minimumOrderQuantity: 1,
      price: '',
      sku: '',
      title: '',
      weight: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Product name is required'),
      country: Yup.string().required('Country is required'),
      currency: Yup.string().required('Currency is required'),
      sku: Yup.string().required('SKU is required'),
      minimumOrderQuantity: Yup.string().required(
        'Minimum order quantity is required'
      ),
      price: Yup.string().required('Selling price is required'),
      cost_price: Yup.string().required('Cost price is required'),
    }),
    onSubmit: async (values) => {
      await updateProductInformation({
        variables: {
          input: {
            product_id: product?.id || '',
            country: values.country as CountryCode,
            cost_price:  Number(values.price),
            currency: values.currency as CurrencyCode,
            price:  Number(product?.price || 0) ===  Number(values.price)? Number(values.price):0,
            supplier_cost:Number(values.cost_price),
            sku: values.sku,
            description: values.description,
            weight: Number(values.weight),
            title: values.title,
            extra_information: values.extra_information,
            minimumOrderQuantity: values.minimumOrderQuantity,
            published:false,
            verified:false,
            personnel:"Supplier",
            reason:"N/A",
            status:ProductStatus.PENDING,
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateProductInformation) {
            toast('Product Category Updated Successfully', 'success');
            form.resetForm();
            navigate({
              search(prev) {
                return {...prev, modal:undefined, id:undefined}
              },
            });
          } else {
            toast('Something went wrong', 'error');
          }
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='Update Product'
      description='Update Product Information'
      renderActions={() => (
        <div className='md:ml-10'>
          <OfficeButton
            onClick={form.handleSubmit}
            size='lg'
            width='auto'
            disabled={updating}
            type='submit'>
            {updating ? 'Updating...' : 'Update'}
          </OfficeButton>
        </div>
      )}>
      <UpdateProductInformationForm form={form} product={product} />
    </Modal>
  );
};

export default UpdateProductInformation;
