import { Modal } from 'components';
import TermsView from './cards/view-terms';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useTerm } from './hooks/use-terms';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const ViewTermContainer = ({
    open,
    setOpen,
}: Props) => {
    const navigate = useNavigate<LocationGenerics>()
    const seacrhParams = useSearch<LocationGenerics>()
    const { term, loading } = useTerm({
        variables: {
            filter: seacrhParams?.id ? {
                id: {
                    eq: seacrhParams?.id
                }
            } : undefined
        }
    })

    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            loading={loading}
            title="View Term"
            description="View Term Details"
        >
            <TermsView term={term} />
        </Modal>
    )
}

export default ViewTermContainer