import { useQuery } from '@apollo/client';
import {
  GetProductTemplates,
  GetProductTemplatesVariables,
} from '_graphql/queries/__generated__/GetProductTemplates';
import { getProductTemplates } from '_graphql/queries/products';

export const useTemplates = (variables?: GetProductTemplatesVariables) => {
  const { data, ...rest } = useQuery<
    GetProductTemplates,
    GetProductTemplatesVariables
  >(getProductTemplates, {
    variables,
  });
  return {
    templates: data?.getProductTemplates ?? [],
    ...rest,
  };
};
