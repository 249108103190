import { RichEditor, SelectInput, TextInput } from "components/core";
import { CurrencyPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

export interface TermFormProps {
    form: FormikProps<any>;
}

const TermForm: FC<TermFormProps> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <div>
                <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <TextInput
                            id="name"
                            label="Name"
                            type="text"
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <SelectInput
                            id="settlementType"
                            label="Settlement Type"
                            options={[
                                {
                                    label: "--- Select Settlement Type ---",
                                    value: ""
                                },
                                {
                                    label: "Commission",
                                    value: "Commission"
                                },
                                {
                                    label: "Markup",
                                    value: "Markup"
                                },
                            ]}
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <TextInput
                            id="returnPeriod"
                            label="Return Period (Days)"
                            type="number"
                            placeholder="e.g. 4"
                            postText="Days"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <SelectInput
                            id="shipTo"
                            label="Ship To"
                            options={[
                                {
                                    label: "--- Select Who to Ship items to  ---",
                                    value: ""
                                },
                                {
                                    label: "Reseller",
                                    value: "Reseller"
                                },
                                {
                                    label: "Customer",
                                    value: "Customer"
                                },
                            ]}
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                    {form?.values?.settlementType === "Commission" && (
                        <div className="">
                            <TextInput
                                id="settlementValue"
                                label={"Commission Value (%)"}
                                type="number"
                                postText="%"
                                placeholder="e.g. 10"
                                required={true}
                                {...form}
                            />
                        </div>
                    )}

                    <div className="">
                        <TextInput
                            id="estimatedDeliveryTime"
                            label="Estimated Delivery Time"
                            type="number"
                            placeholder="e.g. 10"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <TextInput
                            id="estimatedDeliveryTimeUnit"
                            label="Estimated Delivery Time Unit"
                            type="text"
                            placeholder="e.g. days"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="">
                        <CurrencyPicker
                            id="settlementCurrency"
                            label="Settlement Currency"
                            placeholder="e.g. GHS"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="last:odd:col-span-2">
                        <TextInput
                            id="settlementPeriod"
                            label="Settlement Period (Days)"
                            type="number"
                            placeholder="e.g. 4"
                            postText="Days"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <RichEditor
                            id="description"
                            label="Description"
                            placeholder="e.g. Agreement Description"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <RichEditor
                            id="shippingPolicy"
                            label="Shipping Policy"
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <RichEditor
                            id="returnPolicy"
                            label="Return Policy"
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <RichEditor
                            id="cancellationPolicy"
                            label="Cancellation Policy"
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermForm;
