import OfferForm from './forms/bulk-update';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { classNames, wrapClick } from 'utils';
import { BULK_UPDATE_PRODUCT_STOCK } from '_graphql/mutation/products';
import {
  BulkUpdateProductSock,
  BulkUpdateProductSockVariables,
} from '_graphql/mutation/__generated__/BulkUpdateProductSock';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const BulkUpdateProductContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const [initaite, { loading }] = useMutation<
    BulkUpdateProductSock,
    BulkUpdateProductSockVariables
  >(BULK_UPDATE_PRODUCT_STOCK);

  const form = useFormik<any>({
    initialValues: {
      columns: {
        sku: 'SKU',
        price: 'Price',
        cost_price: 'Cost Price',
        qty: 'Quantity',
      },
      rows: [],
    },
    validationSchema: Yup.object().shape({
      columns: Yup.object().shape({
        sku: Yup.string().required('Required'),
        price: Yup.string().required('Required'),
        cost_price: Yup.string().required('Required'),
        qty: Yup.string().required('Required'),
      }),
      rows: Yup.array()
        .of(
          Yup.object().shape({
            sku: Yup.string().required('SKU is Required'),
            price: Yup.number()
              .typeError('Price Must be a number')
              .required('Price is Required'),
            cost_price: Yup.number()
              .typeError('Cost Price Must be a number')
              .required('Cost price is Required'),
            qty: Yup.number()
              .typeError('Quantity Must be a number')
              .required('Quantity is Required'),
          })
        )
        .min(1, 'At least one row is required'),
    }),
    onSubmit: async (values) => {
      initaite({
        variables: {
          input: values.rows?.map((row: any) => ({
            sku: row.sku,
            price: parseFloat(row.price),
            cost_price: parseFloat(row.cost_price),
            qty: parseInt(row.qty),
          })),
        },
      })
        .then(({ data }) => {
          if (data?.bulkUpdateProductSock) {
            toast(
              JSON.stringify({
                title: 'Product Updated Successfully',
                message: 'Product has been updated successfully',
                type: 'success',
              })
            );
            setOpen(false);
            navigate({
              search: (old) => ({
                ...old,
                modal: undefined,
                id: undefined,
              }),
            });
          } else {
            toast(
              JSON.stringify({
                title: 'Product Update Failed',
                message: 'Product update failed',
                type: 'error',
              })
            );
          }
        })
        .catch((err) => {
          toast(
            JSON.stringify({
              title: 'Product Update Failed',
              message: err?.message,
              type: 'error',
            })
          );
        });
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      title="Bulk Update Product"
      size="6xl"
      description="View Product Details"
      renderActions={() => (
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          disabled={!form.isValid}
          className={classNames(
            form.isValid ? 'cursor-pointer' : 'cursor-not-allowed',
            'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 disabled:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
          )}
        >
          {loading ? 'Submitting ...' : 'Submit'}
        </button>
      )}
    >
      <OfferForm form={form} />
    </Modal>
  );
};

export default BulkUpdateProductContainer;
