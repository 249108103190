import { gql } from '@apollo/client';

export const createDiscount = gql`
  mutation CreateDiscount($input: CreateDiscountRequestDto!) {
    createDiscount(input: $input) {
      id
    }
  }
`;
export const deleteDiscount = gql`
  mutation DeleteDiscount($deleteDiscountId: ID!) {
    deleteDiscount(id: $deleteDiscountId)
  }
`;

export const addProductstoDiscount = gql`
  mutation AddProductstoDiscount($discountId: ID!, $productSkUs: [String!]!) {
    addProductstoDiscount(discountId: $discountId, productSKUs: $productSkUs)
  }
`;

export const updateDiscount = gql`
  mutation UpdateDiscount(
    $filter: DiscountFilter!
    $data: UpdateDiscountData!
  ) {
    updateDiscount(filter: $filter, data: $data) {
      id
      type
      valid_country
      code
      percentage
      amount
      description
      is_draft
      start_date
      expiry_date
      redemptions_limit
      product_condition
      products
      fixed_amount_allocation
      created_at
      updated_at
      created_by
      user_id
      has_expired
      number_of_times_used
      locations
      locations_tagname
    }
  }
`;
