import { gql } from "@apollo/client";

export const processOrder = gql`
    mutation ProcessOrder($processOrderId: ID!, $status: String, $quantity: Int) {
        processOrder(id: $processOrderId, status: $status, quantity: $quantity)
    }
`;

export const changeSupplierStatus = gql`
    mutation ChangeSupplierStatus($changeSupplierStatusId: ID!, $supplierStatus: String) {
        changeSupplierStatus(id: $changeSupplierStatusId, supplier_status: $supplierStatus)
    }
`;
