import { FC } from 'react';
import { upperFirst } from 'lodash';
import { classNames } from 'utils';
import _ from 'lodash';

interface Option {
  label: string;
  value: string | number;
}

interface SelectInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  options: (string | Option)[];
  labelHidden?: boolean;
  description?: string;
}

const SelectInput: FC<SelectInputProps> = ({
  id,
  disabled,
  required,
  options,
  values,
  handleChange,
  handleBlur,
  placeholder,
  label,
  labelHidden,
  errors,
  touched,
  description,
}) => {
  return (
    <>
      {!labelHidden && (
        <label htmlFor={id} className='block text-sm font-medium text-gray-700'>
          {label} {required ? '*' : ''}
        </label>
      )}
      <div className={classNames(labelHidden ? '' : 'mt-1', 'relative')}>
        {description && (
          <p className='block text-xs text-gray-500 my-1'>{description}</p>
        )}
        <select
          name={id}
          id={id}
          value={_.get(values, id, '')}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          required={required}
          className={classNames(
            _.get(values, id, '') === '' ? 'font-light text-xs' : 'text-sm',
            _.get(errors, id) && _.get(touched, id)
              ? 'focus:ring-red-500 focus:border-red-500 border-red-600'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300',
            disabled ? 'cursor-not-allowed bg-gray-100' : '',
            'shadow-sm block w-full rounded-md placeholder:font-light placeholder:text-xs h-[38px]'
          )}>
          {options?.map((option, idx) => (
            <option key={idx} value={(option as Option)?.value ?? option}>
              {(option as Option)?.label ?? option}
            </option>
          ))}
        </select>
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className='mt-2 text-sm text-red-600' id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default SelectInput;
