import { gql } from "@apollo/client";


export const getWallet = gql`
query Wallet($filter: WalletFilter) {
  wallet(filter: $filter) {
    id
    user_id
    supplier_id
    balance
    points
    deliveries
  }
}

`

export const getWalletSummary = gql`
query GetSupplierWalletSummary($supplierId: ID!) {
  getSupplierWalletSummary(supplier_id: $supplierId) {
    balance
    totalWithdrawals
    totalRevenueGenerated
    pendingPayoutRequests
  }
}
  `

export const getPayoutTransactions = gql`
  query GetPayoutRequests($filter: PayoutFilter, $pagination: Pagination) {
    getPayoutRequestsLength(filter: $filter)
  getPayoutRequests(filter: $filter, pagination: $pagination) {
    user_id
    id
    country
    currency
    provider
    account_number
    amount
    account_name
    transaction_id
    status
    created_at
    updated_at
  }
}
`;

export const getWalletHistory = gql`
query WalletHistory($filter: WalletHistoryFilter, $pagination: Pagination) {
  walletHistoryLength(filter: $filter)
  walletHistory(filter: $filter, pagination: $pagination) {
    id
    status
    amount
    transaction_type
    previous_balance
    created_at
    reference
  }
}
`