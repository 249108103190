import { useQuery, QueryHookOptions } from '@apollo/client';
import {
  GetDiscount,
  GetDiscountVariables,
} from '_graphql/queries/__generated__/GetDiscount';
import {
  GetDiscounts,
  GetDiscountsVariables,
} from '_graphql/queries/__generated__/GetDiscounts';
import { getDiscount, getDiscounts } from '_graphql/queries/products';

export const useDiscounts = (
  options?: QueryHookOptions<GetDiscounts, GetDiscountsVariables>
) => {
  const { data, ...rest } = useQuery<GetDiscounts, GetDiscountsVariables>(
    getDiscounts,
    options
  );

  return {
    discount: data?.getDiscounts || [],
    count: data?.getDiscounts?.length || 0,
    ...rest,
  };
};

export const useDiscount = (
  options?: QueryHookOptions<GetDiscount, GetDiscountVariables>
) => {
  const { data, ...rest } = useQuery<GetDiscount, GetDiscountVariables>(
    getDiscount,
    options
  );

  return {
    discount: data?.getDiscount,
    ...rest,
  };
};
