import { gql } from "@apollo/client";

export const ACTIVATE_SHOP = gql`
mutation ActivateShop($data: ActivateShopRequestDto!) {
  activateShop(data: $data)
}
`;

export const UPDATE_SUPPLIER_SHOP_DETAILS =gql`
mutation UpdateSupplierShopDetails($data: UpdateShopDetailsRequestDto!) {
  updateSupplierShopDetails(data: $data) {
    shopId
  }
}
`;
export const UPDATE_SUPPLIER_SHOP_SLUG = gql`
mutation UpdateSupplierShopSlug($data: UpdateSupplierSlugInput!) {
  updateSupplierShopSlug(data: $data) {
    shopId
  }
}
`;