const orderStatus = [
    "pending",
    "confirm",
    // "order packed",
    // "out for delivery",
    // "delivered",
    "decline",
];

export const OrderStatus = orderStatus.map((status) => status.toUpperCase());
