import { FC } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import { forgotPassword } from "_graphql/mutation/auth";
import { RequestSupplierOTP, RequestSupplierOTPVariables } from "_graphql/mutation/__generated__/RequestSupplierOTP";
import { useUrlState } from "utils";
import clsx from "clsx";
import { useApp } from "hooks";



const ForgotPasswordPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const [activeTab, setActiveTab] = useUrlState("activeTab")
  const navigate = useNavigate();
  const [initiate, { loading }] = useMutation<RequestSupplierOTP, RequestSupplierOTPVariables>(forgotPassword);
  const { logo, name } = useApp()
  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Phone number or email is required"),
    }),
    onSubmit: async (values) => {
      await initiate({
        variables: {
          input: {
            [activeTab === "phone" ? "phone" : "email"]: values.username,
          }
        },
      }).then(({ data }) => {
        if (data?.requestSupplierOTP?.token) {
          return navigate({
            replace: true,
            to: "/reset-password-otp",
            search: {
              token: data?.requestSupplierOTP?.token,
              redirect: search?.redirect ?? "/",
              email: values.username,
            }
          });
        } else {
          return navigate({
            replace: true,
            to: search?.redirect ?? "/",
          });
        }
      });
    },
  });

  const goToHomepage=()=>{
    navigate({
      replace: true,
      to:  "/",
    });
  }

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96 ">
      <div>
        <div className="flex items-start flex-col">
          <div className="w-full justify-start py-4 flex align-middle flex-shrink-0">
            <img
              className="h-16 w-16 rounded-full"
              src={logo}
              alt="Duke Supplier's Dashboard"
              onClick={goToHomepage}
            />
          </div>
          <div className="">
            <span className="text-lg text-primary-600 font-mono">
              {name} Supplier's  Dashboard
            </span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Forgot Password
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Provide your email address to reset your password
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <span className="isolate mb-6 inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => setActiveTab(undefined)}
              className={
                clsx("relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10", {
                  "bg-primary-600 text-white hover:bg-primary-700": !activeTab,
                  "bg-white text-gray-900 hover:bg-gray-50": activeTab === "phone",
                })
              }
            >
              Email Address
            </button>
            <button
              type="button"
              onClick={() => setActiveTab("phone")}
              className={
                clsx("relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10", {
                  "bg-primary-600 text-white hover:bg-primary-700": activeTab === "phone",
                  "bg-white text-gray-900 hover:bg-gray-50": !activeTab,
                })
              }
            >
              Phone Number
            </button>
          </span>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="username"
                label={activeTab === "phone" ? "Phone Number" : "Email address"}
                type={activeTab === "phone" ? "text" : "email"}
                placeholder={activeTab === "phone" ? "e.g. 0506339153" : "e.g.  ebaidoo79@gmail.com"}
                {...form}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">

              </div>

              <div className="text-sm text-primary-500">
                <Link to="/sign-in">Remembered your password?</Link>
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? "Sending..." : "Send Reset Code"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
