import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetCustomers,
  GetCustomersVariables,
} from "_graphql/queries/__generated__/GetCustomers";
import { getCustomers } from "_graphql/queries/customers";

export const useCustomers = (
  options?: QueryHookOptions<GetCustomers, GetCustomersVariables>
) => {
  const { data, ...rest } = useQuery<GetCustomers, GetCustomersVariables>(
    getCustomers,
    options
  );

  return {
    customers: data?.getCustomers || [],
    count: data?.getCustomersLength || 0,
    ...rest,
  };
};
