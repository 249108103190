import { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { setAuth, setCountry, useAppSearch } from '_graphql/cache/auth';
import { Link, useNavigate } from 'react-location';
import * as Yup from 'yup';
import { TextInput } from 'components/core';
import { useFormik } from 'formik';
import { ResetSupplierPassword,ResetSupplierPasswordVariables } from '_graphql/mutation/__generated__/ResetSupplierPassword';
import { resetpassword } from '_graphql/mutation/updatesupplier';
import { useApp } from 'hooks';
//import { SegmentedControl } from '@mantine/core';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
//import { generateToken } from 'notifications/firebase';
import {toast} from 'components/core';

const ResetPassword: FC = () => {
  const search = useAppSearch();
  const navigate = useNavigate();
  const { name, logo } = useApp();
  const [showPassword, setShowPassword] = useState(false);

  const [reset, { loading }] = useMutation<
  ResetSupplierPassword,
  ResetSupplierPasswordVariables
>(resetpassword);

const resetpasswordForm = useFormik<any>({
  initialValues: {
      password: "",
      token:search?.token!,
      confirmpassword:"",
  },
  validationSchema: Yup.object().shape({
    // input: Yup.object().shape({
    password: Yup.string().required("New Password is required"),
    confirmpassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('password')], 'Passwords must match')
    // }),
  }),
  
  onSubmit: async (values) => {
    console.log(values);
    reset({
      variables: {
        input: {
          password: values.password,
          token: values.token
        },
      },
    })
      .then(({ data }) => {
        if (data?.resetSupplierPassword) {
          resetpasswordForm.resetForm();
          toast("Password reset completed", "success");
          //setAuth({ token: "", user: currentUser });
          return navigate({
            replace: true,
            to:  "/",
            search(prev) {
              return {
                ...prev,
                email: search?.email,
                //token: search?.token,
                from: "reset_success",
              }
            },
          });
        }
      })
      .catch((err) => {
        toast(err.message, "error");
      });
  },
  
});
const goToHomepage=()=>{
  navigate({
    replace: true,
    to:  "/",
  });
}

  return (
    <div className='mx-auto w-full max-w-sm lg:w-96 '>
     
      <div>
        <div className='flex items-start flex-col'>
          <div className='w-full justify-start py-4 flex align-middle flex-shrink-0'>
            <img
              className='h-16 w-16 rounded-full'
              src={logo}
              alt={`${name} Supplier's Dashboard`}
              onClick={goToHomepage}
            />
          </div>
          <div className=''>
            <span className='text-lg text-primary-600 font-mono'>{name} Supplier's Dashboard</span>
          </div>
        </div>

        <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>Reset your password</h2>
      </div>

      <div className='mt-8'>
        <div className='mt-6'>
        
          <form onSubmit={resetpasswordForm.handleSubmit} className='space-y-6'>
             <div className='space-y-1'>
            
              <TextInput
                id='password'
                label='New Password'
                type={showPassword ? 'text' : 'password'}
                placeholder='e.g.  **************'
                {...resetpasswordForm}
              /> 
              <TextInput
                id='confirmpassword'
                label='Confirm Password'
                type={showPassword ? 'text' : 'password'}
                placeholder='e.g.  **************'
                {...resetpasswordForm}
              />
               
              <div className='w-full flex justify-end items-center'>
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='text-xs my-1 font-medium  text-gray-500 hover:text-primary-500'>
                  {showPassword ? (
                    <span className='flex justify-center items-center gap-1'>
                      Hide Password
                      <EyeSlashIcon className='h-4 w-4' />
                    </span>
                  ) : (
                    <span className='flex justify-center items-center gap-1'>
                      Show Password <EyeIcon className='h-4 w-4' />
                    </span>
                  )}
                </button>
              </div>
            </div>


            <div>
              <button
                type='submit'
                //onClick={()=>handleSubmit}
               // disabled={loading}
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'>
                {loading ? 'Resetting password...' : 'Reset password'}
              </button>
            </div>
          </form>
      
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
