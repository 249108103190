import {
  OfficeHeader,
  TableComponent,
  Paginator,
  ActionButton,
  TableLoader,
} from "components";
import { FC } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useCustomers } from "./hooks/useCustomers";
import { usePagination } from "hooks";

import { GetCustomer_getCustomer } from '_graphql/queries/__generated__/GetCustomer';
import { dispatchAction, naviagteTo, useUrlState } from 'utils';
import ViewCustomerContainer from './view';
import { useCurrentUser } from '_graphql/cache/auth';

type Props = {};

const CustomersPage: FC<Props> = () => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const { offset, limit, setPage } = usePagination();
  const currentUser = useCurrentUser();
  const { customers, refetch, loading, count } = useCustomers({
    variables: {
      pagination: {
        limit: limit,
        offset: offset,
      },
      filter: {
        supplier_id: {
          eq: currentUser?.id || '',
        },
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={'Customers'}
            columns={[
              {
                name: 'Name',
                accessor: 'name',
              },
              {
                name: 'Phone',
                accessor: 'phone',
              },
              {
                name: 'Email',
                accessor: 'email',
                render: (customer: GetCustomer_getCustomer) => (
                  <div>{customer?.email || 'N/A'}</div>
                ),
              },

              {
                name: 'Actions',
                state: {},
                accessor: '',
                render: (customer: GetCustomer_getCustomer) => (
                  <div className='flex flex-row'>
                    <ActionButton
                      action={'view'}
                      tooltip='View Customer'
                      onClick={dispatchAction(customer.id, 'view', navigate)}
                    />
                    <ActionButton
                      action='expand'
                      tooltip='View Customer Details'
                      onClick={naviagteTo(customer?.id, navigate, 'sub')}
                    />
                  </div>
                ),
              },
            ]}
            hasSearch
            data={customers}
            refetch={refetch}
            loading={loading}
            renderLoader={() => <TableLoader items={[1, 1, 1]} showAction actionsCount={2} />}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={customers.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>

      {!searchParams?.id?.length && (
        <ViewCustomerContainer
          open={modal === 'view'}
          setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
        />
      )}
    </main>
  );
};

export default CustomersPage;
