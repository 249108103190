import { useQuery, QueryHookOptions } from '@apollo/client';
import { GetSuppliers,GetSuppliersVariables } from '_graphql/queries/__generated__/GetSuppliers';
import { get_suppliers } from '_graphql/queries/suppliers';
export const useSuppliers = (
    options?: QueryHookOptions<GetSuppliers, GetSuppliersVariables>
  ) => {
    const { data, ...rest } = useQuery<GetSuppliers, GetSuppliersVariables>(
      get_suppliers,
      options
    );
  
    return {
      suppliers: data?.getSuppliers || [],
      count: data?.getSuppliers?.length || 0,
      ...rest,
    };
  };