import { Outlet, Route } from 'react-location';
import {
  CategoryPage,
  CompletedJoinPage,
  CreateDiscountPage,
  CreateProductPage,
  CustomersPage,
  DashboardPage,
  DiscountPage,
  ForgotPassword,
  OrdersPage,
  ProductsPage,
  ResellersPage,
  SettingsPage,
  SetupBusinessPage,
  ShopPage,
  SignInPage,
  SignUpPage,
  SignUpResellerPage,
  TermsAcceptancePage,
  TermsPage,
  VerifyOTPPage,
  ViewProductPage,
  WalletPage,
  MarketPlacePage,
  PartnershipsPage,
  
} from 'pages';
import { UserPermission } from '_graphql/cache/auth';
import DashboardIcon from './assests/DashboardIcon.png'
import {ReactComponent as AppsIcon} from './assests/AppsIcon.svg'
import {ReactComponent as CategoryIcon} from './assests/CategoryIcon.svg'
import {ReactComponent as DiscountIcon} from './assests/DiscountIcon.svg'
import {ReactComponent as OrderIcon} from './assests/OrderIcon.svg'
import {ReactComponent as ProductsIcon} from './assests/ProductsIcon.svg'
import {ReactComponent as ResellersIcon} from './assests/ResellersIcon.svg'
import {ReactComponent as SettingsIcon} from './assests/SettingsIcon.svg'
import {ReactComponent as ShopIcon} from './assests/ShopIcon.svg'
import {ReactComponent as WalletIcon} from './assests/WalletIcon.svg'
import {ReactComponent as TermsIcon}  from './assests/TermsIcon.svg'
import { ReactComponent as DashboardIcon2 } from './assests/DashboardIcon2.svg'

import {
  CircleStackIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  PresentationChartBarIcon,
  QueueListIcon,
  ReceiptPercentIcon,
  ShoppingCartIcon,
  UsersIcon,
  RectangleGroupIcon,
  BuildingStorefrontIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import ViewOrders from 'pages/view-order';
import Viewuser from 'pages/View-user';
import ViewSupplier from 'pages/view-supplier';
import ViewCustomer from 'pages/view-customer';
import ViewProductBreadCrumb from 'pages/view-product/breadcrumb';
import { BuildingLibraryIcon, ShoppingBagIcon } from '@heroicons/react/24/solid';
import CustomerOrders from 'pages/customer-orders';
import ShopifyIntegration from 'pages/shopify';
import AppsPage from 'pages/apps';
import { isMobile } from 'react-device-detect';
import ViewDiscount from 'pages/view-discount';
import ResetPassword from 'pages/forgot-password/tabs/resetpassword';
import ResetPasswordOTPPage from 'pages/forgot-password/tabs/resetpasswordotp';

const wrapPath = (path: string, sub: boolean = false) => {
  return isMobile && !sub ? `/${path}` : isMobile && sub ? `${path}` : path;
};

export type RouteProps = Omit<Route, 'children'> & {
  withPermissions?: UserPermission[];
  // navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: '/',
    element: <Outlet />,
    sidebar: {
      label: 'Dashboard',
      icon: DashboardIcon2,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Dashboard',
      section: 'General',
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <DashboardPage />,
        meta: {
          layout: 'App',
          section: 'General',
        },
      },
    ],
    // withPermissions: ["Dashboard"]
  },
  {
    path: wrapPath('resellers'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Resellers',
      section: 'Users',
    },
    sidebar: {
      label: 'Resellers',
      icon: ResellersIcon,
    },
    children: [
      {
        path: '/',
        element: <ResellersPage />,
        meta: {
          layout: 'App',
          section: 'Users',
        },
      },
      {
        path: ':user_id',
        element: <Viewuser />,
        meta: {
          layout: 'App',
          section: 'Users',
          breadcrumb: () => 'View user',
        },
      },
    ],
    withPermissions: ['Resellers'],
  },
  {
    path: wrapPath('wallet'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'My Wallet',
      section: 'General',
    },
    sidebar: {
      label: 'My Wallet',
      icon: WalletIcon,
    },
    children: [
      {
        path: '/',
        element: <WalletPage />,
        meta: {
          layout: 'App',
          section: 'Users',
        },
      },
    ],
    withPermissions: ['My Wallet'],
  },
  {
    path: wrapPath('shop'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'My Shop',
      section: 'Users',
    },
    sidebar: {
      label: 'My Shop',
      icon: ShopIcon,
    },
    children: [
      {
        path: '/',
        element: <ShopPage />,
        meta: {
          layout: 'App',
          section: 'Users',
        },
      },
    ],
    withPermissions: ['My Shop'],
  },
  {
    path: wrapPath('customers'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Customers',
      section: 'Users',
    },
    sidebar: {
      label: 'Customers',
      icon: ResellersIcon,
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <CustomersPage />,
        meta: {
          layout: 'App',
          section: 'Users',
        },
      },
      {
        path: ':customer_id',
        element: <ViewCustomer />,
        meta: {
          layout: 'App',
          section: 'Users',
          breadcrumb: () => 'View customer',
        },
      },
      {
        path: ':customer_id/orders',
        element: <CustomerOrders />,
        meta: {
          layout: 'App',
          section: 'Users',
          breadcrumb: () => 'Customer ',
        },
      },
    ],
    withPermissions: ['Customers'],
  },
  {
    path: wrapPath('terms'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Terms & Conditions',
      section: 'Users',
    },
    sidebar: {
      label: 'Terms & Conditions',
      icon: TermsIcon,
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <TermsPage />,
        meta: {
          layout: 'App',
          section: 'Users',
        },
      },
    ],
    withPermissions: ['Terms & Conditions'],
  },
  {
    path: wrapPath('category'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      section: 'Inventory',
      breadcrumb: () => 'Category',
    },
    sidebar: {
      label: 'Category',
      icon: CategoryIcon,
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <CategoryPage />,
        meta: {
          layout: 'App',
          section: 'Inventory',
        },
      },
    ],
    withPermissions: ['Category'],
  },
  {
    path: wrapPath('products'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Products',
      section: 'Inventory',
    },
    sidebar: {
      label: 'Products',
      icon: ProductsIcon,
    },
    children: [
      {
        path: '/',
        element: <ProductsPage />,
        meta: {
          layout: 'App',
          section: 'Inventory',
        },
      },
      {
        path: 'create-product',
        element: <CreateProductPage />,
        meta: {
          layout: 'App',
          section: 'Inventory',
          breadcrumb: () => 'Create Product',
        },
      },
      {
        path: ':product_id/view',
        element: <ViewProductPage />,
        meta: {
          layout: 'App',
          section: 'Inventory',
          breadcrumb: ({ product_id }: { product_id: string }) => (
            <ViewProductBreadCrumb prodcut_id={product_id} />
          ),
        },
      },
    ],
    withPermissions: ['Products'],
  },
  {
    path: wrapPath('discount'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      section: 'Inventory',
      breadcrumb: () => 'Discount',
    },
    sidebar: {
      label: 'Discount',
      icon: DiscountIcon,
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <DiscountPage />,
        meta: {
          layout: 'App',
          section: 'Inventory',
        },
      },
      {
        path: wrapPath('create-discount'),
        element: <CreateDiscountPage />,
        meta: {
          layout: 'App',
          section: 'Inventory',
          breadcrumb: () => 'Create Discount',
        },
      },
      {
        path: wrapPath('view/:discount_id'),
        element: <ViewDiscount />,
        meta: {
          layout: 'App',
          section: 'Inventory',
          breadcrumb: () => 'View Discount Product',
        },
      },
    ],
    withPermissions: ['Discount'],
  },
  {
    path: wrapPath('orders'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Orders',
      section: 'Orders',
    },
    sidebar: {
      label: 'Orders',
      icon: OrderIcon,
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <OrdersPage />,
        meta: {
          layout: 'App',
          section: 'Orders',
        },
      },
      {
        path: ':orderId',
        element: <ViewOrders />,
        meta: {
          layout: 'App',
          section: 'Orders',
          breadcrumb: () => 'View Order',
        },
      },
    ],
    withPermissions: ['Orders'],
  },
  {
    path: wrapPath('apps'),
    element: <AppsPage />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Apps',
      section: 'Apps',
    },
    sidebar: {
      label: 'Apps',
      icon: AppsIcon,
    },
    children: [],
    withPermissions: ['Apps'],
  },
  {
    path: wrapPath('shopify'),
    element: <ShopifyIntegration />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Shopify',
      section: 'Apps',
    },
    sidebar: {
      label: 'Shopify',
      icon: ShopIcon,
    },
    children: [],
    withPermissions: ['Shopify'],
  },
  {
    path: wrapPath('marketplace'),
    element: <Outlet />,
    sidebar: {
      label: 'Marketplace',
      icon: ShopIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'MarketPlace',
      section: 'MarketPlace',
    },
    children: [
      {
        path: '/',
        element: <MarketPlacePage />,
        meta: {
          layout: 'App',
          section: 'MarketPlace',
        },
      },

      {
        path: ':supplier_id/view-supplier',
        element: <ViewSupplier />,
        meta: {
          layout: 'App',
          section: 'Marketplace',
          breadcrumb: () => 'View supplier',
        },
      },
    ],
  },
  {
    path: wrapPath('partnerships'),
    element: <Outlet />,
    sidebar: {
      label: 'Partnerships',
      icon: ResellersIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Partnerships',
      section: 'MarketPlace',
    },
    children: [
      {
        path: '/',
        element: <PartnershipsPage />,
        meta: {
          layout: 'App',
          section: 'MarketPlace',
        },
      },
    ],
  },

  {
    path: wrapPath('settings'),
    element: <Outlet />,
    meta: {
      layout: 'App',
      breadcrumb: () => 'Settings',
      section: 'Others',
    },
    sidebar: {
      label: 'Settings',
      icon: SettingsIcon,
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <SettingsPage />,
        meta: {
          layout: 'App',
          section: 'Others',
        },
      },
    ],
    withPermissions: ['Settings'],
  },
  {
    path: 'sign-in',
    element: <SignInPage />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'invite',
    element: <TermsAcceptancePage />,
    meta: {
      layout: 'Onboarding',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'invite/join',
    element: <SignUpResellerPage />,
    meta: {
      layout: 'Onboarding',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'invite/completed',
    element: <CompletedJoinPage />,
    meta: {
      layout: 'Onboarding',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'sign-up',
    element: <SignUpPage />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'reset-password-otp',
    element: <ResetPasswordOTPPage />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'verify-otp',
    element: <VerifyOTPPage />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Auth',
    },
  },
  {
    path: 'setup-business',
    element: <SetupBusinessPage />,
    meta: {
      layout: 'Setup',
      breadcrumb: () => 'Setup',
    },
  },
];

export default routes;
