import { OfficeButton, OfficeHeader } from 'components'
import { FC } from 'react'
import { useWalletSummary } from './hooks/use-wallet'
import { useAppNaviagte, useAppSearch, useCurrentUser } from '_graphql/cache/auth'
import _ from 'lodash'
import { Link } from 'react-location'
import { classNames } from 'utils'
import PayoutsTab from './tabs/payouts'
import TransactionsTab from './tabs/transactions'
import numeral from 'numeral'
import ViewPaymentMEthodContainer from './view-payment-method'
import CreatePaymentDetailsContainer from './create-payment-method'
import UpdatePaymentDetailsContainer from './update-payment-method'
import CreatePayoutContainer from './request-payout'
import balance from 'assets/Balance.png'
import payout from 'assets/pendingpayout.png'
import withdrawal from 'assets/withdrawal.png'
import revenue from 'assets/revenue.png'


const stats = [
    { name: 'Balance', value: 'balance', format: "#,#.##", image:balance , bg: '#E7EDFF'},  
    { name: 'Pending Payout Requests', value: 'pendingPayoutRequests', format: "#,#", image:payout,bg:'#FFF5D9' },
    { name: 'Total Withdrawals', value: 'totalWithdrawals', unit: '', format: "#,#.##", image:withdrawal ,bg:'#FFE0EB'},
    { name: 'Total Revenue Generated', value: 'totalRevenueGenerated', format: "#,#.##" , image:revenue,bg:'#DCFAF8'},
]

const tabs = [
    { name: "Payout Requests", href: "payouts" },
]

type Props = {}

const WalletPage: FC<Props> = (props) => {
    const searchParams = useAppSearch();
    const navigate = useAppNaviagte()
    const currentUser = useCurrentUser()
    const { wallet, loading } = useWalletSummary({
        variables: {
            supplierId: currentUser?.id
        },
        fetchPolicy: "network-only"
    })
    return (
        <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-100">
            <OfficeHeader renderActions={() => (
                <>
                    <OfficeButton variant={'minimal'} onClick={() => navigate({
                        search: (old) => ({
                            ...old,
                            modal: "assign"
                        })
                    })}>
                        Request Payout
                    </OfficeButton>
                    <OfficeButton variant={'minimal'} onClick={() => navigate({
                        search: (old) => ({
                            ...old,
                            modal: "view"
                        })
                    })}>
                        Payment Method
                    </OfficeButton>
                </>
            )} />
            <div className="bg-gray-100">
                <div className="">
                    <div className="grid grid-cols-1 gap-4 lg:mx-4 lg:my-6 my-4 bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
                        {stats.map((stat) => (
                            <div key={stat.name} className="bg-white flex flex-row space-x-2  shadow-sm rounded-xl  px-4 py-6 sm:px-6 lg:px-8">
                                <div className='w-16 h-16  rounded-full flex m-auto justify-center items-center 'style={{backgroundColor:`${stat.bg}`}} >
                                    <img src={stat.image} className='w-8 h-8 object-center rounded-full ' alt="" />
                                </div>
                                <div className='w-9/12'>
                                <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                                <p className="mt-2 flex items-baseline gap-x-2">
                                    <span className="text-4xl font-semibold tracking-tight text-black">{loading ? "Loading ..." : numeral(_.get(wallet, stat.value, 0)).format(stat.format)}</span>
                                    {stat.unit ? <span className="text-sm text-primary-400">{stat.unit}</span> : null}
                                </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="border-b border-gray-200 bg-white px-6">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        <Link
                            search={(old) => ({
                                ...old,
                                activeTab: undefined,
                            })}
                            className={classNames(
                                !searchParams?.activeTab
                                    ? "border-primary-500 text-primary-600"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={!searchParams?.activeTab ? "page" : undefined}
                        >
                            Transactions
                        </Link>
                        {tabs.map((_activeTab) => (
                            <Link
                                key={_activeTab.name}
                                search={(old) => ({
                                    ...old,
                                    activeTab: _activeTab.href,
                                })}
                                className={classNames(
                                    searchParams?.activeTab === _activeTab.href
                                        ? "border-primary-500 text-primary-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    searchParams?.activeTab === _activeTab.href ? "page" : undefined
                                }
                            >
                                {_activeTab.name}
                            </Link>
                        ))}
                        {/* <div className="h-6 w-px bg-gray-300" /> */}
                    </nav>
                </div>
            </div>
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    {searchParams?.activeTab === "payouts" ? <PayoutsTab /> : null}
                    {!searchParams?.activeTab ? <TransactionsTab /> : null}
                </div>
            </div>
            <ViewPaymentMEthodContainer open={searchParams?.modal === "view"} setOpen={(value) => { }} />
            <CreatePaymentDetailsContainer open={searchParams?.modal === "create"} setOpen={(value) => { }} />
            {searchParams?.modal === "update" && <UpdatePaymentDetailsContainer open={searchParams?.modal === "update"} setOpen={(value) => { }} />}
            {searchParams?.modal === "assign" && <CreatePayoutContainer open={searchParams?.modal === "assign"} setOpen={(value) => { }} />}
        </main>
    )
}

export default WalletPage