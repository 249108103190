import { Modal } from 'components';
import { Dispatch, SetStateAction } from 'react';
import { useUrlState } from 'utils';
import { useProductHistory } from '../hooks/use-products';
import _ from 'lodash';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ProductStatus } from '_graphql/__generated__/globalTypes';

type Props = { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> };

const RejectReasonModal = ({ open, setOpen }: Props) => {
  const [id] = useUrlState('id');

  const { history, loading } = useProductHistory({
    variables: {
      filter: {
        product_id: { eq: id || '' },
        action: { eq: 'Product_Rejected' },
      },
      pagination: { limit: 1000, offset: 0 },
    },
  });

  return (
    <Modal
      title={'Reason For Product Rejection'}
      hideActions={true}
      open={open}
      setOpen={setOpen}
      size="lg"
    >
      {loading ? (
        <>Loading ...</>
      ) : (
        <div className="w-full flex bg-red-50 text-red-700 p-5 space-x-2  rounded items-center">
          <ExclamationCircleIcon className="w-5 h-5" />
          <h1>{_.last(history)?.notes}</h1>
        </div>
      )}
    </Modal>
  );
};

export default RejectReasonModal;
