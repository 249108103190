import { Modal, OfficeButton, toast } from 'components';
import TermsView from './cards/view-terms';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useTerm } from './hooks/use-terms';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CreateTerm, CreateTermVariables } from '_graphql/mutation/__generated__/CreateTerm';
import { useMutation, useReactiveVar } from '@apollo/client';
import { createTerm, updateTerm } from '_graphql/mutation/terms';
import TermForm from './forms/term-form';
import { useEffect } from 'react';
import { UpdateTerm, UpdateTermVariables } from '_graphql/mutation/__generated__/UpdateTerm';
import { currentUserVar } from '_graphql/cache/auth';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const UpdateTermContainer = ({
    open,
    setOpen,
}: Props) => {
    const navigate = useNavigate<LocationGenerics>()
    const seacrhParams = useSearch<LocationGenerics>();
    const currentUser = useReactiveVar(currentUserVar);
    const [initiate, { loading: updating }] = useMutation<UpdateTerm, UpdateTermVariables>(updateTerm, {
        refetchQueries: ["GetTerms"]
    })
    const form = useFormik<any>({
        initialValues: {
            name: "",
            description: "",
            settlementType: "",
            returnPeriod: "",
            settlementCurrency: "",
            shipTo: "",
            estimatedDeliveryTime: "",
            settlementValue: "",
            estimatedDeliveryTimeUnit: "",
            settlementPeriod: "",
            returnPolicy: "",
            cancellationPolicy: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            description: Yup.string().required("Description is required"),
            settlementType: Yup.string().required("Settlement Type is required"),
            returnPeriod: Yup.string().required("Return Period is required"),
            settlementCurrency: Yup.string().required("Currency is required"),
            shipTo: Yup.string().required("Ship To is required"),
            estimatedDeliveryTime: Yup.string().required("Estimated Delivery Time is required"),
            settlementPeriod: Yup.string().required("Settlement Period is required"),
            settlementValue: Yup.string().required("Settlement Value is required"),
            returnPolicy: Yup.string().required("Return Policy is required"),
            cancellationPolicy: Yup.string().required("Cancelation Policy is required"),
        }),
        onSubmit: async (values) => {
            initiate({
                variables: {
                    input: {
                        ...values,
                        id: seacrhParams?.id,
                        supplier_id: currentUser?.id
                    }
                }
            }).then(({ data }) => {
                if (data?.updateTerm) {
                    setOpen(false);
                    toast("Term updated successfully", "success");
                }
            }).catch((err) => {
                toast(err.message, "error");
            })
        }
    });
    const { term, loading } = useTerm({
        variables: {
            filter: seacrhParams?.id ? {
                id: {
                    eq: seacrhParams?.id
                }
            } : undefined
        }
    });


    useEffect(() => {
        if (term) {
            form.setValues({
                name: term?.name,
                description: term?.description,
                settlementType: term?.settlementType,
                returnPeriod: term?.returnPeriod,
                settlementCurrency: term?.settlementCurrency,
                shipTo: term?.shipTo,
                estimatedDeliveryTime: term?.estimatedDeliveryTime,
                settlementValue: term?.settlementValue,
                estimatedDeliveryTimeUnit: term?.estimatedDeliveryTimeUnit,
                settlementPeriod: term?.settlementPeriod,
                returnPolicy: term?.returnPolicy,
                cancellationPolicy: term?.cancellationPolicy,
            })
        }
    }, [term])

    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false)
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                })
            }}
            loading={loading}
            title="View Term"
            description="View Term Details"
            renderActions={() => (
                <div className='ml-2'>
                    <OfficeButton disabled={!form.isValid || loading} onClick={form.handleSubmit} >
                        {updating ? "Updating ..." : " Update Term"}
                    </OfficeButton>
                </div>
            )}
        >
            <TermForm form={form} />
        </Modal>
    )
}

export default UpdateTermContainer