import { useAppNaviagte, useCurrentUser } from "_graphql/cache/auth";
import { Modal, OfficeButton, toast } from "components";
import { FC, useCallback, useEffect } from "react";
import PaymentDetailsForm from "./forms/payment-details";
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { PaystackResolveAccountNumber, PaystackResolveAccountNumberVariables } from "_graphql/queries/__generated__/PaystackResolveAccountNumber";
import { resolvePaymentMethod } from "_graphql/queries/suppliers";
import { useDebounce } from "hooks";
import { updateSupplierPaymentDetails } from "_graphql/mutation/payment";
import { IPaymentMethod } from "./create-payment-method";
import { UpdateSupplierPayment, UpdateSupplierPaymentVariables } from "_graphql/mutation/__generated__/UpdateSupplierPayment";
import { useSupplier } from "pages/settings/hooks/use-supplier";

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
};

const UpdatePaymentDetailsContainer: FC<Props> = ({ setOpen, open }) => {
    const currentUser = useCurrentUser();
    const { supplier, loading: fetching } = useSupplier({
        variables: {
            filter: {
                id: {
                    eq: currentUser?.id,
                },
            },
        },
    });
    const navigate = useAppNaviagte();
    const [resolve, { loading }] = useLazyQuery<PaystackResolveAccountNumber, PaystackResolveAccountNumberVariables>(resolvePaymentMethod);
    const [create, { loading: creating }] = useMutation<UpdateSupplierPayment, UpdateSupplierPaymentVariables>(updateSupplierPaymentDetails, {
        refetchQueries: ["GetSupplier"],
    });

    const form = useFormik<IPaymentMethod>({
        initialValues: {
            provider: "",
            account_number:  "",
            account_name: "",
        },
        onSubmit: (values) => {
            create({
                variables: {
                    input: {
                        input: {
                            account_name: values.account_name,
                            account_number: values.account_number,
                            provider: values.provider?.code,
                            method: "",
                        },
                        supplier_id: currentUser.id,
                    },
                },
            }).then((res) => {
                setOpen(false);
                if (res.data?.updateSupplierPayment?.id) {
                    navigate({
                        search: (old) => ({
                            ...old,
                            modal: undefined,
                            id: undefined,
                        }),
                    });
                    toast("Payment details updated successfully", "success");
                } else {
                    toast("An error occurred", "error");
                }
            });
        },
    });

    const handleValidation = async () => {
        form.setFieldValue("account_name","")
        if (form.values.provider && form.values.account_number) {
            resolve({
                variables: {
                    data: {
                        bank_code: form.values.provider?.code,
                        account_number: form.values.account_number,
                    },
                },
            })
                .then((res) => {
                    if (res.data?.paystackResolveAccountNumber.status) {
                        form.setFieldValue("account_name", res.data.paystackResolveAccountNumber.data.account_name);
                    } else {
                        toast("Invalid account number", "error");
                    }
                })
                .catch((err) => {
                    toast("Something wrong happened. Try again", "error");
                });
        }
    }


    useEffect(() => {
        if (supplier?.payment_details) {
            form.setValues({
                provider: supplier.payment_details.provider || "",
                account_number: supplier.payment_details.account_number || "",
                account_name: supplier.payment_details.account_name || "",
            });
        }
    }, [supplier,fetching]);

    return (
        <Modal
            open={open}
            loading={fetching}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search: (old) => ({
                        ...old,
                        modal: undefined,
                        id: undefined,
                    }),
                });
            }}
            title="Payment Details"
            description="View your payment details."
            renderActions={() => (
                <OfficeButton ml onClick={form.handleSubmit}>
                    {creating ? "Adding details ..." : "Add details"}
                </OfficeButton>
            )}>
            <PaymentDetailsForm form={form} resolving={loading} handleOnBlur={handleValidation} />
        </Modal>
    );
};

export default UpdatePaymentDetailsContainer;
