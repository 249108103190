import { Modal } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useDiscount } from './hooks/use-discounts';
import DiscountView from './cards/view-discount';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const ViewDiscountContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();

  const { discount, loading } = useDiscount({
    variables: {
      filter: {
        id: {
          eq: seacrhParams?.id ? seacrhParams?.id : null,
        },
      },
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='View Discount'
      description='View Discount Details'>
      <DiscountView discount={discount} />
    </Modal>
  );
};

export default ViewDiscountContainer;
