import { useCurrentUser } from "_graphql/cache/auth";


const useApp = () => {
    const { country } = useCurrentUser();
    const host = window.location.hostname;
    const devColor = process.env.NODE_ENV === "development" ? "tomato" : "theme-tendo";
    return {
        name: host !== "app-supplier.tendo.app" ? "Duke" : "Tendo",
        version: '1.0.0',
        logo: host !== "app-supplier.tendo.app" ? "https://ci6.googleusercontent.com/proxy/7M7sYb0g1l8zX7QW7zLOPLHwsTdb00XJacGA7zCEXLP4OzfWyZ0e2yiC0NFOu3NldoxZhlB6hZR1Dpe6m0CHGXffDsM36UHrU0QD-teVFA64ErlCc7Nru1Y5etmk4Q7J5RYtNT4=s0-d-e1-ft#https://d3ug0vbiixnxyq.cloudfront.net/logos/ny7nh2rgmjceg06p5ip7-167407914072.jpeg" : "https://tendo.app/wp-content/uploads/2023/07/logo-2.jpeg",
        url: host !== "app-supplier.tendo.app" ? "https://dukesell.com/" : "https://tendo.app/",
        theme: host !== "app-supplier.tendo.app" ? "" : devColor,
        country,
        host,
    }
}


export default useApp;