import { useQuery, QueryHookOptions } from '@apollo/client';
import {
  GetProductCategories,
  GetProductCategoriesVariables,
} from '_graphql/queries/__generated__/GetProductCategories';
import { getCategories } from '_graphql/queries/products';

export const useCategories = (
  options?: QueryHookOptions<
    GetProductCategories,
    GetProductCategoriesVariables
  >
) => {
  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery<
    GetProductCategories,
    GetProductCategoriesVariables
  >(getCategories, {
    fetchPolicy: 'network-only',
    ...options,
  });

  return {
    categories: data?.getProductCategories || [],
    totalCount: data?.getProductCategoriesLength || 0,
    error,
    fetchMore,
    networkStatus,
    loading,
    refetch,
  };
};
