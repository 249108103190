/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChannelType {
  sms = "sms",
  whatsapp = "whatsapp",
}

export enum Condition {
  and = "and",
  or = "or",
}

export enum CountryCode {
  GH = "GH",
  NG = "NG",
  ZA = "ZA",
}

export enum CurrencyCode {
  GHS = "GHS",
  NGN = "NGN",
  ZAR = "ZAR",
}

export enum DeliveryAgent {
  SHOP = "SHOP",
  TENDO = "TENDO",
}

export enum DeliveryOption {
  Manual = "Manual",
  Swoove = "Swoove",
}

export enum DiscountType {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  FREE_DELIVERY = "FREE_DELIVERY",
  PERCENTAGE = "PERCENTAGE",
}

export enum FixedAmountAllocation {
  PRODUCT_SPECIFIC = "PRODUCT_SPECIFIC",
  TOTAL_AMOUNT = "TOTAL_AMOUNT",
}

export enum PartnershipStatus {
  Approved = "Approved",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum PaymentOption {
  Cash = "Cash",
  Online = "Online",
}

export enum ProductCondition {
  IN = "IN",
  NOT_IN = "NOT_IN",
}

export enum ProductOrigin {
  IN_HOUSE = "IN_HOUSE",
  MAGENTO = "MAGENTO",
  SHOPIFY = "SHOPIFY",
  SUPPLIER_OFFLINE_CATALOGUE = "SUPPLIER_OFFLINE_CATALOGUE",
  SUPPLIER_TENDO_ONLINE_CATALOGUE = "SUPPLIER_TENDO_ONLINE_CATALOGUE",
  WOOCOMMENCE = "WOOCOMMENCE",
}

export enum ProductStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum ProductType {
  Digital = "Digital",
  Physical = "Physical",
}

export enum ReasonForPayment {
  order_purchase = "order_purchase",
  payout_withdrawal = "payout_withdrawal",
  plugin_purchase = "plugin_purchase",
  wallet_topup = "wallet_topup",
}

export enum Settlement {
  Commission = "Commission",
  Markup = "Markup",
}

export enum SupplierType {
  ADVANCED = "ADVANCED",
  BASIC = "BASIC",
}

export enum TemplateType {
  ALERT = "ALERT",
  CHECKBOX = "CHECKBOX",
  DATE = "DATE",
  EMAIL = "EMAIL",
  FILE = "FILE",
  NUMBER = "NUMBER",
  PASSWORD = "PASSWORD",
  RADIO = "RADIO",
  RICHTEXT = "RICHTEXT",
  SELECT = "SELECT",
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
}

export enum UserAccountType {
  CUSTOMER = "CUSTOMER",
  MANAGER = "MANAGER",
  RESELLER = "RESELLER",
  SUPPLIER = "SUPPLIER",
}

export enum UserType {
  IN_HOUSE = "IN_HOUSE",
  RESELLER = "RESELLER",
  SUPPLIER = "SUPPLIER",
}

export enum distributionChannel {
  MarketPlace = "MarketPlace",
  ResellerNetwork = "ResellerNetwork",
  SupplierStorefront = "SupplierStorefront",
  TendoNetwork = "TendoNetwork",
}

export interface ActivateShopRequestDto {
  userId?: string | null;
  supplierId?: string | null;
}

export interface AddPluginToUserRequestDto {
  plugin_id: string;
  user_id: string;
}

export interface BooleanOperator {
  eq?: boolean | null;
}

export interface BulkProductStockInput {
  sku: string;
  qty: number;
  cost_price: number;
  price: number;
  personnel?: string | null;
  reason?: string | null;
}

export interface CartFilter {
  id?: IdOperator | null;
  user_id?: IdOperator | null;
  customer_id?: IdOperator | null;
  supplier_id?: IdOperator | null;
  source?: StringOperator | null;
  status?: StringOperator | null;
  supplier_status?: StringOperator | null;
  country?: StringOperator | null;
  cart_number?: StringOperator | null;
  payment_id?: StringOperator | null;
  offline?: BooleanOperator | null;
  user_type?: StringOperator | null;
  tracking_code?: StringOperator | null;
  instantDelivery?: BooleanOperator | null;
}

export interface CatalogFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  country?: StringOperator | null;
}

export interface CreateDiscountRequestDto {
  type: DiscountType;
  valid_country: string;
  code?: string | null;
  percentage?: number | null;
  amount?: number | null;
  description: string;
  is_draft: boolean;
  start_date: string;
  expiry_date: string;
  redemptions_limit?: number | null;
  product_condition?: ProductCondition | null;
  products?: string[] | null;
  fixed_amount_allocation?: FixedAmountAllocation | null;
  created_by?: UserType | null;
  user_id?: string | null;
  locations?: (string | null)[] | null;
  locations_tagname?: string | null;
}

export interface CustomerFilter {
  id?: IdOperator | null;
  user_id?: IdOperator | null;
  supplier_id?: IdOperator | null;
  name?: StringOperator | null;
  email?: StringOperator | null;
  address?: StringOperator | null;
  phone?: StringOperator | null;
}

export interface DateOperator {
  eq?: any | null;
  before?: any | null;
  after?: any | null;
  between?: DateRange | null;
}

export interface DateRange {
  start: any;
  end: any;
}

export interface DeliveryFilter {
  id?: StringOperator | null;
  location?: StringOperator | null;
  price?: NumberOperator | null;
  remarks?: StringOperator | null;
  isPayOnline?: BooleanOperator | null;
  hasInstantDelivery?: BooleanOperator | null;
  country?: StringOperator | null;
}

export interface Details {
  user_id?: string | null;
  dob?: any | null;
  gender?: string | null;
  referred_by?: string | null;
}

export interface DiscountFilter {
  id?: IdOperator | null;
  type?: StringOperator | null;
  valid_country?: StringOperator | null;
  code?: StringOperator | null;
  percentage?: NumberOperator | null;
  amount?: NumberOperator | null;
  description?: StringOperator | null;
  is_draft?: BooleanOperator | null;
  start_date?: DateOperator | null;
  expiry_date?: DateOperator | null;
  redemptions_limit?: NumberOperator | null;
  fixed_amount_allocation?: StringOperator | null;
  created_at?: DateOperator | null;
  updated_at?: DateOperator | null;
  created_by?: StringOperator | null;
  user_id?: IdOperator | null;
  number_of_times_used?: NumberOperator | null;
  locations?: StringOperator | null;
}

export interface DiscountProductConditionFilter {
  id?: IdOperator | null;
  product_sku?: StringOperator | null;
  condition?: StringOperator | null;
  discount_id?: IdOperator | null;
  created_at?: DateOperator | null;
  updated_at?: DateOperator | null;
}

export interface FeaturedCategoriesFilter {
  id?: IdOperator | null;
  country?: StringOperator | null;
}

export interface FetchShopifyShopInput {
  filter?: ShopifyShopFilter | null;
  condition?: Condition | null;
}

export interface IdOperator {
  eq?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

export interface InitiateCardSetupInput {
  integration_id: string;
  supplier_email: string;
  supplier_phone: string;
}

export interface LoadProductToShopify {
  uuid: string;
  qty: number;
  title: string;
  description: string;
  price?: number | null;
  sku: string;
  media: string[];
  tags?: (string | null)[] | null;
  supplier_generic_name?: string | null;
  product_origin: ProductOrigin;
  product_id: string;
  combination_string?: string | null;
}

export interface LoadProductToShopifyInput {
  products?: LoadProductToShopify[] | null;
  integration_id?: string | null;
}

export interface MoneyOperator {
  eq?: any | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  between?: MoneyRange | null;
}

export interface MoneyRange {
  start: any;
  end: any;
}

export interface NumberOperator {
  eq?: number | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  between?: NumberRange | null;
}

export interface NumberRange {
  start: number;
  end: number;
}

export interface Pagination {
  offset?: number | null;
  limit?: number | null;
}

export interface PartnershipFilter {
  id?: IdOperator | null;
  supplier_id?: IdOperator | null;
  client_id?: IdOperator | null;
  status?: PartnershipStatus | null;
}

export interface PayoutFilter {
  id?: IdOperator | null;
  status?: StringOperator | null;
  transaction_id?: StringOperator | null;
  provider?: StringOperator | null;
  currency?: StringOperator | null;
  account_name?: StringOperator | null;
  account_number?: StringOperator | null;
  country?: StringOperator | null;
  user_id?: IdOperator | null;
  supplier_id?: IdOperator | null;
}

export interface PayoutInput {
  id?: string | null;
  country: string;
  user_id?: string | null;
  supplier_id?: string | null;
  currency: string;
  provider: string;
  account_number: string;
  amount: number;
  account_name: string;
  transaction_id?: string | null;
  status?: string | null;
}

export interface PaystackResolveAccountNumberRequestDto {
  account_number: string;
  bank_code: string;
}

export interface PluginFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  logo?: StringOperator | null;
  banner_image?: StringOperator | null;
  category?: StringOperator | null;
  developer?: StringOperator | null;
  verified?: BooleanOperator | null;
  description?: StringOperator | null;
  endpoint?: StringOperator | null;
  created_at?: DateOperator | null;
  updated_at?: DateOperator | null;
}

export interface ProductCategoryAndTagsInput {
  product_id: string;
  categories?: string[] | null;
  tags?: string[] | null;
  published?: boolean | null;
  status?: ProductStatus | null;
  verified?: boolean | null;
  personnel?: string | null;
  reason?: string | null;
}

export interface ProductCategoryFilter {
  id?: IdOperator | null;
  shop_id?: IdOperator | null;
  supplier_id?: IdOperator | null;
  name?: StringOperator | null;
  description?: StringOperator | null;
  country?: StringOperator | null;
  parent?: NumberOperator | null;
  random?: boolean | null;
}

export interface ProductCombinationInput {
  id?: string | null;
  sku: string;
  price: number;
  qty: number;
  cost_price: number;
  combination_string: string;
  avatar: string;
  media?: string[] | null;
  categories?: (string | null)[] | null;
}

export interface ProductHistoryFilter {
  product_id?: IdOperator | null;
  action?: StringOperator | null;
}

export interface ProductInformationInput {
  product_id: string;
  title?: string | null;
  price?: number | null;
  cost_price?: number | null;
  supplier_cost?: number | null;
  description?: string | null;
  sku: string;
  currency?: CurrencyCode | null;
  country?: CountryCode | null;
  weight?: number | null;
  minimumOrderQuantity?: number | null;
  extra_information?: string | null;
  published?: boolean | null;
  status?: ProductStatus | null;
  verified?: boolean | null;
  personnel?: string | null;
  reason?: string | null;
}

export interface ProductInventoryInput {
  product_id: string;
  limited_stock?: boolean | null;
  qty?: number | null;
  price?: number | null;
  cost_price?: number | null;
  published?: boolean | null;
  status?: ProductStatus | null;
  verified?: boolean | null;
  personnel?: string | null;
  reason?: string | null;
}

export interface ProductMediaInput {
  product_id: string;
  media?: string[] | null;
  avatar?: string | null;
  published?: boolean | null;
  verified?: boolean | null;
  status?: ProductStatus | null;
  personnel?: string | null;
  reason?: string | null;
}

export interface ShopCollectionProductFilter {
  collection_id?: IdOperator | null;
}

export interface ShopFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  description?: StringOperator | null;
  contactEmail?: StringOperator | null;
  email?: StringOperator | null;
  currency?: StringOperator | null;
  slug?: StringOperator | null;
  primaryDomain?: StringOperator | null;
  sokoWorldDomain?: StringOperator | null;
  hasBeenSetup?: BooleanOperator | null;
  isActive?: BooleanOperator | null;
  createdAt?: DateOperator | null;
  updatedAt?: DateOperator | null;
  avatar?: StringOperator | null;
  country?: StringOperator | null;
  coverImage?: StringOperator | null;
  deliveryOption?: StringOperator | null;
  deliveryAgent?: StringOperator | null;
  url?: StringOperator | null;
  shortUrl?: StringOperator | null;
  phone?: StringOperator | null;
  ownerId?: StringOperator | null;
  supplierId?: IdOperator | null;
}

export interface ShopLocationRequestDto {
  lat: number;
  long: number;
}

export interface ShopProductFilter {
  shop_id?: IdOperator | null;
}

export interface ShopifyShopFilter {
  id?: IdOperator | null;
  shop?: StringOperator | null;
  supplier_id?: IdOperator | null;
}

export interface StringOperator {
  eq?: string | null;
  notEq?: string | null;
  like?: string | null;
  notContains?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  regex?: string | null;
}

export interface SupplierAddDeviceTokenInput {
  id: string;
  deviceToken: string;
}

export interface SupplierBusinessInput {
  id?: string | null;
  supplier_id: string;
  business_name: string;
  industry: string;
  description?: string | null;
  logo?: string | null;
  registered: boolean;
  email: string;
  phone: string;
  city: string;
  state?: string | null;
  postal_code?: string | null;
  delivery_option?: string | null;
  business_address?: string | null;
  country?: string | null;
  referred_by?: string | null;
}

export interface SupplierEmailChangeInput {
  id: string;
  newEmail: string;
}

export interface SupplierFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  email?: StringOperator | null;
  phone?: StringOperator | null;
  country?: StringOperator | null;
  supplier_type?: SupplierType | null;
  has_accepted_terms?: BooleanOperator | null;
}

export interface SupplierInput {
  id?: string | null;
  name: string;
  email: string;
  phone: string;
  country: string;
  password?: string | null;
  supplier_type?: SupplierType | null;
  onboardingType?: string | null;
}

export interface SupplierPasswordChangeInput {
  id: string;
  newPassword: string;
  oldPassword: string;
}

export interface SupplierPasswordResetInput {
  token: string;
  password: string;
}

export interface SyncShopifyProductInput {
  product_ids?: string[] | null;
  integration_id: string;
  distributionChannel?: (distributionChannel | null)[] | null;
  supplier_country: string;
}

export interface TemplateFilter {
  name?: StringOperator | null;
  id?: IdOperator | null;
}

export interface TermInput {
  id?: string | null;
  name: string;
  description?: string | null;
  settlementType: Settlement;
  settlementValue: number;
  settlementCurrency?: string | null;
  settlementPeriod?: number | null;
  returnPeriod?: number | null;
  returnPolicy?: string | null;
  shippingPolicy?: string | null;
  cancellationPolicy?: string | null;
  shipTo?: string | null;
  ShippingLocations?: (string | null)[] | null;
  estimatedDeliveryTime?: number | null;
  estimatedDeliveryTimeUnit?: string | null;
  supplier_id: string;
}

export interface TermsFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  description?: StringOperator | null;
  settlementType?: Settlement | null;
  settlementCurrency?: StringOperator | null;
  settlementPeriod?: NumberOperator | null;
  returnPeriod?: NumberOperator | null;
  supplier_id?: IdOperator | null;
  shipTo?: StringOperator | null;
  estimatedDeliveryTime?: NumberOperator | null;
}

export interface UpdateDiscountData {
  type?: DiscountType | null;
  valid_country?: string | null;
  code?: string | null;
  percentage?: number | null;
  amount?: number | null;
  description?: string | null;
  is_draft?: boolean | null;
  start_date?: string | null;
  expiry_date?: string | null;
  redemptions_limit?: number | null;
  fixed_amount_allocation?: FixedAmountAllocation | null;
  created_by?: UserType | null;
  user_id?: string | null;
  locations?: (string | null)[] | null;
}

export interface UpdateProductDistributionInput {
  product_id: string;
  distributionChannels: string[];
  price?: number | null;
  cost_price?: number | null;
  supplier_cost?: number | null;
  published?: boolean | null;
  verified?: boolean | null;
  status?: ProductStatus | null;
  personnel?: string | null;
  reason?: string | null;
}

export interface UpdateShopDetailsRequestDto {
  shopId: string;
  name?: string | null;
  description?: string | null;
  contactEmail?: string | null;
  email?: string | null;
  currency?: CurrencyCode | null;
  primaryDomain?: string | null;
  sokoWorldDomain?: string | null;
  customDomains?: (string | null)[] | null;
  shopTags?: (string | null)[] | null;
  avatar?: string | null;
  country?: CountryCode | null;
  coverImage?: string | null;
  deliveryOption?: DeliveryOption | null;
  deliveryAgent?: DeliveryAgent | null;
  location?: ShopLocationRequestDto | null;
  paymentOptions?: (PaymentOption | null)[] | null;
  phone?: string | null;
}

export interface UpdateSupplierDetailsData {
  name?: string | null;
  avatar?: string | null;
}

export interface UpdateSupplierDetailsInput {
  id?: string | null;
  data?: UpdateSupplierDetailsData | null;
}

export interface UpdateSupplierPaymentInput {
  supplier_id: string;
  input: UpdateSupplierPaymentInputOptions;
}

export interface UpdateSupplierPaymentInputOptions {
  method: string;
  account_number: string;
  provider: string;
  account_name: string;
}

export interface UpdateSupplierSlugInput {
  supplier_id: string;
  slug: string;
}

export interface UserFilter {
  id?: IdOperator | null;
  email?: StringOperator | null;
  referred_by?: StringOperator | null;
  supplier_id?: IdOperator | null;
  name?: StringOperator | null;
  phone?: StringOperator | null;
  country?: StringOperator | null;
  verified?: BooleanOperator | null;
  user_type?: StringOperator | null;
  username?: StringOperator | null;
}

export interface UserPluginFilter {
  id?: IdOperator | null;
  user_id?: IdOperator | null;
  plugin_id?: IdOperator | null;
}

export interface VariationInput {
  id?: string | null;
  variation_name: string;
  variation_options?: (VariationOptionInput | null)[] | null;
}

export interface VariationOptionInput {
  id?: string | null;
  variation_value?: string | null;
}

export interface WalletFilter {
  id?: IdOperator | null;
  user_id?: IdOperator | null;
  supplier_id?: IdOperator | null;
}

export interface WalletHistoryFilter {
  id?: IdOperator | null;
  status?: StringOperator | null;
  reference?: StringOperator | null;
  amount?: MoneyOperator | null;
  wallet_id?: IdOperator | null;
  user_id?: IdOperator | null;
  supplier_id?: IdOperator | null;
}

export interface accountRegisterInput {
  name: string;
  email: string;
  password: string;
  country: string;
  phone: string;
  details?: Details | null;
  social_login?: boolean | null;
  referred_by?: string | null;
  supplier_shop_id?: string | null;
}

export interface addSupplierPaymentInput {
  id?: string | null;
  supplier_id: string;
  account_name: string;
  account_number: string;
  provider: string;
  method: string;
}

export interface createProductInput {
  id?: string | null;
  title: string;
  sku: string;
  qty?: number | null;
  description: string;
  limited_stock?: boolean | null;
  inhouse?: boolean | null;
  currency: CurrencyCode;
  country: CountryCode;
  published: boolean;
  status?: ProductStatus | null;
  price: number;
  supplier_cost?: number | null;
  weight?: number | null;
  cost_price: number;
  avatar: string;
  categories?: (string | null)[] | null;
  variations?: (VariationInput | null)[] | null;
  combinations?: (ProductCombinationInput | null)[] | null;
  combination_string?: string | null;
  media: string[];
  tags?: (string | null)[] | null;
  shop_id?: string | null;
  catalog_id?: (string | null)[] | null;
  uuid: string;
  user_id?: string | null;
  supplier_id?: string | null;
  verified: boolean;
  extra_information?: string | null;
  distributionChannels?: (string | null)[] | null;
  minimumOrderQuantity?: number | null;
  product_origin?: ProductOrigin | null;
  productType?: ProductType | null;
  digitalDetails?: any | null;
  instantDelivery?: boolean | null;
  personnel?: string | null;
  reason?: string | null;
}

export interface productFilter {
  sku?: StringOperator | null;
  combination_string?: StringOperator | null;
  distributionChannels?: StringOperator | null;
  title?: StringOperator | null;
  price?: MoneyOperator | null;
  id?: IdOperator | null;
  qty?: NumberOperator | null;
  transit_stock?: NumberOperator | null;
  main_stock?: NumberOperator | null;
  supplier_id?: IdOperator | null;
  reseller_id?: IdOperator | null;
  partner?: IdOperator | null;
  contractor?: IdOperator | null;
  shop?: ShopProductFilter | null;
  shopCollection?: ShopCollectionProductFilter | null;
  verified?: BooleanOperator | null;
  published?: BooleanOperator | null;
  status?: StringOperator | null;
  inhouse?: BooleanOperator | null;
  random?: boolean | null;
  catalog?: CatalogFilter | null;
  category?: ProductCategoryFilter | null;
  attended_to?: BooleanOperator | null;
  country?: StringOperator | null;
  tags?: StringOperator | null;
  uuid?: StringOperator | null;
  resell?: BooleanOperator | null;
  product_origin?: StringOperator | null;
  productType?: ProductType | null;
}

export interface requestOTPInput {
  email?: string | null;
  phone?: string | null;
  channel?: ChannelType | null;
}

export interface verifySupplierOTPInput {
  medium: string;
  code: string;
  token: string;
  update?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
