import { useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { currentTokenVar, useCurrentUser } from "_graphql/cache/auth";
import { useApp } from "hooks";

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  };
}>;

const AuthLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>()
  const currentToken = useReactiveVar(currentTokenVar)
  const currentUser = useCurrentUser()
  const { url } = useApp()
  if (currentToken && currentUser?.id) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate
      to={search?.redirect || "/"}
      replace
    />
  }
  return (
    <div className="h-screen">
      <div className="h-screen overflow-hidden grid grid-cols-5">
        <div className="flex-1 flex md:col-span-2 flex-col overflow-y-scroll no-scrollbar justify-center pt-6 lg:py-12 px-4 sm:px-6 col-span-5 lg:flex-none lg:px-6 xl:px-24 relative">
          <Outlet />
          <span className="relative text-sm mt-6 text-center ">
            &copy; {new Date().getFullYear()} - Powered by{" "}
            <a
              href={url}
              target={"_blank"}
              className="text-blue-700 hover:text-blue-900"
              rel="noreferrer"
            >
              Duke Soko Technologies LTD.
            </a>
          </span>
        </div>
        <div className="hidden lg:block relative col-span-3">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require("assets/hero-three.jpg")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
