import { StarIcon, TrashIcon } from '@heroicons/react/24/solid';
import { GetProduct_getProduct } from '_graphql/queries/__generated__/GetProduct';
import { FormikProps } from 'formik';
import _, { uniqueId } from 'lodash';

import useUploadMedia, {
  IFile,
} from 'pages/create-product/hooks/use-upload-media';

import { FC, useEffect, useRef, useState } from 'react';

type Props = {
  form: FormikProps<any>;
  product: GetProduct_getProduct | null | undefined;
  loading: boolean;
};

const UpdateMediaForm: FC<Props> = ({ form, product, loading }: Props) => {
  const [avatarindex, setAvatarindex] = useState(0);
  const mediaUploadRef = useRef<HTMLInputElement>(null);
  // find a way to assign product media images to uploading media to enable deletion or make deletion respective to data in form values and load form values
  useEffect(() => {
    if (product) {
      form.setFieldValue('avatar', product?.avatar);
      product?.media?.forEach((img, i) => {
        form.setFieldValue(`media.${i}`, img?.avatar);
      });
    }
  }, [loading]);

  const onImagesUploaded = (images: IFile[]) => {
    form.setFieldValue('avatar', images[avatarindex]?.imgLink);
    images?.forEach((file, i) => {
      form.setFieldValue(`media`, [...form.values.media, file.imgLink]);
    });
  };

  const handleDelete = (item: any) => {
    form.setFieldValue(
      'media',
      form?.values?.media.filter((el: any) => el !== item)
    );
  };

  const {
    getInputProps,
    isDragActive,
    uploadingMedia,
    deleteFile,
    getRootProps,
  } = useUploadMedia(uniqueId('product-'), {
    onUploaded: onImagesUploaded,
    multiple: true,
  });

  const fileUploadClickHandler = () => {
    if (mediaUploadRef.current) {
      mediaUploadRef.current.click();
    }
  };

  const handleAvatarChoose = (index: number) => {
    setAvatarindex(index);
    form.setFieldValue('avatar', uploadingMedia[index]?.imgLink);
  };

  return (
    <form className='w-full py-5'>
      <div className='w-full'>
        <div className='my-3'>Product Media</div>
        <div
          {...getRootProps()}
          className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer w-full col-span-full'
          onClick={fileUploadClickHandler}>
          <div className='space-y-1 text-center'>
            <svg
              className='mx-auto h-12 w-12 text-gray-400'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'>
              <path
                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <div className='flex text-sm text-gray-600'>
              <input
                {...getInputProps()}
                id='file-upload'
                name='file-upload'
                type='file'
                ref={mediaUploadRef}
                className='hidden sr-only'
              />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <>
                  <label
                    htmlFor='file-upload'
                    className='relatives bg-white rounded-md font-medium   focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'>
                    <span>Upload a file</span>
                  </label>
                  <p className='pl-1'>or drag and drop</p>
                </>
              )}
            </div>
            <p className='text-xs text-gray-500'>
              PNG, JPG, GIF, MP4 up to 10MB
            </p>
          </div>
        </div>

        {form?.values.media.length !== 0 && (
          <div className='mt-2 col-span-full '>
            <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-3 w-full'>
              {form?.values.media.map((item: any, index: number) => (
                <li
                  onClick={() => handleAvatarChoose(index)}
                  key={item}
                  className='relative'>
                  <div className='group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden'>
                    {item?.loading ? (
                      <div className='flex justify-center items-center p-4'>
                        <span>Loading... </span>
                      </div>
                    ) : (
                      <img
                        src={item}
                        alt=''
                        className='object-cover pointer-events-none group-hover:opacity-75'
                      />
                    )}
                  </div>

                  <p className='mt-2  text-sm font-medium flex flex-col items-center text-gray-900 truncate pointer-events-none'>
                    {index === avatarindex ? (
                      <div className='flex'>
                        <StarIcon className='h-3 w-3 text-primary-600' />
                        <span className='text-xs'>Product Avatar</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {item}
                  </p>

                  <span
                    className='md cursor-pointer hover:opacity-80 text-xs flex items-center justify-start'
                    onClick={() => handleDelete(item)}>
                    <TrashIcon className='mr-2 text-red-500 h-4 w-4' />{' '}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {_.get(form.errors, 'media') && (
        <p className='mt-2 mb-5 mx-5 text-sm text-red-600 '>
          <> {_.get(form.errors, 'media')}</>
        </p>
      )}
    </form>
  );
};

export default UpdateMediaForm;
