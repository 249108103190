import { gql } from "@apollo/client";

export const getUsers = gql`
  query GetUsers(
    $pagination: Pagination
    $condition: Condition
    $filter: UserFilter
  ) {
    getUsersLength(filter: $filter)
    getUsers(pagination: $pagination, condition: $condition, filter: $filter) {
      id
      email
      name
      phone
      shop {
        shortUrl
        slug
        url
      }
      country
      username
    }
  }
`;
export const getUser = gql`
  query GetUser($filter: UserFilter, $condition: Condition) {
    getUser(filter: $filter, condition: $condition) {
      id
      email
      name
      country
      phone
      verified
      first_order_created
      user_type
      username
      google_id
      deviceToken
      facebook_id
      twitter_id
      supplier_shop_id
      details {
        id
        gender
        dob
        referred_by
        phone_verified
        email_verified
        user_id
      }
    }
  }
`;


