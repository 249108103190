import { SupplierType } from '_graphql/__generated__/globalTypes';
import { SupplierSignIn_supplierSignIn_supplier_payment_details } from '_graphql/mutation/__generated__/SupplierSignIn';
import { Action } from 'components/buttons/action-button';
import _ from 'lodash';
import { BuildNextOptions } from 'react-location';
import { LocationGenerics } from 'router/location';

export { default as classNames } from './classnames';
export { default as wrapClick } from './wrap-click';
export { default as wrapImage } from './wrap-image';
export { default as wrapOnchange } from './wrap-onchange';
export { default as withPermissions } from './with-permissions';
export { default as useDownloadFile } from './use-download-file';
export { default as useUrlState } from './use-url-state';
export { default as useTableData } from './use-table-data';
export * as validators from './regex';

export const summary: any = {
  _id: null,
  date: null,
  total: 13824,
  urban: 3381,
  rural: 7167,
  periUrban: 3276,
  residential: 12524,
  nonResidential: 1300,
  totalConsumption: 8278237.2,
  totalBilled: 11983821.05,
  __typename: 'RegularizationSummary',
};

export const TEST_DATA = [
  {
    _id: '2023-10-11',
    date: '2023-10-11T00:00:00.000Z',
    total: 664,
    urban: 154,
    rural: 337,
    periUrban: 173,
    residential: 610,
    nonResidential: 54,
    totalConsumption: 404922,
    totalBilled: 583731.1,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-29',
    date: '2023-10-29T00:00:00.000Z',
    total: 11,
    urban: 3,
    rural: 1,
    periUrban: 7,
    residential: 10,
    nonResidential: 1,
    totalConsumption: 9636,
    totalBilled: 13687.61,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-06',
    date: '2023-10-06T00:00:00.000Z',
    total: 506,
    urban: 88,
    rural: 325,
    periUrban: 93,
    residential: 456,
    nonResidential: 50,
    totalConsumption: 255816,
    totalBilled: 376076.33,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-23',
    date: '2023-10-23T00:00:00.000Z',
    total: 668,
    urban: 208,
    rural: 319,
    periUrban: 141,
    residential: 611,
    nonResidential: 57,
    totalConsumption: 391116,
    totalBilled: 575415.65,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-07',
    date: '2023-10-07T00:00:00.000Z',
    total: 68,
    urban: 1,
    rural: 45,
    periUrban: 22,
    residential: 65,
    nonResidential: 3,
    totalConsumption: 34290,
    totalBilled: 50831.380000000005,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-17',
    date: '2023-10-17T00:00:00.000Z',
    total: 669,
    urban: 188,
    rural: 368,
    periUrban: 113,
    residential: 612,
    nonResidential: 57,
    totalConsumption: 380472,
    totalBilled: 557247.33,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-21',
    date: '2023-10-21T00:00:00.000Z',
    total: 76,
    urban: 5,
    rural: 54,
    periUrban: 17,
    residential: 66,
    nonResidential: 10,
    totalConsumption: 41850,
    totalBilled: 59471.51,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-10',
    date: '2023-10-10T00:00:00.000Z',
    total: 698,
    urban: 133,
    rural: 408,
    periUrban: 157,
    residential: 624,
    nonResidential: 74,
    totalConsumption: 387528,
    totalBilled: 563968.1,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-20',
    date: '2023-10-20T00:00:00.000Z',
    total: 581,
    urban: 142,
    rural: 318,
    periUrban: 121,
    residential: 527,
    nonResidential: 54,
    totalConsumption: 331893,
    totalBilled: 488677.4,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-22',
    date: '2023-10-22T00:00:00.000Z',
    total: 54,
    urban: 0,
    rural: 47,
    periUrban: 7,
    residential: 53,
    nonResidential: 1,
    totalConsumption: 26082,
    totalBilled: 39623.340000000004,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-26',
    date: '2023-10-26T00:00:00.000Z',
    total: 606,
    urban: 232,
    rural: 229,
    periUrban: 145,
    residential: 553,
    nonResidential: 53,
    totalConsumption: 481794,
    totalBilled: 681873.41,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-28',
    date: '2023-10-28T00:00:00.000Z',
    total: 64,
    urban: 18,
    rural: 39,
    periUrban: 7,
    residential: 63,
    nonResidential: 1,
    totalConsumption: 35742,
    totalBilled: 53830.340000000004,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-24',
    date: '2023-10-24T00:00:00.000Z',
    total: 539,
    urban: 232,
    rural: 203,
    periUrban: 104,
    residential: 483,
    nonResidential: 56,
    totalConsumption: 395766,
    totalBilled: 563626.1,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-13',
    date: '2023-10-13T00:00:00.000Z',
    total: 609,
    urban: 105,
    rural: 250,
    periUrban: 254,
    residential: 565,
    nonResidential: 44,
    totalConsumption: 350334,
    totalBilled: 512800.92,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-16',
    date: '2023-10-16T00:00:00.000Z',
    total: 686,
    urban: 167,
    rural: 364,
    periUrban: 155,
    residential: 620,
    nonResidential: 66,
    totalConsumption: 399228,
    totalBilled: 579180.57,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-15',
    date: '2023-10-15T00:00:00.000Z',
    total: 29,
    urban: 2,
    rural: 24,
    periUrban: 3,
    residential: 27,
    nonResidential: 2,
    totalConsumption: 17682,
    totalBilled: 25853.9,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-05',
    date: '2023-10-05T00:00:00.000Z',
    total: 690,
    urban: 120,
    rural: 411,
    periUrban: 159,
    residential: 627,
    nonResidential: 63,
    totalConsumption: 374290.2,
    totalBilled: 544012.51,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-12',
    date: '2023-10-12T00:00:00.000Z',
    total: 651,
    urban: 148,
    rural: 285,
    periUrban: 218,
    residential: 599,
    nonResidential: 52,
    totalConsumption: 378432,
    totalBilled: 549870.44,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-02',
    date: '2023-10-02T00:00:00.000Z',
    total: 835,
    urban: 151,
    rural: 489,
    periUrban: 195,
    residential: 747,
    nonResidential: 88,
    totalConsumption: 453546,
    totalBilled: 654195.32,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-03',
    date: '2023-10-03T00:00:00.000Z',
    total: 763,
    urban: 166,
    rural: 439,
    periUrban: 158,
    residential: 710,
    nonResidential: 53,
    totalConsumption: 437628,
    totalBilled: 636573.06,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-09',
    date: '2023-10-09T00:00:00.000Z',
    total: 631,
    urban: 124,
    rural: 282,
    periUrban: 225,
    residential: 554,
    nonResidential: 77,
    totalConsumption: 355728,
    totalBilled: 515193.68,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-14',
    date: '2023-10-14T00:00:00.000Z',
    total: 137,
    urban: 21,
    rural: 69,
    periUrban: 47,
    residential: 128,
    nonResidential: 9,
    totalConsumption: 71766,
    totalBilled: 106540.76,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-04',
    date: '2023-10-04T00:00:00.000Z',
    total: 692,
    urban: 154,
    rural: 406,
    periUrban: 132,
    residential: 625,
    nonResidential: 67,
    totalConsumption: 408120,
    totalBilled: 582539.84,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-25',
    date: '2023-10-25T00:00:00.000Z',
    total: 566,
    urban: 223,
    rural: 243,
    periUrban: 100,
    residential: 484,
    nonResidential: 82,
    totalConsumption: 407100,
    totalBilled: 574412.68,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-01',
    date: '2023-10-01T00:00:00.000Z',
    total: 57,
    urban: 6,
    rural: 41,
    periUrban: 10,
    residential: 52,
    nonResidential: 5,
    totalConsumption: 32922,
    totalBilled: 47141.88,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-18',
    date: '2023-10-18T00:00:00.000Z',
    total: 675,
    urban: 146,
    rural: 407,
    periUrban: 122,
    residential: 610,
    nonResidential: 65,
    totalConsumption: 373722,
    totalBilled: 546277.4199999999,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-27',
    date: '2023-10-27T00:00:00.000Z',
    total: 476,
    urban: 153,
    rural: 204,
    periUrban: 119,
    residential: 436,
    nonResidential: 40,
    totalConsumption: 334092,
    totalBilled: 481313.79,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-08',
    date: '2023-10-08T00:00:00.000Z',
    total: 17,
    urban: 0,
    rural: 15,
    periUrban: 2,
    residential: 16,
    nonResidential: 1,
    totalConsumption: 7572,
    totalBilled: 11094.58,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-19',
    date: '2023-10-19T00:00:00.000Z',
    total: 594,
    urban: 148,
    rural: 344,
    periUrban: 102,
    residential: 537,
    nonResidential: 57,
    totalConsumption: 344862,
    totalBilled: 502003.49,
    __typename: 'RegularizationSummary',
  },
  {
    _id: '2023-10-30',
    date: '2023-10-30T00:00:00.000Z',
    total: 512,
    urban: 143,
    rural: 201,
    periUrban: 168,
    residential: 454,
    nonResidential: 58,
    totalConsumption: 354306,
    totalBilled: 506756.61,
    __typename: 'RegularizationSummary',
  },
];

export const dispatchAction =
  (
    id: string,
    action: Exclude<Action, 'expand' | 'goto' | 'clone'>,
    navigate: (args_0: BuildNextOptions<LocationGenerics>) => void
  ) =>
    () => {
      // const navigate = useNavigate<LocationGenerics>();
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
export const naviagteTo =
  (
    id: string,
    navigate: (args_0: BuildNextOptions<LocationGenerics>) => void,
    type: 'sub' | 'parent',
    search?: Partial<LocationGenerics>
  ) =>
    () => {
      navigate({
        to: type == 'parent' ? `/${id}` : `./${id}`,
        search: (old) => ({
          ...old,
          ...search,
        }),
      });
    };


const NAV_SECTIONS_MAP = {
  "BASIC": ["General", "Inventory", "Orders", "Others"],
  "ADVANCED": ["General",
    "Users",
    "Inventory",
    "Orders",
    "Apps",
    "MarketPlace",
    "Others",],
}

export const processNavigationSections = (supplier_type: SupplierType) => {
  return _.get(NAV_SECTIONS_MAP, supplier_type, NAV_SECTIONS_MAP["BASIC"]);
}
