import lodash from 'lodash'
import numeral from 'numeral'
import { useDashboardStats } from './hooks/use-summary'
import { RectangleStackIcon, LockClosedIcon, UsersIcon, ShoppingCartIcon } from '@heroicons/react/24/solid'
import { useCurrentUser } from '_graphql/cache/auth'
import Customers from './assests/Customers.png'
import FinishedOrders from './assests/Finished Orders.png'
import Flag from './assests/Flag.png'
import Resellers from './assests/Resellers.png'
import TotalProducts from './assests/TotalProducts.png'
import InTransit from './assests/InTransit.png'
type Props = {}

const stats = [
    {
        id: 1,
        name: "Total Products",
        accessor: "products",
        icon: RectangleStackIcon,
        valueKey: "total_products",
        description:"Number of product in inventory",
        image: TotalProducts,
        bgColor:"#EDE7FD"
    },
    {
        id: 7,
        name: " In Stock",
        accessor: "products",
        icon: RectangleStackIcon,
        valueKey: "total_products_in_stock",
        description:"of total product ",
        image:TotalProducts,
        bgColor:"#EDE7FD"
    },
    {
        id: 8,
        name: " Out Of Stock",
        accessor: "products",
        icon: RectangleStackIcon,
        valueKey: "total_products_out_of_stock",
        description:"of total product ",
        image:Flag,
        bgColor:"#FDE7E7"
    },
    {
        id: 8,
        name: " Products In Transit",
        accessor: "products",
        icon: RectangleStackIcon,
        valueKey: "total_products_in_transit",
        description:"Products out for delivery ",
        image:InTransit,
        bgColor:"#F9F2DB"
    },
    // {
    //     id: 2,
    //     name: "Total Active Orders",
    //     accessor: "products",
    //     icon: ActiveOrdes,
    //     valueKey: "total_active_orders",
    // },
    {
        id: 3,
        name: " Finished Orders",
        accessor: "orders",
        valueKey: "total_finished_orders",
        icon: LockClosedIcon,
        description:"Number of orders out of stock ",
        image:FinishedOrders,
        bgColor:"#EEFFE1"
    },
    {
        id: 4,
        name: "Resellers",
        accessor: "resellers",
        valueKey: "total_resellers",
        icon: UsersIcon,
        description:"The total number of resellers ",
        image:Resellers,
        bgColor:"#E7ECFD"
    },
    {
        id: 5,
        name: "Customers",
        accessor: "customers",
        valueKey: "total_customers",
        icon: UsersIcon,
        description:"The total number of customers  ",
        image:Customers,
        bgColor:"#FDE7FD"
    },
    {
        id: 6,
        name: "Total Orders",
        accessor: "orders",
        valueKey: "total_orders",
        icon: ShoppingCartIcon,
        description:"Overall orders received ",
        image:TotalProducts,
        bgColor:"#EDE7FD"
    },
];


const DashboardStats = (props: Props) => {
    const { id } = useCurrentUser()
    const { supplierStats, loading } = useDashboardStats({
        supplierId: id || "",
    })
    return (
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
            <div className="col-span-12">
                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {stats.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex flex-col  space-y-6 md:last-of-type:odd:col-span-4 overflow-hidden rounded-lg bg-white px-4 pt-5  shadow sm:px-6 sm:pt-6"
                        >
                           <div className='flex flex-row justify-between'>
                            <div><p className=" truncate text-sm font-medium text-gray-500">
                                    {item.name}
                                </p></div>
                            <div className=" rounded-md bg-primary-500 p-3" style={{backgroundColor:`${item.bgColor}`}}>
                                    {/* <item.icon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    /> */}
                                    <img src={item.image} className='h-6 w-6' alt="" />
                                </div>
                            </div>

                            <div>
                                <div className='text-xl font-medium'>{loading ? "Loading ..." : numeral(lodash.get(supplierStats, item.valueKey, 0)).format(
                                        "0,0"
                                    )}</div>

                                    <div className='text-gray-400 pb-2'> {item.description}</div>
                                
                            </div>   

                            {/* <dt>
                                <div className="absolute rounded-md bg-primary-500 p-3">
                                    <item.icon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                                    {item.name}
                                </p>
                            </dt> */}
                            
                            {/* <dd className="ml-16 flex justify-between items-end pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    {loading ? "Loading ..." : numeral(lodash.get(supplierStats, item.valueKey, 0)).format(
                                        "0,0"
                                    )}
                                </p>
                            </dd> */}
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}

export default DashboardStats