import { Modal, OfficeButton, toast } from 'components';
import * as Yup from 'yup';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useProduct } from 'pages/products/hooks/use-products';
import UpdateDistributionForm from './forms/update-distribution-form';
import { useFormik } from 'formik';
import { useUpdateProductDistribution } from './hooks/use-update-distribution';
import { ProductStatus } from '_graphql/__generated__/globalTypes';


type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const UpdateProductDistribution = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();
  const { loading: updating, updateProductDistribution } =
    useUpdateProductDistribution();

  const { product, loading } = useProduct({
    variables: {
      filter: seacrhParams?.id
        ? {
          id: {
            eq: seacrhParams?.id,
          },
        }
        : undefined,
    },
  });

  const { ...form } = useFormik({
    initialValues: {
      distribution: [] as any,
    },
    validationSchema: Yup.object().shape({
      distribution: Yup.array()
        .min(1, 'Choose at least one distribution channel')
        .required('Distribution is required'),
    }),
    onSubmit: async (values) => {
      await updateProductDistribution({
        variables: {
          input: {
            product_id: product?.id || '',
            distributionChannels: values.distribution,
            published:false,
            verified:false,
            personnel:"Supplier",
            reason:"N/A",
            status:ProductStatus.PENDING,
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateProductDistribution) {
            toast('Product Distribution Updated Successfully', 'success');
            form.resetForm();
            navigate({
              search(prev) {
                return {...prev, modal:undefined, id:undefined}
              },
            });
          } else {
            toast('Something went wrong', 'error');
          }
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='Update Product'
      description='Update Product Distribution'
      renderActions={() => (
        <div className='md:ml-10'>
          <OfficeButton
            onClick={form.handleSubmit}
            size='lg'
            width='auto'
            disabled={updating}
            type='submit'>
            {updating ? 'Updating...' : 'Update'}
          </OfficeButton>
        </div>
      )}>
      <UpdateDistributionForm form={form} product={product} loading={loading} />
    </Modal>
  );
};

export default UpdateProductDistribution;
