
import { GetSuppliers_getSuppliers } from '_graphql/queries/__generated__/GetSuppliers';
import { TColumn, TableColumn } from 'utils/types';
import image from "assets/noProduct.png"

export const SuppliersColumn: TableColumn<TColumn> = [
  {
    name: 'Supplier Name',
    accessor: 'title',
    render: (supplier: GetSuppliers_getSuppliers | null) => (
      <div className='flex items-center max-w-2xl break-all truncate text-xs'>
        <div className='h-10 w-10 flex-shrink-0 text-xs'>
          <img
            className='h-10 w-10 rounded-none'
            src={supplier?.business_details?.logo || image}
            alt=''
          />
        </div>
        <div className='ml-4 break-all flex-wrap text-xs'>
          <div className=' text-gray-900 truncate flex-wrap text-xs'>
            {supplier?.name.toLocaleUpperCase()}
          </div>
        </div>
      </div>
    ),
  },
  // {
  //   name: 'Actions',
  //   accessor: 'action',
  //   render: (category: GetProductCategory_getProductCategory | null) => (
  //     <div className='-ml-3'>
  //       <ActionButton
  //         action={'view'}
  //         tooltip='View Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //       <ActionButton
  //         action={'update'}
  //         tooltip='Update Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //       <ActionButton
  //         action={'remove'}
  //         tooltip='Delete Product'
  //         onClick={function (...val: any) {
  //           throw new Error('Function not implemented.');
  //         }}
  //       />
  //     </div>
  //   ),
  // },
];
