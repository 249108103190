import { useQuery } from "@apollo/client"
import { GetShopifyShop, GetShopifyShopVariables } from "_graphql/queries/__generated__/GetShopifyShop"
import { getShopifyShop } from "_graphql/queries/shop"

export const useShopifyShop = (variables?: GetShopifyShopVariables) => {
    const { data, ...rest } = useQuery<GetShopifyShop, GetShopifyShopVariables>(getShopifyShop, {
        variables
    })

    return {
        shopData: data?.getShopifyShop,
        ...rest
    }
}