import { Modal, OfficeButton, toast } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useProduct } from 'pages/products/hooks/use-products';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateProductMedia } from './hooks/use-update-media';
import UpdateMediaForm from './forms/update-media-form';
import { ProductStatus } from '_graphql/__generated__/globalTypes';


type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const UpdateProductMedia = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();
  const { loading: updating, updateProductMedia } = useUpdateProductMedia();

  const { product, loading } = useProduct({
    variables: {
      filter: seacrhParams?.id
        ? {
          id: {
            eq: seacrhParams?.id,
          },
        }
        : undefined,
    },
  });

  const { ...form } = useFormik({
    initialValues: {
      media: [],
      avatar: '',
    },
    validationSchema: Yup.object().shape({
      media: Yup.array()
        .min(2, 'Select at least two picture')
        .required('Media is required'),
    }),
    onSubmit: async (values) => {
      await updateProductMedia({
        variables: {
          input: {
            product_id: product?.id || '',
            media: values.media,
            avatar: values.avatar,
            published:false,
            verified:false,
            personnel:"Supplier",
            reason:"N/A",
            status:ProductStatus.PENDING,
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateProductMedia) {
            toast('Product Media Updated Successfully', 'success');
            form.resetForm();
            navigate({
              search(prev) {
                return {...prev, modal:undefined, id:undefined}
              },
            });
          } else {
            toast('Something went wrong', 'error');
          }
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });
  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='Update Product'
      description='Update Product Media'
      renderActions={() => (
        <div className='md:ml-10'>
          <OfficeButton
            onClick={form.handleSubmit}
            size='lg'
            width='auto'
            disabled={updating}
            type='submit'>
            {updating ? 'Updating...' : 'Update'}
          </OfficeButton>
        </div>
      )}>
      <UpdateMediaForm form={form} product={product} loading={loading} />
    </Modal>
  );
};

export default UpdateProductMedia;
