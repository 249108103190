import { QueryHookOptions, useQuery } from '@apollo/client';

import {
  GetDeliveryLocations,
  GetDeliveryLocationsVariables,
} from '_graphql/queries/__generated__/GetDeliveryLocations';
import { getDeliveryLocations } from '_graphql/queries/delivery';

export const useDeliveryLocation = (
  options?: QueryHookOptions<
    GetDeliveryLocations,
    GetDeliveryLocationsVariables
  >
) => {
  const { data, ...rest } = useQuery<
    GetDeliveryLocations,
    GetDeliveryLocationsVariables
  >(getDeliveryLocations, options);

  return {
    deliveryLocation: data?.deliveryLocations || [],
    totalCount: data?.deliveryLocationsLength || 0,
    ...rest,
  };
};
