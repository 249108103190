import { GetProduct_getProduct } from '_graphql/queries/__generated__/GetProduct';
import { RadioInput, TextInput } from 'components';
import { FormikProps } from 'formik';
import { FC } from 'react';

type Props = {
  form: FormikProps<any>;
  product: GetProduct_getProduct | null | undefined;
};

const UpdateInventoryForm: FC<Props> = ({ form, product }: Props) => {
  return (
    <form className='w-full py-5'>
      <div className='w-full'>
        <div className='w-full mb-5'>
          <RadioInput
            id={'availabiltyRadio'}
            label={'Availablity'}
            verticalAlign={true}
            options={[
              {
                description: '',
                label: 'Limited Availablity',
                value: 'limited',
              },
              {
                description: '',
                label: 'Always Available',
                value: 'unlimited',
              },
            ]}
            {...form}
          />
        </div>
        {form.values.availabiltyRadio === 'limited' && (
          <div className='col-span-full'>
            <TextInput
              description='Product will be set to "out of stock" when quantity reaches 0.'
              id={'qty'}
              label='Stock count (Quantity)'
              placeholder='eg. 10'
              {...form}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default UpdateInventoryForm;
