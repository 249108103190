import { Modal, OfficeButton, toast } from 'components';
import { useFormik } from 'formik';
import TermForm from './forms/term-form';
import * as Yup from 'yup';
import { useMutation, useReactiveVar } from '@apollo/client';
import { CreateTerm, CreateTermVariables } from '_graphql/mutation/__generated__/CreateTerm';
import { createTerm } from '_graphql/mutation/terms';
import { currentUserVar, setSupplierSetupConfig } from '_graphql/cache/auth';

type Props = {
    open: boolean;
    setOpen: (val: any) => void;
    refetch?: () => void;
}

const CreateTermsContainer = ({
    open,
    setOpen,
}: Props) => {
    const currentUser = useReactiveVar(currentUserVar);
    const [initiate, { loading }] = useMutation<CreateTerm, CreateTermVariables>(createTerm, {
        refetchQueries: ["GetTerms"]
    })
    const form = useFormik<any>({
        initialValues: {
            name: "",
            description: "",
            settlementType: "",
            returnPeriod: "",
            settlementCurrency: "",
            shipTo: "",
            estimatedDeliveryTime: "",
            settlementValue: "",
            estimatedDeliveryTimeUnit: "",
            settlementPeriod: "",
            returnPolicy: "",
            cancellationPolicy: "",
            shippingPolicy: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            description: Yup.string().required("Description is required"),
            settlementType: Yup.string().required("Settlement Type is required"),
            returnPeriod: Yup.string().required("Return Period is required"),
            settlementCurrency: Yup.string().required("Currency is required"),
            shipTo: Yup.string().required("Ship To is required"),
            estimatedDeliveryTime: Yup.string().required("Estimated Delivery Time is required"),
            settlementPeriod: Yup.string().required("Settlement Period is required"),
            settlementValue: Yup.string().required("Settlement Value is required"),
            returnPolicy: Yup.string().required("Return Policy is required"),
            cancellationPolicy: Yup.string().required("Cancelation Policy is required"),
            shippingPolicy: Yup.string().required("Shipping Policy is required"),
        }),
        onSubmit: async (values) => {
            initiate({
                variables: {
                    input: {
                        ...values,
                        supplier_id: currentUser?.id,
                    }
                }
            }).then(({ data }) => {
                if (data?.createTerm) {
                    setOpen(undefined);
                    setSupplierSetupConfig({
                        hasTerms: true,
                    })
                    toast("Term created successfully", "success");
                }
            }).catch((err) => {
                toast(err.message, "error");
            })
        }
    });
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            // size='5xl'
            title="Create Terms"
            description="Create a new term"
            renderActions={() => (
                <div className='ml-2'>
                    <OfficeButton disabled={!form.isValid || loading} onClick={form.handleSubmit} >
                        {loading ? "Creating ..." : " Create Term"}
                    </OfficeButton>
                </div>
            )}
        >
            <TermForm form={form} />
        </Modal>
    )
}

export default CreateTermsContainer