import { gql } from "@apollo/client";


export const getShop = gql`
query GetShop($filter: ShopFilter!) {
  getShop(filter: $filter) {
    id
    name
    description
    contactEmail
    email
    currency
    slug
    primaryDomain
    sokoWorldDomain
    customDomains
    hasBeenSetup
    isActive
    shopTags
    createdAt
    updatedAt
    avatar
    country
    coverImage
    deliveryOption
    deliveryAgent
    paymentOptions
    url
    shortUrl
    phone
    ownerId
    supplierId
    shopId
  }
}
`;

export const getShopifyShop = gql`
query GetShopifyShop($input: FetchShopifyShopInput) {
  getShopifyShop(input: $input) {
    id
    shop
    redirect_url
    supplier_id
    shop_currency
    country_code
    country_name
    created_at
    updated_at
    shop_payment_method {
      id
      shopify_id
      supplier_id
      name
      email
      phone
      provider
      secure_code
      created_at
      updated_at
    }
    shop_reselling_products {
      product
    }
    products {
      id
      product_id
      image
      title
      shop
      handle
      body_html
      shopify_id
      status
      created_at
      updated_at
    }
  }
}
`