import { useAppURL } from '_graphql/cache/auth'
import { GetCarts_rows } from '_graphql/queries/__generated__/GetCarts'
import { TableComponent, TableLoader, Paginator } from 'components'
import { usePagination } from 'hooks'
import _ from 'lodash'
import numeral from 'numeral'
import { useCarts } from 'pages/orders/hooks/use-carts'

type Props = {}

const OrdersTab = (props: Props) => {
    const { params } = useAppURL()
    const { offset, limit, setPage } = usePagination();
    const { orders, refetch, loading, count } = useCarts({
        variables: {
            filter: {
                id: {
                    eq: params?.customer_id
                }
            },
            pagination: {
                limit: limit,
                offset: offset,
            },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });
    return (
        <TableComponent
            data={orders}
            title={"Orders"}
            refetch={refetch}
            columns={[
                {
                    name: "Cart Number",
                    accessor: "cart_number",
                },
                {
                    name: "Payment Status",
                    accessor: "payment_status",
                },
                {
                    name: "Payment Type",
                    accessor: "payment_type",
                    render: (value: GetCarts_rows) => _.startCase(value?.payment_type) || "N/A"
                },
                {
                    name: "Order Total",
                    accessor: "order_total",
                    render: (value: GetCarts_rows) => numeral(value?.order_total).format("#,#.##")
                },
            ]}
            hasSearch
            loading={loading}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
            pagination={() => (
                <Paginator
                    offset={offset}
                    limit={limit}
                    totalItems={count}
                    currentSize={orders.length}
                    setOffset={setPage}
                />
            )}
        />
    )
}

export default OrdersTab