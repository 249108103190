import { FC } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { Loader, OfficeButton } from "components";
import { useTerm } from "pages/terms/hooks/use-terms";



const TermsAcceptancePage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate();
  const { term, loading: fetchingTerm } = useTerm({
    variables: {
      filter: {
        id: {
          eq: search.term
        }
      }
    }
  })

  if (fetchingTerm) return (
    <div className="mx-auto w-full max-w-lg lg:w-[1200px] ">
      <Loader />
    </div>
  )


  return (
    <div className="w-full h-full flex-1 max-w-5xl lg:w-[1200px]  ">
      <div className="w-full h-full flex-1  ">
        <div className="px-4 flex  justify-between items-end sm:px-0">
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">Contract Information</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              {term?.name ?? "Contract Name"}
            </p>
          </div>
          <div className="space-x-4">
            <OfficeButton variant="tertiary" size="sm">
              Decline
            </OfficeButton>
            <OfficeButton onClick={() => navigate({
              to: "./join",
              search(prev) {
                return {
                  ...prev,
                  term: search.term
                }
              },
            })} variant="primary" size="sm">
              Accept
            </OfficeButton>
          </div>
        </div>
        <div className="mt-6 flex-1 w-full h-full pb-11  overflow-y-auto">
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Settlement Type</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{term?.settlementType}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Settlement Value</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{term?.settlementValue}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Settlement Period</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{term?.settlementPeriod}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Salary expectation</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{term?.settlementType}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2" dangerouslySetInnerHTML={{ __html: term?.description || '' }}>

              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Return Policy</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2" dangerouslySetInnerHTML={{ __html: term?.returnPolicy || '' }}>

              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Cancellation Policy</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2" dangerouslySetInnerHTML={{ __html: term?.cancellationPolicy || '' }}>

              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div >
  );
};

export default TermsAcceptancePage;
