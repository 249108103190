import { Paginator, TableComponent, TableLoader } from 'components'
import { usePagination } from 'hooks';
import { FC } from 'react'
import { useWalletHistory } from '../hooks/use-wallet';
import { WalletHistory_walletHistory } from '_graphql/queries/__generated__/WalletHistory';
import _ from 'lodash';
import { useAppSearch, useCurrentUser } from '_graphql/cache/auth';
import numeral from 'numeral';


type Props = {}

const TransactionsTab: FC<Props> = (props) => {
    const { offset, limit, setPage } = usePagination();
    const searchParams = useAppSearch()
    const currentUser = useCurrentUser()
    const { history, loading, refetch, count } = useWalletHistory({
        variables: {
            filter: {
                supplier_id: {
                    eq: currentUser?.id
                },
                reference:searchParams?.search? {
                    like:`${searchParams.search}`
                  }:undefined
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    })
    return (
        <TableComponent
            title={'Transactions'}
            columns={[
                {
                    accessor: "reference",
                    name: "Transaction Reference"
                },
                {
                    accessor: "transaction_type",
                    name: "Transaction Type",
                    render: (value: WalletHistory_walletHistory) => _.startCase(value?.transaction_type) || "N/A"
                },
                {
                    accessor: "previous_balance",
                    name: "Previous Balance",
                    render: (value: WalletHistory_walletHistory) => `${numeral(value?.previous_balance).format("0,0.00")}`
                },
                {
                    accessor: "amount",
                    name: "Amount",
                    render: (value: WalletHistory_walletHistory) => `${numeral(value?.amount).format("0,0.00")}`
                },
                {
                    accessor: "status",
                    name: "Status"
                },
                {
                    accessor: "created_at",
                    name: "Created At",
                    // render: (value: WalletHistory_walletHistory) => 
                },
            ]}
            hasSearch
            data={history}
            loading={loading}
            refetch={refetch}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1, 1]} />}
            emptyDescription='No Payout Requests found'
            pagination={() => <Paginator
                offset={offset}
                limit={limit}
                totalItems={count}
                currentSize={history.length}
                setOffset={setPage} />}
        />
    )
}

export default TransactionsTab