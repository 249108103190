import { TextInput, toast } from "components";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import * as Yup from "yup";
import { GetShop_getShop } from "_graphql/queries/__generated__/GetShop";
import { FC } from "react";
import { UPDATE_SUPPLIER_SHOP_SLUG } from "_graphql/mutation/shop";
import { useMutation } from "@apollo/client"
import { UpdateSupplierShopSlug,UpdateSupplierShopSlugVariables } from "_graphql/mutation/__generated__/UpdateSupplierShopSlug";
import { useCurrentUser } from "_graphql/cache/auth";
import { useShop } from '../hooks/use-shop';

type Props = {
  shop: GetShop_getShop | null | undefined;
  setOpen: (val: boolean) => void;
};

const EditSlugModal: FC<Props & {ref?: any}> = forwardRef(({ shop,
  setOpen, }, ref) => {
    const currentUser = useCurrentUser();

    const { refetch } = useShop({
      filter:{
        id:{
          eq:shop?.id
        }
      }
        })
  
  const [update] = useMutation<
  UpdateSupplierShopSlug,UpdateSupplierShopSlugVariables>(UPDATE_SUPPLIER_SHOP_SLUG);

  const  editSlugForm = useFormik({
    initialValues: {
      slug: shop?.slug,
      
    },
    validationSchema: Yup.object().shape({
      slug: Yup.string().required("Slug is required"),
     
    }),
    onSubmit: async (values) => {
      update({

        variables:{
          // input:{
            // shopId:shop?.id,
            data: {
            slug: values.slug!,
            supplier_id:currentUser?.id,

            
          // }
          }
        }
      }).then(({ data }) => {
        console.log(data);
        if (data?.updateSupplierShopSlug) {
          toast("Shop slug successfully updated", "success");
          setOpen(false); 
          refetch();
        }
      });
    },
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: editSlugForm.handleSubmit,
  }));

  return ( 
    <div className=" mx-16">
       <form className="md:col-span-2" onSubmit={editSlugForm.handleSubmit}>
          <div className="grid grid-cols-1 my-28 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            

            {/* <div className="sm:col-span-3">
              <TextInput
                id={"name"}
                label=" Name"
                {...editSlugForm}
              />
            </div>

            <div className="sm:col-span-3">
              <TextInput
                id={"number"}
                label="Phone Number"
                {...editSlugForm}
              />
            </div> */}

            <div className="col-span-full">
                            <TextInput id={'slug'} placeholder='eg. mersamgroceries' label='Slug' {...editSlugForm} />
                        </div>
                        
            {/* <div className="col-span-full">
                            <TextArea id={'description'} placeholder='eg. We deal in organic and inorganic foods.' label='Description' {...editSlugForm} />
                        </div> */}

                        
          </div>

          {/* <div className="mt-8 flex">
            <OfficeButton
              disabled={!personalInfoForm.isValid || loading}
              onClick={personalInfoForm.handleSubmit}
            >
              {loading ? "Saving.." : " Save"}
            </OfficeButton>
          </div> */}
        </form>
    </div>
   );
})
 
export default EditSlugModal;