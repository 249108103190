import { useQuery } from "@apollo/client"
import { LoadShopifyProducts, LoadShopifyProductsVariables } from "_graphql/queries/__generated__/LoadShopifyProducts"
import { loadShoifyProducts } from "_graphql/queries/products"

export const useLoadShopifyProducts = (variables?: LoadShopifyProductsVariables) => {
    const { data, ...rest } = useQuery<LoadShopifyProducts, LoadShopifyProductsVariables>(loadShoifyProducts, {
        variables
    })

    return {
        shopifyProducts: data?.loadShopifyProducts ?? [],
        ...rest
    }
}