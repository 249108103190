import { GetCustomer_getCustomer } from "_graphql/queries/__generated__/GetCustomer";
import _ from "lodash";
import { FC } from "react";

type Props = {
    customer: GetCustomer_getCustomer | null | undefined
};

const CustomerView: FC<Props> = ({ customer }) => {

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Customer Information</span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {customer?.name || "N/A"}
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Email
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {customer?.email || "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className=" gap-6 mt-2">
                        <div>
                            <span className="block text-sm font-light text-gray-700">
                                Phone
                            </span>
                            <div className="mt-1 block w-full sm:text-sm">
                                {customer?.phone || "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerView;
