import { FC } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import { createSupplier } from "_graphql/mutation/auth";
import { OfficeButton } from "components";
import { CreateSupplier, CreateSupplierVariables } from "_graphql/mutation/__generated__/CreateSupplier";
import { useApp } from "hooks";
import { SupplierType } from "_graphql/__generated__/globalTypes";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "utils";
import { CheckCircleIcon } from "@heroicons/react/24/solid";


const AccountTypes = [
  { value: SupplierType.BASIC, title: 'Basic Account', description: 'Provide products as a wholesaler' },
  { value: SupplierType.ADVANCED, title: 'Advanced Account', description: 'Provide products as a wholesaler with extra functionalities' },
]


const RegisterPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate();
  const [login, { loading }] = useMutation<CreateSupplier, CreateSupplierVariables>(createSupplier);
  const { logo, name } = useApp()
  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      country: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      supplier_type: SupplierType.BASIC,
    },
    validationSchema: Yup.object().shape({
      country: Yup.string().required("Country is required"),
      supplier_type: Yup.string().required("Supplier type is required"),
      name: Yup.string().required("Full Name is required"),
      phone: Yup.string().required("Phone Number is required"),
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().min(8, "Password should be at least 8 character long with an uppercase character, a lowercase character, with a special character and a digit.").required("Password is required"),
    }),
    onSubmit: async (values) => {
      await login({
        variables: {
          input: {
            country: values.country,
            email: values.email,
            name: values.name,
            phone: values.phone,
            onboardingType: "SUPPLIER",
            password: values.password,
            supplier_type: values.supplier_type
          }
        },
      }).then(({ data }) => {
        if (data?.createSupplier?.created) {
          if (data.createSupplier?.verification_token) {
            return navigate({
              replace: true,
              to: "/verify-otp",
              search(prev) {
                return {
                  ...prev,
                  token: data.createSupplier?.verification_token,
                  email: values.email,
                };
              },
            });
          } else {
            return navigate({
              replace: true,
              to: search?.redirect ?? "/",
            });
          }
        }
      });
    },
  });

  return (
    <div className="mx-auto w-full md:max-w-3xl max-w-sm ">
      <div>
        <div className="flex items-start flex-col">
          <div className="w-full justify-start py-4 flex align-middle flex-shrink-0">
            <img
              className="h-16 w-16 rounded-full"
              src={logo}
              alt="Duke Supplier's Dashboard"
            />
          </div>
          <div className="">
            <span className="text-lg text-primary-600 font-mono">
              {name} Supplier's  Dashboard
            </span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Sign up for an account
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Or{' '}
          <Link
            to="/sign-in"
            className="font-medium text-primary-600 hover:text-primary-500"
          >
            sign in to your account
          </Link>
        </p>
        {search?.from === "reset_success" && (
          <div className="bg-teal-100 px-2 rounded-md py-1 mt-3">
            <p className="text-sm text-teal-600">
              Password Changed SuccessFully. Login to access the {name} Supplier's Dashboard
            </p>
          </div>
        )}

      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6 grid gap-4 md:grid-cols-2">
            <div className="md:col-span-2">
              <RadioGroup value={form.values.supplier_type} onChange={form.handleChange("supplier_type")}>
                <RadioGroup.Label className="text-sm font-medium text-gray-700">Supplier Type</RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {AccountTypes.map((serviceClass) => (
                    <RadioGroup.Option
                      key={serviceClass.value}
                      value={serviceClass.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                          'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                {serviceClass.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                {serviceClass.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600')}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-primary-500' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <div className="md:col-span-2">
              <TextInput
                id="name"
                label="Full Name"
                type="text"
                placeholder="e.g.Emmanuel Baidoo"
                {...form}
              />
            </div>
            <div>
              <SelectInput
                id="country"
                label="Country"
                options={[
                  { label: "--- Select ---", value: "" },
                  { label: "Ghana", value: "GH" },
                  { label: "Nigeria", value: "NG" },
                  { label: "South Africa", value: "ZA" },
                ]}
                {...form}
              />
            </div>
            <div>
              <TextInput
                id="phone"
                label="Phone Number"
                type="text"
                placeholder="e.g. 050639153"
                {...form}
              />
            </div>
            <div>
              <TextInput
                id="email"
                label="Email address"
                type="email"
                placeholder="e.g. ebaidoo79@gmail.com"
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder="e.g.  **************"
                {...form}
              />
            </div>
            <div>
              <OfficeButton
                width="full"
                size="lg"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Creating account..." : "Create Account"}
              </OfficeButton>
            </div>
          </form>
        </div>
      </div >
    </div >
  );
};

export default RegisterPage;
