import { useQuery } from "@apollo/client";
import { GetShop, GetShopVariables } from "_graphql/queries/__generated__/GetShop";
import { getShop } from "_graphql/queries/shop";


export const useShop = (variables: GetShopVariables) => {
    const { data, ...rest } = useQuery<GetShop, GetShopVariables>(getShop, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    return {
        shop: data?.getShop,
        ...rest
    };
};