import { gql } from '@apollo/client';

export const getDeliveryLocations = gql`
  query GetDeliveryLocations($filter: DeliveryFilter, $pagination: Pagination) {
    deliveryLocations(filter: $filter, pagination: $pagination) {
      id
      location
      price
      country
      remarks
      isPayOnline
    }
    deliveryLocationsLength(filter: $filter)
  }
`;
