import { gql } from "@apollo/client";

export const getTerms = gql`
  query GetTerms($filter: TermsFilter, $pagination: Pagination) {
    getTermsLength(filter: $filter)
    getTerms(filter: $filter, pagination: $pagination) {
      id
      name
      description
      settlementType
      settlementValue
      settlementCurrency
      settlementPeriod
      returnPeriod
      returnPolicy
      shippingPolicy
      cancellationPolicy
      shipTo
      shippingLocations
      estimatedDeliveryTime
      estimatedDeliveryTimeUnit
    }
  }
`;

export const getTerm = gql`
  query GetTerm($filter: TermsFilter) {
    getTerm(filter: $filter) {
      id
      name
      description
      settlementType
      settlementValue
      settlementCurrency
      settlementPeriod
      returnPeriod
      returnPolicy
      shippingPolicy
      cancellationPolicy
      shipTo
      shippingLocations
      estimatedDeliveryTime
      estimatedDeliveryTimeUnit
    }
  }
`;
