import { useMutation } from '@apollo/client';
import {
  UpdateProductMedia,
  UpdateProductMediaVariables,
} from '_graphql/mutation/__generated__/UpdateProductMedia';
import { UPDATE_PRODUCT_MEDIA } from '_graphql/mutation/products';

export const useUpdateProductMedia = (
  variables?: UpdateProductMediaVariables
) => {
  const [updateProductMedia, { ...rest }] = useMutation<
    UpdateProductMedia,
    UpdateProductMediaVariables
  >(UPDATE_PRODUCT_MEDIA, {
    variables,
    refetchQueries: ['GetProduct'],
  });
  return {
    ...rest,
    updateProductMedia,
  };
};
