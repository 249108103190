import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { useCurrentUser } from '_graphql/cache/auth';
import { useApp } from "hooks";

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  }
}>

const SetupLayout: FC = () => {
  const { name, logo } = useApp();
  const search = useSearch<AuthLocationGenerics>()
  const currentUser = useCurrentUser();
  if (currentUser?.setup_complete) {
    return <Navigate
      to={search?.redirect ?? "/"}
      replace
    />
  }
  return (
    <div className="h-screen">
      <div className="min-h-full flex flex-row-reverse">
        <div className="hidden md:w-1/2 xl:w-2/3 lg:block relative md:h-screen flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require("assets/setup.jpeg")}
            alt=""
          />
          <div className='flex absolute items-center inset-0 h-auto bg-slate-500/70 justify-center space-x-4 flex-row'>
            <div className='w-auto justify-start py-4 flex align-middle flex-shrink-0'>
              <img
                className='h-16 w-16 rounded-full'
                src={logo}
                alt="Duke Supplier's Dashboard"
              />
            </div>
            <div className='flex flex-col'>
              <span className='text-lg text-primary-600 font-mono'>
                {name} Supplier's Dashboard
              </span>
              <span className='text-lg text-gray-300 font-mono'>
                Business Setup
              </span>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 xl flex md:h-screen overflow-y-scroll no-scrollbar flex-col justify-start py-6 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative min-w-1/2">
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupLayout;