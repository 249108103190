import { Line } from 'components';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react'
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { TEST_DATA } from 'utils';
import { DATE_FORMAT } from 'utils/regex';
import { useOrdersPerDay } from './hooks/use-summary';
import { useCurrentUser } from '_graphql/cache/auth';

type Props = {}

const OrderPerDaySection = (props: Props) => {
    const searchParams = useSearch<LocationGenerics>();
    const currentUser = useCurrentUser()
    
    const { ordersPerDay } = useOrdersPerDay({
        from: searchParams.fromDate as string,
        to: searchParams.toDate as string,
        id: currentUser.id || '',
    })
    const keyedGraphData = useMemo(
        () => _.keyBy(ordersPerDay, e => moment(e.date).format(DATE_FORMAT)),
        [ordersPerDay]
    );

    const data = useMemo(() => _.times(
        moment(searchParams.toDate, DATE_FORMAT).diff(
            moment(searchParams.fromDate, DATE_FORMAT),
            "days"
        ) + 1,
        (idx) => {
            const currentDate = moment(searchParams.fromDate)
                .add(idx, "days")
                .format(DATE_FORMAT);
            return {
                value: _.get(keyedGraphData, currentDate),
                day: currentDate,
            };
        }
    ), [keyedGraphData]);


    return (
        <>
            {searchParams?.view !== "day" && (
                <div className="col-span-2">
                    <h3 className="text-md font-medium leading-6 text-gray-900">
                        Orders Per Day
                    </h3>
                    <div className="mt-3 overflow-hidden rounded-lg bg-white shadow xl:h-96">
                        <Line
                            data={data}
                            fromDate={searchParams.fromDate as string}
                            toDate={searchParams.toDate as string}
                            xLabel="Dates"
                            yLabel="Count"
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default OrderPerDaySection