import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  GetUser,
  GetUserVariables,
} from "_graphql/queries/__generated__/GetUser";
import { getUser } from "_graphql/queries/users";

export const useUser = (
  options?: QueryHookOptions<GetUser, GetUserVariables>
) => {
  const { data, ...rest } = useQuery<GetUser, GetUserVariables>(
    getUser,
    options
  );

  return {
    user: data?.getUser,
    ...rest,
  };
};
