import {
  OfficeButton,
  OfficeHeader,
  Paginator,
  TableComponent,
} from 'components';
import { FC } from 'react';
import { useCategories } from './hooks/use-categories';
import { usePagination } from 'hooks';
import { CategoryColumn } from './table-columns';
import { useAppSearch, useCurrentUser } from '_graphql/cache/auth';
import { useUrlState } from 'utils';
import CreateCategoryContainer from './create';

type Props = {};

const CategoryPage: FC<Props> = () => {
  const currentUser = useCurrentUser()
  const [open, setOpen] = useUrlState("modal");
  const searchParams = useAppSearch()
  const { offset, limit, setPage } = usePagination();
  const { categories, totalCount, refetch, loading } = useCategories({
    variables: {
      pagination: {
        limit,
        offset,
      },
      filter: {
        supplier_id: {
          eq: currentUser?.id || "",
        },
        name:searchParams?.search? {
          like:`${searchParams.search}`
        }:undefined
      }
    }
  });

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader renderActions={() => <OfficeButton variant={'minimal'} onClick={() => setOpen("create")}>Add Category(ies)</OfficeButton>} />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={'Category'}
            columns={CategoryColumn}
            data={categories}
            hasSearch
            defaultView='grid'
            renderGridItem={(category) => (
              <div key={category?.id} className="">
                <div className="flex rounded-md bg-white shadow-lg p-3  justify-between">
                  <div className="flex flex-col ">
                    <div className="flex-shrink-0">
                      <img
                        className="h-48 w-full object-cover rounded-full"
                        src={
                          category?.avatar && category?.avatar !== "string"
                            ? category?.avatar
                            : "https://d3ug0vbiixnxyq.cloudfront.net/utility/qejpc8bkdzgvlfnghc9no-165859213609.jpeg"
                        }
                        alt={category?.name}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium leading-5 text-gray-900">
                        {category?.name}
                      </div>
                      <div className="text-sm leading-5 text-ellipsis text-gray-500">
                        {category?.description?.substring(0, 30)}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            refetch={refetch}
            loading={loading}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={totalCount}
                currentSize={categories.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>

      {/*  */}
      <CreateCategoryContainer open={open === "create"} setOpen={setOpen} />
      {/*  */}
    </main>
  );
};

export default CategoryPage;
