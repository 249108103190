import { FC, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import { joinSupplierNetwork, registerReseller } from "_graphql/mutation/auth";
import { Loader, OfficeButton } from "components";
import { useUser } from "pages/resellers/hooks/use-user";
import { Condition } from "_graphql/__generated__/globalTypes";
import { AccountRegister, AccountRegisterVariables } from "_graphql/mutation/__generated__/AccountRegister";
import { JoinSupplierResellerNetwork, JoinSupplierResellerNetworkVariables } from "_graphql/mutation/__generated__/JoinSupplierResellerNetwork";
import { useApp } from "hooks";



const RegisterPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { logo, name } = useApp()
  const [create, { loading: creating }] = useMutation<AccountRegister, AccountRegisterVariables>(registerReseller);
  const [join, { loading: joining }] = useMutation<JoinSupplierResellerNetwork, JoinSupplierResellerNetworkVariables>(joinSupplierNetwork);
  const { user, loading: fetchingUser } = useUser({
    variables: {
      filter: {
        email: {
          eq: search?.email || "LOL"
        }
      },
      condition: Condition.and
    },
    fetchPolicy: "network-only"
  });




  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      country: search?.country || "",
      name: "",
      phone: "",
      email: search?.email || "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      country: Yup.string().required("Country is required"),
      name: Yup.string().required("Full Name is required"),
      phone: Yup.string().required("Phone Number is required"),
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().min(8, "Password should be at least 8 character long with an uppercase character, a lowercase character, with a special character and a digit.").required("Password is required"),
    }),
    onSubmit: async (values) => {
      await create({
        variables: {
          input: {
            country: values.country,
            email: values.email,
            name: values.name,
            phone: values.phone,
            password: values.password,
          }
        },
      }).then(({ data }) => {
        if (data?.accountRegister?.user?.id) {
          join({
            variables: {
              resellerId: data.accountRegister.user.id,
              supplierId: search?.supplier || "",
              termsId: search?.term || "",
            }
          }).then(({ data }) => {
            if (data?.joinSupplierResellerNetwork) {
              return navigate({
                replace: true,
                to: "/invite/completed",
                search(prev) {
                  return {
                    ...prev,
                    email: values.name,
                    message: "Your account has been created successfully. You can now login to access the Duke Supplier's StoreFront and start selling"
                  };
                },
              });
            }
          })
        }
      });
    },
  });

  useEffect(() => {
    if (user) {
      join({
        variables: {
          resellerId: user?.id || "",
          supplierId: search?.supplier || "",
          termsId: search?.term || "",
        }
      }).then(({ data }) => {
        if (data?.joinSupplierResellerNetwork) {
          return navigate({
            replace: true,
            to: "/invite/completed",
            search(prev) {
              return {
                ...prev,
                email: user.name,
                message: "You have successfully joined the Duke Supplier's Network. You can now login to access the Duke Supplier's StoreFront and start selling"
              };
            },
          });
        }
      })
    }
  }, [user, search?.supplier, search?.term, join, navigate]);

  if (fetchingUser) return (
    <div className="mx-auto w-full max-w-lg lg:w-[1200px] ">
      <Loader />
    </div>
  )

  return (
    <div className="mx-auto w-full max-w-lg lg:w-[1200px] ">
      <div>
        <div className="flex items-start flex-col">
          <div className="w-full justify-start py-4 flex align-middle flex-shrink-0">
            <img
              className="h-16 w-16 rounded-full"
              src={logo}
              alt="Duke Supplier's Dashboard"
            />
          </div>
          <div className="">
            <span className="text-lg text-primary-600 font-mono">
              {name} Reseller's Network
            </span>
          </div>
        </div>


        {user && (
          <div className="bg-teal-100 px-2 rounded-md py-1 mt-3">
            <p className="text-sm text-teal-600">
              You already have an account with us. Login to access the {name} Supplier's Dashboard
            </p>
          </div>
        )}

        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Create your Reseller account
        </h2>

      </div>
      {!user && (
        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <SelectInput
                  id="country"
                  label="Country"
                  disabled={!!search?.country}
                  options={[
                    { label: "--- Select ---", value: "" },
                    { label: "Ghana", value: "GH" },
                    { label: "Nigeria", value: "NG" },
                    { label: "South Africa", value: "ZA" },
                  ]}
                  {...form}
                />
              </div>
              <div>
                <TextInput
                  id="name"
                  label="Full Name"
                  type="text"
                  placeholder="e.g.Emmanuel Baidoo"
                  {...form}
                />
              </div>
              <div>
                <TextInput
                  id="phone"
                  label="Phone Number"
                  type="text"
                  placeholder="e.g. 050639153"
                  {...form}
                />
              </div>
              <div>
                <TextInput
                  id="email"
                  label="Email address"
                  type="email"
                  disabled
                  placeholder="e.g. ebaidoo79@gmail.com"
                  {...form}
                />
              </div>

              <div className="space-y-1">
                <TextInput
                  id="password"
                  label="Password"
                  type="password"
                  placeholder="e.g.  **************"
                  {...form}
                />
              </div>



              <div>
                <OfficeButton
                  width="full"
                  size="lg"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={creating || joining}
                >
                  {creating || joining ? "Creating account..." : "Create Account"}
                </OfficeButton>
              </div>
            </form>
          </div>
        </div >
      )}
    </div >
  );
};

export default RegisterPage;
