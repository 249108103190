import { FC, useEffect } from "react";
import TableHeaderComponent from "./table-header";
import { useUrlState } from "../../utils";
import TableBodyComponent from "./table-body";
import { TableColumn, TColumn } from "../../utils/types";
import TableGridBodyComponent from "./table-grid-body";
import TableEmptyComponent from "./table-empty";
import { Show } from "components/core";

interface TableComponentProps<TData = any> {
  title: string;
  emptyDescription?: string;
  columns: TableColumn<TColumn>;
  data: TData[];
  loading?: boolean;
  isRefetching?: boolean;
  renderColumns?: FC<TData>;
  renderItem?: FC<TData>;
  renderGridItem?: FC<TData>;
  renderFilter?: FC<{
    filterOpen: boolean;
    setFilterOpen: (val: boolean) => void;
  }>;
  renderExport?: FC<{
    exportOpen: boolean;
    setExportOpen: (val: boolean) => void;
  }>;
  renderLoader?: FC;
  renderGridLoader?: FC;
  renderHeaderItems?: FC;
  refetch: () => void;
  hasSearch?: boolean;
  defaultView?: "grid" | "list";
  pagination?: FC;
  showHeader?: boolean;
}

const Table: FC<TableComponentProps> = ({
  title,
  renderFilter,
  renderExport,
  renderGridItem,
  renderItem,
  refetch,
  renderHeaderItems,
  hasSearch,
  isRefetching,
  defaultView = "list",
  data,
  loading,
  renderLoader,
  renderGridLoader,
  columns,
  pagination,
  emptyDescription,
  showHeader = true,
}) => {
  const [viewType, setViewType] = useUrlState("viewType");
  const hasGridMode = !!renderGridItem;
  const isEmpty = !loading && (data?.length || 0) === 0;

  useEffect(() => {
    if (defaultView) {
      setViewType(defaultView);
    }
  }, []);

  return (
    <div className="space-y-6 flex-1 flex flex-col overflow-y-hidden min-w-full">
      <div className="px-4 sm:px-6">
        <TableHeaderComponent
          title={title}
          renderFilter={renderFilter}
          renderExport={renderExport}
          // gridable={hasGridMode}
          listable={true}
          refetch={refetch}
          renderHeaderItems={renderHeaderItems}
          hasSearch={hasSearch}
          loading={isRefetching}
          defaultView={defaultView}
        />
      </div>

      {viewType === "grid" && hasGridMode ? (
        <TableGridBodyComponent
          data={data}
          loading={loading}
          renderLoader={renderGridLoader}
          renderItem={renderGridItem}
        />
      ) : (
        <TableBodyComponent
          data={data}
          loading={loading}
          renderLoader={renderLoader}
          isEmpty={isEmpty}
          renderItem={renderItem}
          showHeader={showHeader}
          columns={columns}
        />
      )}
      <Show if={isEmpty}>
        <TableEmptyComponent
          title={title}
          emptyDescription={emptyDescription}
        />
      </Show>
      <div className="px-4 sm:px-6">{pagination?.({})}</div>
    </div>
  );
};

export default Table;
