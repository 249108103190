import { FC, useEffect, useState } from "react";
import { StarIcon, CheckIcon } from "@heroicons/react/24/solid";
import { dispatchAction, useUrlState } from "utils";
import { useAppNaviagte } from "_graphql/cache/auth";


type Props = {
  product: any;
  productselected?: boolean;
  onSelectionChange: (productId: string, selected: boolean) => void;
  onViewDetails: () => void;
};

const ProductCard: FC<Props> = ({
  product,
  productselected = false,
  onSelectionChange,
  onViewDetails,
}) => {
  const [modal, setModal] = useUrlState("modal");
  
  const isSelected=productselected;

  const toggleselected = () => {
    onSelectionChange(product?.sku, !isSelected);
  };
  
  function truncateAndTitleCase(title: string, maxLength = 25) {
    if (!title) return "";
    const truncatedTitle =
      title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
    const titleCase = truncatedTitle
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
    return titleCase;
  }
 

  return (
    <div key={product?.id}>
      <div className="flex flex-col space-y-2 w-72 px-3 py-2 border rounded-lg ">
        <div className="relative">
          {isSelected ? (
            <div className="absolute z-10  w-4 h-4 bg-primary-500  rounded-sm flex justify-center items-center">
              <CheckIcon className="w-3 h-2 font-bold text-white " />
            </div>
          ) : (
            <div></div>
          )}

          <img
            src={product?.avatar}
            className="object-cover w-full h-48"
            alt=""
          />
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-gray-500 font-normal text-sm w-48">
            {truncateAndTitleCase(product?.title)}
          </div>
          {/* <div className="flex flex-row space-x-1">
            <div>
              <StarIcon className="text-yellow-500 text-center items-center m-auto mt-1 w-4 h-4" />
            </div>
            <div className="text-yellow-500">{product?.overalReview}</div>
          </div> */}
          {/* <div className="flex flex-row space-x-1">
            <div>
              <BuildingStorefrontIcon className="w-3 mt-1 h-3 text-red-400" />
            </div>
            <div className="text-xs">
              {product?.supplier?.business_details?.business_name}
            </div>
          </div> */}
        </div>
        <div className="text-black font-bold">
          {product?.currency} {product?.price}
        </div>
        <div className="bg-red-50 text-red-500 w-16 text-[10px] font-light text-center items-center border rounded-md">
          {product?.inhouse == true ? (
            <div>In house</div>
          ) : (
            <div>Outsourced</div>
          )}
        </div>
        <div className="flex flex-row space-x-2 w-full">
          <div className="w-1/2  " onClick={toggleselected}>
            {isSelected ? (
              <div className="text-center items-center  py-1 cursor-pointer text-black text-sm font-normal border rounded-lg bg-green-200">
                Selected
              </div>
            ) : (
              <div className="text-center items-center  py-1 cursor-pointer text-white text-sm font-normal border rounded-lg bg-primary-600">
                Add
              </div>
            )}
          </div>
          <div
           onClick={onViewDetails}
            className="text-center items-center w-1/2 py-1 cursor-pointer text-primary-600 text-sm font-normal border border-gray-500 rounded-lg bg-white  "
          >
            View details
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ProductCard;
