import { Modal, OfficeButton, toast } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useProduct } from 'pages/products/hooks/use-products';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateProductInventory } from './hooks/use-update-inventory';
import UpdateInventoryForm from './forms/update-inventory-form';
import { useEffect } from 'react';
import { ProductStatus } from '_graphql/__generated__/globalTypes';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const UpdateProductInventory = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();
  const { loading: updating, updateProductInventory } =
    useUpdateProductInventory();

  const { product, loading } = useProduct({
    variables: {
      filter: seacrhParams?.id
        ? {
          id: {
            eq: seacrhParams?.id,
          },
        }
        : undefined,
    },
  });

  useEffect(() => {
    if (product) {
      form.setValues({
        availabiltyRadio: product?.limited_stock ? 'limited' : 'unlimited',
        qty: `${product?.qty}`,
      });
    }
  }, [loading]);
  const { ...form } = useFormik({
    initialValues: {
      availabiltyRadio: 'limited',
      qty: '',
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values) => {
      await updateProductInventory({
        variables: {
          input: {
            product_id: product?.id || '',
            limited_stock: values.availabiltyRadio === 'limited',
            qty:
              values.availabiltyRadio === 'limited'
                ? Number(values.qty)
                : 1000000,
            published:false,
            verified:false,
            personnel:"Supplier",
            reason:"N/A",
            status:ProductStatus.PENDING,
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateProductInventory) {
            toast('Product Category Updated Successfully', 'success');
            form.resetForm();
            navigate({
              search(prev) {
                return {...prev, modal:undefined, id:undefined}
              },
            });
          } else {
            toast('Something went wrong', 'error');
          }
        })
        .catch((err) => {
          toast(err.message, 'error');
        });
    },
  });
  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title='Update Product'
      description='Update Product Category'
      renderActions={() => (
        <div className='md:ml-10'>
          <OfficeButton
            onClick={form.handleSubmit}
            size='lg'
            width='auto'
            disabled={updating}
            type='submit'>
            {updating ? 'Updating...' : 'Update'}
          </OfficeButton>
        </div>
      )}>
      <UpdateInventoryForm form={form} product={product} />
    </Modal>
  );
};

export default UpdateProductInventory;
