import { useMutation } from '@apollo/client';
import {
  UpdateProductInventory,
  UpdateProductInventoryVariables,
} from '_graphql/mutation/__generated__/UpdateProductInventory';
import { UPDATE_PRODUCT_INVENTORY } from '_graphql/mutation/products';

export const useUpdateProductInventory = (
  variables?: UpdateProductInventoryVariables
) => {
  const [updateProductInventory, { ...rest }] = useMutation<
    UpdateProductInventory,
    UpdateProductInventoryVariables
  >(UPDATE_PRODUCT_INVENTORY, {
    variables,
    refetchQueries: ['GetProduct'],
  });
  return {
    ...rest,
    updateProductInventory,
  };
};
