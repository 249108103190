import { FormikProps } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import lodash from 'lodash';
import { read, utils } from 'xlsx';
import _ from 'lodash';
import { Disclosure } from '@headlessui/react';
import {
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { SelectInput } from 'components';
import { classNames, wrapClick } from 'utils';

interface OfferFormProps {
  form: FormikProps<any>;
}

const OfferForm: FC<OfferFormProps> = ({ form }) => {
  const [columns, setColumns] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [showFinal, setShowFinal] = useState<boolean>(false);
  const [showSelectors, setShowSelectors] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const processData = (dataString: any) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setData(list);
    const initiations = list.map((el) => {
      const item = lodash.keys(form.values)?.map((column) => {
        const obj: any = {};
        const keyValue = lodash.get(form.values, column);
        obj[column] = el[keyValue];
        return obj;
      });
      return lodash.merge({}, ...item);
    });
    form.setFieldValue('rows', initiations);
    setColumns(headers);
    setLoadingFile(false);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    setLoadingFile(true);
    // Do something with the files
    const file = acceptedFiles[0];
    // console.log("---FILE---", file)
    // setMembers(file)
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt?.target?.result;
      const wb = read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = utils.sheet_to_csv(ws, {});
      processData(data);
      setShowSelectors(true);
    };
    reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'text/xlsx': ['.xlsx'],
      'text/xls': ['.xls'],
      'text/json': ['.json'],
    },
  });

  useEffect(() => {
    const rows = data.map((el: any) => {
      const item = lodash.keys(form.values.columns)?.map((column) => {
        const obj: any = {};
        const keyValue = lodash.get(form.values.columns, column);
        obj[column] = el[keyValue];
        return obj;
      });
      return lodash.merge({}, ...item);
    });
    form.setFieldValue('rows', rows);
    return () => {};
  }, [form?.values.columns, data]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden no-scrollbar">
      <div className="space-y-6 divide-y divide-gray-200 flex-1 overflow-y-auto no-scrollbar">
        {loadingFile && (
          <div>
            <div className="flex items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin transform -ml-1 mr-3 h-5 w-5 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.003 8.003 0 0112 4.472v3.09a4.001 4.001 0 00-4 3.999H6zm2 5.238a8.015 8.015 0 01-3.528-2.528h3.528v2.528zm5.238 2a8.015 8.015 0 01-2.528-3.528v3.528h2.528z"
                    ></path>
                  </svg>
                </div>
                <p className="mt-2 text-sm font-medium text-gray-900">
                  Loading file...
                </p>
              </div>
            </div>
          </div>
        )}
        {!showSelectors && (
          <div className="">
            <div className="rounded-md 0 bg-red-100 p-4 px-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-11 w-11 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Please pay attention to instructions below
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul className="list-disc space-y-1 pl-5">
                      <li>The file uploaded must be in CSV or Excel format.</li>
                      <li>
                        The file must contain the following columns:{' '}
                        <span className="font-semibold">
                           SKU, PRICE, COST PRICE, QUANTITY
                        </span>
                      </li>
                      <li>
                        The first row of the file must contain the column names
                      </li>
                      <li>The file must contain at least one row of data</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <span className="text-xs font-light mt-5 mb-2 ">CSV Template</span>
            <div className="w-full mb-5 h-10 border flex justify-between items-center px-5 text-sm bg-slate-50 gap-5">
              <span>SKU</span>
              <span>PRICE</span>
              <span>COST PRICE</span>
              <span>QUANTITY</span>
            </div>
            <span className="text-xs font-light">Product List</span>
            <div className="grid grid-cols-3 gap-6 h-full">
              <button
                type="button"
                {...getRootProps()}
                className="relative block w-full h-full rounded-lg border-2 col-span-3 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No file selected
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Kindly select a file by clicking the button above.
                </p>
                <input
                  {...getInputProps()}
                  id="members"
                  name="members"
                  type="file"
                  className="sr-only"
                />
              </button>
            </div>
          </div>
        )}
        <>
          {showSelectors && (
            <div className="w-full  border border-primary-400 rounded-md bg-primary-50 border-dashed my-2 py-4">
              <div className="flex justify-between px-4">
                <div>
                  <span className="text-primary-700">
                    Select the columns that correspond to the product details
                    below
                  </span>
                </div>
                <div>
                  <button
                    {...getRootProps()}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Upload new file
                    <input
                      {...getInputProps()}
                      id="members"
                      name="members"
                      type="file"
                      className="sr-only"
                    />
                  </button>
                  <button
                    onClick={wrapClick(() => setShowFinal(!showFinal))}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {showFinal ? 'Hide' : 'Show'} preview
                  </button>
                </div>
              </div>

              <div className="w-full flex justify-between p-3 items-end flex-1">
                <div className="grid grid-cols-4 gap-4 w-full">
                  <div>
                    <SelectInput
                      label="SKU"
                      id={'columns.sku'}
                      {...form}
                      options={columns
                        ?.filter((_c: any[]) => _c.length > 0)
                        ?.map((column: any, idx: number) => ({
                          label: column,
                          value: column,
                        }))}
                    />
                  </div>
                  <div>
                    <SelectInput
                      label="Price"
                      id={'columns.price'}
                      {...form}
                      options={columns
                        ?.filter((_c: any[]) => _c.length > 0)
                        ?.map((column: any, idx: number) => ({
                          label: column,
                          value: column,
                        }))}
                    />
                  </div>
                  <div>
                    <SelectInput
                      label="Cost Price"
                      id={'columns.cost_price'}
                      {...form}
                      options={columns
                        ?.filter((_c: any[]) => _c.length > 0)
                        ?.map((column: any, idx: number) => ({
                          label: column,
                          value: column,
                        }))}
                    />
                  </div>
                  <div>
                    <SelectInput
                      label="Quantity"
                      id={'columns.qty'}
                      {...form}
                      options={columns
                        ?.filter((_c: any[]) => _c.length > 0)
                        ?.map((column: any, idx: number) => ({
                          label: column,
                          value: column,
                        }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {JSON.stringify(form.values.rows, null, 2)} */}

          {_.isArray(form.errors.rows) && form.errors.rows.length > 0 && (
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-danger-100 px-4 py-2 text-left text-sm font-medium text-danger-900 hover:bg-danger-200 focus:outline-none focus-visible:ring focus-visible:ring-danger-500/75">
                    <span>Errors found in file</span>
                    <ChevronUpIcon
                      className={`${!open ? 'rotate-180 transform' : ''} h-5 w-5 text-danger-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 grid grid-cols-4 pt-4 text-sm text-gray-500">
                    {_.isArray(form.errors.rows) &&
                      form.errors.rows.length > 0 &&
                      form.errors.rows?.map((error: any, idx: number) => (
                        <div>
                          <b>Row: {idx + 1}</b>
                          {Object.values(error).map((err: any, key: number) => (
                            <p key={key} className="text-red-500">
                              {err}
                            </p>
                          ))}
                        </div>
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}

          {showFinal && (
            <table className="min-w-full divide-y divide-gray-200 overflow-x-scroll">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  {['SKU', 'Price', 'Cost Price', 'Quantity']?.map(
                    (column: any, idx: number) => (
                      <th
                        scope="col"
                        className={classNames(
                          idx === 0 ? 'sticky left-0 bg-gray-50' : '',
                          'px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider'
                        )}
                      >
                        {column}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((product: any, key: number) => {
                  return (
                    <tr key={key}>
                      {lodash.keys(form.values.columns)?.map((column) => {
                        const keyValue = lodash.get(
                          form.values.columns,
                          column
                        );
                        return (
                          <td
                            key={keyValue + key}
                            className={classNames(
                              'px-6 py-4 whitespace-nowrap text-sm ',
                              'text-gray-500'
                            )}
                          >
                            {product[keyValue] || 'N/A'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </>
      </div>
    </div>
  );
};

export default OfferForm;
