import { useMutation } from '@apollo/client';
import {
  CreateProduct,
  CreateProductVariables,
} from '_graphql/mutation/__generated__/CreateProduct';
import { CREATE_PRODUCT } from '_graphql/mutation/products';
import { getProducts } from '_graphql/queries/products';

export const useCreateProduct = (variables?: CreateProductVariables) => {
  const [productCreation, { ...rest }] = useMutation<
    CreateProduct,
    CreateProductVariables
  >(CREATE_PRODUCT, {
    variables,
    refetchQueries: ['GetProducts'],
  });
  return {
    ...rest,
    productCreation,
  };
};
