import { OfficeHeader } from 'components'
import { FC } from 'react'
import { Link } from 'react-location'
import { classNames, useUrlState } from 'utils'
import SettingsAccountTab from './tabs/account'
// import AppIntegration from './tabs/app-integration'

type Props = {}


const tabs = [
    // { name: "Account", href: "account" },
    // { name: "Notifications", href: "notifications" },
    // { name: "Billing", href: "billing" },
    // { name: "Teams", href: "teams" },
    // { name: "Payment Methods", href: "payment-methods" },
];

const SettingsPage: FC<Props> = (props) => {
    const [activeTab] = useUrlState("activeTab");
    return (
        <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
            <OfficeHeader />
            <div className="block">
                <div className="border-b border-gray-200 bg-white px-6">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        <Link
                            search={(old) => ({
                                ...old,
                                activeTab: undefined,
                            })}
                            className={classNames(
                                !activeTab
                                    ? "border-primary-500 text-primary-600"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={!activeTab ? "page" : undefined}
                        >
                            Account
                        </Link>
                        {/* {tabs.map((_activeTab) => (
                            <Link
                                key={_activeTab.name}
                                search={(old) => ({
                                    ...old,
                                    activeTab: _activeTab.href,
                                })}
                                className={classNames(
                                    activeTab === _activeTab.href
                                        ? "border-primary-500 text-primary-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    activeTab === _activeTab.href ? "page" : undefined
                                }
                            >
                                {_activeTab.name}
                            </Link>
                        ))} */}
                        {/* <div className="h-6 w-px bg-gray-300" /> */}
                    </nav>
                </div>
            </div>
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0   sm:py-6 overflow-hidden overflow-y-auto light flex">
                    {!activeTab && <SettingsAccountTab />}
                    {/* {activeTab === "integrations" && <AppIntegration />} */}
                </div>
            </div>
        </main>
    )
}

export default SettingsPage