import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { GetPartnership, GetPartnership_getPartnership } from "_graphql/queries/__generated__/GetPartnership";
import { TermsPicker } from "containers";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";
import { Link } from "react-location";

type Props = {
    partnership: GetPartnership_getPartnership | null | undefined
    form: FormikProps<any>
};

const ApprovePartnerForm: FC<Props> = ({ partnership, form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div className="rounded-md bg-sky-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-sky-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-sky-800">
                            Take note
                        </h3>
                        <div className="mt-2 text-sm text-sky-700">
                            <p>Hello Supplier, Just a friendly reminder: without clear terms and conditions in place, approving this partnership would not work</p>
                        </div>
                        <div className="mt-4">
                            <div className="-mx-2 -my-1.5 flex">
                                <Link
                                    to="/terms"
                                    search={{ modal: 'create' }}
                                    className="rounded-md bg-sky-50 px-2 py-1.5 text-sm font-medium text-sky-800 hover:bg-sky-100 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2 focus:ring-offset-sky-50"
                                >
                                    Create terms and conditions
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-6">
                <span className="text-xs font-light">Partnership Information</span>
                <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <TermsPicker
                            id="terms_id"
                            label="Terms and Conditions"
                            rawId
                            filter={{ supplierId: form.values.supplier_id }}
                            placeholder="e.g. Agreement with Emmanuel Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
            <div className="pt-6">
                <span className="text-xs font-light">Products Information</span>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                SKU
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Title
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Cost Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Selling Price
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {partnership?.products.map((product, idx) => (
                                            <tr key={idx}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {product.sku}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.title}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.cost_price}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApprovePartnerForm;
