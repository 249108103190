import { ProductStatus } from '_graphql/__generated__/globalTypes';
import { GetProducts_getProducts } from '_graphql/queries/__generated__/GetProducts';
import { toast } from 'components';
import { wrapImage } from 'utils';
import { TColumn, TableColumn } from 'utils/types';

export const ProductColumn: TableColumn<TColumn> = [
  {
    name: 'Image',
    accessor: 'image',
    render: (product: GetProducts_getProducts | null) => (
      <div className=" h-10 w-10 flex-shrink-0 text-xs">
        {wrapImage(
          <img
            className="h-10 w-10 object-contain rounded-md shadow-md"
            src={product?.avatar}
            alt=""
          />
        )}
      </div>
    ),
  },
  {
    name: ' Name',
    accessor: 'title',
    render: (product: GetProducts_getProducts | null) => (
      <div className="flex items-center max-w-lg break-all truncate text-xs">
        <div className=" break-all flex-wrap text-xs">
          <div className=" text-gray-900 truncate flex-wrap text-xs">
            {product?.title.toLocaleUpperCase()}
          </div>
          <div className="text-gray-500">{product?.sku}</div>
        </div>
      </div>
    ),
  },
  {
    name: 'Cost Price',
    accessor: 'cost_price',
    render: (product: GetProducts_getProducts | null) => (
      <>
        {product?.currency} {product?.supplier_cost || 'N/A'}
      </>
    ),
  },
  {
    name: 'Selling Price',
    accessor: 'price',
    render: (product: GetProducts_getProducts | null) => (
      <>
        {product?.currency} {product?.cost_price}
      </>
    ),
  },

  {
    name: 'Total Qty',
    accessor: 'qty',
    render: (product: GetProducts_getProducts) => (
      <span>
        {product?.limited_stock ? product.qty : <span> &infin;</span>}
      </span>
    ),
  },
  {
    name: 'Qty At Hand',
    accessor: 'qty',
    render: (product: GetProducts_getProducts) => (
      <span>{product?.main_stock}</span>
    ),
  },
  {
    name: 'Qty In Transit',
    accessor: 'qty',
    render: (product: GetProducts_getProducts) => (
      <span>{product?.transit_stock}</span>
    ),
  },
];
