import { useMutation } from '@apollo/client';
import {
  DeleteDiscount,
  DeleteDiscountVariables,
} from '_graphql/mutation/__generated__/DeleteDiscount';
import { deleteDiscount } from '_graphql/mutation/discount';
import { OfficeButton, PopUp, toast } from 'components';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const DeleteDiscountContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [deletion, { loading, ...rest }] = useMutation<
    DeleteDiscount,
    DeleteDiscountVariables
  >(deleteDiscount, {
    refetchQueries: ['GetDiscounts'],
  });

  const handleDelete = async () => {
    await deletion({
      variables: {
        deleteDiscountId: searchParams.id || '',
      },
    })
      .then(() => setOpen(false))
      .catch((err: any) => {
        toast(err?.message, 'error');
      });
  };

  return (
    <PopUp
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      title='Delete Discount'
      description='Confirm delete to remove discount item from all products with this discount.
                    This will lead to the removal of this discount from all products that were assigned to this discount,
                    as well as all orders and cart items can contain product with this discount.'
      renderActions={() => (
        <OfficeButton
          type='button'
          variant='danger'
          size='sm'
          disabled={loading}
          onClick={handleDelete}>
          {loading ? 'Loading ...' : 'Confirm'}
        </OfficeButton>
      )}
    />
  );
};

export default DeleteDiscountContainer;
