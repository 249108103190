import { useQuery, QueryHookOptions } from '@apollo/client';
import {
  GetProduct,
  GetProductVariables,
} from '_graphql/queries/__generated__/GetProduct';
import { GetProductHistory, GetProductHistoryVariables } from '_graphql/queries/__generated__/GetProductHistory';
import {
  GetProductTitle,
  GetProductTitleVariables,
} from '_graphql/queries/__generated__/GetProductTitle';
import {
  GetProducts,
  GetProductsVariables,
} from '_graphql/queries/__generated__/GetProducts';

import {
  getProduct,
  getProductHistory,
  getProductTitle,
  getProducts,
} from '_graphql/queries/products';

export const useProducts = (
  options?: QueryHookOptions<GetProducts, GetProductsVariables>
) => {
  const { data, ...rest } = useQuery<GetProducts, GetProductsVariables>(
    getProducts,
    options
  );

  return {
    products: data?.getProducts || [],
    count: data?.getProductsLength || 0,
    ...rest,
  };
};
export const useProduct = (
  options?: QueryHookOptions<GetProduct, GetProductVariables>
) => {
  const { data, ...rest } = useQuery<GetProduct, GetProductVariables>(
    getProduct,
    options
  );

  return {
    product: data?.getProduct,
    ...rest,
  };
};
export const useProductTitle = (
  options?: QueryHookOptions<GetProductTitle, GetProductTitleVariables>
) => {
  const { data, ...rest } = useQuery<GetProductTitle, GetProductTitleVariables>(
    getProductTitle,
    options
  );

  return {
    title: data?.getProduct?.title.concat(' - ').concat(data?.getProduct?.sku),
    ...rest,
  };
};


export const useProductHistory = (options?:QueryHookOptions<GetProductHistory, GetProductHistoryVariables>)=>{
  const {data, ...rest} = useQuery<GetProductHistory, GetProductHistoryVariables>(getProductHistory,options)

  return {
    history:data?.getProductHistory,
    ...rest
  }

}