import { TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

export interface PayoutFormProps {
    form: FormikProps<any>;
}

const PayoutForm: FC<PayoutFormProps> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <div className="grid grid-cols-1 gap-4">
                    <div className="">
                        <TextInput
                            id="amount"
                            label="Amount"
                            description="Amount to be withdrawn"
                            type="text"
                            placeholder="e.g. 10"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayoutForm;
