import { AvatarUpload, TextInput, TextArea, toast } from "components";
import { useFormik } from "formik";
import { useState, forwardRef, useImperativeHandle } from "react";
import * as Yup from "yup";
import { GetShop_getShop } from "_graphql/queries/__generated__/GetShop";
import { FC } from "react";
import { UPDATE_SUPPLIER_SHOP_DETAILS } from "_graphql/mutation/shop";
import { useMutation } from "@apollo/client"
import { useShop } from '../hooks/use-shop';
import { UpdateSupplierShopDetails,UpdateSupplierShopDetailsVariables } from "_graphql/mutation/__generated__/UpdateSupplierShopDetails";

type Props = {
  shop: GetShop_getShop | null | undefined;
  setOpen: (val: boolean) => void;
  refetch?:()=>void;
};

const EditShopModal: FC<Props & {ref?: any}> = forwardRef(({ shop,
  setOpen }, ref) => {

  const [update,{loading}] = useMutation<
  UpdateSupplierShopDetails,UpdateSupplierShopDetailsVariables>(UPDATE_SUPPLIER_SHOP_DETAILS);

  const { refetch } = useShop({
    filter:{
      id:{
        eq:shop?.id
      }
    }
      })

  const  editShopForm = useFormik({
    initialValues: {
      name: shop?.name,
      email: shop?.email,
      description:shop?.description,
       avatar: shop?.avatar,
      number:shop?.phone
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("First name is required"),
      email: Yup.string().required("Last name is required"),
      avatar: Yup.string().notRequired(),
      description: Yup.string().required(),
      number: Yup.string().required("Phone number is required")
    }),
    onSubmit: async (values) => {
      update({
        variables:{
          // input:{
            // shopId:shop?.id,
            data: {
            avatar: values.avatar,
            email: values.email,
            name: values.name,
            description: values.description,
            phone: values.number,
            shopId:shop?.id!
          // }
          }
        }
      }).then(({ data }) => {
        console.log(data);
        if (data?.updateSupplierShopDetails) {
          //editShopForm.resetForm();
          toast("Shop details successfully updated", "success");
          setOpen(false); 
          refetch();
        }
      });
    },
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: editShopForm.handleSubmit,
  }));

  return ( 
    <div className=" mx-16">
       <form className="md:col-span-2" onSubmit={editShopForm.handleSubmit}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full flex flex-col gap-x-8">
              {/* <img
                src={supplier?.avatar! || img}
                alt=""
                className="h-24 w-24 flex-none rounded-full  object-cover"
              /> */}
              <div className="mt-4">
                <AvatarUpload id={"avatar"}  {...editShopForm} />
                {/* <OfficeButton>Change avatar</OfficeButton> */}

                <p className="mt-2 text-xs leading-5 text-gray-600">
                  JPG, GIF or PNG. 1MB max.
                </p>
              </div>
            </div>

            <div className="sm:col-span-3">
              <TextInput
                id={"name"}
                label=" Name"
                {...editShopForm}
              />
            </div>

            <div className="sm:col-span-3">
              <TextInput
                id={"number"}
                label="Phone Number"
                {...editShopForm}
              />
            </div>

            <div className="col-span-full">
                            <TextInput id={'email'} placeholder='eg. ebaidoo79@gmail.com' label='Email' {...editShopForm} />
                        </div>
                        
            <div className="col-span-full">
                            <TextArea id={'description'} placeholder='eg. We deal in organic and inorganic foods.' label='Description' {...editShopForm} />
                        </div>

                        
          </div>

          {/* <div className="mt-8 flex">
            <OfficeButton
              disabled={!personalInfoForm.isValid || loading}
              onClick={personalInfoForm.handleSubmit}
            >
              {loading ? "Saving.." : " Save"}
            </OfficeButton>
          </div> */}
        </form>
    </div>
   );
})
 
export default EditShopModal;